import classNames from 'classnames';
import { FC } from 'react';
import { Row } from 'reactstrap';

import { TProductInstance } from '@lib/core/products/types';
import { useApp } from '@lib/core/service/hooks';
import { localeTEREA } from '@lib/tools/locale/source/pmi/terea';
import LocaleFragment from '@lib/tools/locale/views/LocaleFragment';
import { reOrderEssencesForIQOS4Designs, sortEssences } from '@lib/tools/shared/pmi/essences';
import { TEREA_ESSENCE_COOLING_ID, TEREA_EU } from '@lib/tools/shared/pmi/essences/consts';
import { retailerProductLocationTags } from '@lib/tools/shared/pmi/products';
import { SENTIA } from '@lib/tools/shared/pmi/retailers/consts';
import { useAddons, useEqualElementsHeightV2, useRetailerDesignSet } from '@lib/tools/views/hooks';

import EssencesProgressBar from '@components/pmi/src/designSets/terea/common/EUessenceComponent';

interface Props {
  retailerProductLocation: TProductInstance;
  retailerTags: Record<string, string>;
  isProductShortDescriptionHidden?: boolean;
  isEUessencesPresented?: boolean;
}

const ProductPortfolioCard: FC<Props> = ({
  retailerProductLocation,
  isProductShortDescriptionHidden,
  retailerTags,
  isEUessencesPresented = false,
}) => {
  const { isLayoutRightToLeft } = useApp();

  const { isDesignSetTasteAdvisorIQOS4Global, isDesignSetTasteAdvisorIQOS4One } = useRetailerDesignSet();

  const { essences: productEssences, attributes, name, caption, aromas, category } = retailerProductLocation.product;

  const {
    isMentholDisclaimerAddon,
    isAromaNotesDisabledAddon,
    isRestrictedResultPageAddon,
    isEssenceBodyDisabledAddon,
    isEssenceAromaDisabledAddon,
    isEssenceCoolingDisabledAddon,
    isTobaccoExperienceHiddenAddon,
    isTereaEssenceSortAddon,
    isTereaEssenceOrderTopDownAddon,
    isTereaHideEssencesAddon,
    isAromaNoteSecondaryAddon,
  } = useAddons();

  const {
    color_primary: colorPrimary,
    color_secondary: colorSecondary,
    product_type: productCategory,
    portfolio_image: portfolioImage,
    portfolio_image_two: portfolioImageTwo,
    is_pearl: isPearl,
    is_dimensions: isDimension,
    pistil_image: pistilImage,
    product_type: productType,
  } = attributes;

  const isSentiaProduct = productType === SENTIA;

  const { isEuClassicTobaccoTag, isEuClassicTobaccoEssencesTag } = retailerProductLocationTags(retailerProductLocation);

  let essences = productEssences;
  if (isEuClassicTobaccoTag && isEuClassicTobaccoEssencesTag) {
    essences = productEssences.filter(essence => essence.group === TEREA_EU);
  } else {
    essences = reOrderEssencesForIQOS4Designs({
      essences: productEssences,
      isEssenceAromaDisabled: isEssenceAromaDisabledAddon,
      isEssenceBodyDisabled: isEssenceBodyDisabledAddon,
      isEssenceCoolingDisabled: isEssenceCoolingDisabledAddon,
    });
  }
  const coolingEssence = essences.find(essence => essence.essence_id === TEREA_ESSENCE_COOLING_ID);
  const isCooling = coolingEssence && Number(coolingEssence.score) > 0 && isMentholDisclaimerAddon;

  if (isTereaEssenceSortAddon && typeof retailerTags !== 'string' && retailerTags?.essences_sort_order?.[category]) {
    essences = sortEssences(essences, retailerTags?.essences_sort_order?.[category]);
  }

  let currentColor = '';
  if (isDesignSetTasteAdvisorIQOS4Global && isDimension !== 'true') {
    currentColor = colorPrimary;
  }
  if (!isDesignSetTasteAdvisorIQOS4Global || isDimension === 'true') {
    currentColor = colorSecondary;
  }

  useEqualElementsHeightV2([
    'iqos4-portfolio-card-title-ref',
    'iqos4-portfolio-card-desc-ref',
    'iqos4-portfolio-card-essences-and-aroma-note-ref',
    'iqos4-portfolio-card-essences-ref',
    'iqos4-portfolio-card-aroma-note-ref',
    'iqos4-portfolio-card-sentia-title-ref',
    'iqos4-portfolio-card-sentia-desc-ref',
    'iqos4-portfolio-card-sentia-essences-and-aroma-note-ref',
    'iqos4-portfolio-card-sentia-essences-ref',
    'iqos4-portfolio-card-sentia-aroma-note-ref',
  ]);

  return (
    <div className="taste-advisor-4-product-portfolio-card d-flex flex-column">
      <img
        alt="product_image"
        src={isRestrictedResultPageAddon ? portfolioImageTwo : portfolioImage}
        className={classNames('portfolio-card-img', {
          'dimensions-img': isDimension === 'true',
          'global-img': isDesignSetTasteAdvisorIQOS4Global,
        })}
      />
      <span className="pc-name ta-jp-p5-2">
        {isDimension === 'true' ? <LocaleFragment message={localeTEREA.product.dimensions} /> : productCategory}
      </span>
      <div
        className={`${
          isSentiaProduct ? 'iqos4-portfolio-card-sentia-title-ref' : 'iqos4-portfolio-card-title-ref'
        } product-title ta-jp-p1 ta-jp-mob-p1 font-weight-md-bold`}
      >
        {name}
      </div>

      <div className={`${isSentiaProduct ? 'iqos4-portfolio-card-sentia-desc-ref' : 'iqos4-portfolio-card-desc-ref'}`}>
        {!isProductShortDescriptionHidden && (
          <div
            className="mb-1 mb-md-0 font-weight-bold ta-jp-p3 ta-jp-mob-p3-bold w-md-50 mx-auto"
            style={{
              color: isDesignSetTasteAdvisorIQOS4Global && isDimension !== 'true' ? colorPrimary : colorSecondary,
            }}
          >
            <span>{`${caption}${isCooling ? '*' : ''}`}</span>
          </div>
        )}

        <div
          className={classNames('ta-jp-p5-2 text-capitalize margin-top-minus5px', {
            'opacity-0': isTobaccoExperienceHiddenAddon,
          })}
        >
          {isPearl === 'true' ? (
            <LocaleFragment message={localeTEREA.product.tobaccoPearl} />
          ) : (
            <LocaleFragment message={localeTEREA.product.tobacco} />
          )}
        </div>
      </div>
      <div
        className={`d-flex flex-column ${
          isSentiaProduct
            ? 'iqos4-portfolio-card-senria-essences-and-aroma-note-ref'
            : 'iqos4-portfolio-card-essences-and-aroma-note-ref'
        } ${isEUessencesPresented ? 'justify-content-between' : ''}`}
      >
        {isEuClassicTobaccoEssencesTag && isEuClassicTobaccoTag ? (
          <EssencesProgressBar
            isItInPortfolioCardTerea
            className="margin-top-10px"
            color={currentColor}
            name={essences?.[0].name}
            score={parseInt(essences?.[0].score, 10)}
          />
        ) : (
          <>
            <div
              className={classNames('essences-wrapper mx-auto text-left', {
                'essences-wrapper-top-down-order': isTereaEssenceOrderTopDownAddon,
                'iqos4-portfolio-card-essences-ref': !isSentiaProduct,
                'iqos4-portfolio-card-sentia-essences-ref': isSentiaProduct,
                'opacity-0': isTereaHideEssencesAddon && productCategory !== SENTIA,
              })}
            >
              {essences.map(essence =>
                parseInt(essence.score, 10) > 0 ? (
                  <div key={essence.essence_id} className="py-0 item">
                    <div
                      className={classNames('ta-jp-p5-2 text-capitalize item-text', {
                        'rtl-orientation': isLayoutRightToLeft,
                      })}
                    >
                      {essence.name}
                    </div>
                    <div className="essences-score-wrapper">
                      {new Array(5).fill(null).map((_, index) => {
                        let currentBackgroundColor = '';
                        if (
                          parseInt(essence.score, 10) >= index + 1 &&
                          isDesignSetTasteAdvisorIQOS4Global &&
                          isDimension !== 'true'
                        ) {
                          currentBackgroundColor = colorPrimary;
                        }
                        if (
                          parseInt(essence.score, 10) >= index + 1 &&
                          (!isDesignSetTasteAdvisorIQOS4Global || isDimension === 'true')
                        ) {
                          currentBackgroundColor = colorSecondary;
                        }

                        let currentBorderColor = '';
                        if (
                          parseInt(essence.score, 10) < index + 1 &&
                          isDesignSetTasteAdvisorIQOS4Global &&
                          isDimension !== 'true'
                        ) {
                          currentBorderColor = colorPrimary;
                        }
                        if (
                          parseInt(essence.score, 10) < index + 1 &&
                          (!isDesignSetTasteAdvisorIQOS4Global || isDimension === 'true')
                        ) {
                          currentBorderColor = colorSecondary;
                        }
                        return (
                          <div
                            key={index}
                            className={classNames('essences-score', {
                              'essences-border': parseInt(essence.score, 10) < index + 1,
                              'ltr-orientation': !isLayoutRightToLeft,
                              'rtl-orientation': isLayoutRightToLeft,
                            })}
                            style={{
                              backgroundColor: currentBackgroundColor,
                              borderColor: currentBorderColor,
                            }}
                          />
                        );
                      })}
                    </div>
                  </div>
                ) : null,
              )}
            </div>

            {!isAromaNotesDisabledAddon && productCategory !== SENTIA && (
              <Row
                className={`${
                  isSentiaProduct ? 'iqos4-portfolio-card-sentia-aroma-note-ref' : 'iqos4-portfolio-card-aroma-note-ref'
                } justify-content-start align-items-center mx-0 flex-nowrap`}
              >
                <div
                  style={{ backgroundColor: isDesignSetTasteAdvisorIQOS4One ? 'inherit' : colorPrimary }}
                  className={classNames('aroma-note', {
                    'japan-size': isDesignSetTasteAdvisorIQOS4One,
                    'ltr-orientation': !isLayoutRightToLeft,
                    'rtl-orientation': isLayoutRightToLeft,
                  })}
                >
                  <img
                    alt="aroma_note_icon"
                    className={`${isDesignSetTasteAdvisorIQOS4One ? 'japan-img-style' : 'global-img-style'}`}
                    src={
                      isAromaNoteSecondaryAddon && aromas?.[0]?.secondary_image
                        ? aromas?.[0]?.secondary_image
                        : aromas?.[0]?.image
                    }
                  />
                </div>
                <div className="d-flex flex-column justify-content-center aroma-info">
                  <div className="ta-jp-p4 ta-jp-mob-p4 font-weight-normal">
                    {isPearl === 'true' || pistilImage ? (
                      <LocaleFragment message={localeTEREA.product.onAClick} />
                    ) : (
                      <LocaleFragment message={localeTEREA.product.aromaNote} />
                    )}
                  </div>
                  <div
                    className="ta-jp-p4-bold ta-jp-mob-p4"
                    style={{ color: isDesignSetTasteAdvisorIQOS4Global ? colorPrimary : colorSecondary }}
                  >
                    {aromas[0]?.description}
                  </div>
                </div>
              </Row>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default ProductPortfolioCard;
