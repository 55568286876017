import classNames from 'classnames';
import { FC } from 'react';

interface Props {
  className?: string;
  onClickHandler: () => void;
}

const HomeButton: FC<Props> = ({ className, onClickHandler }) => (
  <button className={classNames('blends-gen-btn home-btn', className)} type="button" onClick={onClickHandler} />
);

export default HomeButton;
