import React from 'react';

import Question from '@components/pmi/src/designSets/blends/main/Question';
import Answers from '@components/pmi/src/designSets/blends/main/quiz/QDS9001/Answers';

interface Props {
  answers: Record<
    string,
    {
      image?: string;
      text: string;
    }
  >[];
  questionText: string;
  progressPercent: number;
  onSelect: (idAnswer: string) => void;
  goBack: () => void;
  goHome: () => void;
  showBackButton: boolean;
}

const QuizPage: React.FC<Props> = ({
  questionText,
  answers,
  progressPercent,
  onSelect,
  goBack,
  showBackButton,
  goHome,
}) => (
  <div className="blends-qds8001">
    <Question
      goBack={goBack}
      goHome={goHome}
      progressPercent={progressPercent}
      showBackButton={showBackButton}
      text={questionText}
    />
    <Answers answers={answers} onSelect={onSelect} />
  </div>
);

export default QuizPage;
