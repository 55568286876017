import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { selectRetailerLanguages } from '@lib/core/retailers/selectors/retailer';
import { selectProductCategories } from '@lib/core/retailers/selectors/retailerLocation';
import { PRODUCT_CATEGORY_NONE } from '@lib/core/service/consts';
import { useApp } from '@lib/core/service/hooks';
import { setProductCategory } from '@lib/core/service/slices';
import RouteUtils from '@lib/tools/routes';

import ProductCategorySelectorComponent from '@components/pmi/src/ProductCategorySelector_v2';

const ProductCategorySelectorContainer = () => {
  const dispatch = useDispatch();
  const productCategories = useSelector(selectProductCategories);
  const retailerLanguages = useSelector(selectRetailerLanguages);

  const handleSelectProductCategory = productCategoryName => {
    const supportedProductCategories = productCategories;
    const currentProductCategory = supportedProductCategories.find(product => product === productCategoryName);

    if (currentProductCategory) {
      dispatch(setProductCategory(currentProductCategory));
    }
  };

  const { route } = useApp();
  useEffect(() => {
    if (RouteUtils.getProductCategory()) {
      dispatch(setProductCategory(PRODUCT_CATEGORY_NONE));
    }
  }, [route]);

  return (
    <ProductCategorySelectorComponent
      handleSelectProductCategory={handleSelectProductCategory}
      productCategories={productCategories}
      supportedLanguages={retailerLanguages}
    />
  );
};

export default ProductCategorySelectorContainer;
