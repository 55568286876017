/* eslint-disable no-underscore-dangle */
import { FC, useEffect, useRef } from 'react';

import wheel from '@app/pmi/src/assets/media/crafted/Wheel.png';

import { TQuizAnswerData } from '@lib/core/quizzes/types';
import { localeCrafted } from '@lib/tools/locale/source/pmi/crafted';
import LocaleFragment from '@lib/tools/locale/views/LocaleFragment';

import { Propeller, initPropeller } from '@components/pmi/src/designSets/crafted/Components/Gears/utils';

interface IGearComponent {
  elementId: string;
  gearOrder: number;
  quizAnswers: TQuizAnswerData[];
  onClickQuizAnswer: (quizAnswerId: string, gearIndex) => void;
  isResetResult: boolean;
  selectedAnswerId: string;
}

const GearComponent: FC<IGearComponent> = ({
  isResetResult,
  selectedAnswerId,
  gearOrder,
  elementId,
  quizAnswers,
  onClickQuizAnswer,
}) => {
  const gearRef = useRef<any>(null);

  useEffect(() => {
    initPropeller(window);
    const gearElement = document.getElementById(elementId);
    // @ts-ignore
    if (gearElement && window.Propeller) {
      gearRef.current = new Propeller(gearElement, {
        angle: 0,
        speed: 15,
        step: 90,
        stepTransitionTime: 150,
      });

      gearRef.current.onDragStart = () => {};

      gearRef.current.onDragStop = () => {
        gearRef.current._angle = ((gearRef.current._angle / 90) % (360 / 90)) * 90;
        const gearAngle = gearRef.current._angle;
        if (gearAngle === 90 || gearAngle === -270) {
          onClickQuizAnswer(Object.keys(quizAnswers?.[2])?.[0], gearOrder);
        } else if (gearAngle === -90 || gearAngle === 270) {
          onClickQuizAnswer(Object.keys(quizAnswers?.[0])?.[0], gearOrder);
        } else if (gearAngle === 180 || gearAngle === -180) {
          onClickQuizAnswer(Object.keys(quizAnswers?.[1])?.[0], gearOrder);
        } else {
          onClickQuizAnswer('', gearOrder);
        }
      };
    }

    // Cleanup on unmount
    return () => {
      if (gearRef.current) {
        // Unbind listeners or perform cleanup if needed
        gearRef.current.unbind();
      }
    };
  }, []);

  useEffect(() => {
    if (isResetResult) {
      onClickQuizAnswer('', gearOrder);
      gearRef.current._angle = 0;
      gearRef.current.virtualAngle = 0;
    }
  }, [isResetResult]);

  if (!quizAnswers) return null;
  return (
    <div className={`${elementId} gear-wrapper`} id={elementId} style={{ backgroundImage: `url(${wheel})` }}>
      <div className="text-select">
        <button
          onClick={() => {
            onClickQuizAnswer('', gearOrder);
            gearRef.current._angle = 0;
            gearRef.current.virtualAngle = 0;
          }}
        >
          <LocaleFragment message={localeCrafted.quiz.rotate} />
        </button>
      </div>

      <div className="text-first">
        <button
          className={`${selectedAnswerId === Object.keys(quizAnswers?.[0])?.[0] && 'bold'}`}
          onClick={() => {
            onClickQuizAnswer(Object.keys(quizAnswers?.[0])?.[0], gearOrder);

            gearRef.current._angle = -90;
            gearRef.current.virtualAngle = -90;
          }}
        >
          {Object.values(Object.values(quizAnswers)?.[0])?.[0].text}
        </button>
      </div>
      <div className="text-second">
        <button
          className={`${selectedAnswerId === Object.keys(quizAnswers?.[1])?.[0] && 'bold'}`}
          onClick={() => {
            onClickQuizAnswer(Object.keys(quizAnswers?.[1])?.[0], gearOrder);

            gearRef.current._angle = 180;
            gearRef.current.virtualAngle = 180;
          }}
        >
          {Object.values(Object.values(quizAnswers)?.[1])?.[0]?.text}
        </button>
      </div>
      <div className="text-third">
        <button
          className={`${selectedAnswerId === Object.keys(quizAnswers?.[2])?.[0] && 'bold'}`}
          onClick={() => {
            onClickQuizAnswer(Object.keys(quizAnswers?.[2])?.[0], gearOrder);

            gearRef.current._angle = 90;
            gearRef.current.virtualAngle = 90;
          }}
        >
          {Object.values(Object.values(quizAnswers)?.[2])?.[0]?.text}
        </button>
      </div>
    </div>
  );
};

export default GearComponent;
