import { FC } from 'react';
import { Col, Row } from 'reactstrap';

import { localeLegacy } from '@lib/tools/locale/source/pmi/legacy';
import LocaleFragment from '@lib/tools/locale/views/LocaleFragment';

import AnswerItem from '@components/pmi/src/designSets/blends/main/quiz/QDS9001/AnswerItem';

interface Props {
  answers: Record<
    string,
    {
      image?: string;
      text: string;
    }
  >[];
  onSelect: (id: string) => void;
}

const Answers: FC<Props> = ({ answers, onSelect }) => (
  <Col className="answers-section">
    <Row>
      <Col className="ta-veev-p2 text-center text-veev-color">
        <LocaleFragment message={localeLegacy.blends.quizPickOneAnswer} />
      </Col>
    </Row>
    <Row className="justify-content-center">
      <Row className="answers-container">
        {answers.map(answer => {
          const answerId = Object.keys(answer)[0];
          return (
            <AnswerItem
              key={answerId}
              id={answerId}
              imageUrl={answer[answerId].image}
              text={answer[answerId].text}
              onSelect={id => onSelect(id)}
            />
          );
        })}
      </Row>
    </Row>
  </Col>
);

export default Answers;
