import { FC, Fragment } from 'react';
import { useDispatch } from 'react-redux';
import { Col, Row } from 'reactstrap';

import { TProductInstance } from '@lib/core/products/types';
import { store } from '@lib/core/service/store';
import { localeLegacy } from '@lib/tools/locale/source/pmi/legacy';
import LocaleFragment from '@lib/tools/locale/views/LocaleFragment';
import { sortProductsByPortfolioOrder } from '@lib/tools/shared/pmi/products';
import { useAddons } from '@lib/tools/views/hooks';

import MentholDisclaimer from '@components/pmi/src/designSets/addOns/common/MentholDisclaimer';
import ProductPortfolioCard from '@components/pmi/src/designSets/heets/main/one/ProductPortfolioCard';
import { isOpenIntegration, navigateHome } from '@components/pmi/src/utils';
import { MAX_NUMBER_OF_CARDS } from '@components/pmi/src/utils/consts';

interface Props {
  gprls: TProductInstance[];
}

const ProductPortfolio: FC<Props> = ({ gprls }) => {
  const nonDimensionsTAProductsList = sortProductsByPortfolioOrder(gprls, false);
  const dimensionsTAProductsList = sortProductsByPortfolioOrder(gprls, true);
  const { isMentholDisclaimerAddon } = useAddons();
  const dispatch = useDispatch();

  return (
    <Fragment>
      <Row
        className={`cards-wrapper cards-length-${nonDimensionsTAProductsList.length} ${
          nonDimensionsTAProductsList.length > MAX_NUMBER_OF_CARDS ? 'd-flex' : 'd-grid'
        }`}
      >
        {nonDimensionsTAProductsList.map((retailerProductLocation, index) => (
          <ProductPortfolioCard key={index} cardNumber={index + 1} retailerProductLocation={retailerProductLocation} />
        ))}
      </Row>
      {dimensionsTAProductsList.length > 0 ? (
        <Row className="mx-0">
          <Col className="px-0">
            <Row className="text-center mx-0 my-5">
              {store.getState().retailer?.data.tags?.special_heets_logo ? (
                <img
                  alt="HEETS"
                  className="mx-auto logo-heets-dimensions"
                  src={store.getState().retailer?.data.tags?.special_heets_logo}
                />
              ) : null}
            </Row>
            <Row
              className={`cards-wrapper cards-length-${dimensionsTAProductsList.length} ${
                dimensionsTAProductsList.length > MAX_NUMBER_OF_CARDS ? 'd-flex' : 'd-grid'
              }`}
            >
              {dimensionsTAProductsList.map((retailerProductLocation, index) => (
                <ProductPortfolioCard
                  key={index}
                  cardNumber={index + 1}
                  retailerProductLocation={retailerProductLocation}
                />
              ))}
            </Row>
          </Col>
        </Row>
      ) : null}

      {isMentholDisclaimerAddon && (
        <div className="max-width-690px mx-auto margin-bottom-5px margin-top-15px padding-x-15px">
          <MentholDisclaimer />
        </div>
      )}
      {/* Start over */}
      {isOpenIntegration() ? null : (
        <Col className="px-0 d-flex flex-column align-items-center" xs={12}>
          <button className="start-over-btn ta-pmi3-p2_2" type="button" onClick={() => navigateHome(dispatch)}>
            <LocaleFragment message={localeLegacy.iqos3.resultTestStartOver} />
          </button>
        </Col>
      )}
    </Fragment>
  );
};

export default ProductPortfolio;
