import cn from 'classnames';
import { useEffect, useState } from 'react';

import { ReactComponent as ChangeIcon } from '@app/pmi/src/assets/media/V2/v2_change_white.svg';

import { localeV2 } from '@lib/tools/locale/source/pmi/v2';
import LocaleFragment, { ILocaleText } from '@lib/tools/locale/views/LocaleFragment';

import PopUp from '@components/pmi/src/designSetsV2/Components/Quiz/PopUp/PopUp';

interface Props {
  classname?: string;
  caption: string;
  essenceDescription: string;
  name: string;
  image: string;
  primaryColor: string;
  showEssenceLink: boolean;
  goChange: () => void;
  productCardLinkText: ILocaleText;
  isPearlProduct: boolean;
  isVeev?: boolean;
  isTobaccoExperienceHiddenAddon: boolean;
  isQDS8003?: boolean;
}
function ProductReferenceCard({
  classname = '',
  caption,
  essenceDescription,
  name,
  primaryColor,
  showEssenceLink,
  image,
  productCardLinkText,
  goChange,
  isPearlProduct,
  isVeev = false,
  isTobaccoExperienceHiddenAddon,
  isQDS8003 = false,
}: Props) {
  const [openPopup, setOpenPopup] = useState(false);
  const [productCardTextColor, setProductCardTextColor] = useState('');
  const handlePopup = () => {
    setOpenPopup(prev => !prev);
  };

  useEffect(() => {
    if (primaryColor.includes('linear-gradient')) {
      setProductCardTextColor('transparent');
    } else {
      setProductCardTextColor(primaryColor);
    }
  }, [primaryColor]);

  return (
    <>
      <PopUp
        essenceDescription={essenceDescription}
        essenceTitle={productCardLinkText}
        isOpen={openPopup}
        isQDS8003={isQDS8003}
        onClose={handlePopup}
      />
      <div className={`ta-v2-product-reference-card ${classname}`}>
        <div className="ta-v2-product-reference-card-left-block">
          <img alt="Product Reference Card" className="ta-v2-product-reference-card-left-block-image" src={image} />
          <div
            className={cn('ta-v2-product-reference-card-left-block-btn-cover', {
              isVeev,
            })}
          >
            <button className="ta-v2-product-reference-card-left-block-btn" onClick={goChange}>
              <span className="ta-v2-product-reference-card-left-block-btn-icon">
                <ChangeIcon />
              </span>
              <span className="font-ta-v2-global-regulatory-text-small">
                <LocaleFragment message={localeV2.quiz.productReferenceChange} />
              </span>
            </button>
          </div>
        </div>
        <div className="ta-v2-product-reference-card-right-block">
          <span className="ta-v2-product-reference-card-right-block-title font-ta-v2-super-paragraph-small">
            {name}
          </span>
          <span
            className="ta-v2-product-reference-card-right-block-caption"
            style={{ backgroundImage: primaryColor, color: productCardTextColor, fontWeight: 'bold' }}
          >
            {caption}
          </span>
          {!isTobaccoExperienceHiddenAddon ? (
            <span
              className="ta-v2-product-reference-card-right-block-description"
              style={{ backgroundImage: primaryColor, color: productCardTextColor, fontWeight: 'bold' }}
            >
              {isPearlProduct ? (
                <LocaleFragment message={localeV2.common.tobaccoExperiencePearl} />
              ) : (
                <LocaleFragment message={localeV2.common.tobaccoExperience} />
              )}
            </span>
          ) : null}
          {showEssenceLink && (
            <button
              className="ta-v2-product-reference-card-right-block-btn font-ta-v2-global-regulatory-text-small"
              onClick={handlePopup}
            >
              <LocaleFragment message={productCardLinkText} />
            </button>
          )}
        </div>
      </div>
    </>
  );
}

export default ProductReferenceCard;
