import classNames from 'classnames';
import React, { FC, Fragment, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Row } from 'reactstrap';

import arrowExpandImage from '@app/pmi/src/assets/media/legacy/icons/iqos_nbw_static_image_arrow_expand.png';

import { actionPostProductFeedback } from '@lib/core/products/slices/feedback';
import { TProduct } from '@lib/core/products/types';
import { selectUserQuizId } from '@lib/core/quizzes/selectors';
import { useApp } from '@lib/core/service/hooks';
import { localeLegacy } from '@lib/tools/locale/source/pmi/legacy';
import LocaleFragment from '@lib/tools/locale/views/LocaleFragment';
import { useAddons } from '@lib/tools/views/hooks';

interface IFeedbackTobaccoCards {
  handlePostFeedbackDispatched: (value: boolean) => void;
}

const FeedbackTobaccoCards: FC<IFeedbackTobaccoCards> = ({ handlePostFeedbackDispatched }) => {
  const [isLiked, setIsLiked] = useState<null | boolean>(null);
  const { isLayoutRightToLeft } = useApp();

  return (
    <Col>
      <div className="d-flex justify-content-center align-items-center span-wrapper">
        <span className="ta-pmi3-p5">
          <LocaleFragment message={localeLegacy.iqos3.resultFeedbackQuestion} />
        </span>
      </div>
      <Row className="mx-0 justify-content-center">
        <button
          type="button"
          className={classNames({
            rtl: isLayoutRightToLeft,
          })}
          onClick={() => {
            if (!isLiked) {
              handlePostFeedbackDispatched(true);
              setIsLiked(true);
            }
          }}
        >
          <div>
            <div
              className={classNames('imageLike mx-auto ta3', {
                selected: isLiked,
              })}
            />
            <span className="ta-pmi3-p3_1-bold accept-icon">
              <LocaleFragment message={localeLegacy.iqos3.resultLike} />
            </span>
          </div>
        </button>
        <button
          type="button"
          onClick={() => {
            if (isLiked === null || isLiked) {
              handlePostFeedbackDispatched(false);
              setIsLiked(false);
            }
          }}
        >
          <div>
            <div
              className={classNames('imageUnlike ta3 mx-auto', {
                selected: isLiked === false,
              })}
            />
            <span className="ta-pmi3-p3_1-bold accept-icon">
              <LocaleFragment message={localeLegacy.iqos3.resultDislike} />
            </span>
          </div>
        </button>
      </Row>
    </Col>
  );
};

interface Props {
  product: TProduct;
}

const Feedback: FC<Props> = ({ product }) => {
  const dispatch = useDispatch();
  const quizSessionId = useSelector(selectUserQuizId);
  const { isOpenFeedbackAddon } = useAddons();
  const [isFeedbackOpen, setIsFeedbackOpen] = useState(isOpenFeedbackAddon);
  const toggleShowFeedback = () => setIsFeedbackOpen(!isFeedbackOpen);

  const handlePostFeedbackDispatched = (value: boolean) => {
    dispatch(
      actionPostProductFeedback({
        metadata: {
          feedback: {
            liked: value,
            product: product.identifier,
          },
        },
        session: quizSessionId,
      }),
    );
  };

  return (
    <Fragment>
      <div className="feedback-taste-advisor-3">
        <div
          className={classNames('feedback', {
            'feedback-hidden': !isFeedbackOpen,
          })}
        >
          <FeedbackTobaccoCards handlePostFeedbackDispatched={handlePostFeedbackDispatched} />
        </div>
        {!isOpenFeedbackAddon && (
          <img
            alt="arrow expand img"
            role="presentation"
            src={arrowExpandImage}
            className={`mx-auto pointer width-15px ml-3 mr-3 border-none z-index-1 ${
              isFeedbackOpen ? 'mt-2' : 'rotate mt-0'
            } `}
            onClick={toggleShowFeedback}
            onKeyDown={toggleShowFeedback}
          />
        )}
      </div>
    </Fragment>
  );
};

export { Feedback as FeedbackWithoutMemo };
export default React.memo(Feedback);
