import classNames from 'classnames';
import React, { FC, useLayoutEffect, useRef, useState } from 'react';
import { Col, Row } from 'reactstrap';

import tobaccoRecommendationImage from '@app/pmi/src/assets/media/legacy/terea/tobacco_recommendation.png';

import { TProductInstance } from '@lib/core/products/types';
import { selectQuizViewAnswerTags } from '@lib/core/quizzes/selectors';
import { useApp } from '@lib/core/service/hooks';
import { store } from '@lib/core/service/store';
import { isTouchDevice } from '@lib/core/service/utils';
import { localeTEREA } from '@lib/tools/locale/source/pmi/terea';
import { languages } from '@lib/tools/locale/utils/consts';
import LocaleFragment from '@lib/tools/locale/views/LocaleFragment';
import { reOrderEssencesForIQOS4Designs, sortEssences } from '@lib/tools/shared/pmi/essences';
import { TEREA_ESSENCE_COOLING_ID, TEREA_EU } from '@lib/tools/shared/pmi/essences/consts';
import { retailerProductLocationTags } from '@lib/tools/shared/pmi/products';
import { isProductDimensions, isProductSentia } from '@lib/tools/shared/pmi/products/filters';
import {
  isQuizAnswerTagDissatisfied,
  isQuizAnswerTagLau,
  isQuizAnswerTagSatisfied,
} from '@lib/tools/shared/pmi/quizzes/filters';
import { SENTIA } from '@lib/tools/shared/pmi/retailers/consts';
import { useAddons, useEqualElementsHeightV2, useRetailerDesignSet } from '@lib/tools/views/hooks';

import EssencesProgressBar from '@components/pmi/src/designSets/terea/common/EUessenceComponent';
import Feedback from '@components/pmi/src/designSets/terea/common/FeedbackComponent';
import AromaNote from '@components/pmi/src/designSets/terea/main/one/AromaNote';
import CharacterVectorImage, {
  CharacterVectorImageBigThree,
  CharacterVectorImageThree,
} from '@components/pmi/src/designSets/terea/main/one/CharacterVectorImage';
import Essences from '@components/pmi/src/designSets/terea/main/one/Essences';

interface CharacterProductProps {
  gprl: TProductInstance;
  i: number;
  isProductLongDescriptionHidden?: boolean;
  isAromaNotesDisabled?: boolean;
  isProductShortDescriptionHidden?: boolean;
  isJapanLanguage: boolean;
  threeProductsRef?: any;
  retailerTags: Record<string, string>;
  isLayoutRightToLeft: boolean;
}

const CharacterProduct: FC<CharacterProductProps> = ({
  gprl,
  i,
  isProductLongDescriptionHidden,
  isProductShortDescriptionHidden,
  isJapanLanguage,
  retailerTags,
  isLayoutRightToLeft,
}) => {
  const {
    isMentholDisclaimerAddon,
    isEssenceBodyDisabledAddon,
    isEssenceAromaDisabledAddon,
    isAromaNotesDisabledAddon,
    isRestrictedResultPageAddon,
    isEssenceCoolingDisabledAddon,
    isTobaccoExperienceHiddenAddon,
    isTereaEssenceSortAddon,
  } = useAddons();

  const {
    essences: productEssences,
    name,
    slug,
    category,
    caption,
    description,
    attributes,
    aromas,
    identifier,
  } = gprl.product;
  const {
    recommendation_background: backgroundImage,
    product_type: type,
    color_primary: colorPrimary,
    color_secondary: colorSecondary,
    color_flower_light: colorFlowerLight,
    color_flower_dark: colorFlowerDark,
    color_flower_gradient_start: colorFlowerGradientStart,
    color_flower_gradient_stop: colorFlowerGradientStop,
    is_pearl: isPearl,
    pistil_image: pistilImage,
  } = attributes;

  const { isEuClassicTobaccoTag, isEuClassicTobaccoEssencesTag, isHighMenthol } = retailerProductLocationTags(gprl);

  const { isDesignSetTasteAdvisorIQOS4Global } = useRetailerDesignSet();

  let essences = productEssences;
  if (isEuClassicTobaccoTag && isEuClassicTobaccoEssencesTag) {
    essences = productEssences.filter(essence => essence.group === TEREA_EU);
  } else {
    essences = reOrderEssencesForIQOS4Designs({
      essences: productEssences,
      isEssenceAromaDisabled: isEssenceAromaDisabledAddon,
      isEssenceBodyDisabled: isEssenceBodyDisabledAddon,
      isEssenceCoolingDisabled: isEssenceCoolingDisabledAddon,
    });
  }
  const coolingEssence = essences.find(essence => essence.essence_id === TEREA_ESSENCE_COOLING_ID);
  const isCooling = Number(coolingEssence?.score) > 0 && isMentholDisclaimerAddon;

  if (isTereaEssenceSortAddon && typeof retailerTags !== 'string' && retailerTags?.essences_sort_order?.[category]) {
    essences = sortEssences(essences, retailerTags?.essences_sort_order?.[category]);
  }

  return (
    <Col
      key={identifier}
      xs={6}
      className={classNames('taste-advisor-4-one-character-product px-0', {
        'rtl-orientation-svg': isLayoutRightToLeft,
      })}
    >
      {isPearl !== 'true' ? (
        <CharacterVectorImage
          colorFlowerDark={colorFlowerDark}
          colorFlowerGradientStart={colorFlowerGradientStart}
          colorFlowerGradientStop={colorFlowerGradientStop}
          colorFlowerLight={colorFlowerLight}
          isHighMenthol={isHighMenthol}
          orientation={(i === 0 && !isLayoutRightToLeft) || (i === 1 && isLayoutRightToLeft) ? 'left' : 'right'}
          slug={slug}
        />
      ) : null}
      <Row className={`mx-0 decorator w-100 h-100 ${i ? 'flex-row' : 'flex-row-reverse'}`}>
        <Col className="h-100 col-wrapper d-flex flex-column" style={{ backgroundColor: colorPrimary }} xs={7} />
        <Col
          xs={5}
          className={classNames('h-100 bg-image px-0', {
            'left-pearl':
              (isPearl === 'true' && i === 0 && !isLayoutRightToLeft) ||
              (isPearl === 'true' && i === 1 && isLayoutRightToLeft),
            'right-pearl':
              (isPearl === 'true' && i === 1 && !isLayoutRightToLeft) ||
              (isPearl === 'true' && i === 0 && isLayoutRightToLeft),
          })}
        >
          <div className="h-100 w-100" style={{ backgroundImage: `url(${backgroundImage})` }} />
          {!isRestrictedResultPageAddon && isPearl !== 'true' && (
            <img
              alt=""
              src={tobaccoRecommendationImage}
              className={`bg-center-image ${
                (i === 0 && isLayoutRightToLeft) || (i === 1 && !isLayoutRightToLeft) ? 'left' : 'right'
              }`}
            />
          )}
          {pistilImage && (
            <img
              alt=""
              src={pistilImage}
              className={`bg-center-image-cover ${
                (i === 0 && isLayoutRightToLeft) || (i === 1 && !isLayoutRightToLeft) ? 'left' : 'right'
              }`}
            />
          )}
        </Col>
      </Row>
      <Row
        className={classNames('mx-0 content', {
          'justify-content-end': !i,
          'justify-content-start': i,
          'rtl-orientation': isLayoutRightToLeft,
        })}
      >
        <Col className="col-wrapper d-flex flex-column" xs={7}>
          <div className="ta-iqos4-global-p4-bold text-uppercase" style={{ color: colorSecondary }}>
            {category}
          </div>
          <div className="iqos4-product-card-title-ref" style={{ height: 'fit-content' }}>
            <h4
              className={classNames('ta-jp-mob-h4-bold mb-0 color-white', {
                'ta-jp-h4': isJapanLanguage,
                'ta-jp-h4-bold': !isJapanLanguage,
              })}
            >
              {name}
            </h4>
          </div>
          <img
            alt=""
            src={gprl.image}
            className={classNames('product-image', {
              'rtl-orientation': isLayoutRightToLeft,
            })}
          />
          {!isProductShortDescriptionHidden && (
            <div className="iqos4-product-card-caption-and-tobacco-ref">
              <p
                style={{ color: colorSecondary }}
                className={classNames('iqos4-product-card-caption-ref ta-jp-p3-bold-2 product-caption mb-0', {
                  japan: isJapanLanguage,
                })}
              >
                {`${caption}${isCooling ? '*' : ''}`}
              </p>
              <div
                className={classNames('iqos4-product-card-tobacco-experience-ref ta-jp-p4_2_2 color-white', {
                  'opacity-0': isTobaccoExperienceHiddenAddon,
                })}
              >
                {isPearl === 'true' ? (
                  <LocaleFragment message={localeTEREA.product.tobaccoPearl} />
                ) : (
                  <LocaleFragment message={localeTEREA.product.tobacco} />
                )}
              </div>
            </div>
          )}

          {!isProductLongDescriptionHidden && (
            <div className="iqos4-product-card-description-ref">
              <p
                className={classNames('ta-jp-p4_2_2 mb-0 mt-1 product-description color-white', {
                  'description-ds-global': isDesignSetTasteAdvisorIQOS4Global,
                })}
              >
                {description}
              </p>
            </div>
          )}
          <Row className="aroma-note-wrapper mx-0">
            <div className="iqos4-product-card-essences-and-aroma-note-ref aroma-note-wrapper-col px-0">
              {isEuClassicTobaccoEssencesTag && isEuClassicTobaccoTag ? (
                <EssencesProgressBar
                  isItInResultPage
                  color={colorSecondary}
                  name={essences?.[0].name}
                  score={parseInt(essences?.[0].score, 10)}
                />
              ) : (
                <>
                  {/* eslint-disable-next-line max-len */}
                  {aromas[0] && !isAromaNotesDisabledAddon && !isEuClassicTobaccoTag && type !== SENTIA ? (
                    <div className="iqos4-product-card-aroma-note-ref">
                      <AromaNote
                        aroma={aromas?.[0]}
                        aromaColor={colorSecondary}
                        isLayoutRightToLeft={isLayoutRightToLeft}
                        isPearl={isPearl}
                        pistilImage={pistilImage}
                      />
                    </div>
                  ) : (
                    <div className="iqos4-product-card-aroma-note-ref" />
                  )}
                  {essences.length ? (
                    <Essences
                      essenceColor={colorSecondary}
                      essences={essences}
                      isLayoutRightToLeft={isLayoutRightToLeft}
                      type={type}
                    />
                  ) : null}
                </>
              )}
            </div>
          </Row>
          <Row
            className={classNames('feedback-btns-wrapper mt-auto', {
              global: isDesignSetTasteAdvisorIQOS4Global,
            })}
          >
            <Feedback isLayoutRightToLeft={isLayoutRightToLeft} product={gprl.product} />
          </Row>
        </Col>
      </Row>
    </Col>
  );
};

const CharacterProductThree: FC<CharacterProductProps> = ({
  gprl,
  isProductLongDescriptionHidden,
  isProductShortDescriptionHidden,
  isJapanLanguage,
  threeProductsRef,
  retailerTags,
}) => {
  const {
    isMentholDisclaimerAddon,
    isEssenceBodyDisabledAddon,
    isEssenceAromaDisabledAddon,
    isAromaNotesDisabledAddon,
    isRestrictedResultPageAddon,
    isEssenceCoolingDisabledAddon,
    isTobaccoExperienceHiddenAddon,
    isTereaEssenceSortAddon,
  } = useAddons();
  const { isLayoutRightToLeft } = useApp();

  const {
    essences: productEssences,
    name,
    slug,
    caption,
    description,
    attributes,
    aromas,
    identifier,
    category,
  } = gprl.product;
  const {
    recommendation_background: backgroundImage,
    product_type: type,
    color_primary: colorPrimary,
    color_secondary: colorSecondary,
    color_flower_light: colorFlowerLight,
    color_flower_dark: colorFlowerDark,
    color_flower_gradient_start: colorFlowerGradientStart,
    color_flower_gradient_stop: colorFlowerGradientStop,
    is_pearl: isPearl,
    is_dimensions: isDimension,
    pistil_image: pistilImage,
  } = attributes;

  const { isDesignSetTasteAdvisorIQOS4Global } = useRetailerDesignSet();

  const { isEuClassicTobaccoTag, isEuClassicTobaccoEssencesTag, isHighMenthol } = retailerProductLocationTags(gprl);
  let essences = productEssences;
  if (isEuClassicTobaccoTag && isEuClassicTobaccoEssencesTag) {
    essences = productEssences.filter(essence => essence.group === TEREA_EU);
  } else {
    essences = reOrderEssencesForIQOS4Designs({
      essences: productEssences,
      isEssenceAromaDisabled: isEssenceAromaDisabledAddon,
      isEssenceBodyDisabled: isEssenceBodyDisabledAddon,
      isEssenceCoolingDisabled: isEssenceCoolingDisabledAddon,
    });
  }
  const coolingEssence = essences.find(essence => essence.essence_id === TEREA_ESSENCE_COOLING_ID);
  const isCooling = coolingEssence && Number(coolingEssence.score) > 0 && isMentholDisclaimerAddon;

  if (isTereaEssenceSortAddon && typeof retailerTags !== 'string' && retailerTags?.essences_sort_order?.[category]) {
    essences = sortEssences(essences, retailerTags?.essences_sort_order?.[category]);
  }

  return (
    <Col
      key={identifier}
      xs={4}
      className={classNames('taste-advisor-4-one-character-product isThreeProducts px-0', {
        'rtl-orientation': isLayoutRightToLeft,
      })}
    >
      <div ref={threeProductsRef}>
        {isPearl !== 'true' ? (
          <CharacterVectorImageThree
            colorFlowerDark={colorFlowerDark}
            colorFlowerGradientStart={colorFlowerGradientStart}
            colorFlowerGradientStop={colorFlowerGradientStop}
            colorFlowerLight={colorFlowerLight}
            isHighMenthol={isHighMenthol}
            orientation="right"
            slug={slug}
          />
        ) : null}
        <Row className="mx-0 decorator isThreeProducts w-100 h-100">
          <Col
            style={{ backgroundColor: colorPrimary }}
            className={classNames('left-part h-100 bg-image px-0 isThreeProducts', {
              'left-pearl': isPearl === 'true',
            })}
          >
            {isPearl !== 'true' ? (
              <CharacterVectorImageBigThree
                colorFlowerDark={colorFlowerDark}
                colorFlowerGradientStart={colorFlowerGradientStart}
                colorFlowerGradientStop={colorFlowerGradientStop}
                colorFlowerLight={colorFlowerLight}
                isHighMenthol={isHighMenthol}
                isSentia={type === SENTIA}
                orientation="right"
                slug={slug}
              />
            ) : null}
            <div className="h-100 w-100" style={{ backgroundImage: `url(${backgroundImage})` }} />
            {!isRestrictedResultPageAddon && isPearl !== 'true' && (
              <img alt="" className="bg-center-image right" src={tobaccoRecommendationImage} />
            )}
            {pistilImage && <img alt="" className="bg-center-image-cover" src={pistilImage} />}
          </Col>
          <Col className="right-part h-100 col-wrapper d-flex flex-column" style={{ backgroundColor: colorPrimary }} />
        </Row>
        <Row className="mx-0 content justify-content-end">
          <Col className="col-wrapper d-flex flex-column right-part">
            <div className="ta-jp-p5-bold-2 text-uppercase" style={{ color: colorSecondary }}>
              {isDimension === 'true' ? <LocaleFragment message={localeTEREA.product.dimensions} /> : type}
            </div>

            <div className="iqos4-product-card-title-ref" style={{ height: 'fit-content' }}>
              <h4 className="ta-jp-h4_2 color-white mb-0">{name}</h4>
            </div>
            <div
              className={classNames('product-image-wrapper', {
                'rtl-orientation': isLayoutRightToLeft,
              })}
            >
              <img
                alt=""
                src={gprl.image}
                className={classNames('product-image', {
                  'rtl-orientation': isLayoutRightToLeft,
                })}
              />
            </div>

            {!isProductShortDescriptionHidden && (
              <div className="iqos4-product-card-caption-and-tobacco-ref">
                <p
                  style={{ color: colorSecondary }}
                  className={classNames('iqos4-product-card-caption-ref ta-jp-p3-bold-2 product-caption mb-0', {
                    japan: isJapanLanguage,
                  })}
                >
                  {`${caption}${isCooling ? '*' : ''}`}
                </p>

                <div
                  className={classNames('iqos4-product-card-tobacco-experience-ref ta-jp-p4_2_2 color-white', {
                    'opacity-0': isTobaccoExperienceHiddenAddon,
                  })}
                >
                  {isPearl === 'true' ? (
                    <LocaleFragment message={localeTEREA.product.tobaccoPearl} />
                  ) : (
                    <LocaleFragment message={localeTEREA.product.tobacco} />
                  )}
                </div>
              </div>
            )}
            {!isProductLongDescriptionHidden && (
              <div className="iqos4-product-card-description-ref">
                <p
                  className={classNames('product-description color-white ta-jp-p4_2_2 mb-0', {
                    'description-ds-global': isDesignSetTasteAdvisorIQOS4Global,
                  })}
                >
                  {description}
                </p>
              </div>
            )}

            <Row className="aroma-note-wrapper mx-0">
              <div
                className={classNames('iqos4-product-card-essences-and-aroma-note-ref aroma-note-wrapper-col px-0', {
                  'rtl-orientation': isLayoutRightToLeft,
                })}
              >
                {isEuClassicTobaccoEssencesTag && isEuClassicTobaccoTag ? (
                  <EssencesProgressBar
                    isItInResultPage
                    color={colorSecondary}
                    name={essences?.[0].name}
                    score={parseInt(essences?.[0].score, 10)}
                  />
                ) : (
                  <>
                    {aromas[0] && !isAromaNotesDisabledAddon && !isEuClassicTobaccoTag && type !== SENTIA ? (
                      <div className="iqos4-product-card-aroma-note-ref">
                        <AromaNote
                          isThreeProducts
                          aroma={aromas?.[0]}
                          aromaColor={colorSecondary}
                          isPearl={isPearl}
                          pistilImage={pistilImage}
                          type={type}
                        />
                      </div>
                    ) : (
                      <div className="iqos4-product-card-aroma-note-ref" />
                    )}
                    {essences.length ? (
                      <Essences
                        essenceColor={colorSecondary}
                        essences={essences}
                        isLayoutRightToLeft={isLayoutRightToLeft}
                        type={type}
                      />
                    ) : null}
                  </>
                )}
              </div>
            </Row>
            <Row
              className={classNames('feedback-btns-wrapper mt-aut', {
                global: isDesignSetTasteAdvisorIQOS4Global,
              })}
            >
              <Feedback isThreeProducts isLayoutRightToLeft={isLayoutRightToLeft} product={gprl.product} />
            </Row>
          </Col>
        </Row>
      </div>
    </Col>
  );
};

interface Props {
  products: TProductInstance[];
  isProductLongDescriptionHidden?: boolean;
  isProductShortDescriptionHidden?: boolean;
  retailerTags: Record<string, string>;
}

const IQOS4OneCharacterProducts: FC<Props> = ({
  products: gprls,
  isProductLongDescriptionHidden,
  isProductShortDescriptionHidden,
  retailerTags,
}) => {
  const { locale } = useApp();
  const [activeSlide, setActiveSlide] = useState(0);
  const ref = useRef<any>(null);
  const scrollRef = useRef<any>(null);
  const { isLayoutRightToLeft } = useApp();

  const threeProductsRefs = [useRef(null), useRef(null), useRef(null)];

  const resizeCallback = () => {
    if (ref.current && window.innerWidth > 960) {
      ref.current.scrollIntoView({
        behavior: 'auto',
        block: 'center',
        inline: 'center',
      });
    }
  };

  useLayoutEffect(() => {
    resizeCallback();
    window.addEventListener('resize', resizeCallback);
    return () => {
      window.removeEventListener('resize', resizeCallback);
    };
  }, []);

  useLayoutEffect(() => {
    resizeCallback();
  }, gprls);

  const isJapanese = locale === languages.JAPANESE;
  const isThreeProducts = gprls.length >= 3;

  useEqualElementsHeightV2([
    'iqos4-product-card-title-ref',
    'iqos4-product-card-description-ref',
    'iqos4-product-card-aroma-note-ref',
    'iqos4-product-card-tobacco-experience-ref',
    'iqos4-product-card-caption-ref',
    'iqos4-product-card-caption-and-tobacco-ref',
    'iqos4-product-card-essences-and-aroma-note-ref',
  ]);

  const tags = selectQuizViewAnswerTags(store.getState());

  const isSentiaAvailable = gprls.some(isProductSentia);
  const isDimensionAvailable = gprls.some(isProductDimensions);

  const header = (
    <Row
      className={classNames('header-wrapper justify-content-center align-items-center text-center mx-auto', {
        isThreeProducts,
      })}
    >
      {/* IQOS4 LAS */}
      {(!tags.some(isQuizAnswerTagLau) || isDimensionAvailable) && (
        <LocaleFragment message={localeTEREA.result.yourTereaMatch} />
      )}
      {tags.some(isQuizAnswerTagLau) && isSentiaAvailable && !isDimensionAvailable && (
        <LocaleFragment message={localeTEREA.result.yourBestMatches} />
      )}
      <p className="mb-0 ta-jp-mob-p3_2 ta-jp-p8_3 w-100">
        {!isSentiaAvailable &&
          !isDimensionAvailable &&
          (tags.some(isQuizAnswerTagSatisfied) ? (
            <Row>
              <Col xs={6}>
                <div className={classNames('recommendation', 'mx-auto', { 'jap-text': isJapanese })}>
                  <div className="ta-jp-p1 ta-jp-mob-p5 text-left">
                    <LocaleFragment message={localeTEREA.result.theOneYouLove} />
                  </div>
                  <div
                    className={classNames('ta-jp-h4-bold', 'ta-jp-mob-p1-bold', 'text-right', 'margin-top-minus-6px', {
                      'reduced-font-size': isJapanese,
                    })}
                  >
                    <LocaleFragment message={localeTEREA.result.madeBetter} />
                  </div>
                </div>
              </Col>

              <Col className="w-50 mx-0 separator" xs={6}>
                <div className={classNames('recommendation', 'mx-auto', { 'jap-text': isJapanese })}>
                  <div className="ta-jp-p7 ta-jp-mob-p5 text-left">
                    <LocaleFragment message={localeTEREA.result.youMayAppreciate} />
                  </div>
                  <div
                    className={classNames('ta-jp-h4-bold', 'ta-jp-mob-p1-bold', 'margin-top-minus-6px', 'text-right', {
                      'reduced-font-size': isJapanese,
                    })}
                  >
                    <LocaleFragment message={localeTEREA.result.thisFlavour} />
                  </div>
                </div>
              </Col>
            </Row>
          ) : null)}
        {!isSentiaAvailable && !isDimensionAvailable && tags.some(isQuizAnswerTagDissatisfied) ? (
          <Row>
            <Col xs={6}>
              <div className={classNames('recommendation', 'mx-auto', { 'jap-text': isJapanese })}>
                <div className="ta-jp-p7 ta-jp-mob-p5 text-left">
                  <LocaleFragment message={localeTEREA.result.thisIsYour} />
                </div>
                <div
                  className={classNames('ta-jp-h4-bold', 'ta-jp-mob-p1-bold', 'margin-top-minus-6px', 'text-right', {
                    'reduced-font-size': isJapanese,
                  })}
                >
                  <LocaleFragment message={localeTEREA.result.betterMatch} />
                </div>
              </div>
            </Col>

            <Col className="w-50 mx-0 separator" xs={6}>
              <div className={classNames('recommendation', 'mx-auto', { 'jap-text': isJapanese })}>
                <div className="ta-jp-p7 ta-jp-mob-p5 text-left">
                  <LocaleFragment message={localeTEREA.result.theOneYouLove} />
                </div>
                <div
                  className={classNames('ta-jp-h4-bold', 'ta-jp-mob-p1-bold', 'margin-top-minus-6px', 'text-right', {
                    'reduced-font-size': isJapanese,
                  })}
                >
                  <LocaleFragment message={localeTEREA.result.madeBetter} />
                </div>
              </div>
            </Col>
          </Row>
        ) : null}
      </p>
    </Row>
  );

  if (gprls) {
    const handleScrollCharacterProducts = () => {
      const scrollX = scrollRef.current ? scrollRef.current.scrollLeft : 0;
      if (scrollX < 100) {
        setActiveSlide(0);
      } else if (scrollX >= 100 && scrollX <= 250) {
        setActiveSlide(1);
      } else {
        setActiveSlide(2);
      }
    };

    return (
      <Row className="mx-auto taste-advisor-4-one-character-products-wrapper">
        <Col className="px-0">
          {isThreeProducts && header}
          <div
            ref={scrollRef}
            className={classNames('scroll-helper', {
              isThreeProducts,
              isTouchDevice: isTouchDevice(),
            })}
            onScroll={handleScrollCharacterProducts}
          >
            <div ref={ref} className="scroll-point" />
            <Col
              className={classNames({
                'px-0': isThreeProducts,
              })}
            >
              {!isThreeProducts && header}
              <Row
                className={classNames({
                  'justify-content-center three-products-wrapper flex-nowrap mx-auto': isThreeProducts,
                })}
              >
                {gprls.map(
                  (gprl, i) =>
                    gprl &&
                    (isThreeProducts ? (
                      <CharacterProductThree
                        key={i}
                        gprl={gprl}
                        i={i}
                        isJapanLanguage={isJapanese}
                        isLayoutRightToLeft={isLayoutRightToLeft}
                        isProductLongDescriptionHidden={isProductLongDescriptionHidden}
                        isProductShortDescriptionHidden={isProductShortDescriptionHidden}
                        retailerTags={retailerTags}
                        threeProductsRef={threeProductsRefs[i]}
                      />
                    ) : (
                      <CharacterProduct
                        key={i}
                        gprl={gprl}
                        i={i}
                        isJapanLanguage={isJapanese}
                        isLayoutRightToLeft={isLayoutRightToLeft}
                        isProductLongDescriptionHidden={isProductLongDescriptionHidden}
                        isProductShortDescriptionHidden={isProductShortDescriptionHidden}
                        retailerTags={retailerTags}
                      />
                    )),
                )}
              </Row>
            </Col>
          </div>
          {isThreeProducts && (
            <Row className="mx-0 justify-content-center three-btns-wrapper">
              {threeProductsRefs.map((threeProductsRef, i) => (
                <button
                  key={i}
                  className={classNames('scroll-btn', {
                    isActive: activeSlide === i,
                  })}
                  onClick={() => {
                    setActiveSlide(i);
                    threeProductsRef.current?.scrollIntoView({
                      behavior: 'smooth',
                      inline: 'center',
                    });
                  }}
                />
              ))}
            </Row>
          )}
        </Col>
      </Row>
    );
  }
  return <div />;
};

export default React.memo(IQOS4OneCharacterProducts);
