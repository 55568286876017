import { FC, MutableRefObject } from 'react';

import { localeLegacy } from '@lib/tools/locale/source/pmi/legacy';
import LocaleFragment from '@lib/tools/locale/views/LocaleFragment';
import { useAddons } from '@lib/tools/views/hooks';

interface HealthWarningFooterPropsType {
  isVisible: boolean;
  legalWarningFlag?: boolean;
  reference?: MutableRefObject<any> | null;
}

const HealthWarningFooter: FC<HealthWarningFooterPropsType> = ({
  isVisible,
  reference = null,
  legalWarningFlag = false,
}) => {
  const { isHealthWarningBottomBlack15PerAddon, isHealthWarningMultilingualAddon } = useAddons();

  const healthWarningMessage = <LocaleFragment message={localeLegacy.healthWarning.iqos3HealthWarning} />;

  return (
    <div ref={reference} className="health-warning-footer">
      {legalWarningFlag && (
        <div className="ta-pmi3-p5-lighten text-center legal-warning">
          <LocaleFragment message={localeLegacy.healthWarning.retailerPageWarningOne} />
          &nbsp;
          <a
            className="text-decoration-underline"
            href="https://www.pmiprivacy.com/consumer/"
            rel="noopener noreferrer"
            target="_blank"
          >
            <LocaleFragment message={localeLegacy.healthWarning.retailerPageWarningOne} />
          </a>
          &nbsp;
          <LocaleFragment message={localeLegacy.healthWarning.retailerPageWarningTwo} />
          &nbsp;
          <LocaleFragment message={localeLegacy.healthWarning.retailerPageWarningThree} />
        </div>
      )}
      {isVisible && (
        <>
          {isHealthWarningMultilingualAddon && (
            <div className="ta-pmi3-p1_1 font-weight-bold px-4 bordered multilingual text-center d-flex flex-column justify-content-center align-items-center">
              <LocaleFragment message={localeLegacy.healthWarning.retailerPageMultilingualOne} />
              <LocaleFragment message={localeLegacy.healthWarning.retailerPageMultilingualTwo} />
              <LocaleFragment message={localeLegacy.healthWarning.retailerPageMultilingualThree} />
            </div>
          )}
          {isHealthWarningBottomBlack15PerAddon && !isHealthWarningMultilingualAddon && (
            <div className="iqos3-health-warning-landing-bottom-black w-100 d-flex align-items-center justify-content-center p-2">
              <span className="color-white ta-pmi3-p1_arial text-uppercase text-center">
                <LocaleFragment message={localeLegacy.healthWarning.landingBottomBlack} />
              </span>
            </div>
          )}
          {!isHealthWarningBottomBlack15PerAddon && !isHealthWarningMultilingualAddon && (
            <div className="ta-pmi3-p5-bold inner-p-mb-0 bordered text-center nbw-10 d-flex flex-column justify-content-center align-items-center">
              {healthWarningMessage}
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default HealthWarningFooter;
