import { FC } from 'react';

import { localeLegacy } from '@lib/tools/locale/source/pmi/legacy';
import LocaleFragment from '@lib/tools/locale/views/LocaleFragment';

interface Props {
  privacyLink?: string;
  className?: string;
}

const Disclaimer: FC<Props> = ({ privacyLink, className }) => {
  return (
    <div className={`blends-disclaimer-font text-soft-white ${className}`}>
      <p>
        <LocaleFragment message={localeLegacy.blends.landingDisclaimer1} />
      </p>
      <p>
        {<LocaleFragment message={localeLegacy.blends.landingDisclaimer2} />}{' '}
        <a
          className="text-decoration-underline text-soft-white"
          href={privacyLink || 'https://www.pmiprivacy.com/consumer/'}
          rel="noopener noreferrer"
          target="_blank"
        >
          <LocaleFragment message={localeLegacy.blends.landingDisclaimerLink} />
        </a>
      </p>
    </div>
  );
};

export default Disclaimer;
