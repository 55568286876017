import { FC } from 'react';
import { useDispatch } from 'react-redux';
import { Col, Row } from 'reactstrap';

import { TCharacter } from '@lib/core/characters/types';
import { localeLegacy } from '@lib/tools/locale/source/pmi/legacy';
import LocaleFragment from '@lib/tools/locale/views/LocaleFragment';

import { navigateHome } from '@components/pmi/src/utils';

interface TobaccoCharacterDescriptionProps {
  character: TCharacter;
}

const TobaccoCharacterDescriptionComponent: FC<TobaccoCharacterDescriptionProps> = ({ character }) => {
  const dispatch = useDispatch();

  return (
    <Row className="mx-0 spec-resolution-iqos3-three">
      <Col className="tobacco-header-iqos3-container px-0" xs={12}>
        <div className="mx-0 iqos3-result-header">
          <button
            className="home-btn"
            type="button"
            onClick={() => {
              navigateHome(dispatch);
            }}
          />
        </div>
        <div className="text-justify">
          <img alt="header_image" src={character.attributes?.image_nbw || character.image} />
        </div>
        <Row className="ta-jp-h3-1-bold ta-jp-mob-h4 font-weight-bold title">
          <p>
            <LocaleFragment message={localeLegacy.heets.resultRecommendation} />
          </p>
        </Row>
      </Col>
    </Row>
  );
};

export default TobaccoCharacterDescriptionComponent;
export { TobaccoCharacterDescriptionComponent as TobaccoCharacterDescriptionComponentWithoutMemo };
