import React, { FC } from 'react';
import { useDispatch } from 'react-redux';
import { Col, Row } from 'reactstrap';

import PreviousButton from '@components/pmi/src/designSets/terea/main/quiz/PreviousButton';
import { navigateHome } from '@components/pmi/src/utils';

interface Props {
  goBack: () => void;
  isFirstQuestion: boolean;
}

const IQOS4OneHeader: FC<Props> = ({ goBack, isFirstQuestion }) => {
  const dispatch = useDispatch();

  return (
    <Row className="mx-0 taste-advisor-4-one-header">
      <Col className="max-height-30 px-0 d-flex btns-wrapper">
        <button
          className="general-btn home-btn"
          type="button"
          onClick={() => {
            navigateHome(dispatch);
          }}
        />
        {!isFirstQuestion && <PreviousButton onClickHandler={goBack} />}
      </Col>
    </Row>
  );
};

export default React.memo(IQOS4OneHeader);
