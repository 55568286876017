import classNames from 'classnames';
import React, { FC } from 'react';
import { Col, Row } from 'reactstrap';

import { TProductInstance } from '@lib/core/products/types';
import { useApp } from '@lib/core/service/hooks';
import { localeLegacy } from '@lib/tools/locale/source/pmi/legacy';
import CustomMDReactComponent from '@lib/tools/locale/views/CustomMDReactComponent';
import LocaleFragment from '@lib/tools/locale/views/LocaleFragment';
import { reOrderEssencesForIQOS3Designs } from '@lib/tools/shared/pmi/essences';
import { HEETS_ESSENCE_COOLING_ID } from '@lib/tools/shared/pmi/essences/consts';
import { useAddons, useEqualElementsHeightV2 } from '@lib/tools/views/hooks';

interface Props {
  genericProductRetailerLocation: TProductInstance;
}

const ProductCard: FC<Props> = ({ genericProductRetailerLocation: gprl }) => {
  const {
    isProductLongDescriptionHiddenAddon,
    isAromaNotesDisabledAddon,
    isProductShortDescriptionHiddenAddon,
    isTobaccoExperienceHiddenAddon,
    isEssenceCoolingDisabledAddon,
    isEssenceBodyDisabledAddon,
    isEssenceAromaDisabledAddon,
    isMentholDisclaimerAddon,
    isAromaNoteSecondaryAddon,
  } = useAddons();
  const { isLayoutRightToLeft } = useApp();

  const { attributes: productAttributes } = { ...gprl.product };
  const isShowProductImageDisclaimerAttributes = productAttributes.show_image_disclaimer === 1;
  const isPearl = productAttributes.is_pearl === 'true';

  productAttributes.slug = gprl.product.slug;
  const { essences: productEssences } = gprl.product;
  const essences = reOrderEssencesForIQOS3Designs({
    essences: productEssences,
    isEssenceAromaDisabled: isEssenceAromaDisabledAddon,
    isEssenceBodyDisabled: isEssenceBodyDisabledAddon,
    isEssenceCoolingDisabled: isEssenceCoolingDisabledAddon,
  });
  const coolingEssence = essences.find(essence => essence.essence_id === HEETS_ESSENCE_COOLING_ID);
  const isCooling = Number(coolingEssence?.score) > 0 && isMentholDisclaimerAddon;

  useEqualElementsHeightV2([
    'iqos3-blends-product-card-title-ref',
    'iqos3-blends-product-card-description-ref',
    'iqos3-blends-product-card-image-disclaimer-ref',
    'iqos3-blends-product-card-tobacco-experience-ref',
  ]);

  return (
    <Col key={gprl.identifier} className="taste-advisor-3-one-character-product px-0 px-md-4" xs={6}>
      <Row className="mx-0 content justify-content-center">
        <Col className="col-wrapper d-flex flex-column no-padding">
          <div className="iqos3-blends-product-card-title-ref character-product-name-wrapper">
            <h4 className="ta-pmi3-p3_3-bold inner-p-mb-0 character-product-name">
              {gprl.product.name.split('\n').map(el => (
                <CustomMDReactComponent text={el} />
              ))}
            </h4>
          </div>

          <img alt="" className="product-image mx-auto" src={gprl.image} />
          <div className="iqos3-blends-product-card-image-disclaimer-ref">
            {isShowProductImageDisclaimerAttributes && (
              <div className="mx-auto image-disclaimer">
                <span className="ta-pmi3-p4">
                  <LocaleFragment message={localeLegacy.blends.resultImageDisclaimer} />
                </span>
              </div>
            )}
          </div>

          {!isProductShortDescriptionHiddenAddon && (
            <div
              className={classNames(
                'iqos3-blends-product-card-tobacco-experience-ref ta-pmi3-p3_1-bold',
                'product-caption',
                {
                  'without-tobacco-experience': isTobaccoExperienceHiddenAddon,
                },
              )}
            >
              <p className="mb-0">
                {gprl.product.caption.split('\n').map(el => (
                  <CustomMDReactComponent text={`${el}${isCooling ? '*' : ''}`} />
                ))}
              </p>
              {!isTobaccoExperienceHiddenAddon && (
                <span className="text-lowercase margin-top-minus3px mb-0 d-block">
                  {isPearl ? (
                    <LocaleFragment message={localeLegacy.blends.resultTobaccoExperienceWithCapsule} />
                  ) : (
                    <LocaleFragment message={localeLegacy.blends.resultTobaccoExperience} />
                  )}
                </span>
              )}
            </div>
          )}

          {!isProductLongDescriptionHiddenAddon && (
            <div className="iqos3-blends-product-card-description-ref">
              <p className="ta-pmi3-p4 mb-0 mt-3 mt-md-3 px-3 text-align-center product-description">
                {gprl.product.description.split('\n').map(el => (
                  <CustomMDReactComponent text={el} />
                ))}
              </p>
            </div>
          )}

          <Row className="aroma-note-wrapper mx-auto">
            <Col className="px-0 d-flex flex-column">
              {!isAromaNotesDisabledAddon && (
                <Row className="aroma-note flex-row-reverse">
                  <Col className="note d-flex flex-column text-left px-0" md={8} xs={12}>
                    <span
                      className={classNames('ta-pmi3-p4 text-nowrap', {
                        'text-right': isLayoutRightToLeft,
                      })}
                    >
                      {isPearl ? (
                        <LocaleFragment message={localeLegacy.blends.resultOnClick} />
                      ) : (
                        <LocaleFragment message={localeLegacy.blends.resultAromaNote} />
                      )}
                    </span>
                    <span
                      className={classNames('ta-pmi3-p3_1-bold mt-1', {
                        'text-right white-space-pre': isLayoutRightToLeft,
                      })}
                    >
                      {gprl.product.aromas?.[0]?.description}
                    </span>
                  </Col>
                  <Col className="aroma-img d-flex px-0" md={4} xs={12}>
                    <img
                      alt=""
                      className={classNames({
                        rtl: isLayoutRightToLeft,
                      })}
                      src={
                        isAromaNoteSecondaryAddon && gprl.product.aromas?.[0]?.secondary_image
                          ? gprl.product.aromas?.[0]?.secondary_image
                          : gprl.product.aromas?.[0]?.image
                      }
                    />
                  </Col>
                </Row>
              )}
            </Col>
          </Row>
        </Col>
      </Row>
    </Col>
  );
};

export default React.memo(ProductCard);
