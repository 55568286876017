import React, { FC } from 'react';
import { Row } from 'reactstrap';

interface Props {
  progressPercent: number;
}

const BlendsProgressBar: FC<Props> = ({ progressPercent }) => (
  <Row className="blends-progress-bar">
    <div className="blends-bar mx-auto">
      <div
        className="blends-filled-in"
        style={{ borderRadius: `${progressPercent >= 100 ? '5px' : '5px 0 0 5px'}`, width: `${progressPercent}%` }}
      />
    </div>
  </Row>
);

export default React.memo(BlendsProgressBar);
