import { Dispatch, FC, SetStateAction } from 'react';

import { TProductCategory } from '@lib/core/products/types';
import { IRetailerLanguage } from '@lib/core/retailers/types/retailer';
import { localeLegacy } from '@lib/tools/locale/source/pmi/legacy';
import LocaleFragment from '@lib/tools/locale/views/LocaleFragment';

import ExpirationPeriod from '@components/pmi/src/admin/remoteAccess/components/ExpirationPeriod';
import LanguageSelect from '@components/pmi/src/admin/remoteAccess/components/LanguageSelect';
import ProductCategories from '@components/pmi/src/admin/remoteAccess/components/ProductCategories';
import Uses from '@components/pmi/src/admin/remoteAccess/components/Uses';

interface AccessKeyGeneratorProps {
  retriesValue: string | null;
  retailerName: string;
  expiryHoursValue: string | null;
  selectedLanguage: IRetailerLanguage;
  retailerLanguages: IRetailerLanguage[];
  setSelectedLanguage: Dispatch<SetStateAction<IRetailerLanguage>>;
  handleGenerateLink: () => void;
  generatedLink: string;
  selectedExpirationPeriod: { value: number; label: string };
  setSelectedExpirationPeriod: Dispatch<SetStateAction<{ value: number; label: string }>>;
  selectedNumberOfUses: { value: number; label: string };
  setSelectedNumberOfUses: Dispatch<SetStateAction<{ value: number; label: string }>>;
  resetGeneratedLink: () => void;
  productCategories: TProductCategory[];
  selectedProductCategory: TProductCategory;
  setSelectedDesignSet: Dispatch<SetStateAction<string>>;
  isLayoutRightToLeft: boolean;
}

const AccessKeyGeneratorComponent: FC<AccessKeyGeneratorProps> = ({
  retriesValue,
  retailerName,
  expiryHoursValue,
  selectedLanguage,
  retailerLanguages,
  setSelectedLanguage,
  handleGenerateLink,
  generatedLink,
  selectedExpirationPeriod,
  setSelectedExpirationPeriod,
  selectedNumberOfUses,
  setSelectedNumberOfUses,
  resetGeneratedLink,
  productCategories,
  selectedProductCategory,
  setSelectedDesignSet,
  isLayoutRightToLeft,
}) => (
  <div className="access-key-generator-wrapper-nbw">
    <p className="w-100 text-uppercase ta-pmi3-p2_1-bold mb-0 text-soft-white text-right">{retailerName}</p>
    <h2 className="page-name text-capitalize ta-pmi3-h2_2-bold text-soft-white">
      <LocaleFragment message={localeLegacy.accessKeyGenerator.header} />
    </h2>
    <div className="generator-card">
      <div className="first-menu">
        <ProductCategories
          productCategories={productCategories}
          selectedProductCategory={selectedProductCategory}
          setSelectedDesignSet={setSelectedDesignSet}
        />
        <LanguageSelect
          isLayoutRightToLeft={isLayoutRightToLeft}
          languages={retailerLanguages}
          selectedLanguage={selectedLanguage}
          setSelectedLanguage={setSelectedLanguage}
        />
      </div>
      <div className="second-menu">
        <ExpirationPeriod
          expiryHoursValue={expiryHoursValue}
          selectedExpirationPeriod={selectedExpirationPeriod}
          setSelectedExpirationPeriod={setSelectedExpirationPeriod}
        />
        <Uses
          retriesValue={retriesValue}
          selectedNumberOfUses={selectedNumberOfUses}
          setSelectedNumberOfUses={setSelectedNumberOfUses}
        />
      </div>
      <button
        className="mx-auto generate-link-btn ta-pmi3-h3_2-bold text-capitalize text-soft-white"
        onClick={handleGenerateLink}
      >
        <LocaleFragment message={localeLegacy.accessKeyGenerator.generateLink} />
      </button>
      <div className="mx-auto form-wrapper">
        <form
          className="w-100 mx-auto"
          onSubmit={e => {
            e.preventDefault();
          }}
        >
          <input
            readOnly
            className="ta-pmi3-p2_1-light link-field"
            id="generatedLink"
            placeholder="https://"
            type="text"
            value={generatedLink}
          />
        </form>
      </div>
      <div className="d-flex justify-content-center">
        <button
          className="copy-generated-link-btn ta-pmi3-p3_1 text-underlined"
          disabled={!generatedLink}
          type="button"
          onClick={() => {
            const el = document.getElementById('generatedLink') as HTMLInputElement;
            if (el.value !== 'https://') {
              el.select();
              document.execCommand('copy');
              resetGeneratedLink();
            }
          }}
        >
          <LocaleFragment message={localeLegacy.accessKeyGenerator.copyToClipboard} />
        </button>
      </div>
      <div className="footer-key-generator d-flex justify-content-center">
        <span className="ta-pmi3-p5-bold">
          *
          <LocaleFragment message={localeLegacy.accessKeyGenerator.fromTheMoment} />
        </span>
      </div>
    </div>
  </div>
);

export default AccessKeyGeneratorComponent;
