import cn from 'classnames';
import { useEffect, useState } from 'react';

import { TQuizAnswerData } from '@lib/core/quizzes/types';
import { useApp } from '@lib/core/service/hooks';
import { localeV2 } from '@lib/tools/locale/source/pmi/v2';
import LocaleFragment from '@lib/tools/locale/views/LocaleFragment';
import { useHeight } from '@lib/tools/views/hooks';

import ProgressBar from '@components/pmi/src/atoms/ProgressBar/ProgressBar';
import HealthWarningFooterToIsrael from '@components/pmi/src/common/HealthWarningFooterToIsrael';
import CTAButton from '@components/pmi/src/designSetsV2/Components/CTA/Button';
import HealthWarning from '@components/pmi/src/designSetsV2/Components/HealthWarning/HealthWarning';
import { useCurrentQuizBackground } from '@components/pmi/src/hooks/useCurrentQuizBackground';
import { isMediaVideo } from '@components/pmi/src/utils';

interface Props {
  onAnswerSelect: (id: string) => void;
  questionImage: string;
  answers: TQuizAnswerData[];
  noOfAnswersLeft: number;
  question: {
    caption: string;
    title: string;
  };
  progressPercent: number;
  isHealthWarningQuizAddon: boolean;
  isLight?: boolean;
  currentPrimaryColor: string;
  isBackgroundTint?: boolean;
  isVeev?: boolean;
  isShowRestrictedBackground: boolean;
  isHealthWarningFooterToIsraelAddon?: boolean;
}

function QuizPage({
  questionImage,
  answers,
  noOfAnswersLeft,
  question,
  onAnswerSelect,
  progressPercent,
  isHealthWarningQuizAddon,
  isLight = false,
  currentPrimaryColor,
  isBackgroundTint = false,
  isVeev = false,
  isShowRestrictedBackground,
  isHealthWarningFooterToIsraelAddon,
}: Props) {
  const [progressBarWidth, setProgressBarWidth] = useState(0);
  const isVideoFormat = questionImage ? isMediaVideo(questionImage) : false;

  const changeProgressBarWidth = () => {
    setTimeout(() => {
      setProgressBarWidth(progressPercent);
    }, 200);
  };

  useEffect(() => {
    changeProgressBarWidth();
  }, [progressPercent]);

  const [footerRef, paddingForFooter] = useHeight<HTMLDivElement>();

  const noOfAnswersLeftString = noOfAnswersLeft.toString();

  const questionsLeft = (
    <LocaleFragment message={localeV2.quiz.qds8006QuestionLastQuestions} options={{ noOfAnswersLeftString }} />
  );

  const answerObj1 = answers[0];
  const answerId1 = Object.keys(answerObj1)[0];

  const answerObj2 = answers[1];
  const answerId2 = Object.keys(answerObj2)[0];

  const { isLayoutRightToLeft } = useApp();
  const currentBackground = useCurrentQuizBackground(questionImage, isShowRestrictedBackground);

  return (
    <div
      className={cn(
        `ta-v2-qds8006-page ${isHealthWarningFooterToIsraelAddon ? 'padding-bottom-30vh minh-101vh' : 'minh-100vh'}`,
        {
          isLight,
        },
      )}
      style={{
        background: `${!isVideoFormat && currentBackground}`,
        paddingBottom: paddingForFooter,
      }}
    >
      {isVideoFormat ? (
        <video autoPlay loop muted playsInline className="ta-v2-qds8006-page-cover-video">
          <source src={questionImage} type="video/mp4" />
        </video>
      ) : null}
      {isBackgroundTint && <div className="ta-v2-qds8006-page-overlay" />}
      <div
        className={cn('ta-v2-qds8006-page-container', {
          'rtl-orientation': isLayoutRightToLeft,
        })}
      >
        <span className="ta-v2-qds8006-page-question-header font-ta-v2-title-2">
          <LocaleFragment message={localeV2.quiz.qds8006QuestionHeaderText} />
        </span>
        <ProgressBar
          className="ta-v2-qds8006-page-progressbar"
          currentPrimaryColor={currentPrimaryColor}
          isDark={!isVeev}
          progressPercentage={progressBarWidth}
        />
        <span className="ta-v2-qds8006-page-question-title">{question.title}</span>
        <span className="ta-v2-qds8006-page-question-caption">{question.caption}</span>
        <div className="ta-v2-qds8006-page-btn-group">
          <CTAButton
            btnText={answerObj1[answerId1]?.text}
            className="ta-v2-qds8006-page-btn"
            isDark={false}
            onBtnClick={() => onAnswerSelect(answerId1)}
          />
          <CTAButton
            isDark
            btnText={answerObj2[answerId2]?.text}
            className="ta-v2-qds8006-page-btn"
            onBtnClick={() => onAnswerSelect(answerId2)}
          />
        </div>
        <div className="ta-v2-qds8006-page-questions-remaining-container">
          <span
            className={cn('ta-v2-qds8006-page-questions-remaining font-ta-v2-global-body-small', {
              isLight,
            })}
          >
            {questionsLeft}
          </span>
        </div>
      </div>
      <div ref={footerRef} className="ta-v2-quiz-footer-wrapper">
        {isHealthWarningQuizAddon && <HealthWarning />}
      </div>
      {isHealthWarningFooterToIsraelAddon && <HealthWarningFooterToIsrael />}
    </div>
  );
}

export default QuizPage;
