// * Design Sets
export const DESIGN_SET_TASTE_ADVISOR_V2 = 'taste-advisor-v2';
export const DESIGN_SET_TASTE_ADVISOR_CRAFTED = 'taste-advisor-crafted';
export const DESIGN_SET_TASTE_ADVISOR_IQOS3_ONE = 'taste-advisor-iqos3-one';
export const DESIGN_SET_TASTE_ADVISOR_IQOS3_THREE = 'taste-advisor-iqos3-three';
export const DESIGN_SET_TASTE_ADVISOR_IQOS4_ONE = 'taste-advisor-iqos4-one';
export const DESIGN_SET_TASTE_ADVISOR_IQOS4_GLOBAL = 'taste-advisor-iqos4-global';
export const DESIGN_SET_TASTE_ADVISOR_BLENDS_ONE = 'taste-advisor-blends-one';

// * Remote Access Custom IDs
export const CUSTOM_ID_OPEN_INTEGRATION = 'open_integration';

// * Product Categories
export const SENTIA = 'sentia';
export const TEREA = 'terea';
export const HEETS = 'heets';
export const VEEV = 'veev';
export const CRAFTED = 'crafted';
