import { ReactComponent as Heart } from '@app/pmi/src/assets/media/V2/v2_product_match_heart.svg';
import { ReactComponent as Thumb } from '@app/pmi/src/assets/media/V2/v2_product_match_thumb.svg';

import { TechnicalCaptionIcon } from '@components/pmi/src/designSetsV2/Components/Results/RecommendedProduct/RecommendedProduct';

interface Props {
  icon: TechnicalCaptionIcon;
  text: string;
}

const TechnicalCaption = ({ icon, text }: Props) => {
  const getCurrentIcon = iconName => {
    let currentIcon;

    switch (iconName) {
      case 'thumb':
        currentIcon = <Thumb />;
        break;
      case 'heart':
        currentIcon = <Heart />;
        break;
      default:
    }

    return { currentIcon };
  };

  return (
    <div className="ta-v2-technical-caption-cover">
      <span className="ta-v2-technical-caption-icon">{getCurrentIcon(icon).currentIcon}</span>
      <span className="ta-v2-technical-caption-text font-ta-v2-global-hint-text-medium">{text}</span>
    </div>
  );
};

export default TechnicalCaption;
