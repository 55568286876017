import { useSelector } from 'react-redux';

import { resetUserProductPreferencesSlice } from '@lib/core/service/slices/productPreferencesSlice';
import { actionChangeAgreements, actionResetAllAgreements } from '@lib/core/service/slices/technical/agreements';
import { actionGetClientIp } from '@lib/core/service/slices/technical/clientIp';
import { Agreements } from '@lib/core/service/slices/technical/types';
import { selectAuthToken } from '@lib/core/users/selectors/auth';
import { selectUserProfileId } from '@lib/core/users/selectors/profile';
import {
  selectCharacters,
  selectIsFetchingUserData,
  selectIsUploadingProfilePicture,
  selectIsUserFullUserDataLoadedTemporaryHint,
  selectIsUserHasAllCharactersForCurrentDS,
  selectIsUserHasAnyCharacter,
  selectIsUserHasCharacterForCurrentPC,
  selectIsUserProfiled,
  selectKioskUserProductCategory,
  selectKioskUserRetailerLocationId,
  selectKioskUserRetailerSlug,
  selectMixpanelUserDidIdentify,
  selectSelectedUserProductPreferences,
  selectUserCharacterForSinglePCRetailer,
  selectUserCharactersIds,
  selectUserCountry,
  selectUserDataRaw,
  selectUserDisplayPictureUrl,
  selectUserEmail,
  selectUserFirstName,
  selectUserGroups,
  selectUserId,
  selectUserLanguage,
  selectUserLastName,
  selectUserRole,
  selectUserRoleIsKiosk,
  selectUserSessionId,
} from '@lib/core/users/selectors/user';
import { actionResetAuthSlice } from '@lib/core/users/slices/auth';
import { actionResetProductList } from '@lib/core/users/slices/productList';
import { actionResetUserProfileId } from '@lib/core/users/slices/profile';
import { actionGenerateUserSession, actionResetUserSlice } from '@lib/core/users/slices/user';
import { IUser } from '@lib/core/users/types';
import { USER_PRODUCT_PREFERENCES } from '@lib/tools/shared/helpers/consts';
import { useTypedDispatch } from '@lib/tools/views/hooks/useTypedDispatch';

export const useUser = (): IUser => {
  const dispatch = useTypedDispatch();

  const handleResetUserData = () => {
    dispatch(actionResetUserSlice());
    dispatch(actionResetAuthSlice());
    dispatch(actionResetAllAgreements());
    dispatch(actionGenerateUserSession());
    dispatch(
      actionChangeAgreements({
        [Agreements.isUserAllowedUseLocalStorage]: false,
      }),
    );
    localStorage.removeItem(USER_PRODUCT_PREFERENCES);
    dispatch(resetUserProductPreferencesSlice());
    dispatch(actionResetUserProfileId());
    dispatch(actionResetProductList());

    Promise.resolve(dispatch(actionResetUserProfileId())).then(() => {
      dispatch(actionGetClientIp());
    });
  };

  return {
    handleResetUserData,
    isFetchingUserData: useSelector(selectIsFetchingUserData),
    isUploadingProfilePicture: useSelector(selectIsUploadingProfilePicture),
    isUserAuthenticated: !!useSelector(selectAuthToken),
    isUserFullUserDataLoadedTemporaryHint: useSelector(selectIsUserFullUserDataLoadedTemporaryHint),
    isUserHasAllCharactersForCurrentDS: useSelector(selectIsUserHasAllCharactersForCurrentDS),
    isUserHasAnyCharacter: useSelector(selectIsUserHasAnyCharacter),
    isUserHasCharacterForCurrentPC: useSelector(selectIsUserHasCharacterForCurrentPC),
    isUserKioskAdmin: useSelector(selectUserRoleIsKiosk),
    isUserProfiled: useSelector(selectIsUserProfiled),

    kioskUserProductCategory: useSelector(selectKioskUserProductCategory),
    kioskUserRetailerLocationId: useSelector(selectKioskUserRetailerLocationId),
    kioskUserRetailerSlug: useSelector(selectKioskUserRetailerSlug),

    mixpanelUserDidIdentify: useSelector(selectMixpanelUserDidIdentify),
    selectedUserProductPreferences: useSelector(selectSelectedUserProductPreferences),
    userCharacterForSinglePCRetailer: useSelector(selectUserCharacterForSinglePCRetailer),
    userCharacters: useSelector(selectCharacters),
    userCharactersIds: useSelector(selectUserCharactersIds),
    userCountry: useSelector(selectUserCountry),
    userDataRaw: useSelector(selectUserDataRaw),
    userDisplayPictureUrl: useSelector(selectUserDisplayPictureUrl),
    userEmail: useSelector(selectUserEmail),
    userFirstName: useSelector(selectUserFirstName),
    userGroups: useSelector(selectUserGroups),
    userId: useSelector(selectUserId),
    userLanguage: useSelector(selectUserLanguage),
    userLastName: useSelector(selectUserLastName),
    userProfileId: useSelector(selectUserProfileId),
    userRole: useSelector(selectUserRole),
    userSessionId: useSelector(selectUserSessionId),
  };
};
