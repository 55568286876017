import classNames from 'classnames';
import Slider from 'rc-slider';
import React, { FC, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { Col, Row } from 'reactstrap';

import CustomMDReactComponent from '@lib/tools/locale/views/CustomMDReactComponent';

import NextBtn from '@components/pmi/src/designSets/heets/main/one/NextBtn';

interface Props {
  answers: Record<
    string,
    {
      image?: string;
      text: string;
    }
  >[];
  handleConfirmAnswer: (idAnswer: string, callback: () => void) => void;
}

const QuestionAnswers: FC<Props> = ({ answers, handleConfirmAnswer }) => {
  const isMobileResolution = useMediaQuery({ maxWidth: 767 });
  const isDesktopResolution = useMediaQuery({ minWidth: 1366 });
  const [selectedAnswer, setSelectedAnswer] = useState(2);
  const [isAnswerSelected, setIsAnswerSelected] = useState(false);
  const [isNotAbleAnswerSelected, setIsNotAbleAnswerSelected] = useState(false);

  const answerNumber = isNotAbleAnswerSelected ? 5 : selectedAnswer;
  const lastAnswerId = answers[5] && Object.keys(answers[5])[0];
  const lastAnswerText = lastAnswerId && answers[5][lastAnswerId].text;

  const idAnswer = answers[answerNumber] && Object.keys(answers[answerNumber])[0];

  const dispatchAnswer = () => {
    handleConfirmAnswer(idAnswer, () => {
      setIsAnswerSelected(false);
      setSelectedAnswer(2);
      setIsNotAbleAnswerSelected(false);
    });
  };

  const marks = [0, 1, 2, 3, 4].map(index => {
    if (index === 0 || index === 2 || index === 4) {
      const answerId = answers[index] && Object.keys(answers[index])[0];
      const text = answerId && answers[index][answerId].text;
      return (
        <div
          key={index}
          className={classNames('ta-slider-test-answer withText margin-top-15px', {
            isSelected: answerId && answerId === idAnswer && isAnswerSelected,
          })}
        >
          <CustomMDReactComponent text={text} />
        </div>
      );
    }
    return <div className="ta-slider-test-answer disabled-click" />;
  });

  const isNextBtnActive = isAnswerSelected || isNotAbleAnswerSelected;

  return (
    <div className="taste-advisor-3-qds2002-question-answers w-100">
      <Row
        className="mx-0 position-relative"
        onClick={event => {
          if ((event.target as HTMLElement).className.includes('rc-slider-handle') && !isAnswerSelected) {
            setIsAnswerSelected(true);
            setSelectedAnswer(2);
            setIsNotAbleAnswerSelected(false);
          }
        }}
      >
        <div
          className={classNames({
            'first-answer-selected': selectedAnswer === 0,
            'fourth-answer-selected': selectedAnswer === 3,
            'last-answer-selected': selectedAnswer === 4,
            'rc-slider ta-answers-slider-range-copy': true,
            'second-answer-selected': selectedAnswer === 1,
          })}
        >
          <div
            className="rc-slider-handle-before"
            style={{
              display: `${isAnswerSelected && selectedAnswer !== 0 ? 'block' : 'none'}`,
              left: `${answerNumber * 25}%`,
            }}
          >
            <div />
          </div>
          <div
            className="rc-slider-handle-after"
            style={{
              display: `${isAnswerSelected && selectedAnswer !== 4 ? 'block' : 'none'}`,
              left: `${answerNumber * 25}%`,
            }}
          >
            <div />
          </div>
        </div>
        <Slider
          defaultValue={2}
          included={false}
          marks={marks}
          max={4}
          min={0}
          step={0.01}
          value={selectedAnswer}
          className={classNames({
            'answer-not-selected': !isAnswerSelected,
            'first-answer-selected': selectedAnswer === 0,
            'fourth-answer-selected': selectedAnswer === 3,
            'last-answer-selected': selectedAnswer === 4,
            'second-answer-selected': selectedAnswer === 1,
            'ta-answers-slider-range p-0': true,
          })}
          dotStyle={{
            display: 'none',
          }}
          railStyle={{
            backgroundColor: '#fff',
            border: isMobileResolution ? '2px solid #E5E5E5' : '5px solid #E5E5E5',
            borderRadius: '28px',
            height: isMobileResolution ? '18px' : '45px',
            maxWidth: '1230px',
            width: isDesktopResolution ? '91vw' : '65vw',
          }}
          onAfterChange={value => {
            setSelectedAnswer(Math.round(value));
          }}
          onChange={value => {
            if (!isAnswerSelected) {
              setIsAnswerSelected(true);
            }
            setIsNotAbleAnswerSelected(false);
            if (value <= 0.1) {
              setSelectedAnswer(0);
            } else if (value >= 3.9) {
              setSelectedAnswer(4);
            } else {
              setSelectedAnswer(value);
            }
          }}
        />
      </Row>

      <Row className="mx-auto other-btns-wrapper max-width-900px w-85vw px-3 px-md-0 padding-bottom-40px">
        {answers.length >= 6 ? (
          <Col className="px-0 d-flex flex-column align-items-center ta-pmi3-p2_2" xs={12}>
            <button
              type="button"
              className={classNames({
                'is-selected': isNotAbleAnswerSelected,
                'non-answer-btn': true,
              })}
              onClick={() => {
                setIsAnswerSelected(false);
                setSelectedAnswer(2);
                setIsNotAbleAnswerSelected(true);
              }}
            >
              <span>
                <CustomMDReactComponent text={lastAnswerText} />
              </span>
            </button>
          </Col>
        ) : null}
        <Col className="ta-pmi3-next-btn-wrapper px-0 d-flex flex-column align-items-center" xs={12}>
          <NextBtn dispatchAnswer={dispatchAnswer} isNextBtnActive={isNextBtnActive} />
        </Col>
      </Row>
    </div>
  );
};

export default React.memo(QuestionAnswers);
