import classNames from 'classnames';
import { FC, Fragment } from 'react';

import { TProductInstance } from '@lib/core/products/types';
import { useApp } from '@lib/core/service/hooks';
import { localeLegacy } from '@lib/tools/locale/source/pmi/legacy';
import CustomMDReactComponent from '@lib/tools/locale/views/CustomMDReactComponent';
import LocaleFragment from '@lib/tools/locale/views/LocaleFragment';
import { reOrderEssencesForIQOS3Designs } from '@lib/tools/shared/pmi/essences';
import { HEETS_ESSENCE_COOLING_ID } from '@lib/tools/shared/pmi/essences/consts';
import { useAddons, useEqualElementsHeightV2 } from '@lib/tools/views/hooks';

interface Props {
  retailerProductLocation: TProductInstance;
  cardNumber: number;
}

const ProductPortfolioCard: FC<Props> = ({ retailerProductLocation, cardNumber }) => {
  const {
    isAromaNotesDisabledAddon,
    isProductShortDescriptionHiddenAddon,
    isTobaccoExperienceHiddenAddon,
    isEssenceCoolingDisabledAddon,
    isLowercasePortfolioAddon,
    isEssenceBodyDisabledAddon,
    isEssenceAromaDisabledAddon,
    isMentholDisclaimerAddon,
    isAromaNoteSecondaryAddon,
  } = useAddons();
  const { isLayoutRightToLeft } = useApp();

  const { product } = retailerProductLocation;
  const { name, caption, attributes, essences: productEssences, aromas } = product;
  const { portfolio_image: portfolioImage, color } = attributes;

  const isPearl = retailerProductLocation?.product.attributes.is_pearl === 'true';

  const essences = reOrderEssencesForIQOS3Designs({
    essences: productEssences,
    isEssenceAromaDisabled: isEssenceAromaDisabledAddon,
    isEssenceBodyDisabled: isEssenceBodyDisabledAddon,
    isEssenceCoolingDisabled: isEssenceCoolingDisabledAddon,
  });
  const coolingEssence = essences.find(essence => essence.essence_id === HEETS_ESSENCE_COOLING_ID);
  const isCooling = Number(coolingEssence?.score) > 0 && isMentholDisclaimerAddon;

  useEqualElementsHeightV2([
    'iqos3-portfolio-card-ref',
    'iqos3-portfolio-caption-ref',
    'iqos3-portfolio-essences-ref',
    'iqos3-portfolio-aroma-note-ref',
    'iqos3-portfolio-name-ref',
  ]);

  return (
    <div
      className={`iqos3-portfolio-card-ref taste-advisor-3-product-portfolio-card text-center grid-area-${cardNumber}`}
    >
      <div className="iqos3-portfolio-name-ref product-title ta-pmi3-p6-bold inner-p-mb-0">
        {name.split('\n').map((el, i) => (
          <CustomMDReactComponent key={i} text={el} />
        ))}
      </div>

      {!isProductShortDescriptionHiddenAddon && (
        <div className={classNames('iqos3-portfolio-caption-ref product-caption w-md-50 mx-auto ta-pmi3-p6_1')}>
          <span className={classNames('inner-p-mb-0', { 'text-uppercase': !isLowercasePortfolioAddon })}>
            {caption.split('\n').map((el, index) => (
              <CustomMDReactComponent key={index} text={`${el}${isCooling ? '*' : ''}`} />
            ))}
            {!isTobaccoExperienceHiddenAddon && (
              <span className="text-lowercase margin-top-minus3px mt-md-0 d-block">
                {isPearl ? (
                  <LocaleFragment message={localeLegacy.iqos3.resultTobaccoExperienceWithCapsule} />
                ) : (
                  <LocaleFragment message={localeLegacy.iqos3.resultTobaccoExperience} />
                )}
              </span>
            )}
          </span>
        </div>
      )}
      <div className="d-flex justify-content-center">
        <img alt="product_image" className="portfolio-image" src={portfolioImage} />
      </div>

      <div
        className={classNames('iqos3-portfolio-essences-ref essences-wrapper mx-auto text-center', {
          'mb-0': isAromaNotesDisabledAddon,
        })}
      >
        {essences.map(essence => (
          <Fragment key={essence.essence_id}>
            {parseInt(essence.score, 10) > 0 && (
              <div className="essence-wrapper">
                <div className="essences-score-wrapper">
                  {new Array(5).fill(null).map((_, index) => (
                    <div
                      key={index}
                      className={classNames('essences-score', {
                        rtl: isLayoutRightToLeft,
                      })}
                      style={{
                        backgroundColor: parseInt(essence.score, 10) >= index + 1 ? color : '#e5e5e5',
                      }}
                    />
                  ))}
                </div>
                <div
                  className={classNames('ta-pmi3-p6_1', {
                    'text-capitalize': isLowercasePortfolioAddon,
                    'text-uppercase': !isLowercasePortfolioAddon,
                  })}
                >
                  {essence.name}
                </div>
              </div>
            )}
          </Fragment>
        ))}
      </div>
      {!isAromaNotesDisabledAddon && (
        <div className="iqos3-portfolio-aroma-note-ref">
          <div className="aroma-note mx-auto">
            {aromas?.[0]?.image.includes('.svg') ? (
              <svg data-src={aromas?.[0]?.image} height="20px" width="20px" />
            ) : (
              <img
                alt="aroma_note_icon"
                src={
                  isAromaNoteSecondaryAddon && aromas?.[0]?.secondary_image
                    ? aromas?.[0]?.secondary_image
                    : aromas?.[0]?.image
                }
              />
            )}
          </div>

          <div
            className={classNames('mt-1 ta-pmi3-p6_1', {
              'text-uppercase': !isLowercasePortfolioAddon,
            })}
          >
            {isPearl ? (
              <LocaleFragment message={localeLegacy.iqos3.portfolioOnClick} />
            ) : (
              <LocaleFragment message={localeLegacy.iqos3.portfolioAromaNote} />
            )}
          </div>
          <div className={classNames('ta-pmi3-p6-bold', 'mt-md-1', { 'text-uppercase': !isLowercasePortfolioAddon })}>
            {aromas?.[0]?.description}
          </div>
        </div>
      )}
    </div>
  );
};

export default ProductPortfolioCard;
