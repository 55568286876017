import { FC } from 'react';

import { useRetailerDesignSet } from '@lib/tools/views/hooks';

import InvalidVerificationLinkGate from '@components/pmi/src/designSets/addOns/common/InvalidVerificationLinkGate';

const RemoteGuidedTrialAccess: FC = () => {
  const { isDesignSetTasteAdvisorIQOS3 } = useRetailerDesignSet();

  return <InvalidVerificationLinkGate isIQOS3DesignSet={isDesignSetTasteAdvisorIQOS3} />;
};

export default RemoteGuidedTrialAccess;
