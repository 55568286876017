import classNames from 'classnames';
import Slider from 'rc-slider';
import React, { FC, useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { Col, Row } from 'reactstrap';

import noOptionImage from '@app/pmi/src/assets/media/legacy/icons/pictures_no_option_iqos_4.png';

import { useQuizView } from '@lib/core/quizzes/hooks';
import { useApp } from '@lib/core/service/hooks';
import { localeTEREA } from '@lib/tools/locale/source/pmi/terea';
import CustomMDReactComponent from '@lib/tools/locale/views/CustomMDReactComponent';
import LocaleFragment from '@lib/tools/locale/views/LocaleFragment';

interface Props {
  answers: Record<
    string,
    {
      image?: string;
      text: string;
    }
  >[];
  handleConfirmAnswer: (idAnswer: string, callback: () => void) => void;
}

const QuestionAnswers: FC<Props> = ({ answers, handleConfirmAnswer }) => {
  const isDesktopResolution = useMediaQuery({ minWidth: 1366 });
  const isMobileResolution = useMediaQuery({ maxWidth: 767 });
  const [selectedAnswer, setSelectedAnswer] = useState(2);
  const [isAnswerSelected, setIsAnswerSelected] = useState(false);
  const [isNotAbleAnswerSelected, setIsNotAbleAnswerSelected] = useState(false);
  const { isLayoutRightToLeft } = useApp();

  const answersRTL = [...answers].reverse();
  const [first, ...rest] = answersRTL;
  const answerArrayRTL = [...rest, first];
  const answersArray = isLayoutRightToLeft ? answerArrayRTL : answers;
  const answerNumber = isNotAbleAnswerSelected ? 5 : selectedAnswer;
  const lastAnswerId = answersArray[5] && Object.keys(answersArray[5])[0];
  const lastAnswerText = lastAnswerId && answersArray[5][lastAnswerId].text;

  const idAnswer = answersArray[answerNumber] && Object.keys(answersArray[answerNumber])[0];

  const { quizViewQuestionId } = useQuizView();

  useEffect(() => {
    if (quizViewQuestionId) {
      setIsAnswerSelected(false);
      setSelectedAnswer(2);
      setIsNotAbleAnswerSelected(false);
    }
  }, [quizViewQuestionId]);

  const dispatchAnswer = () => {
    handleConfirmAnswer(idAnswer, () => {
      setIsAnswerSelected(false);
      setSelectedAnswer(2);
      setIsNotAbleAnswerSelected(false);
    });
  };

  const marks = [0, 1, 2, 3, 4].map(index => {
    if (index === 0 || index === 2 || index === 4) {
      const answerId = answersArray[index] && Object.keys(answersArray[index])[0];
      const text = answerId && answersArray[index][answerId].text;
      return (
        <div
          key={index}
          className={classNames('ta-slider-test-answer withText', {
            isSelected: answerId && answerId === idAnswer && isAnswerSelected,
          })}
        >
          <CustomMDReactComponent text={text} />
        </div>
      );
    }
    return <div className="ta-slider-test-answer disabled-click" />;
  });

  const isNextBtnActive = isAnswerSelected || isNotAbleAnswerSelected;

  return (
    <div
      className={classNames('taste-advisor-4-four-question-answers w-100', {
        'rtl-orientation': isLayoutRightToLeft,
      })}
    >
      <Row
        className="mx-0 position-relative"
        onClick={event => {
          if ((event.target as HTMLElement).className.includes('rc-slider-handle') && !isAnswerSelected) {
            setIsAnswerSelected(true);
            setSelectedAnswer(2);
            setIsNotAbleAnswerSelected(false);
          }
        }}
      >
        <div
          className={classNames({
            'first-answer-selected': selectedAnswer === 0,
            'fourth-answer-selected': selectedAnswer === 3,
            'last-answer-selected': selectedAnswer === 4,
            'rc-slider ta-answers-slider-range-copy': true,
            'second-answer-selected': selectedAnswer === 1,
          })}
        >
          <div
            className="rc-slider-handle-before"
            style={{
              display: `${isAnswerSelected && selectedAnswer !== 0 ? 'block' : 'none'}`,
              left: `${answerNumber * 25}%`,
            }}
          >
            <div />
          </div>
          <div
            className="rc-slider-handle-after"
            style={{
              display: `${isAnswerSelected && selectedAnswer !== 4 ? 'block' : 'none'}`,
              left: `${answerNumber * 25}%`,
            }}
          >
            <div />
          </div>
        </div>
        <Slider
          defaultValue={2}
          included={false}
          marks={marks}
          max={4}
          min={0}
          step={0.01}
          value={selectedAnswer}
          className={classNames({
            'answer-not-selected': !isAnswerSelected,
            'first-answer-selected': selectedAnswer === 0,
            'fourth-answer-selected': selectedAnswer === 3,
            'last-answer-selected': selectedAnswer === 4,
            'second-answer-selected': selectedAnswer === 1,
            'ta-answers-slider-range p-0': true,
          })}
          dotStyle={{
            display: 'none',
          }}
          railStyle={{
            backgroundColor: '#fff',
            border: isMobileResolution ? '2px solid #E5E5E5' : '5px solid #E5E5E5',
            borderRadius: '28px',
            height: isMobileResolution ? '18px' : '45px',
            maxWidth: '900px',
            width: isDesktopResolution ? '91vw' : '65vw',
          }}
          onAfterChange={value => {
            setSelectedAnswer(Math.round(value));
          }}
          onChange={value => {
            if (!isAnswerSelected) {
              setIsAnswerSelected(true);
            }
            setIsNotAbleAnswerSelected(false);
            if (value <= 0.1) {
              setSelectedAnswer(0);
            } else if (value >= 3.9) {
              setSelectedAnswer(4);
            } else {
              setSelectedAnswer(value);
            }
          }}
        />
      </Row>

      <Row className="mx-auto other-btns-wrapper w-80vw px-3 px-md-0 padding-bottom-40px">
        {answers.length >= 6 ? (
          <Col className="px-0 wrapper" xs={12}>
            <button
              type="button"
              className={classNames({
                'is-selected': isNotAbleAnswerSelected,
                'non-answer-btn': true,
              })}
              onClick={() => {
                setIsAnswerSelected(false);
                setSelectedAnswer(2);
                setIsNotAbleAnswerSelected(true);
              }}
            >
              <div className="cross">
                <img alt="x" src={noOptionImage} />
              </div>
              <span>
                <CustomMDReactComponent text={lastAnswerText} />
              </span>
            </button>
            <button
              disabled={!isNextBtnActive}
              type="button"
              className={classNames('next-btn is-selected', {
                disabled: !isNextBtnActive,
                'ltr-orientation': !isLayoutRightToLeft,
                'rtl-orientation': isLayoutRightToLeft,
              })}
              onClick={dispatchAnswer}
            >
              <LocaleFragment message={localeTEREA.common.buttonNext} />
            </button>
          </Col>
        ) : null}
      </Row>
    </div>
  );
};

export default React.memo(QuestionAnswers);
