import { FC } from 'react';
import { Col, Row } from 'reactstrap';

import CustomMDReactComponent from '@lib/tools/locale/views/CustomMDReactComponent';

import BackButton from '@components/pmi/src/designSets/blends/main/BackButton';
import HomeButton from '@components/pmi/src/designSets/blends/main/HomeButton';
import BlendsProgressBar from '@components/pmi/src/designSets/blends/main/ProgressBar';

interface Props {
  showBackButton: boolean;
  goBack: () => void;
  goHome: () => void;
  progressPercent: number;
  text: string;
  secondaryText?: string;
}

const Question: FC<Props> = ({ showBackButton, goBack, progressPercent, text, secondaryText, goHome }) => (
  <Row className="blends-question text-soft-white mx-0">
    <Col className="z-index-1 d-flex flex-column align-items-center px-0">
      <Row className="question-hdr mx-0 align-self-start">
        <Col className="px-0">
          <HomeButton className="btns" onClickHandler={goHome} />
          {showBackButton && <BackButton className="btns" goBack={goBack} />}
        </Col>
      </Row>
      <BlendsProgressBar progressPercent={progressPercent} />
      <Row className="mx-0 question-text">
        <Col className="ta-veev-h5-bold text-center px-0">
          {text.split('\n').map((el, i) =>
            i ? (
              <span key={i} className="ta-veev-p1 font-weight-normal">
                <CustomMDReactComponent text={el} />
              </span>
            ) : (
              <CustomMDReactComponent text={el} />
            ),
          )}
        </Col>
      </Row>
      {secondaryText && (
        <Row className="ta-veev-p1 justify-content-center text-center mx-auto sec-text">{secondaryText}</Row>
      )}
    </Col>
  </Row>
);

export default Question;
