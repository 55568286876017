import React, { FC } from 'react';
import { Col, Row } from 'reactstrap';

import CharacterDescriptionContainer from '@app/pmi/src/containers/ResultDescriptionContainer';

import { TCharacter } from '@lib/core/characters/types';
import { TProductInstance } from '@lib/core/products/types';

import CharacterProducts from '@components/pmi/src/designSets/blends/main/CharacterProducts';

interface Props {
  character: TCharacter;
  gprls: TProductInstance[];
}

const ResultPageBLENDS: FC<Props> = ({ character, gprls }) => (
  <Row className="mx-0 taste_advisor_3_one_result_test">
    <Col className="px-0 d-flex flex-column" xs={12}>
      <CharacterDescriptionContainer character={character} />
      <div className="margin-top-30px">
        <CharacterProducts products={gprls} />
      </div>
    </Col>
  </Row>
);

export { ResultPageBLENDS as ResultPageBLENDSWithoutMemo };
export default React.memo(ResultPageBLENDS);
