import classNames from 'classnames';
import { Dispatch, FC, SetStateAction, useCallback, useState } from 'react';
import { useIntl } from 'react-intl';

import { ReactComponent as ArrowIcon } from '@app/pmi/src/assets/media/caret.svg';

import { localeLegacy } from '@lib/tools/locale/source/pmi/legacy';
import LocaleFragment from '@lib/tools/locale/views/LocaleFragment';

import { getExpirationLabel } from '@components/pmi/src/utils';

interface ExpirationPeriodDropdownProps {
  period: { value: number; label: string };
  setPeriod: Dispatch<SetStateAction<{ value: number; label: string }>>;
}

const ExpirationPeriodDropdown: FC<ExpirationPeriodDropdownProps> = ({ period, setPeriod }) => {
  const [isVisible, setIsVisible] = useState(false);

  const intl = useIntl();

  const periodArr = [
    { label: getExpirationLabel(1, intl), value: 1 },
    { label: getExpirationLabel(2, intl), value: 2 },
    { label: getExpirationLabel(3, intl), value: 3 },
    { label: getExpirationLabel(4, intl), value: 4 },
    { label: getExpirationLabel(5, intl), value: 5 },
    { label: getExpirationLabel(6, intl), value: 6 },
    { label: getExpirationLabel(7, intl), value: 7 },
  ];

  const chosePeriod = useCallback(item => {
    setPeriod(item);
    setIsVisible(false);
  }, []);

  return (
    <div className="dropdown-select">
      <button
        className="text-capitalize dropdown-toggle-select text-left"
        type="submit"
        onClick={() => setIsVisible(!isVisible)}
      >
        {getExpirationLabel(period.value, intl)}
        <ArrowIcon
          className={classNames('toggle-icon-select', {
            open: isVisible,
          })}
        />
      </button>
      <div
        className={classNames('dropdown-menu-select', {
          open: isVisible,
        })}
      >
        {periodArr.map(item => (
          <button
            key={item.value}
            className="dropdown-menu-select-btn"
            type="button"
            value={item.value}
            onClick={() => {
              chosePeriod(item);
            }}
            onKeyDown={() => {
              chosePeriod(item);
            }}
          >
            <p className="mb-0 text-capitalize">{item.label}</p>
          </button>
        ))}
      </div>
    </div>
  );
};

export const ExpirationPeriodDropdownWithIntl = ExpirationPeriodDropdown;

interface Props {
  expiryHoursValue: string | null;
  setSelectedExpirationPeriod: Dispatch<SetStateAction<{ value: number; label: string }>>;
  selectedExpirationPeriod: { value: number; label: string };
}
const ExpirationPeriod: FC<Props> = ({ expiryHoursValue, setSelectedExpirationPeriod, selectedExpirationPeriod }) => (
  <div className="d-flex flex-column text-description">
    <span className="text-capitalize margin-bottom-5px">
      <LocaleFragment message={localeLegacy.accessKeyGenerator.expirationPeriod} />
      *: &nbsp;
    </span>
    <span className="text-uppercase">
      {expiryHoursValue ? (
        <span className="text-capitalize">
          {expiryHoursValue}
          &nbsp;
          <LocaleFragment message={localeLegacy.accessKeyGenerator.hours} />
        </span>
      ) : (
        <ExpirationPeriodDropdownWithIntl period={selectedExpirationPeriod} setPeriod={setSelectedExpirationPeriod} />
      )}
    </span>
  </div>
);

export default ExpirationPeriod;
