import classNames from 'classnames';
import { FC } from 'react';

import { localeTEREA } from '@lib/tools/locale/source/pmi/terea';
import LocaleFragment from '@lib/tools/locale/views/LocaleFragment';
import { useRetailerDesignSet } from '@lib/tools/views/hooks';

const MentholDisclaimer: FC = () => {
  const { isDesignSetTasteAdvisorIQOS4Global } = useRetailerDesignSet();

  const mentholDisclaimerMessage = <LocaleFragment message={localeTEREA.result.mentholDisclaimer} />;

  return (
    <div className="disclaimer-wrapper">
      <p
        className={classNames('my-0', {
          'ta-jp-p2-2': isDesignSetTasteAdvisorIQOS4Global,
          'ta-jp-p4_3_1': !isDesignSetTasteAdvisorIQOS4Global,
        })}
      >
        {mentholDisclaimerMessage}
      </p>
    </div>
  );
};

export default MentholDisclaimer;
