import { FC } from 'react';

import productImage from '@app/pmi/src/assets/media/legacy/blends/blends-product.png';
import { ReactComponent as BlendsLogo } from '@app/pmi/src/assets/media/legacy/blends/Logo.svg';
import useHealthWarningFooter from '@app/pmi/src/hooks/useHealthWarningFooter';

import { TLanguage } from '@lib/core/retailers/types';
import { localeLegacy } from '@lib/tools/locale/source/pmi/legacy';
import LocaleFragment from '@lib/tools/locale/views/LocaleFragment';
import { useAddons } from '@lib/tools/views/hooks';

import CTAButton from '@components/pmi/src/designSets/blends/main/Button';
import Disclaimer from '@components/pmi/src/designSets/blends/main/Disclaimer';
import HealthWarningFooter from '@components/pmi/src/designSets/blends/main/HealthWarningFooter';
import LanguageSwitcher from '@components/pmi/src/designSets/blends/main/LanguageSwitcher';

interface Props {
  supportedLanguages: TLanguage[];
  selectedLanguageCode: string;
  onLanguageSelect: (code: string) => void;
  handleStartTasteLocalTest: () => void;
  isShowFooter?: boolean;
  enableDisclaimer: boolean;
}

const LandingPage: FC<Props> = ({
  supportedLanguages,
  selectedLanguageCode,
  onLanguageSelect,
  handleStartTasteLocalTest,
  enableDisclaimer,
}) => {
  const { isHealthWarningLandingBottom10PerAddon } = useAddons();
  const isBlendsFooter = true;
  const padding = useHealthWarningFooter(24, isBlendsFooter);

  return (
    <div
      className="blends-landing"
      style={isHealthWarningLandingBottom10PerAddon ? { paddingBottom: `${padding}px` } : {}}
    >
      <img alt="product" className="blends-product-image" src={productImage} />
      <LanguageSwitcher
        selectedLanguageCode={selectedLanguageCode}
        supportedLanguages={supportedLanguages}
        onLanguageSelect={onLanguageSelect}
      />

      <div className="blends-landing-content-wrapper">
        <div className="first-part">
          <BlendsLogo className="blends-logo content-margin-bottom" />
          <p className="blends-title-font text-soft-white mb-0">
            <LocaleFragment message={localeLegacy.blends.landingTitle1} />
          </p>
          <p className="blends-title-font-bold text-soft-white content-margin-bottom">
            <LocaleFragment message={localeLegacy.blends.landingTitle2} />
          </p>
          <CTAButton
            btnText={<LocaleFragment message={localeLegacy.blends.landingCta} />}
            onBtnClick={handleStartTasteLocalTest}
          />
        </div>
        {enableDisclaimer ? (
          <div className="second-part">
            <Disclaimer />
          </div>
        ) : null}
      </div>

      {isHealthWarningLandingBottom10PerAddon ? <HealthWarningFooter /> : null}
    </div>
  );
};

export default LandingPage;
