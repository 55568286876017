import classNames from 'classnames';
import React, { FC, Fragment, useState } from 'react';
import { Col, Row } from 'reactstrap';

import { TProductInstance } from '@lib/core/products/types';
import { useApp } from '@lib/core/service/hooks';
import { localeLegacy } from '@lib/tools/locale/source/pmi/legacy';
import CustomMDReactComponent from '@lib/tools/locale/views/CustomMDReactComponent';
import LocaleFragment from '@lib/tools/locale/views/LocaleFragment';
import { reOrderEssencesForIQOS3Designs } from '@lib/tools/shared/pmi/essences';
import { HEETS_ESSENCE_COOLING_ID } from '@lib/tools/shared/pmi/essences/consts';
import { useAddons, useEqualElementsHeightV2 } from '@lib/tools/views/hooks';

import Feedback from '@components/pmi/src/designSets/heets/common/FeedbackTasteAdvisor3';

interface Props {
  genericProductRetailerLocation: TProductInstance;
  clickPairGprl?: TProductInstance;
}

const IQOS3OneProductCard: FC<Props> = ({ genericProductRetailerLocation: gprl, clickPairGprl }) => {
  const {
    isProductLongDescriptionHiddenAddon,
    isAromaNotesDisabledAddon,
    isProductShortDescriptionHiddenAddon,
    isTobaccoExperienceHiddenAddon,
    isEssenceCoolingDisabledAddon,
    isEssenceBodyDisabledAddon,
    isFeedbackDisabledAddon,
    isEssenceAromaDisabledAddon,
    isMentholDisclaimerAddon,
    isAromaNoteSecondaryAddon,
  } = useAddons();
  const { isLayoutRightToLeft } = useApp();

  const [isClickProductToggled, setIsClickProductToggled] = useState(true);

  const toggleClickProduct = () => setIsClickProductToggled(!isClickProductToggled);
  const { product, identifier, image } = gprl;
  const { attributes: productAttributes, name, caption, description, aromas, essences: essencesProduct } = product;
  const { show_image_disclaimer: showImageDisclaimer, color, is_pearl: isPearlAttribute } = productAttributes;
  const isShowProductImageDisclaimerAttributes = showImageDisclaimer === 1;
  const isPearl = isPearlAttribute === 'true';
  const clickPairProduct = clickPairGprl?.product;
  const currentColorSwitcher = isClickProductToggled ? clickPairProduct?.attributes?.color : color;
  const currentColor = isClickProductToggled || !clickPairProduct ? color : clickPairProduct?.attributes?.color;
  const currentEssences = isClickProductToggled || !clickPairProduct ? essencesProduct : clickPairProduct?.essences;

  const essences = reOrderEssencesForIQOS3Designs({
    essences: currentEssences,
    isEssenceAromaDisabled: isEssenceAromaDisabledAddon,
    isEssenceBodyDisabled: isEssenceBodyDisabledAddon,
    isEssenceCoolingDisabled: isEssenceCoolingDisabledAddon,
  });
  const coolingEssence = essences.find(essence => essence.essence_id === HEETS_ESSENCE_COOLING_ID);
  const isCooling = Number(coolingEssence?.score) > 0 && isMentholDisclaimerAddon;

  useEqualElementsHeightV2([
    'iqos3-product-card-title-ref',
    'iqos3-product-card-description-ref',
    'iqos3-product-card-image-disclaimer-ref',
    'iqos3-product-card-tobacco-experience-ref',
    'iqos3-product-card-essences-ref',
    'iqos3-product-card-switcher-ref',
  ]);

  return (
    <Col key={identifier} className="taste-advisor-3-one-character-product px-0 px-md-4" xs={6}>
      <Row className="mx-0 content justify-content-center">
        <Col className="col-wrapper d-flex flex-column no-padding">
          <div className="iqos3-product-card-title-ref character-product-name-wrapper">
            <h4 className="ta-pmi3-p3_3-bold inner-p-mb-0 character-product-name">
              {name.split('\n').map((el, i) => (
                <CustomMDReactComponent key={i} text={el} />
              ))}
            </h4>
          </div>

          <Fragment>
            <img alt="" className="product-image mx-auto" src={image} />
            <div className="iqos3-product-card-image-disclaimer-ref">
              {isShowProductImageDisclaimerAttributes && (
                <div className="mx-auto image-disclaimer">
                  <span className="ta-pmi3-p4">
                    <LocaleFragment message={localeLegacy.iqos3.resultImageDisclaimer} />
                  </span>
                </div>
              )}
            </div>
          </Fragment>

          {!isProductShortDescriptionHiddenAddon && (
            <div
              className={classNames('iqos3-product-card-tobacco-experience-ref ta-pmi3-p3_1-bold', 'product-caption', {
                'without-tobacco-experience': isTobaccoExperienceHiddenAddon,
              })}
            >
              <div className="mb-0">
                {caption.split('\n').map((el, i) => (
                  <CustomMDReactComponent key={i} text={`${el}${isCooling ? '*' : ''}`} />
                ))}
              </div>
              {!isTobaccoExperienceHiddenAddon && (
                <span className="text-lowercase margin-top-minus3px mb-0 d-block">
                  {isPearl ? (
                    <LocaleFragment message={localeLegacy.iqos3.resultTobaccoExperienceWithCapsule} />
                  ) : (
                    <LocaleFragment message={localeLegacy.iqos3.resultTobaccoExperience} />
                  )}
                </span>
              )}
            </div>
          )}

          {!isProductLongDescriptionHiddenAddon && (
            <div className="iqos3-product-card-description-ref">
              <div className="ta-pmi3-p4 mb-0 mt-3 mt-md-3 px-3 text-align-center product-description">
                {description.split('\n').map((el, i) => (
                  <CustomMDReactComponent key={i} text={el} />
                ))}
              </div>
            </div>
          )}

          <div className="iqos3-product-card-switcher-ref">
            {clickPairGprl && (
              <Row className="align-items-center justify-content-center flex-nowrap switch-con">
                {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                <label
                  className="iqos3-switch my-auto"
                  htmlFor={`${identifier}_switch`}
                  style={{ border: `1px solid ${currentColorSwitcher}` }}
                >
                  <input
                    checked={isClickProductToggled}
                    id={`${identifier}_switch`}
                    type="checkbox"
                    onClick={toggleClickProduct}
                  />
                  <div style={{ backgroundColor: currentColorSwitcher }} />
                </label>
                <div className="ta-pmi3-p4">
                  {isClickProductToggled ? (
                    <LocaleFragment message={localeLegacy.iqos3.resultClickProductOn} />
                  ) : (
                    <LocaleFragment message={localeLegacy.iqos3.resultClickProductOff} />
                  )}
                </div>
              </Row>
            )}
          </div>

          <Row className="aroma-note-wrapper mx-auto">
            <Col className="px-0 d-flex flex-column">
              <div className="iqos3-product-card-essences-ref">
                {essences.length && (
                  <Row className="essences-wrapper d-block">
                    {essences.map(essence => (
                      <Fragment key={essence.essence_id}>
                        {parseInt(essence.score, 10) > 0 && (
                          <Col className="py-0 px-3" xs={12}>
                            <div className="essences-score-wrapper mt-1">
                              {new Array(5).fill(null).map((_, index) => {
                                return (
                                  <div
                                    key={index}
                                    className={classNames('essences-score', {
                                      rtl: isLayoutRightToLeft,
                                    })}
                                    style={{
                                      backgroundColor:
                                        parseInt(essence.score, 10) >= index + 1 ? currentColor : '#e5e5e5',
                                    }}
                                  />
                                );
                              })}
                            </div>
                            <p className="ta-pmi3-p4">{essence.name}</p>
                          </Col>
                        )}
                      </Fragment>
                    ))}
                  </Row>
                )}
              </div>
              {!isAromaNotesDisabledAddon && (
                <Row className="aroma-note flex-row-reverse">
                  <Col className="note d-flex flex-column text-left px-0" md={8} xs={12}>
                    <span
                      className={classNames('ta-pmi3-p4 text-nowrap', {
                        'text-right': isLayoutRightToLeft,
                      })}
                    >
                      {isPearl ? (
                        <LocaleFragment message={localeLegacy.iqos3.resultOnClick} />
                      ) : (
                        <LocaleFragment message={localeLegacy.iqos3.resultAromaNote} />
                      )}
                    </span>
                    <span
                      className={classNames('ta-pmi3-p3_1-bold mt-1', {
                        'text-right white-space-pre': isLayoutRightToLeft,
                      })}
                    >
                      {aromas?.[0]?.description}
                    </span>
                  </Col>
                  <Col className="aroma-img d-flex px-0" md={4} xs={12}>
                    <img
                      alt=""
                      className={classNames({
                        rtl: isLayoutRightToLeft,
                      })}
                      src={
                        isAromaNoteSecondaryAddon && aromas?.[0]?.secondary_image
                          ? aromas?.[0]?.secondary_image
                          : aromas?.[0]?.image
                      }
                    />
                  </Col>
                </Row>
              )}
            </Col>
          </Row>
        </Col>
      </Row>

      {!isFeedbackDisabledAddon && (
        <Row className="feedback-btns-wrapper mt-auto">
          <Feedback product={product} />
        </Row>
      )}
    </Col>
  );
};

export default React.memo(IQOS3OneProductCard);
