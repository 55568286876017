import { FC } from 'react';

import { localeLegacy } from '@lib/tools/locale/source/pmi/legacy';
import LocaleFragment from '@lib/tools/locale/views/LocaleFragment';

const DisableRetailerPage: FC = () => (
  <div className="iqos3-disable-retailer-page">
    <div className="info-block ta-pmi3-p1_2-bold">
      <p className="mb-0">
        <LocaleFragment message={localeLegacy.common.disableRetailerPageText1} />
      </p>

      <p className="mb-0">
        <LocaleFragment message={localeLegacy.common.disableRetailerPageText2} />
      </p>
    </div>
  </div>
);

export default DisableRetailerPage;
