import React, { FC } from 'react';
import { Row } from 'reactstrap';

interface Props {
  quizViewProgress: number;
}

const IQOS4ProgressBar: FC<Props> = ({ quizViewProgress }) => (
  <Row className="taste-advisor-4-one-progress-bar">
    <div className="bar mx-auto">
      <div className="filled-in" style={{ width: `calc(${quizViewProgress}% + 2px)` }} />
    </div>
  </Row>
);

export default React.memo(IQOS4ProgressBar);
