import { useCallback, useEffect, useLayoutEffect, useState } from 'react';

import { useAddons } from '@lib/tools/views/hooks';

const useHealthWarningFooter = (
  defaultPadding: number,
  isBlendsFooter = false,
  forcedHealthWarningFooter = false,
): number => {
  const [padding, setPadding] = useState(defaultPadding);
  const [resizeFlag, setResizeFlag] = useState<boolean>(false);

  const resizeCallback = useCallback(() => {
    setPadding(defaultPadding);
    setResizeFlag(prev => !prev);
  }, [defaultPadding]);

  const {
    isHealthWarningFooterAddon,
    isHealthWarningLandingBottom10PerAddon,
    isHealthWarningBottomBlack15PerAddon,
    isHealthWarningMultilingualAddon,
    isEnableDisclaimerAddon,
    isHealthWarningFooterToIsraelAddon,
  } = useAddons();

  const isHealthWarningFooterEnabled =
    isHealthWarningLandingBottom10PerAddon ||
    forcedHealthWarningFooter ||
    isHealthWarningFooterAddon ||
    isHealthWarningMultilingualAddon;
  const isBlendsHealthWarningFooterEnabled = isHealthWarningLandingBottom10PerAddon && isBlendsFooter;
  const isHealthWarningLandingBottomEnabled = isHealthWarningBottomBlack15PerAddon;
  const isHealthWarningFooterToIsraelEnabled = isHealthWarningFooterToIsraelAddon;

  useLayoutEffect(() => {
    const condition =
      isHealthWarningFooterEnabled ||
      isHealthWarningLandingBottomEnabled ||
      isEnableDisclaimerAddon ||
      isHealthWarningFooterToIsraelEnabled ||
      isBlendsHealthWarningFooterEnabled;

    if (condition) {
      let element: HTMLElement;

      if (isEnableDisclaimerAddon) {
        element = document.querySelector<HTMLElement>('.disclaimers-wrapper');
      }

      if (isHealthWarningFooterEnabled) {
        element = document.querySelector<HTMLElement>('.health-warning-footer');
      }

      if (isHealthWarningFooterToIsraelEnabled) {
        element = document.querySelector<HTMLElement>('.health-warning-footer-to-israel');
      }

      if (isHealthWarningLandingBottomEnabled) {
        element = document.querySelector<HTMLElement>('.health-warning-footer');
      }

      if (isBlendsHealthWarningFooterEnabled) {
        element = document.querySelector<HTMLElement>('.blends-health-warning');
      }

      const elementHeight = element ? element.offsetHeight : 0;
      setPadding(elementHeight || defaultPadding);
    }
  }, [
    isHealthWarningFooterEnabled,
    isHealthWarningLandingBottomEnabled,
    isEnableDisclaimerAddon,
    isHealthWarningFooterToIsraelEnabled,
    isBlendsHealthWarningFooterEnabled,
    resizeFlag,
    defaultPadding,
  ]);

  useEffect(() => {
    window.addEventListener('resize', resizeCallback);
    return () => window.removeEventListener('resize', resizeCallback);
  }, [resizeCallback]);

  useLayoutEffect(() => {
    // ToDo find approach for detect that child nodes are full rendered
    setTimeout(() => {
      resizeCallback();
    }, 500);
  }, [resizeCallback]);

  return padding;
};

export default useHealthWarningFooter;
