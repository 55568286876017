import cn from 'classnames';
import React, { FC } from 'react';
import { useMediaQuery } from 'react-responsive';

import tereaVideo from '@app/pmi/src/assets/media/legacy/ta_jp_terea.mp4';
import useHealthWarningFooter from '@app/pmi/src/hooks/useHealthWarningFooter';

import { TLanguage } from '@lib/core/retailers/types';
import { localeTEREA } from '@lib/tools/locale/source/pmi/terea';
import LocaleFragment from '@lib/tools/locale/views/LocaleFragment';
import { useAddons } from '@lib/tools/views/hooks';

import LanguageSwitcherComponent from '@components/pmi/src/common/LanguageSwitcher';
import Disclaimer from '@components/pmi/src/designSets/addOns/common/Disclaimer';
import HealthWarningFooter from '@components/pmi/src/designSets/addOns/common/HealthWarningFooter3';

interface TasteAdvisorRetailersPageTypes {
  handleStartTasteLocalTest: () => void;
  supportedLanguages: TLanguage[];
  retailerTags: Record<string, string>;
}

const TasteAdvisor4OneRetailerPageComponent: FC<TasteAdvisorRetailersPageTypes> = ({
  handleStartTasteLocalTest,
  supportedLanguages,
  retailerTags,
}) => {
  const isMobileResolution = useMediaQuery({ maxWidth: 600 });

  const {
    isHealthWarningFooterAddon,
    isHealthWarningBottomBlack15PerAddon,
    isHealthWarningLandingBottom10PerAddon,
    isHealthWarningMultilingualAddon,
    isEnableDisclaimerAddon,
    isHideIqosAddon,
  } = useAddons();

  const isHealthWarningFooterEnabled =
    isHealthWarningBottomBlack15PerAddon ||
    isHealthWarningLandingBottom10PerAddon ||
    isHealthWarningMultilingualAddon ||
    isHealthWarningFooterAddon;

  const paddingBottomValue = useHealthWarningFooter(0);

  const findFlavours = <LocaleFragment message={localeTEREA.landing.findFlavours} />;
  const discover = <LocaleFragment message={localeTEREA.landing.discover} />;

  return (
    <div className="taste-advisor-4-one-retailer-page-wrapper">
      <div className="bg-video px-0">
        <video autoPlay loop muted playsInline>
          <source src={retailerTags.video_cover ? retailerTags.video_cover : tereaVideo} type="video/mp4" />
        </video>
      </div>
      <div className="content-wrapper">
        <div className="content-part">
          <LanguageSwitcherComponent
            isTA4OneDS
            className="taste-advisor4-language-switcher-wrapper"
            supportedLanguages={[...supportedLanguages].reverse()}
          />
          <div
            className={cn('logo-img', {
              'hide-logo': isHideIqosAddon,
            })}
          />
          <div className="content-block">
            <div
              className={`header-text-wrapper inner-p-mb-0 ${
                isMobileResolution ? 'ta-jp-mob-h3-bold' : 'ta-jp-h3-bold'
              }`}
            >
              {findFlavours}
            </div>
            <div
              className={`description-text-wrapper inner-p-mb-0 ${isMobileResolution ? 'ta-jp-mob-p1 ' : 'ta-jp-p1'}`}
            >
              {discover}
            </div>
            <button
              type="button"
              className={`retailer-page-btn inner-p-mb-0 text-center px-0 ${
                isMobileResolution ? 'ta-jp-h4_2' : 'ta-jp-h4 '
              }`}
              onClick={handleStartTasteLocalTest}
            >
              <LocaleFragment message={localeTEREA.landing.buttonBegin} />
            </button>
          </div>
        </div>

        <div
          className="disclaimers-wrapper margin-bottom-10px justify-content-center"
          style={isHealthWarningFooterEnabled ? { paddingBottom: `${paddingBottomValue}px` } : {}}
        >
          {isEnableDisclaimerAddon && (
            <Disclaimer
              enableDisclaimer={false}
              privacyLink=""
              text={
                <div className="d-flex flex-column px-0">
                  <span>
                    <LocaleFragment message={localeTEREA.landing.disclaimerTextOne} />
                  </span>
                  <span className="mt-1">
                    <LocaleFragment message={localeTEREA.landing.disclaimerTextTwo} />
                  </span>
                  <span className="mt-1">
                    <LocaleFragment message={localeTEREA.landing.privacyText} />
                    &nbsp;
                    <a
                      className="text-decoration-underline"
                      href="https://www.pmiprivacy.com/consumer/"
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      <LocaleFragment message={localeTEREA.landing.privacyLink} />
                    </a>
                    .
                  </span>
                </div>
              }
            />
          )}
        </div>
      </div>
      <HealthWarningFooter isVisible={isHealthWarningFooterEnabled} legalWarningFlag={false} />
    </div>
  );
};

export default React.memo(TasteAdvisor4OneRetailerPageComponent);
