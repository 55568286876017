import { useSelector } from 'react-redux';

import {
  selectRetailerDefaultLanguage,
  selectRetailerError,
  selectRetailerId,
  selectRetailerLanguages,
  selectRetailerLoading,
  selectRetailerName,
  selectRetailerPageType,
  selectRetailerPrivacyUrl,
  selectRetailerSlug,
  selectRetailerTags,
  selectRetailerWidgetAccess,
} from '@lib/core/retailers/selectors/retailer';
import { TLanguage, TPageType, TRetailerAuthentication } from '@lib/core/retailers/types';

type IRetailer = {
  isRetailerLoading: boolean;
  retailerAuthType: TRetailerAuthentication;
  retailerDefaultLanguage: string;
  retailerError: string;
  retailerId: string;
  retailerLanguages: TLanguage[];
  retailerName: string;
  retailerPageType: TPageType;
  retailerPrivacyLink: string;
  retailerSlug: string;
  retailerTags: Record<string, any>;
};

export const useRetailer = (): IRetailer => {
  return {
    isRetailerLoading: useSelector(selectRetailerLoading),

    retailerAuthType: useSelector(selectRetailerWidgetAccess),
    retailerDefaultLanguage: useSelector(selectRetailerDefaultLanguage),
    retailerError: useSelector(selectRetailerError),
    retailerId: useSelector(selectRetailerId),
    retailerLanguages: useSelector(selectRetailerLanguages),
    retailerName: useSelector(selectRetailerName),
    retailerPageType: useSelector(selectRetailerPageType),
    retailerPrivacyLink: useSelector(selectRetailerPrivacyUrl),
    retailerSlug: useSelector(selectRetailerSlug),
    retailerTags: useSelector(selectRetailerTags),
  };
};
