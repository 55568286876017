import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { Col } from 'reactstrap';

import { TQuizQuestion } from '@lib/core/quizzes/types';
import { prependBasename } from '@lib/core/service/utils';
import { localeTEREA } from '@lib/tools/locale/source/pmi/terea';
import LocaleFragment from '@lib/tools/locale/views/LocaleFragment';
import { PAGES } from '@lib/tools/views/urls';

import Header from '@components/pmi/src/designSets/terea/main/one/Header';
import ProgressBar from '@components/pmi/src/designSets/terea/main/one/ProgressBar';
import QuestionText from '@components/pmi/src/designSets/terea/main/one/QuestionText';
import Answers from '@components/pmi/src/designSets/terea/main/one/Quiz/QDS1003/QuestionAnswers';

interface Props {
  handleSelectAnswerId: (idAnswer: string | string[]) => void;
  quizViewProgress: number;
  quizViewData: TQuizQuestion;
  handleGoBack: () => void;
  isFirstQuestion: boolean;
}

const AnswersTestTasteAdvisor4OneComponent: FC<Props> = ({
  handleSelectAnswerId,
  quizViewProgress,
  quizViewData,
  handleGoBack,
  isFirstQuestion,
}) => {
  const navigate = useNavigate();

  const goBack = () => {
    if (isFirstQuestion) {
      navigate(prependBasename(PAGES.ta.landing));
    } else {
      handleGoBack();
    }
  };

  return (
    <Col className="minh-100vh no-padding">
      <div className="bg-color-iqos4-teal">
        <Header goBack={goBack} isFirstQuestion={isFirstQuestion} />
        <ProgressBar quizViewProgress={quizViewProgress} />
        <QuestionText text={quizViewData?.text || ''} />
        <p className="mb-0 ta-jp-mob-p1 ta-jp-p1 text-center px-2 pb-5">
          <LocaleFragment message={localeTEREA.quiz.pickOneAnswer} />
        </p>
      </div>
      <Answers answers={quizViewData.answers} handleSelectAnswerId={handleSelectAnswerId} />
    </Col>
  );
};

export default AnswersTestTasteAdvisor4OneComponent;
