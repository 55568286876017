import classNames from 'classnames';
import React, { FC } from 'react';
import { Col, Row } from 'reactstrap';

import blendsIconAnswerSelected from '@app/pmi/src/assets/media/legacy/blends/answer_selected.svg';

interface Props {
  id: string;
  imageUrl?: string;
  text: string;
  className?: string;
  isQDS8001?: boolean;
  onSelect: (id: string) => void;
}

const AnswerItem: FC<Props> = ({ id, imageUrl, text, onSelect, className }) => (
  <Col className={classNames('answer-item', 'px-0', 'd-flex', 'flex-column', 'align-items-center', className)}>
    <Row className="image-box mx-auto">
      <img alt="selected" className="tick" src={blendsIconAnswerSelected} />
      <button
        className="image"
        style={{ background: `url(${imageUrl || ''}) no-repeat` }}
        type="button"
        onClick={() => {
          onSelect(id);
        }}
      />
    </Row>
    <Row className="mx-auto w-100">
      <button
        className="answer-ta-button ta-veev-p2 justify-content-center"
        type="button"
        onClick={() => {
          onSelect(id);
        }}
      >
        {text}
      </button>
    </Row>
  </Col>
);

export default React.memo(AnswerItem);
