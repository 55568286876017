import classNames from 'classnames';
import { ButtonHTMLAttributes, FC } from 'react';

import { useApp } from '@lib/core/service/hooks';

type LanguageSwitchProps = ButtonHTMLAttributes<HTMLButtonElement> & {
  supportedLanguages: Array<{ code: string; language: string }>;
  selectedLanguageCode: string;
  onLanguageSelect: (code: string) => void;
};

const LanguageSwitcher: FC<LanguageSwitchProps> = ({ onLanguageSelect, supportedLanguages, selectedLanguageCode }) => {
  const { isLayoutRightToLeft } = useApp();

  return (
    <div className="blends-lang-container text-soft-white">
      {supportedLanguages.map(({ code, language }) => (
        <button
          key={code}
          className={classNames('blends-lang-btn', {
            'ltr-orientation': !isLayoutRightToLeft,
            'rtl-orientation': isLayoutRightToLeft,
          })}
          onClick={() => onLanguageSelect(code)}
        >
          <span
            className={classNames('blends-content-font', { 'blends-content-font-bold': code === selectedLanguageCode })}
          >
            {language}
          </span>
        </button>
      ))}
    </div>
  );
};

export default LanguageSwitcher;
