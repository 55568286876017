import { getRequestCommonParameters } from '@lib/core/service/requests/api';

/**
 * Get list of quizzes
 *
 * @param {string} retailerSlug
 * @returns {string}
 */
export const apiUrlGetQuizzes = () => {
  const { retailerSlug } = getRequestCommonParameters();

  return `/tests/v2/${retailerSlug}/quizzes/`;
};

/**
 * Get quiz data
 *
 * @param {string} retailerSlug
 * @param {string} quizSlug
 * @returns {string}
 */
export const apiUrlGetQuiz = (quizSlug: string) => {
  const { retailerSlug } = getRequestCommonParameters();

  return `/tests/v2/${retailerSlug}/quizzes/${quizSlug}/`;
};

/**
 * Create new user_test
 *
 * @param {string} retailerSlug
 * @param {string} quizSlug
 * @returns {string}
 */
export const apiUrlPostUserQuiz = (quizSlug: string) => {
  const { retailerSlug } = getRequestCommonParameters();

  return `/tests/v2/${retailerSlug}/quizzes/${quizSlug}/user-tests/`;
};

/**
 * Submit an answer
 *
 * @param {string} retailerSlug
 * @param {string} userQuizSlug
 * @param {string} userQuizId
 * @returns {string}
 */
export const apiUrlPatchUserQuizAnswer = (userQuizSlug: string, userQuizId: string) => {
  const { retailerSlug } = getRequestCommonParameters();

  return `/tests/v2/${retailerSlug}/quizzes/${userQuizSlug}/user-tests/${userQuizId}/record-new-answer/`;
};

/**
 * Flag the user_test completed to get the results
 *
 * @param {string} retailerSlug
 * @param {string} userQuizSlug
 * @param {string} quizId
 * @returns {string}
 */
export const apiUrlPatchUserQuizComplete = (userQuizSlug: string, quizId: string) => {
  const { retailerSlug } = getRequestCommonParameters();

  return `/tests/v2/${retailerSlug}/quizzes/${userQuizSlug}/user-tests/${quizId}/`;
};

/**
 * Associates a user to an anon userQuiz
 *
 * @param {string} retailerSlug
 * @param {string} userQuizSlug
 * @param {string} quizId
 * @returns {string}
 */
export const apiUrlPatchUserQuizAssociateUser = (userQuizSlug: string, quizId: string) => {
  const { retailerSlug } = getRequestCommonParameters();

  return `/tests/v2/${retailerSlug}/quizzes/${userQuizSlug}/user-tests/${quizId}/link-user/`;
};

/**
 * Populate metadata field
 *
 * @param {string} retailerSlug
 * @param {string} userQuizSlug
 * @param {string} quizId
 * @returns {string}
 */
export const apiUrlPatchUserQuizMetadata = (userQuizSlug: string, quizId: string) => {
  const { retailerSlug } = getRequestCommonParameters();

  return `/tests/v2/${retailerSlug}/quizzes/${userQuizSlug}/user-tests/${quizId}/set-metadata/`;
};
