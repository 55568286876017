import { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { actionPostProductFeedback } from '@lib/core/products/slices/feedback';
import { selectUserQuizId } from '@lib/core/quizzes/selectors';
import { actionPatchUserQuizMetadata } from '@lib/core/quizzes/slices';
import { localeV2 } from '@lib/tools/locale/source/pmi/v2';
import LocaleFragment from '@lib/tools/locale/views/LocaleFragment';

import CTAButton from '@components/pmi/src/designSetsV2/Components/CTA/Button';

interface Props {
  className?: string;
  isBtnDisable?: boolean;
  isFusePage?: boolean;
  identifier?: string;
  productSetID?: string;
  handleBundleProductFeedback?: (data: Record<string, string>) => void;
}

function Feedback({
  className = '',
  isBtnDisable = false,
  identifier,
  handleBundleProductFeedback,
  productSetID,
  isFusePage,
}: Props) {
  const dispatch = useDispatch();
  const userQuizId = useSelector(selectUserQuizId);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isLiked, setIsLiked] = useState<null | boolean>(null);

  const fetchPostFeedbackDispatched = useCallback(
    value => {
      if (value !== isLiked) {
        setIsLiked(!!value);
        dispatch(
          actionPostProductFeedback({
            metadata: {
              feedback: {
                liked: value,
                product: identifier,
              },
            },
            session: userQuizId,
          }),
        );
      }
    },
    [isLiked],
  );

  const handlePostFeedbackDispatched = (value: boolean) => {
    setIsSubmitted(prev => !prev);
    if (productSetID && identifier) {
      // Bundles page
      const feedbackPayload = { product_set_id: productSetID };
      feedbackPayload[identifier] = !!value;

      handleBundleProductFeedback(feedbackPayload);
    } else if (isFusePage) {
      //  Fuse Page
      const payload = {
        metadata: {
          best_match_feedback: [{ [identifier]: value }],
        },
      };

      dispatch(actionPatchUserQuizMetadata(payload));
    } else {
      // Result page
      fetchPostFeedbackDispatched(value);
    }
  };

  return (
    <div className={`ta-v2-feedback-card ${className}`}>
      <span className="ta-v2-feedback-title font-ta-v2-global-caption-small">
        <LocaleFragment message={localeV2.product.feedbackTitle} />
      </span>
      {isSubmitted ? (
        <div className="ta-v2-feedback-confirmation">
          <span className="font-ta-v2-custom-paragraph">
            <LocaleFragment message={localeV2.product.onClickFeedback} />
          </span>
          <button
            className="ta-v2-feedback-undo font-ta-v2-custom-underline-paragraph-bold"
            onClick={() => setIsSubmitted(prev => !prev)}
          >
            <LocaleFragment message={localeV2.product.feedbackUndo} />
          </button>
        </div>
      ) : (
        <div className="ta-v2-feedback-btn-group">
          <CTAButton
            isDark
            btnText={<LocaleFragment message={localeV2.product.feedbackYes} />}
            className="ta-v2-feedback-btn"
            icon="check"
            isDisabled={isBtnDisable}
            onBtnClick={() => handlePostFeedbackDispatched(true)}
          />
          <CTAButton
            isDark
            btnText={<LocaleFragment message={localeV2.product.feedbackNo} />}
            className="ta-v2-feedback-btn"
            icon="close"
            isDisabled={isBtnDisable}
            onBtnClick={() => handlePostFeedbackDispatched(false)}
          />
        </div>
      )}
    </div>
  );
}

export default Feedback;
