import { PayloadAction, createAsyncThunk, createSlice, isAnyOf } from '@reduxjs/toolkit';

import { selectUserQuizId } from '@lib/core/quizzes/selectors';
import {
  selectRemoteAccessCustomId,
  selectRemoteAccessExpiry,
  selectRemoteAccessKey,
  selectRemoteAccessRetries,
} from '@lib/core/retailers/selectors/remoteAccess';
import { selectRetailerId } from '@lib/core/retailers/selectors/retailer';
import {
  apiUrlGenerateAccessKey,
  apiUrlOpenIframeIntegration,
  apiUrlUseAccessKey,
  apiUrlVerifyAccessKey,
} from '@lib/core/retailers/slices/urls';
import { TGenerateRetailerAccessKey } from '@lib/core/retailers/types';
import { REQUEST_METHODS } from '@lib/core/service/consts';
import request from '@lib/core/service/requests/request';
import { RootState } from '@lib/core/service/types/appStateType';
import { uuidv4 } from '@lib/core/service/utils';

export interface IRemoteAccessSlice {
  data: TGenerateRetailerAccessKey;
  error: string;
  isLoading: boolean;
}

const initialState: IRemoteAccessSlice = {
  data: undefined,
  error: '',
  isLoading: false,
};

export const actionVerifyRemoteAccessKey = createAsyncThunk(
  'retailers/remoteAccess/verifyKey',
  async (_, { getState, rejectWithValue }) => {
    // @ts-ignore
    const state: RootState = getState();

    const config = {
      method: REQUEST_METHODS.POST,
    };

    const remoteExpiry = selectRemoteAccessExpiry(state);
    const remoteAccessKey = selectRemoteAccessKey(state);
    const remoteRetries = selectRemoteAccessRetries(state);
    const remoteCustomId = selectRemoteAccessCustomId(state);
    const retailerId = selectRetailerId(state);
    const userQuizId = selectUserQuizId(state);

    const requestBody = {
      access_key: remoteAccessKey,
      custom_id: remoteCustomId,
      expiry: remoteExpiry.replace(' ', '+'),
      retailer_id: retailerId,
      retries: remoteRetries,
      user_session_id: userQuizId || uuidv4(),
    };

    if (!remoteCustomId) delete requestBody.custom_id;

    try {
      return await request(apiUrlVerifyAccessKey, config, requestBody);
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const actionUseRemoteAccessKey = createAsyncThunk(
  'retailers/remoteAccess/useKey',
  async (_, { getState, rejectWithValue }) => {
    // @ts-ignore
    const state: RootState = getState();

    const config = {
      method: REQUEST_METHODS.POST,
    };

    const remoteExpiry = selectRemoteAccessExpiry(state);
    const remoteAccessKey = selectRemoteAccessKey(state);
    const remoteRetries = selectRemoteAccessRetries(state);
    const remoteCustomId = selectRemoteAccessCustomId(state);
    const retailerId = selectRetailerId(state);
    const userQuizId = selectUserQuizId(state);

    const requestBody = {
      access_key: remoteAccessKey,
      custom_id: remoteCustomId,
      expiry: remoteExpiry.replace(' ', '+'),
      retailer_id: retailerId,
      retries: remoteRetries,
      user_session_id: userQuizId,
    };

    if (!remoteCustomId) delete requestBody.custom_id;

    try {
      return await request(apiUrlUseAccessKey, config, requestBody);
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const actionGenerateRemoteAccessKey = createAsyncThunk(
  'retailers/remoteAccess/generate',
  async (
    requestData: { retailer_id: string; retries: string; timestamp: string; custom_id?: string },
    { rejectWithValue },
  ) => {
    const config = {
      method: REQUEST_METHODS.POST,
    };

    if (!requestData.custom_id) delete requestData.custom_id;

    try {
      return await request(apiUrlGenerateAccessKey, config, requestData);
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const actionSetRemoteAccessParams = createAsyncThunk(
  'retailers/remoteAccess/setParams',
  (params: TGenerateRetailerAccessKey) => params,
);

export const actionValidateOpenIntegration = createAsyncThunk(
  'retailers/remoteAccess/validateOpenIntegration',
  async (requestData: { ciphertext: string; retailer_identifier: string; custom_id?: string }, { rejectWithValue }) => {
    const config = {
      method: REQUEST_METHODS.POST,
    };

    try {
      return await request(apiUrlOpenIframeIntegration, config, requestData);
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

const remoteAccessSlice = createSlice({
  extraReducers: builder => {
    builder.addMatcher(
      isAnyOf(
        actionGenerateRemoteAccessKey.fulfilled,
        actionSetRemoteAccessParams.fulfilled,
        actionValidateOpenIntegration.fulfilled,
      ),
      (state, action: PayloadAction<TGenerateRetailerAccessKey>) => {
        state.data = { ...action.payload, is_valid: true };
        state.error = '';
        state.isLoading = false;
      },
    );
    builder.addMatcher(
      isAnyOf(
        actionGenerateRemoteAccessKey.pending,
        actionUseRemoteAccessKey.pending,
        actionVerifyRemoteAccessKey.pending,
        actionValidateOpenIntegration.pending,
      ),
      state => {
        state.error = '';
        state.isLoading = true;
      },
    );

    builder.addMatcher(
      isAnyOf(actionVerifyRemoteAccessKey.fulfilled, actionUseRemoteAccessKey.fulfilled),
      (state, action: PayloadAction<TGenerateRetailerAccessKey>) => {
        state.data.is_valid = action.payload?.is_valid;
        state.error = '';
        state.isLoading = false;
      },
    );
    builder.addMatcher(
      isAnyOf(
        actionGenerateRemoteAccessKey.rejected,
        actionUseRemoteAccessKey.rejected,
        actionVerifyRemoteAccessKey.rejected,
        actionValidateOpenIntegration.rejected,
      ),
      state => {
        state.error = 'Access Key Error';
        state.isLoading = false;

        if (!state.data) {
          state.data = {
            access_key: undefined,
            custom_id: undefined,
            expiry: undefined,
            is_valid: false,
            retries: undefined,
          };
        } else {
          state.data.is_valid = false;
        }
      },
    );
  },
  initialState,
  name: 'remoteAccess',
  reducers: {
    actionResetRemoteAccess: () => initialState,
  },
});

export default remoteAccessSlice.reducer;

export const { actionResetRemoteAccess } = remoteAccessSlice.actions;
