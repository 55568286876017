import { store } from '@lib/core/service/store';
import {
  selectKioskUserProductCategory,
  selectKioskUserRetailerLocationId,
  selectKioskUserRetailerSlug,
  selectUserEmail,
  selectUserLanguage,
} from '@lib/core/users/selectors/user';

/**
 * Utility class for working with user data.
 */
class UserUtils {
  /**
   * Retrieves the kiosk user data from the store.
   * @returns The kiosk user data.
   */
  public static getKioskUser() {
    const state = store.getState();

    const userEmail = selectUserEmail(state);
    const kioskUserRetailerSlug = selectKioskUserRetailerSlug(state);
    const userLanguage = selectUserLanguage(state);
    const kioskUserProductCategory = selectKioskUserProductCategory(state);
    const kioskUserRetailerLocationId = selectKioskUserRetailerLocationId(state);
    return {
      kioskUserProductCategory,
      kioskUserRetailerLocationId,
      kioskUserRetailerSlug,
      userEmail,
      userLanguage,
    };
  }
}

export default UserUtils;
