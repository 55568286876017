import classnames from 'classnames';
import { FC, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { Col, Container, Nav, NavItem, NavLink, Row, TabContent, TabPane } from 'reactstrap';

import { TProductInstance } from '@lib/core/products/types';
import { localeTEREA } from '@lib/tools/locale/source/pmi/terea';
import LocaleFragment from '@lib/tools/locale/views/LocaleFragment';
import { filterDimensionsProducts, orderTereaProductsList } from '@lib/tools/shared/pmi/products';
import { isProductTagEuEssences } from '@lib/tools/shared/pmi/products/filters';
import { SENTIA, TEREA } from '@lib/tools/shared/pmi/retailers/consts';
import { useAddons, useRetailerDesignSet } from '@lib/tools/views/hooks';

import MentholDisclaimer from '@components/pmi/src/designSets/addOns/common/MentholDisclaimer';
import ProductPortfolioCard from '@components/pmi/src/designSets/terea/main/one/ProductPortfolioCard';

interface Props {
  gprls: TProductInstance[];
  isProductShortDescriptionHidden?: boolean;
  retailerTags: Record<string, string>;
}

const ProductPortfolio: FC<Props> = ({ gprls, isProductShortDescriptionHidden, retailerTags }) => {
  const { isDesignSetTasteAdvisorIQOS4One, isDesignSetTasteAdvisorIQOS4Global } = useRetailerDesignSet();

  const [activeTab, setActiveTab] = useState<typeof TEREA | typeof SENTIA>(TEREA);

  const sentiaProducts = [];
  const tereaProducts = [];

  gprls.forEach(grpl => {
    if (grpl.product.attributes.product_type === SENTIA) {
      sentiaProducts.push(grpl);
    } else {
      tereaProducts.push(grpl);
    }
  });

  const orderedTereaProducts = orderTereaProductsList(tereaProducts);
  const orderedSentiaProducts = orderTereaProductsList(sentiaProducts);

  const nonDimensionsTAProductsList = filterDimensionsProducts(orderedTereaProducts, false);

  const dimensionsTAProductsList = filterDimensionsProducts(orderedTereaProducts, true);

  const isDimensionsProduct = dimensionsTAProductsList.length > 0;

  const { isMentholDisclaimerAddon } = useAddons();

  const isMobileResolution = useMediaQuery({ query: '(max-width: 767px)' });
  const isEUessencesPresented = gprls.some(gprl => gprl.retailer_product_location_tags?.some(isProductTagEuEssences));

  const tereaProductsEl = (
    <>
      {isDimensionsProduct && isDesignSetTasteAdvisorIQOS4Global ? (
        <>
          <div
            className={`d-block ta-jp-h5-bold_1 text-uppercase ${
              isMobileResolution ? 'margin-top-20px' : 'margin-top-30px'
            } margin-bottom-20px text-center`}
          >
            <LocaleFragment message={localeTEREA.result.terea} />
          </div>
          <Row className={classnames('cards-wrapper mx-auto', { 'japan-wrapper': isDesignSetTasteAdvisorIQOS4One })}>
            {nonDimensionsTAProductsList.map(retailerProductLocation => (
              <ProductPortfolioCard
                key={retailerProductLocation.identifier}
                isEUessencesPresented={isEUessencesPresented}
                isProductShortDescriptionHidden={isProductShortDescriptionHidden}
                retailerProductLocation={retailerProductLocation}
                retailerTags={retailerTags}
              />
            ))}
          </Row>
          <div className="d-block ta-jp-h5-bold_1 margin-top-20px margin-bottom-20px text-center">
            <LocaleFragment message={localeTEREA.result.tereaDimensions} />
          </div>
          <Row className={classnames('cards-wrapper mx-auto', { 'japan-wrapper': isDesignSetTasteAdvisorIQOS4One })}>
            {dimensionsTAProductsList.map(retailerProductLocation => (
              <ProductPortfolioCard
                key={retailerProductLocation.identifier}
                isEUessencesPresented={isEUessencesPresented}
                isProductShortDescriptionHidden={isProductShortDescriptionHidden}
                retailerProductLocation={retailerProductLocation}
                retailerTags={retailerTags}
              />
            ))}
          </Row>
        </>
      ) : (
        <Row className={classnames('cards-wrapper mx-auto', { 'japan-wrapper': isDesignSetTasteAdvisorIQOS4One })}>
          {orderedTereaProducts.map(retailerProductLocation => (
            <ProductPortfolioCard
              key={retailerProductLocation.identifier}
              isEUessencesPresented={isEUessencesPresented}
              isProductShortDescriptionHidden={isProductShortDescriptionHidden}
              retailerProductLocation={retailerProductLocation}
              retailerTags={retailerTags}
            />
          ))}
        </Row>
      )}
    </>
  );

  const sentiaProductsEl = (
    <Row className={classnames('cards-wrapper mx-auto', { 'japan-wrapper': isDesignSetTasteAdvisorIQOS4One })}>
      {orderedSentiaProducts.map(retailerProductLocation => (
        <ProductPortfolioCard
          key={retailerProductLocation.identifier}
          isEUessencesPresented={isEUessencesPresented}
          isProductShortDescriptionHidden={isProductShortDescriptionHidden}
          retailerProductLocation={retailerProductLocation}
          retailerTags={retailerTags}
        />
      ))}
    </Row>
  );

  return (
    <Container
      fluid
      className={classnames('taste-advisor-4-product-portfolio px-0 w-100 max-width-100percent', {
        'without-switcher': !sentiaProducts.length,
      })}
    >
      <Row className="portfolio-wrapper mx-0">
        <div className="d-block ta-jp-h4-bold ta-jp-mob-h4-bold text-center text-brand-slate-color">
          <LocaleFragment message={localeTEREA.result.discover} />
        </div>

        {sentiaProducts.length ? (
          <>
            <Nav tabs className="ta-jp-p1_2 font-weight-bold mt-3 mt-md-4 text-uppercase product-type-switcher">
              <Col className="px-0 h-100" xs={6}>
                <NavItem>
                  <NavLink
                    className={classnames({ notActive: activeTab !== TEREA }, 'terea mr-1')}
                    onClick={() => setActiveTab(TEREA)}
                  >
                    <LocaleFragment message={localeTEREA.result.terea} />
                  </NavLink>
                </NavItem>
              </Col>
              <Col className="px-0 h-100" xs={6}>
                <NavItem>
                  <NavLink
                    className={classnames({ notActive: activeTab !== SENTIA }, 'sentia')}
                    onClick={() => setActiveTab(SENTIA)}
                  >
                    <LocaleFragment message={localeTEREA.result.sentia} />
                  </NavLink>
                </NavItem>
              </Col>
            </Nav>
            <div className={`bg-color-wrapper padding-top-10px pb-3 pb-md-4 ${activeTab}`}>
              <Container className="px-0">
                <TabContent activeTab={activeTab} className="w-100">
                  <TabPane tabId="terea">{tereaProductsEl}</TabPane>
                  <TabPane tabId="sentia">{sentiaProductsEl}</TabPane>
                </TabContent>
              </Container>
            </div>
          </>
        ) : (
          <Container>{tereaProductsEl}</Container>
        )}
        {isMentholDisclaimerAddon && (
          <div className="max-width-1236px mx-auto margin-top-30px padding-x-10px">
            <MentholDisclaimer />
          </div>
        )}
      </Row>
    </Container>
  );
};

export default ProductPortfolio;
