import classNames from 'classnames';
import React, { FC } from 'react';
import { useDispatch } from 'react-redux';
import { Col, Row } from 'reactstrap';

import { isOpenIntegration, navigateHome } from '@components/pmi/src/utils';

interface Props {
  goBack: () => void;
  isFirstQuestion: boolean;
  isLayoutRightToLeft: boolean;
}

const IQOS3OneHeader: FC<Props> = ({ goBack, isFirstQuestion, isLayoutRightToLeft }) => {
  const dispatch = useDispatch();

  return (
    <Row className="mx-0 taste-advisor-3-one-header">
      <Col className="max-height-30 px-0">
        {isOpenIntegration() ? null : (
          <button
            className="general-btn home-btn"
            type="button"
            onClick={() => {
              navigateHome(dispatch);
            }}
          />
        )}
        {!isFirstQuestion && (
          <button
            type="button"
            className={classNames('general-btn back-btn', {
              rtl: isLayoutRightToLeft,
            })}
            onClick={goBack}
          />
        )}
      </Col>
    </Row>
  );
};

export default React.memo(IQOS3OneHeader);
