import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import { broadcastDomHeightToHost } from '@app/pmi/src/comms/utils';

import { useQuizzes } from '@lib/core/quizzes/hooks';
import { useRemoteAccess } from '@lib/core/retailers/hooks/remoteAccess';
import { useRetailer } from '@lib/core/retailers/hooks/retailer';
import {
  selectRetailerCommunicationSetText,
  selectRetailerLocationAddOns,
} from '@lib/core/retailers/selectors/retailerLocation';
import { useApp } from '@lib/core/service/hooks';
import { setAppLocale } from '@lib/core/service/slices';
import { prependBasename } from '@lib/core/service/utils';
import { HEETS, TEREA, VEEV } from '@lib/tools/shared/pmi/retailers/consts';
import { useAddons, useRetailerDesignSet } from '@lib/tools/views/hooks';
import { useTypedDispatch } from '@lib/tools/views/hooks/useTypedDispatch';
import { PAGES } from '@lib/tools/views/urls';

import TABLENDSLandingPage from '@components/pmi/src/designSets/blends/main/LandingPage';
import LandingCrafted from '@components/pmi/src/designSets/crafted/Pages/LandingPage/LandingPage';
import TA3RetailerPageComponent from '@components/pmi/src/designSets/heets/main/Landing';
import TA4OneRetailerPageComponent from '@components/pmi/src/designSets/terea/main/LandingPage';
import LandingPage from '@components/pmi/src/designSetsV2/Components/Pages/LandingPage/LandingPage';
import DisableRetailerPage from '@components/pmi/src/DisableRetailerPage';

import imgBackground from 'assets/media/crafted/background_cover.png';
import imgLogo from 'assets/media/crafted/botanicals_landing_logo.svg';
import TAV2LandingPageHeetsBgVideo from 'assets/media/V2/heets_v2_landing_restricted_video_original.mp4';
import TAV2LandingPageTereaBgVideo from 'assets/media/V2/terea_v2_landing_restricted_video_original.mp4';

function LandingContainer() {
  const dispatch = useTypedDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const { locale } = useApp();

  const { retailerLanguages, retailerSlug, retailerTags, retailerPageType } = useRetailer();
  const { productCategory } = useApp();
  const retailerLocationAddOns: string[] = useSelector(selectRetailerLocationAddOns);
  const retailerCommunicationSet: string = useSelector(selectRetailerCommunicationSetText);

  const { isQuizzesFetching } = useQuizzes();
  const { previousRoute } = useApp();

  const {
    retailerDesignSet,
    isDesignSetTasteAdvisorIQOS3One,
    isDesignSetTasteAdvisorCrafted,
    isDesignSetTasteAdvisorIQOS3Three,
    isDesignSetTasteAdvisorIQOS4One,
    isDesignSetTasteAdvisorIQOS4Global,
    isDesignSetTasteAdvisorV2,
    isDesignSetTasteAdvisorBlendsOne,
  } = useRetailerDesignSet();

  const {
    isEnableDisclaimerAddon,
    isDisableRetailerAddon,
    isHideIqosAddon,
    isCustomInfoPageAddon,
    isHealthWarningFooterToIsraelAddon,
  } = useAddons();

  const { remoteAccessKey } = useRemoteAccess();

  useEffect(() => {
    if (location.pathname.includes(PAGES.ta.quiz.local)) {
      if (!isDesignSetTasteAdvisorIQOS4One && !isDesignSetTasteAdvisorIQOS4Global) {
        document.body.style.backgroundColor = '#f9f8f8';
      }
    }

    broadcastDomHeightToHost();
  }, [productCategory]);

  const handleStartTasteLocalTest = () => {
    navigate(prependBasename(remoteAccessKey ? `${PAGES.ta.quiz.remote}` : `${PAGES.ta.quiz.local}`));
  };

  const sendProps = {
    dispatch,
    handleStartTasteLocalTest,
    locale,
    pageType: retailerPageType,
    previousRoute,
    productCategory,
    retailerCommunicationSet,
    retailerDesignSet,
    retailerLocationAddOns,
    retailerSlug,
    retailerTags,
    supportedLanguages: retailerLanguages,
  };

  const onLanguageSelect = code => {
    dispatch(setAppLocale(code));
  };

  if (isDesignSetTasteAdvisorV2) {
    let currentBgVideo = '';
    let productLogo = '';
    if (productCategory === TEREA) {
      productLogo = retailerTags.image_cover_terea
        ? retailerTags.image_cover_terea
        : 'https://media-staging.taste-advisor-vh.com/media/logos/v2_landing_logo_terea.png';
      currentBgVideo = retailerTags.video_cover_terea ? retailerTags.video_cover_terea : TAV2LandingPageTereaBgVideo;
    }

    if (productCategory === HEETS) {
      productLogo = retailerTags.image_cover_heets
        ? retailerTags.image_cover_heets
        : 'https://media.taste-advisor-vh.com/media/logos/v2_landing_logo_heets.png';
      currentBgVideo = retailerTags.video_cover_heets ? retailerTags.video_cover_heets : TAV2LandingPageHeetsBgVideo;
    }

    if (productCategory === VEEV) {
      productLogo = retailerTags.image_cover_veev
        ? retailerTags.image_cover_veev
        : 'https://media-staging.taste-advisor-vh.com/media/logos/v2_landing_veevone.png';
      currentBgVideo = retailerTags.video_cover_veev ? retailerTags.video_cover_veev : TAV2LandingPageHeetsBgVideo;
    }

    return (
      <LandingPage
        isEnableDisclaimerAddon
        backgroundVideo={currentBgVideo}
        handleStartTest={handleStartTasteLocalTest}
        isCustomInfoPageAddon={isCustomInfoPageAddon}
        isHealthWarningFooterToIsraelAddon={isHealthWarningFooterToIsraelAddon}
        isHideIqosAddon={isHideIqosAddon}
        isLight={productCategory === VEEV}
        isLoadingQuizzes={isQuizzesFetching}
        productCategory={productCategory}
        productLogo={productLogo}
        selectedLanguageCode={locale}
        supportedLanguages={retailerLanguages}
        onLanguageSelect={onLanguageSelect}
      />
    );
  }

  if (isDesignSetTasteAdvisorIQOS4One || isDesignSetTasteAdvisorIQOS4Global) {
    return <TA4OneRetailerPageComponent {...sendProps} />;
  }

  if (isDesignSetTasteAdvisorIQOS3One || isDesignSetTasteAdvisorIQOS3Three) {
    if (isDisableRetailerAddon) {
      return <DisableRetailerPage />;
    }
    return <TA3RetailerPageComponent {...sendProps} />;
  }

  if (isDesignSetTasteAdvisorBlendsOne) {
    return (
      <TABLENDSLandingPage
        {...sendProps}
        enableDisclaimer={isEnableDisclaimerAddon}
        selectedLanguageCode={locale}
        onLanguageSelect={onLanguageSelect}
      />
    );
  }
  if (isDesignSetTasteAdvisorCrafted) {
    return (
      <LandingCrafted
        backgroundImage={imgBackground}
        backgroundVideo="https://media-staging.taste-advisor-vh.com/media/video_covers/terea_landing_crafted.mp4"
        productLogo={imgLogo}
        onClick={() => navigate(prependBasename(PAGES.ta.quiz.crafted))}
      />
    );
  }

  return <div />;
}

export default LandingContainer;
