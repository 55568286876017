import { FC } from 'react';
import { useDispatch } from 'react-redux';
import { Col, Row } from 'reactstrap';

import { TCharacter } from '@lib/core/characters/types';
import { localeTEREA } from '@lib/tools/locale/source/pmi/terea';
import LocaleFragment from '@lib/tools/locale/views/LocaleFragment';
import { useAddons, useRetailerDesignSet } from '@lib/tools/views/hooks';

import { navigateHome } from '@components/pmi/src/utils';

interface TobaccoCharacterDescriptionProps {
  character: TCharacter;
}

const TobaccoCharacterDescriptionComponent: FC<TobaccoCharacterDescriptionProps> = ({ character }) => {
  const dispatch = useDispatch();

  const { isRestrictedResultDescriptionAddon } = useAddons();

  const { isDesignSetTasteAdvisorIQOS4Global } = useRetailerDesignSet();

  return (
    <Row className="mx-0 spec-resolution">
      <Col className="tobacco-header-taste-advisor-4-container px-0" xs={12}>
        <div className="mx-0 taste-advisor-4-result-header">
          <button
            className="home-btn"
            type="button"
            onClick={() => {
              navigateHome(dispatch);
            }}
          />
        </div>
        <div className="text-justify">
          <img alt="header_image" src={character.image} />
        </div>
        <Row className="text-center no-gutters general-m">
          {isRestrictedResultDescriptionAddon && isDesignSetTasteAdvisorIQOS4Global ? (
            <Col className="restricted-sentence">
              <div className="ta-jp-h4-question color-white">
                <LocaleFragment message={localeTEREA.result.ourRecommendation} />
              </div>
            </Col>
          ) : (
            <Col>
              <div className="ta-jp-h3 ta-jp-mob-h3 color-white bold px-2">
                <LocaleFragment message={localeTEREA.result.tasteCharacter} />
                <p className="ta-jp-h1 color-white">{character?.name || 'character-name'}</p>
              </div>

              <div className="hide ta-jp-p1 color-white w-75 w-md-50 text-center mx-auto margin-top-12px">
                {character?.character_caption || 'character_caption'}
              </div>
              <div className="hide ta-jp-p1 color-white w-75 w-md-50 text-center mx-auto">
                {character?.character_description || 'character_description'}
              </div>
            </Col>
          )}
        </Row>
      </Col>
    </Row>
  );
};

export default TobaccoCharacterDescriptionComponent;
export { TobaccoCharacterDescriptionComponent as TobaccoCharacterDescriptionComponentWithoutMemo };
