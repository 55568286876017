import { createSelector } from 'reselect';

import { IRetailerSlice } from '@lib/core/retailers/slices/retailer';
import { TLanguage, TPageType, TRetailerAgreementItem, TRetailerAuthentication } from '@lib/core/retailers/types';
import { IRetailerDetail } from '@lib/core/retailers/types/retailerDetail';
import { STORE } from '@lib/core/service/consts';
import { RootState } from '@lib/core/service/types/appStateType';
import { selectUserRetailerSlug, selectUserRoleIsKiosk } from '@lib/core/users/selectors/user';
import { USER_ROLE_KIOSK } from '@lib/core/users/utils/consts';
import { languages } from '@lib/tools/locale/utils/consts';
import { DISABLED_RETAILER_ACCESS } from '@lib/tools/shared/helpers/consts';

import { THIRD_PARTY_CATEGORY } from '@components/web/src/widget/consts';

/**
 * @returns the whole retailer state
 */
const selectRetailerState = (state): IRetailerSlice => state?.retailer || {};

/**
 * @returns {string} the retailer's data
 */
export const selectRetailerData: (state) => IRetailerDetail | Record<string, never> = createSelector(
  [
    (state: RootState) => state?.user?.data?.role === USER_ROLE_KIOSK,
    (state: RootState) => state?.user?.data?.initial_retailer,
    (state: RootState) => state?.retailer?.data,
  ],
  (isUserRoleKiosk, userRetailerData, retailerData) => {
    return isUserRoleKiosk ? userRetailerData : retailerData;
  },
);

/**
 * @returns {string} the retailer's slug
 */
export const selectRetailerSlug = (state: RootState): string => {
  // ! Revisit issue with tests
  const isUserRoleKiosk = selectUserRoleIsKiosk(state);
  const userRetailerSlug = selectUserRetailerSlug(state);

  return isUserRoleKiosk ? userRetailerSlug : state.retailer?.data?.slug || '';
};

/**
 * @returns {string}
 */
export const selectRetailerPrivacyUrl: (state) => string = createSelector(
  [selectRetailerData],
  retailerData => retailerData?.privacy_url || '',
);
/**
 * Contains custom configs for the retailer.
 * @returns {string} the retailer's tags
 */
export const selectRetailerTags: (state) => Record<string, any> = createSelector(
  [selectRetailerData],
  retailerData => retailerData?.tags || {},
);

/**
 * @returns {string} the retailer's name
 */
export const selectRetailerName: (state) => string = createSelector(
  [selectRetailerData],
  retailerData => retailerData?.name || '',
);

export const selectRetailerId: (state) => string = createSelector(
  [selectRetailerData],
  retailerData => retailerData?.identifier || '',
);

/**
 * @param {TLanguage[]} defaultValue
 * @returns {TLanguage[]} the retailer's languages
 */
export const selectRetailerLanguages: (state) => TLanguage[] = createSelector(
  [selectRetailerState],
  retailer => retailer?.data?.supported_languages || [],
);
/**
 * @param {TPageType} defaultValue
 * @returns {TPageType} the retailer's page types
 */
export const selectRetailerPageType: (state) => TPageType = createSelector(
  [selectRetailerData],
  retailerData => retailerData?.page_type || STORE,
);

/**
 * @param {TRetailerAgreementItem[]} defaultValue
 * @returns {TRetailerAgreementItem[]} the retailer's privacy options
 */
export const selectRetailerAgreementsArray: (state) => TRetailerAgreementItem[] = createSelector(
  [selectRetailerData],
  retailerData => retailerData?.privacy_options || [],
);

/**
 * @returns {TRetailerAgreementItem | undefined} the retailer's privacy option where category is marketing
 */
export const selectRetailer3dPartyAgreement: (state) => TRetailerAgreementItem = createSelector(
  [selectRetailerData],
  retailerData => retailerData?.privacy_options.find(privacy => privacy.category === THIRD_PARTY_CATEGORY),
);
/**
 * @returns {TRetailerAgreementItem} the retailer's privacy options json with IDs as indexed
 */

export const selectRetailerAgreementsIndexedObj = (
  state: RootState,
  defaultValue?: Record<never, string> | TRetailerAgreementItem,
): Record<string, TRetailerAgreementItem> => {
  const newRetailerUserAgreementsIndexedObj = {};
  selectRetailerAgreementsArray(state).forEach(agreement => {
    newRetailerUserAgreementsIndexedObj[agreement.privacy_id] = agreement;
  });
  return newRetailerUserAgreementsIndexedObj || defaultValue;
};

/**
 * @returns {string} the retailer's default language
 */
export const selectRetailerDefaultLanguage: (state) => string = createSelector(
  [selectRetailerData],
  retailerData => retailerData?.language || languages.ENGLISH,
);

/**
 * @returns {string} the retailer's error
 */

export const selectRetailerError: (state) => string = createSelector(
  [selectRetailerState],
  retailer => retailer?.error || '',
);

/**
 * @returns the retailer loader.
 */
export const selectRetailerLoading: (state) => boolean = createSelector(
  [selectRetailerState],
  retailer => retailer?.isLoading || false,
);

/**
 * @returns {TRetailerAuthentication} the retailer widget access
 */
export const selectRetailerWidgetAccess: (state) => TRetailerAuthentication = createSelector(
  [selectRetailerData],
  retailerData => retailerData?.widget || DISABLED_RETAILER_ACCESS,
);

/**
 * @returns {string} the retailer custom style
 */
export const selectRetailerCustomStyle: (state) => string = createSelector(
  [selectRetailerData],
  retailerData => retailerData?.custom_style || '',
);
