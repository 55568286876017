import cn from 'classnames';
import React, { FC } from 'react';

import { useApp } from '@lib/core/service/hooks';
import { localeLegacy } from '@lib/tools/locale/source/pmi/legacy';
import LocaleFragment from '@lib/tools/locale/views/LocaleFragment';

interface Props {
  onClickHandler: () => void;
}

const PreviousButton: FC<Props> = ({ onClickHandler }) => {
  const { isLayoutRightToLeft } = useApp();

  return (
    <button className="iqos4-prev-btn" type="button" onClick={onClickHandler}>
      <div
        className={cn('iqos4-prev-btn-icon', {
          'rtl-orientation': isLayoutRightToLeft,
        })}
      />
      <div className="iqos4-prev-btn-wrapper">
        <span className="ta-pmi3-p5s-bold iqos4-prev-btn-text">
          <LocaleFragment message={localeLegacy.terea.quizPickOneAnswer} />
        </span>
      </div>
    </button>
  );
};

export default React.memo(PreviousButton);
