import cn from 'classnames';
import { FC } from 'react';
import { useSelector } from 'react-redux';
import { Col, Row } from 'reactstrap';

import LogoImg from '@app/pmi/src/assets/media/start_page_iqos_logo.png';

import { selectRetailerDesignSets } from '@lib/core/retailers/selectors/retailerLocation';
import { IRetailerLanguage } from '@lib/core/retailers/types/retailer';
import { useApp } from '@lib/core/service/hooks';
import { localeTEREA } from '@lib/tools/locale/source/pmi/terea';
import LocaleFragment from '@lib/tools/locale/views/LocaleFragment';
import { DESIGN_SET_TASTE_ADVISOR_V2 } from '@lib/tools/shared/pmi/retailers/consts';
import { useAddons } from '@lib/tools/views/hooks';

import LanguageSwitcherComponent from '@components/pmi/src/common/LanguageSwitcher';
import Disclaimer from '@components/pmi/src/designSets/addOns/common/Disclaimer';

interface Props {
  productCategories: string[];
  supportedLanguages: IRetailerLanguage[];
  handleSelectProductCategory: (el: string) => void;
}

const ProductCategorySelectorComponent: FC<Props> = ({
  productCategories,
  supportedLanguages,
  handleSelectProductCategory,
}) => {
  const { isHideIqosAddon } = useAddons();
  const { isLayoutRightToLeft } = useApp();

  const titleLength = localeTEREA.landing.flavours.defaultMessage.length;

  const retailerDesignSets = useSelector(selectRetailerDesignSets);

  return (
    <>
      <LanguageSwitcherComponent
        className="taste-advisor-start-page-v2-desktop-language-switcher-wrapper"
        supportedLanguages={supportedLanguages}
      />

      <Col
        className={cn('taste-advisor-start-page v2 d-flex flex-column', {
          'rtl-orientation': isLayoutRightToLeft,
        })}
      >
        <LanguageSwitcherComponent
          className="taste-advisor-start-page-v2-mobile-language-switcher-wrapper"
          supportedLanguages={supportedLanguages}
        />

        <Row className="flex-grow-1">
          <Col className="main-part">
            {!isHideIqosAddon && (
              <img
                alt="Logo"
                src={LogoImg}
                className={cn('logo-img', {
                  'ltr-orientation': !isLayoutRightToLeft,
                  'rtl-orientation': isLayoutRightToLeft,
                })}
              />
            )}
            <h2 className="ta-jp-h3-text mb-0">
              <LocaleFragment message={localeTEREA.landing.findYourWayTo} />
            </h2>

            <h2
              className={cn('title', 'ta-jp-h1-text-bold', {
                'font-1': titleLength < 12,
                'font-2': titleLength >= 12 && titleLength < 16,
                'font-3': titleLength >= 16 && titleLength < 20,
                'font-4': titleLength >= 20,
              })}
            >
              <LocaleFragment message={localeTEREA.landing.flavours} />
            </h2>
            <p className="ta-jp-h4-text-bold mb-0 discover">
              <LocaleFragment message={localeTEREA.landing.selectorDescriptionText} />
            </p>
            <Row className="product-selection-section mx-0">
              {productCategories.map(productCategory => (
                <button
                  key={productCategory}
                  type="button"
                  onClick={() => {
                    handleSelectProductCategory(productCategory);
                  }}
                >
                  {retailerDesignSets?.veev === DESIGN_SET_TASTE_ADVISOR_V2 && productCategory === 'veev'
                    ? 'veev one'
                    : productCategory}
                </button>
              ))}
            </Row>
          </Col>
        </Row>
        <Row className="justify-content-center disclaimer-wrapper v2">
          <Disclaimer
            enableDisclaimer={false}
            privacyLink=""
            text={
              <div className="d-flex flex-column px-0">
                <span>
                  <LocaleFragment message={localeTEREA.landing.selectorDisclaimerText} />
                </span>
                <span>
                  <LocaleFragment message={localeTEREA.landing.privacyText} />
                  &nbsp;
                  <a
                    className="text-decoration-underline"
                    href="https://www.pmiprivacy.com/consumer/"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    <LocaleFragment message={localeTEREA.landing.privacyLink} />
                  </a>
                </span>
              </div>
            }
          />
        </Row>
      </Col>
    </>
  );
};

export default ProductCategorySelectorComponent;
