import { FC } from 'react';

import { TProductInstance } from '@lib/core/products/types';
import { useApp } from '@lib/core/service/hooks';
import { VEEV } from '@lib/tools/shared/pmi/retailers/consts';

import BundlesProductCard from '@components/pmi/src/designSetsV2/Components/Results/BundlesProduct/BundlesProductCard';

interface Props {
  bundlesProducts: TProductInstance[];
  minQuantity: number;
  maxQuantity: number;
  isBtnDisable?: boolean;
  productSetID?: string;
  handleBundleProductFeedback: (data: Record<string, string>) => void;
  selectedProductCategory: string;
  isAromaNotesDisabledAddon: boolean;
  isAromaNoteSecondaryAddon: boolean;
  isAddToCartAddon: boolean;
  isEnableBuyEcommerceAddon: boolean;
  isEssenceAromaDisabledAddon: boolean;
  isEssenceBodyDisabledAddon: boolean;
  isEssenceCoolingDisabledAddon: boolean;
  isRestrictedProductCardsAddon: boolean;
  isTereaHideEssencesAddon: boolean;
  isTobaccoExperienceHiddenAddon: boolean;
  isProductLongDescriptionHiddenAddon: boolean;
  isProductShortDescriptionHiddenAddon: boolean;
  isMentholDisclaimerAddon: boolean;
}

const RecommendedProducts: FC<Props> = ({
  bundlesProducts,
  selectedProductCategory,
  isAromaNotesDisabledAddon,
  isAromaNoteSecondaryAddon,
  isAddToCartAddon,
  isEnableBuyEcommerceAddon,
  isEssenceAromaDisabledAddon,
  isEssenceBodyDisabledAddon,
  isEssenceCoolingDisabledAddon,
  isRestrictedProductCardsAddon,
  isTereaHideEssencesAddon,
  isTobaccoExperienceHiddenAddon,
  isBtnDisable = false,
  handleBundleProductFeedback,
  productSetID,
  maxQuantity,
  minQuantity,
  isProductLongDescriptionHiddenAddon,
  isProductShortDescriptionHiddenAddon,
  isMentholDisclaimerAddon,
}) => {
  const { productCategory } = useApp();
  const isVeev = productCategory === VEEV;

  return (
    <div className="ta-v2-bundles-products">
      <BundlesProductCard
        bundlesProduct={bundlesProducts[0]}
        handleBundleProductFeedback={handleBundleProductFeedback}
        isAddToCartAddon={isAddToCartAddon}
        isAromaNoteSecondaryAddon={isAromaNoteSecondaryAddon}
        isAromaNotesDisabledAddon={isAromaNotesDisabledAddon}
        isBtnDisable={isBtnDisable}
        isEnableBuyEcommerceAddon={isEnableBuyEcommerceAddon}
        isEssenceAromaDisabledAddon={isEssenceAromaDisabledAddon}
        isEssenceBodyDisabledAddon={isEssenceBodyDisabledAddon}
        isEssenceCoolingDisabledAddon={isEssenceCoolingDisabledAddon}
        isMentholDisclaimerAddon={isMentholDisclaimerAddon}
        isProductLongDescriptionHiddenAddon={isProductLongDescriptionHiddenAddon}
        isProductShortDescriptionHiddenAddon={isProductShortDescriptionHiddenAddon}
        isRestrictedProductCardsAddon={isRestrictedProductCardsAddon}
        isTereaHideEssencesAddon={isTereaHideEssencesAddon}
        isTobaccoExperienceHiddenAddon={isTobaccoExperienceHiddenAddon}
        isVeev={isVeev}
        maxQuantity={maxQuantity}
        minQuantity={minQuantity}
        orientation="left"
        productSetID={productSetID}
        selectedProductCategory={selectedProductCategory}
      />
      <div className="ta-v2-bundles-products-line" />
      <BundlesProductCard
        bundlesProduct={bundlesProducts[1]}
        handleBundleProductFeedback={handleBundleProductFeedback}
        isAddToCartAddon={isAddToCartAddon}
        isAromaNoteSecondaryAddon={isAromaNoteSecondaryAddon}
        isAromaNotesDisabledAddon={isAromaNotesDisabledAddon}
        isBtnDisable={isBtnDisable}
        isEnableBuyEcommerceAddon={isEnableBuyEcommerceAddon}
        isEssenceAromaDisabledAddon={isEssenceAromaDisabledAddon}
        isEssenceBodyDisabledAddon={isEssenceBodyDisabledAddon}
        isEssenceCoolingDisabledAddon={isEssenceCoolingDisabledAddon}
        isMentholDisclaimerAddon={isMentholDisclaimerAddon}
        isProductLongDescriptionHiddenAddon={isProductLongDescriptionHiddenAddon}
        isProductShortDescriptionHiddenAddon={isProductShortDescriptionHiddenAddon}
        isRestrictedProductCardsAddon={isRestrictedProductCardsAddon}
        isTereaHideEssencesAddon={isTereaHideEssencesAddon}
        isTobaccoExperienceHiddenAddon={isTobaccoExperienceHiddenAddon}
        isVeev={isVeev}
        maxQuantity={maxQuantity}
        minQuantity={minQuantity}
        orientation="right"
        productSetID={productSetID}
        selectedProductCategory={selectedProductCategory}
      />
    </div>
  );
};

export default RecommendedProducts;
