import { useEffect, useState } from 'react';
import { Navigate, Outlet, useParams } from 'react-router-dom';

import { useUserQuiz } from '@lib/core/quizzes/hooks';
import { URL_QUIZ_TYPE_TASTE_REMOTE } from '@lib/core/quizzes/utils/consts';
import { useRemoteAccess } from '@lib/core/retailers/hooks/remoteAccess';
import { useRetailer } from '@lib/core/retailers/hooks/retailer';
import {
  actionResetRemoteAccess,
  actionSetRemoteAccessParams,
  actionUseRemoteAccessKey,
  actionVerifyRemoteAccessKey,
} from '@lib/core/retailers/slices/remoteAccess';
import { AGE_CONFIRMED } from '@lib/core/service/consts';
import { prependBasename, stringToBoolean } from '@lib/core/service/utils';
import RouteUtils from '@lib/tools/routes';
import { useAddons, useEffectSkipFirst, useRetailerDesignSet } from '@lib/tools/views/hooks';
import { useTypedDispatch } from '@lib/tools/views/hooks/useTypedDispatch';
import { PAGES } from '@lib/tools/views/urls';

import TasteAdvisorAgeGate from '@components/pmi/src/designSets/addOns/common/AgeGate';
import GeoblockComponent from '@components/pmi/src/designSets/addOns/common/Geoblock';
import HealthWarningPrePage from '@components/pmi/src/designSets/addOns/common/HealthWarningPrepage';
import InvalidVerificationLinkGate from '@components/pmi/src/designSets/addOns/common/InvalidVerificationLinkGate';

export function HealthWarningPrePageAccess() {
  const { isHealthWarningPrepageAddon } = useAddons();

  const [showWarning, setShowWarning] = useState(isHealthWarningPrepageAddon);

  const handleClose = () => {
    setShowWarning(false);
  };

  return showWarning ? <HealthWarningPrePage handleClose={handleClose} /> : <Outlet />;
}

export function GeoBlockAccess() {
  const { isDesignSetTasteAdvisorIQOS3 } = useRetailerDesignSet();

  const { retailerError } = useRetailer();

  return retailerError.length ? <GeoblockComponent isIQOS3DesignSet={isDesignSetTasteAdvisorIQOS3} /> : <Outlet />;
}

export function RemoteAccess() {
  const dispatch = useTypedDispatch();
  const { remoteAccessKey, isRemoteAccessValid } = useRemoteAccess();
  const { userQuizId } = useUserQuiz();
  const { quizType: urlQuizType } = useParams();

  const remoteGtRoutes = [PAGES.ta.landingRemote, PAGES.ta.quiz.remote];
  const isVerificationRequired = remoteAccessKey && remoteGtRoutes.some(route => RouteUtils.getPage().includes(route));
  const isOpenIntegrationPage = RouteUtils.getPage().includes(PAGES.ta.openIntegration);

  useEffect(() => {
    const { accessKey, expiry, retries, customId } = RouteUtils.getRemoteAccessParams();

    if (accessKey !== remoteAccessKey && !isOpenIntegrationPage) {
      dispatch(actionResetRemoteAccess());
    }

    if (accessKey) {
      dispatch(
        actionSetRemoteAccessParams({
          access_key: accessKey,
          custom_id: customId,
          expiry,
          is_valid: true,
          retries: parseInt(retries, 10),
        }),
      ).then(() => {
        dispatch(actionVerifyRemoteAccessKey());
      });
    }
  }, []);

  /**
   * * Use remote access key on remote quiz route, if valid
   */
  useEffectSkipFirst(() => {
    if (urlQuizType === URL_QUIZ_TYPE_TASTE_REMOTE) {
      if (userQuizId) {
        if (remoteAccessKey && isRemoteAccessValid) {
          dispatch(actionUseRemoteAccessKey());
        }
      } else {
        dispatch(actionVerifyRemoteAccessKey());
      }
    }
  }, [userQuizId]);

  return isRemoteAccessValid || !isVerificationRequired ? <Outlet /> : <InvalidVerificationLinkGate />;
}

export function LegalAgeAccess() {
  const { isAgeGateAddon } = useAddons();
  const [currentAgeConfirmedValue, setCurrentAgeConfirmedValue] = useState<boolean>(false);

  useEffect(() => {
    const ageConfirmedFromLocalStorage = stringToBoolean(localStorage.getItem(AGE_CONFIRMED) || '');
    setCurrentAgeConfirmedValue(ageConfirmedFromLocalStorage || false);
  }, []);

  const handleUpdateCurrentAgeConfirmedValue = () => {
    setCurrentAgeConfirmedValue(true);
  };

  return !isAgeGateAddon || currentAgeConfirmedValue ? (
    <Outlet />
  ) : (
    <TasteAdvisorAgeGate handleUpdateCurrentAgeConfirmedValue={handleUpdateCurrentAgeConfirmedValue} />
  );
}

/**
 * * Handles old quiz URLs distributed prior to v0.7.0
 */
export function LegacyQuizRouteAccess() {
  const page = RouteUtils.getPage();

  const legacyRouteTasteLocal = '/taste_advisor_test/';
  const legacyRouteTasteEcommerce = '/taste_advisor_ecommerce_test/';
  const legacyRouteTasteRemote = '/taste_advisor_remote_gt/';

  if (page.includes(legacyRouteTasteLocal)) {
    return <Navigate replace to={prependBasename(PAGES.ta.quiz.local)} />;
  }
  if (page.includes(legacyRouteTasteEcommerce)) {
    return <Navigate replace to={prependBasename(PAGES.ta.quiz.ecommerce)} />;
  }
  if (page.includes(legacyRouteTasteRemote)) {
    return <Navigate replace to={prependBasename(PAGES.ta.quiz.remote)} />;
  }

  return <Outlet />;
}

/**
 * * Patches German fuck-up of incorrectly constructed distributed remote GT links
 * ! Incorrect link: /remotegt/?access_key=xxx&expiry=xxx&retries=500000&custom_id=xxx?utm_cid=xxx
 */
export const PatchRemoteGuidedTrialAccess = () => {
  const { pathname } = window.location;
  const searchParams = window.location.search;

  const splitParams = searchParams.split('?');
  const isLinkInvalid = splitParams.length > 2;

  return isLinkInvalid ? <Navigate replace to={`${pathname}?${splitParams[1]}`} /> : <Outlet />;
};
