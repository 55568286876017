import classNames from 'classnames';
import { FC } from 'react';
import { Row } from 'reactstrap';

import { localeLegacy } from '@lib/tools/locale/source/pmi/legacy';
import LocaleFragment from '@lib/tools/locale/views/LocaleFragment';
import { useAddons } from '@lib/tools/views/hooks';

type ModeType = 'short' | 'long' | 'coaches';

interface DescriptionProps {
  mode: ModeType;
}

const Description: FC<DescriptionProps> = ({ mode }) => {
  switch (mode) {
    case 'long':
      return (
        <span>
          <LocaleFragment message={localeLegacy.iqos3.longDescriptionText1} />

          <p className="margin-bottom-7px" />
          <LocaleFragment message={localeLegacy.iqos3.longDescriptionText2} />
        </span>
      );
    case 'coaches':
      return (
        <span>
          <LocaleFragment message={localeLegacy.iqos3.coachesText} />
        </span>
      );
    case 'short':
    default:
      return (
        <span>
          <LocaleFragment message={localeLegacy.iqos3.discover} />
        </span>
      );
  }
};

interface TAIQOS3RetailerPageTextBlockTypes {
  handleStartTasteLocalTest: () => void;
  isDesktop: boolean;
  isLayoutRightToLeft: boolean;
}

const TAIQOS3RetailerPageTextBlock: FC<TAIQOS3RetailerPageTextBlockTypes> = ({
  handleStartTasteLocalTest,
  isDesktop,
  isLayoutRightToLeft,
}) => {
  const { isLandingPageLongDescriptionAddon, isLandingPageCoachesAddon } = useAddons();

  let mode: ModeType = isLandingPageLongDescriptionAddon ? 'long' : 'short';
  if (isLandingPageCoachesAddon) {
    mode = 'coaches';
  }

  const bestMatchText = <LocaleFragment message={localeLegacy.iqos3.findFlavors} />;

  return (
    <>
      <Row
        className={classNames('header-text ta-pmi3-h1_2-bold', {
          'color-white': isDesktop,
        })}
      >
        {isLandingPageCoachesAddon ? <LocaleFragment message={localeLegacy.iqos3.coachesFindHeets} /> : bestMatchText}
      </Row>
      <Row
        className={classNames('justify-content-start ta-pmi3-p1_2', {
          'color-white': isDesktop,
          'mr-0 pr-3': !isDesktop && !isLayoutRightToLeft,
        })}
      >
        <Description mode={mode} />
      </Row>
      <Row>
        <button
          type="button"
          className={classNames('ta-pmi3-p2_2 text-center px-0', {
            'color-white': !isDesktop,
          })}
          onClick={handleStartTasteLocalTest}
        >
          <LocaleFragment message={localeLegacy.iqos3.letsBegin} />
        </button>
      </Row>
    </>
  );
};

export default TAIQOS3RetailerPageTextBlock;
