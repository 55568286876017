/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { FC, Fragment } from 'react';
import { EditTextarea } from 'react-edit-text';
import { useIntl } from 'react-intl';
import ReactMarkdown from 'react-markdown';
import remarkBreaks from 'remark-breaks';

import { useApp } from '@lib/core/service/hooks';
import { capitalizeFirstLetter, insertIntlJSXPart } from '@lib/core/service/utils';
import StyledTooltip from '@lib/tools/devtools/components/Tooltip';
import { LocaleUtils } from '@lib/tools/locale/utils';
import { languages } from '@lib/tools/locale/utils/consts';
import CustomMDReactComponent from '@lib/tools/locale/views/CustomMDReactComponent';

// eslint-disable-next-line no-restricted-imports
import './locale_fragment.scss';
import 'react-edit-text/dist/index.css';

export type ILocaleText = { readonly id: string; readonly defaultMessage: string } | string;

export interface LocaleFragmentProps {
  message: ILocaleText;
  options?: Record<string, string>;
  variables?: Record<string, { readonly id: string; readonly defaultMessage: string }>;
  index?: Record<string, string>;
  insertIntlJSXPartOptions?: {
    linkId: string;
    linkRender: (text: string) => React.ReactElement;
    className?: string;
  };
  transformText?: 'default' | 'none';
}

/**
 * * This component is used for any static text. It injectsIntl and sources translations from firebase or local files.
 * ! Pass productCategory into the options payload
 * Can handle both JSON terms and plain strings.
 * Supports markdown.
 */
const LocaleFragment: FC<LocaleFragmentProps> = ({
  message,
  insertIntlJSXPartOptions,
  options,
  variables,
  index,
  transformText = 'default',
}) => {
  const { isDevToolsEnabled, isLocaleEditMode, locale } = useApp();
  const intl = useIntl();

  if (typeof message === 'string') {
    const transformedText = transformText === 'none' ? message : capitalizeFirstLetter(message);

    return (
      <ReactMarkdown
        remarkPlugins={[remarkBreaks]}
        components={{
          p: Fragment,
        }}
      >
        {transformedText}
      </ReactMarkdown>
    );
  }

  // ! Improve custom formatting approaches
  if (options?.jsx === 'true') {
    const linkRenderMethod = copy => (
      <span
        aria-hidden
        style={{ cursor: 'pointer', textDecoration: 'underline' }}
        onClick={() => window.open('https://ec.europa.eu/consumers/odr/', '_blank')}
      >
        <CustomMDReactComponent className="no-margin d-inline" text={copy} />
      </span>
    );

    const pageContent = insertIntlJSXPart({
      className: '',
      linkId: 'custom-info-message-link',
      linkRender: linkRenderMethod,
      text: intl.formatMessage(message, options).replace(/\n/gi, '&nbsp;  \n'),
    });

    return { ...pageContent };
  }

  const allOptions = { ...index, ...options };

  if (variables && Object.keys(variables).length > 0) {
    Object.keys(variables).forEach(variableKey => {
      allOptions[variableKey] = intl.formatMessage(variables[variableKey]);
    });
  }

  const text = intl.formatMessage(message, allOptions)?.replace('||', '   \n') || message.defaultMessage;

  if (isDevToolsEnabled && isLocaleEditMode && locale !== languages.ENGLISH) {
    return (
      <StyledTooltip arrow title={message.id}>
        <div
          className="locale-fragment-wrapper"
          onClick={e => e.stopPropagation()}
          onKeyDown={e => e.stopPropagation()}
        >
          <EditTextarea
            className="locale-fragment"
            defaultValue={text}
            id={message.id}
            inputClassName="locale-fragment-input"
            onSave={d => LocaleUtils.updateTerm(message.id, d.value)}
          />
        </div>
      </StyledTooltip>
    );
  }

  if (insertIntlJSXPartOptions) {
    return insertIntlJSXPart({ ...insertIntlJSXPartOptions, text });
  }

  const transformedText = transformText === 'none' ? text : capitalizeFirstLetter(text);

  return (
    <ReactMarkdown components={{ p: Fragment }} remarkPlugins={[remarkBreaks]}>
      {transformedText}
    </ReactMarkdown>
  );
};

export default LocaleFragment;
