import React, { FC, Fragment } from 'react';
import { Col, Row } from 'reactstrap';

interface Props {
  answers;
  handleSelectAnswerId: (asnwerId: string) => void;
}

const QuestionAnswers: FC<Props> = ({ answers, handleSelectAnswerId }) => (
  <Fragment>
    <Row className="mx-auto taste-advisor-4-two-question-answers justify-content-center">
      {answers.map(answer => {
        const answerId = Object.keys(answer)[0];
        return (
          <Col
            key={answerId}
            className="ta-jp-02-select-answer-btn px-0 d-flex flex-column align-items-center"
            md={2}
            xs={6}
            onClick={() => {
              handleSelectAnswerId(answerId);
            }}
          >
            <Row className="image-box mx-auto">
              <button
                className="image"
                style={{ background: `url(${answer[answerId].image || ''}) no-repeat` }}
                type="button"
              />
            </Row>
            <Row className="mx-auto">
              <button className="answer-ta-button ta-jp-mob-p2 ta-jp-p2 justify-content-center" type="button">
                {answer[answerId].text}
              </button>
            </Row>
          </Col>
        );
      })}
    </Row>
  </Fragment>
);

export default React.memo(QuestionAnswers);
