import classNames from 'classnames';
import React, { FC } from 'react';
import { useMediaQuery } from 'react-responsive';
import { Row } from 'reactstrap';

interface Props {
  answers: Record<
    string,
    {
      image?: string;
      text: string;
    }
  >[];
  handleSelectAnswerId: (asnwerId: string) => void;
}

const QuestionAnswers: FC<Props> = ({ answers, handleSelectAnswerId }) => {
  const isDesktopResolution = useMediaQuery({ minWidth: 1366 });
  const isDesktopResolutionQuiz = isDesktopResolution || (answers.length === 2 && !isDesktopResolution);

  return (
    <Row
      className={classNames('mx-auto taste-advisor-4-one-question-answers justify-content-between', {
        isTwoAnswersVersion: answers.length === 2,
      })}
    >
      {answers.map(answer => {
        const answerId = Object.keys(answer)[0];
        if (answers.length === 2) {
          return (
            <div
              key={answerId}
              className={`${
                isDesktopResolutionQuiz ? '' : 'col-lg-12'
              } d-flex flex-column align-items-center max-width-304px`}
            >
              <button
                className="image-button"
                style={{ background: `url(${answer[answerId].image || ''}) no-repeat` }}
                type="button"
                onClick={() => {
                  handleSelectAnswerId(answerId);
                }}
              />
              <button
                className="ta-jp-select-answer-btn ta-jp-mob-p3 ta-jp-p3-normal"
                type="button"
                onClick={() => {
                  handleSelectAnswerId(answerId);
                }}
              >
                {answer[answerId].text}
              </button>
            </div>
          );
        }
        return (
          <div
            key={answerId}
            className={`${isDesktopResolutionQuiz ? '' : 'col-lg-12'} d-flex flex-column align-items-center`}
          >
            <button
              className="ta-jp-select-answer-btn ta-jp-mob-p3_1 ta-jp-p3_1"
              type="button"
              onClick={() => {
                handleSelectAnswerId(answerId);
              }}
            >
              {answer[answerId].text}
            </button>
          </div>
        );
      })}
    </Row>
  );
};

export default React.memo(QuestionAnswers);
