import { defineMessages } from '@formatjs/intl';

export const localeTEREA = {
  banner: defineMessages({
    sentenceOne: {
      defaultMessage:
        'TEREA sticks contain a metal part. Keep out of reach of children to prevent disassembly or ingestion.',
      id: 'taste-advisor-result-page.static-banner-sentence-1',
    },
    sentenceTwo: {
      defaultMessage: 'Placeholder for Health Warning and/or Duty to Inform',
      id: 'taste-advisor-result-page.static-banner-sentence-2',
    },
    titleOne: {
      defaultMessage: 'Made only for',
      id: 'taste-advisor.result-page.static-banner-title-key-visual-1',
    },
    titleTwo: {
      defaultMessage: 'each other',
      id: 'taste-advisor.result-page.static-banner-title-key-visual-2',
    },
  }),
  common: defineMessages({
    buttonNext: {
      defaultMessage: 'Next',
      id: 'taste-advisor_answer_test.next',
    },
    buttonStartOver: { defaultMessage: 'Start over', id: 'taste-advisor-iqos4_two_result_page_test.start_over' },
    healthWarning: {
      defaultMessage:
        'This product is not risk free and provides nicotine, which is addictive. Only for use by adults.',
      id: 'taste-advisor.iqos4_two.health_warning_footer',
    },
  }),
  landing: defineMessages({
    buttonBegin: { defaultMessage: "Let's begin", id: 'taste-advisor_retailer_page.lets_begin' },
    descriptionIqos4two: {
      defaultMessage: 'Discover your personal taste character using our quiz and find your match.',
      id: 'taste-advisor_retailer_page.iqos4_two.coaches_main_text',
    },
    disclaimerTextOne: {
      defaultMessage:
        'Flavor quiz helps us to understand your taste preferences in general, to make further recommendations on TEREA flavors. It does not imply that any products mentioned in the quiz are linked to TEREA flavors.',
      id: 'taste-advisor_retailer_page.long_disclaimer_1',
    },
    disclaimerTextTwo: {
      defaultMessage: 'IQOS ILUMA™ is designed to be used only with TEREA™ sticks.',
      id: 'taste-advisor_retailer_page.long_disclaimer_2',
    },
    discover: {
      defaultMessage: 'Discover your personal taste character using our flavor quiz and find your tobacco blend.',
      id: 'taste-advisor.iqos4.retailer_page.discover',
    },
    findBest: {
      defaultMessage: 'Find your best match.',
      id: 'taste-advisor.iqos4_two.retailer-page.find_best_match',
    },
    findFlavours: {
      defaultMessage: 'Find your favorite TEREA flavors',
      id: 'taste-advisor.iqos4.retailer_page.find_flavors',
    },
    findYourWayTo: {
      defaultMessage: 'Find your way to',
      id: 'taste-advisor.select-start-page.header_1',
    },
    flavours: {
      defaultMessage: 'flavours.',
      id: 'taste-advisor.select-start-page.header_2',
    },
    privacyLink: {
      defaultMessage: 'PMI privacy policy',
      id: 'taste-advisor.select-start-page.privacy_footer_3',
    },
    privacyText: {
      defaultMessage: 'Your data will be processed in accordance to the',
      id: 'taste-advisor.select-start-page.privacy_footer_2',
    },
    selectorDescriptionText: {
      defaultMessage: 'Follow the Flavour Guide for IQOS and find new flavours crafted for your taste.',
      id: 'taste-advisor.select-start-page.discover',
    },
    selectorDisclaimerText: {
      defaultMessage:
        'Flavour quiz helps us to understand your taste preferences in general, to make further recommendations on flavours. It does not imply that any products mentioned in the quiz are linked to the product flavours.',
      id: 'taste-advisor.select-start-page.privacy_footer_1',
    },
    selectorDiscover: {
      defaultMessage: 'Discover your personal taste character using our flavour quizzes and find your flavour.',
      id: 'taste-advisor.pc-select-page.discover',
    },
    selectorSelect: {
      defaultMessage: 'Select a product line',
      id: 'taste-advisor.pc-select-page.select',
    },
    selectorWelcome: {
      defaultMessage: 'Welcome to Flavour Advisor',
      id: 'taste-advisor.pc-select-page.header',
    },
  }),
  product: defineMessages({
    aromaNote: { defaultMessage: 'Aroma note', id: 'taste-advisor-iqos4_result_page.aroma_note' },

    dimensions: {
      defaultMessage: 'terea dimensions',
      id: 'taste-advisor-iqos4_result_page.terea_dimensions',
    },
    feedbackNo: { defaultMessage: 'No', id: 'taste-advisor-iqos4_result_page.dislike' },
    feedbackText: {
      defaultMessage: 'Is this variant a good match?',
      id: 'taste-advisor-iqos4_result_page.feedback_question',
    },
    feedbackYes: { defaultMessage: 'Yes', id: 'taste-advisor-iqos4_result_page.like' },
    onAClick: { defaultMessage: 'On a click', id: 'taste-advisor-iqos4_result_page.on_click' },
    tobacco: { defaultMessage: 'tobacco experience', id: 'taste-advisor-iqos4_result_page.tobacco_experience' },
    tobaccoPearl: {
      defaultMessage: 'tobacco experience with a capsule',
      id: 'taste-advisor-iqos4_result_page.tobacco_experience_capsule',
    },
  }),
  quiz: defineMessages({
    pickOneAnswer: {
      defaultMessage: 'Pick one answer',
      id: 'taste-advisor_answer_test.pick_one_answer',
    },
  }),
  result: defineMessages({
    betterMatch: {
      defaultMessage: 'better match',
      id: 'taste-advisor-iqos4_result_page.better_match',
    },
    characterInfo: {
      defaultMessage: 'Your taste character is',
      id: 'taste-advisor-iqos4_two_result_page.your_character_is',
    },
    discover: {
      defaultMessage: 'Discover the rest of our range',
      id: 'taste_advisor.iqos4.product_portfolio.title',
    },
    discoverHeets: {
      defaultMessage: 'Discover your recommended HEETS',
      id: 'taste_advisor.result_page.discover_heets',
    },
    madeBetter: {
      defaultMessage: 'made better',
      id: 'taste-advisor-iqos4_result_page.made_better',
    },
    mentholDisclaimer: {
      defaultMessage:
        '*This product contains menthol. Flavourings should not be confused with the additives contained in tobacco sticks. Find out more: www.philipmorris.ch.',
      id: 'taste-advisor.iqos.menthol_disclaimer_messages',
    },
    ourRecommendation: {
      defaultMessage: 'This is our recomendation for you based on the results of the test',
      id: 'taste-advisor-iqos4_result_page.restricted_sentence',
    },
    sentia: {
      defaultMessage: 'sentia',
      id: 'taste-advisor.result-page.sentia',
    },
    startOver: {
      defaultMessage: 'Start over',
      id: 'taste-advisor-iqos4_result_page_test.start_over',
    },
    tasteCharacter: {
      defaultMessage: 'Your taste character is',
      id: 'taste-advisor-iqos4_result_page.your_character_is',
    },
    terea: {
      defaultMessage: 'terea',
      id: 'taste_advisor.iqos4.product_portfolio.title_terea',
    },
    tereaDimensions: {
      defaultMessage: 'TEREA Dimensions',
      id: 'taste_advisor.iqos4.product_portfolio.title_dimensions',
    },
    theOneYouLove: {
      defaultMessage: 'The one you love',
      id: 'taste-advisor-iqos4_result_page.the_one_you_love',
    },
    thisFlavour: {
      defaultMessage: 'this flavor',
      id: 'taste-advisor-iqos4_result_page.this_flavor',
    },
    thisIsYour: {
      defaultMessage: 'This is your',
      id: 'taste-advisor-iqos4_result_page.this_is_your',
    },
    youMayAppreciate: {
      defaultMessage: 'You may appreciate',
      id: 'taste-advisor-iqos4_result_page.you_may_appreciate',
    },
    yourBestMatches: {
      defaultMessage: 'Your best matches',
      id: 'taste-advisor-iqos4_result_page.your_best_matches',
    },
    yourRecommendation: {
      defaultMessage: 'Your recommendation',
      id: 'taste-advisor-iqos4_two_result_page.your_recommendation',
    },
    yourTereaMatch: {
      defaultMessage: 'Your TEREA match',
      id: 'taste-advisor-iqos4_result_page.your_terea_match',
    },
  }),
};
