import { useEffect, useState } from 'react';

import { actionGetCharacters } from '@lib/core/characters/slices';
import { actionGetEssenceGroups } from '@lib/core/essences/slices/groups';
import { useProducts } from '@lib/core/products/hooks/products';
import { actionGetAllProducts } from '@lib/core/products/slices/products';
import { useUserQuiz } from '@lib/core/quizzes/hooks';
import { PRODUCT_CATEGORY_NONE } from '@lib/core/service/consts';
import { useApp } from '@lib/core/service/hooks';
import { abortController, resetController } from '@lib/core/service/requests/abortController';
import { useEffectSkipFirst, useRetailerDesignSet } from '@lib/tools/views/hooks';
import { useTypedDispatch } from '@lib/tools/views/hooks/useTypedDispatch';

export const DataProvider = ({ children }) => {
  const dispatch = useTypedDispatch();

  const { locale, productCategory } = useApp();
  const { isDesignSetTasteAdvisorV2 } = useRetailerDesignSet();
  const { isProductsLoading } = useProducts();
  const { userQuizType } = useUserQuiz();

  const [isQuizLoaded, setIsQuizLoaded] = useState(false);

  const isStartPage = productCategory === PRODUCT_CATEGORY_NONE;

  /**
   * * Flag to ensure fast initial load of the quiz without fetching products
   */
  useEffectSkipFirst(() => {
    // Fetch products after the quiz is available to avoid blocking the UI during initial quiz container loads.
    if (userQuizType) setIsQuizLoaded(true);
  }, [userQuizType]);

  /**
   * * Prefetch all products
   */
  useEffect(() => {
    if ((isDesignSetTasteAdvisorV2 || isStartPage) && !isProductsLoading && isQuizLoaded) {
      dispatch(actionGetAllProducts());
    }
  }, [locale, isQuizLoaded]);

  useEffect(() => {
    if (isDesignSetTasteAdvisorV2 || isStartPage) {
      dispatch(actionGetEssenceGroups());
    }
    dispatch(actionGetCharacters());

    return () => {
      abortController();
      if (!isProductsLoading) {
        dispatch(actionGetAllProducts());
      }
      resetController();
    };
  }, [locale]);

  return children;
};
