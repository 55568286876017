import { FC } from 'react';
import { Container } from 'reactstrap';

import { localeLegacy } from '@lib/tools/locale/source/pmi/legacy';
import LocaleFragment from '@lib/tools/locale/views/LocaleFragment';

interface Props {
  className?: string;
}

const HealthWarningLanding: FC<Props> = ({ className = '' }) => (
  <Container fluid className={`blends-content-font-bold blends-health-warning ${className}`}>
    <LocaleFragment message={localeLegacy.blends.healthWarning1} />
    <LocaleFragment message={localeLegacy.blends.healthWarning2} />
  </Container>
);

export default HealthWarningLanding;
