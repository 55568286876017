import { TablePagination, tablePaginationClasses as classes } from '@mui/base/TablePagination';
import ChevronLeftRoundedIcon from '@mui/icons-material/ChevronLeftRounded';
import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded';
import FirstPageRoundedIcon from '@mui/icons-material/FirstPageRounded';
import LastPageRoundedIcon from '@mui/icons-material/LastPageRounded';
import { styled } from '@mui/system';
import { ChangeEvent, MouseEvent, useState } from 'react';

import { useApp } from '@lib/core/service/hooks';
import { LocaleUtils } from '@lib/tools/locale/utils';
import { languages } from '@lib/tools/locale/utils/consts';

const blue = {
  200: '#A5D8FF',
  400: '#3399FF',
  50: '#F0F7FF',
  900: '#003A75',
};

const grey = {
  100: '#E5EAF2',
  200: '#DAE2ED',
  300: '#C7D0DD',
  400: '#B0B8C4',
  50: '#F3F6F9',
  500: '#9DA8B7',
  600: '#6B7A90',
  700: '#434D5B',
  800: '#303740',
  900: '#1C2025',
};

const Root = styled('div')(
  ({ theme }) => `
  width: 100%;

  table {
    font-family: 'IBM Plex Sans', sans-serif;
    font-size: 0.875rem;
    border-collapse: collapse;
    border: none;
    width: 100%;
    padding: 0 10px;
    margin: -1px;
  }

  td,
  th {
    border: 1px solid ${theme.palette.mode === 'dark' ? grey[800] : grey[200]};
    text-align: left;
    padding: 8px;
  }

  `,
);

const CustomTablePagination = styled(TablePagination)(
  ({ theme }) => `
  & .${classes.spacer} {
    display: none;
  }

  & .${classes.toolbar}  {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    padding: 4px 0;

    @media (min-width: 768px) {
      flex-direction: row;
      align-items: center;
    }
  }

  & .${classes.selectLabel} {
    margin: 0;
  }

  & .${classes.select}{
    font-family: 'IBM Plex Sans', sans-serif;
    padding: 2px 0 2px 4px;
    border: 1px solid ${theme.palette.mode === 'dark' ? grey[800] : grey[200]};
    border-radius: 6px; 
    background-color: transparent;
    color: ${theme.palette.mode === 'dark' ? grey[300] : grey[900]};
    transition: all 100ms ease;

    &:hover {
      background-color: ${theme.palette.mode === 'dark' ? grey[800] : grey[50]};
      border-color: ${theme.palette.mode === 'dark' ? grey[600] : grey[300]};
    }

    &:focus {
      outline: 3px solid ${theme.palette.mode === 'dark' ? blue[400] : blue[200]};
      border-color: ${blue[400]};
    }
  }

  & .${classes.displayedRows} {
    margin: 0;

    @media (min-width: 768px) {
      margin-left: auto;
    }
  }

  & .${classes.actions} {
    display: flex;
    gap: 6px;
    border: transparent;
    text-align: center;
  }

  & .${classes.actions} > button {
    display: flex;
    align-items: center;
    padding: 0;
    border: transparent;
    border-radius: 50%;
    background-color: transparent;
    border: 1px solid ${theme.palette.mode === 'dark' ? grey[800] : grey[200]};
    color: ${theme.palette.mode === 'dark' ? grey[300] : grey[900]};
    transition: all 120ms ease;

    > svg {
      font-size: 22px;
    }

    &:hover {
      background-color: ${theme.palette.mode === 'dark' ? grey[800] : grey[50]};
      border-color: ${theme.palette.mode === 'dark' ? grey[600] : grey[300]};
    }

    &:focus {
      outline: 3px solid ${theme.palette.mode === 'dark' ? blue[400] : blue[200]};
      border-color: ${blue[400]};
    }

    &:disabled {
      opacity: 0.3;
      &:hover {
        border: 1px solid ${theme.palette.mode === 'dark' ? grey[800] : grey[200]};
        background-color: transparent;
      }
    }
  }
  `,
);

export interface ILocaleTableDataRow {
  context: string;
  defaultText: string;
  publishedText: string;
  draftText: string;
}

/**
 * * Returns a table with search, pagination, and filtering on ILocaleTable.
 */
export function LocaleTable(props: { rowData: ILocaleTableDataRow[] }) {
  // ! Throw warning when englishContexts and currentLocaleContexts don't align.
  let { rowData } = props;

  const { locale } = useApp();

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  // createData('ta.v2.text.example.11', 'flavor', 'flavour'),
  // .sort((a, b) => (a.calories < b.calories ? -1 : 1));

  if (!rowData) rowData = [];

  // Avoid a layout jump when reaching the last page with empty rowData.
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rowData?.length) : 0;

  const handleChangePage = (_event: MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <Root sx={{ maxWidth: '100%' }}>
      <table aria-label="custom pagination table">
        <thead>
          <tr>
            <th>context</th>
            <th>{languages.ENGLISH}</th>
            <th>{locale}</th>
          </tr>
        </thead>

        <tbody>
          {(rowsPerPage > 0 ? rowData?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : rowData)?.map(
            row => (
              <tr key={row.context}>
                <td>{row.context}</td>
                <td>{row.defaultText}</td>
                <td>
                  <textarea
                    className={row.draftText !== row.publishedText && row.draftText ? 'unpublished' : ''}
                    id={row.context}
                    placeholder="No translation"
                    value={row.draftText}
                    onChange={e => LocaleUtils.updateTerm(row.context, e.target.value)}
                  />
                </td>
              </tr>
            ),
          )}

          {emptyRows > 0 && (
            <tr style={{ height: 34 * emptyRows }}>
              <td aria-hidden colSpan={3} />
            </tr>
          )}
        </tbody>

        <tfoot>
          <tr>
            <CustomTablePagination
              colSpan={3}
              count={rowData?.length || 0}
              page={page}
              rowsPerPage={rowsPerPage}
              rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
              slotProps={{
                actions: {
                  showFirstButton: true,
                  showLastButton: true,
                  slots: {
                    backPageIcon: ChevronLeftRoundedIcon,
                    firstPageIcon: FirstPageRoundedIcon,
                    lastPageIcon: LastPageRoundedIcon,
                    nextPageIcon: ChevronRightRoundedIcon,
                  },
                },
                select: {
                  'aria-label': 'rows per page',
                },
              }}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </tr>
        </tfoot>
      </table>
    </Root>
  );
}
