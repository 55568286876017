// Questions design sets
export const QDS1001 = 'QDS1001';
export const QDS1002 = 'QDS1002';
export const QDS1003 = 'QDS1003';
export const QDS1004 = 'QDS1004';
export const QDS1005 = 'QDS1005';
export const QDS2001 = 'QDS2001';
export const QDS2002 = 'QDS2002';

export const QDS8001 = 'QDS8001';
export const QDS8002 = 'QDS8002';
export const QDS8003 = 'QDS8003';
export const QDS8004 = 'QDS8004';
export const QDS8005 = 'QDS8005';
export const QDS8006 = 'QDS8006';
export const QDS8007 = 'QDS8007';
export const QDS8008 = 'QDS8008';
export const QDS8009 = 'QDS8009';
export const QDS8010 = 'QDS8010';
export const QDS8011 = 'QDS8011';

export const QDS9001 = 'QDS9001';

// EXTRA QUIZ PAGES
export const EXTRA_QUIZ_PAGE_CONDITION_URL_PARAM_QA_CHARACTER = 'character';
export const EXTRA_QUIZ_PAGE_CONDITION_URL_PARAM_QA_MOOD = 'mood';
export const EXTRA_QUIZ_PAGE_CONDITION_URL_PARAM_QA_PRODUCT = 'product';

export const QUIZ_CRAFTED_SCORE_MATRIX = {
  QA15206011: {
    ITCC01S01: 0.4,
    ITCC02S01: 0.3,
    ITCC03S01: 2.2,
  },
  QA15206012: {
    ITCC01S01: 2.4,
    ITCC02S01: 2.3,
    ITCC03S01: 0.2,
  },
  QA15206013: {
    ITCC01S01: 1.4,
    ITCC02S01: 0.3,
    ITCC03S01: 0.2,
  },
  QA15206021: {
    ITCC01S01: 0.4,
    ITCC02S01: 1.3,
    ITCC03S01: 2.2,
  },
  QA15206022: {
    ITCC01S01: 1.4,
    ITCC02S01: 0.3,
    ITCC03S01: 2.2,
  },
  QA15206023: {
    ITCC01S01: 1.4,
    ITCC02S01: 0.3,
    ITCC03S01: 0.2,
  },
  QA15206031: {
    ITCC01S01: 0.4,
    ITCC02S01: 0.3,
    ITCC03S01: 2.2,
  },
  QA15206032: {
    ITCC01S01: 1.4,
    ITCC02S01: 2.3,
    ITCC03S01: 0.2,
  },
  QA15206033: {
    ITCC01S01: 3.4,
    ITCC02S01: 4.3,
    ITCC03S01: 0.2,
  },
};
