import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { Col } from 'reactstrap';

import { TQuizQuestion } from '@lib/core/quizzes/types';
import { prependBasename } from '@lib/core/service/utils';
import { useAddons } from '@lib/tools/views/hooks';
import { PAGES } from '@lib/tools/views/urls';

import HealthWarningFooterToIsrael from '@components/pmi/src/common/HealthWarningFooterToIsrael';
import Header from '@components/pmi/src/designSets/heets/main/one/Header';
import ProgressBar from '@components/pmi/src/designSets/heets/main/one/ProgressBar';
import QuestionText from '@components/pmi/src/designSets/heets/main/one/QuestionText';
import Answers from '@components/pmi/src/designSets/heets/main/one/Quiz/QDS2002/QuizAnswers';

interface Props {
  handleSelectAnswerId: (idAnswer: string | string[]) => void;
  quizViewProgress: number;
  quizViewData: TQuizQuestion;
  handleGoBack: () => void;
  isFirstQuestion: boolean;
  isLayoutRightToLeft: boolean;
}

const QuizPage: FC<Props> = ({
  handleSelectAnswerId,
  quizViewProgress,
  quizViewData,
  handleGoBack,
  isFirstQuestion,
  isLayoutRightToLeft,
}) => {
  const navigate = useNavigate();

  const { isHealthWarningFooterToIsraelAddon } = useAddons();

  const goBack = () => {
    if (isFirstQuestion) {
      navigate(prependBasename(PAGES.ta.landing));
    } else {
      handleGoBack();
    }
  };

  const questionParts = quizViewData.text?.split('\n');

  return (
    <Col
      className={`${isHealthWarningFooterToIsraelAddon ? 'minh-101vh padding-bottom-30vh' : 'minh-100vh'} no-padding`}
    >
      <div style={{ backgroundColor: '#00D1D2' }}>
        <Header goBack={goBack} isFirstQuestion={isFirstQuestion} isLayoutRightToLeft={isLayoutRightToLeft} />
        <ProgressBar quizViewProgress={quizViewProgress} />
        <QuestionText text={quizViewData?.text || ''} />
      </div>
      {questionParts[1] && (
        <p className="mb-0 ta-jp-p2-bold text-center px-2 margin-top-20px font-weight-normal">{questionParts[1]}</p>
      )}

      <Answers answers={quizViewData.answers} handleConfirmAnswer={handleSelectAnswerId} />
      {isHealthWarningFooterToIsraelAddon && <HealthWarningFooterToIsrael />}
    </Col>
  );
};

export default QuizPage;
