import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useRetailer } from '@lib/core/retailers/hooks/retailer';
import { useRetailerLocation } from '@lib/core/retailers/hooks/retailerLocation';
import { selectRetailerLocation, selectRetailerLocationsList } from '@lib/core/retailers/selectors/retailerLocation';
import { ENV_IS_BUILD, isApplicationPmi } from '@lib/core/service/consts';
import { useApp } from '@lib/core/service/hooks';
import { setAppLocale, setProductCategory } from '@lib/core/service/slices';
import { prependBasename } from '@lib/core/service/utils';
import { history } from '@lib/core/service/utils/Navigator';
import { useUser } from '@lib/core/users/hooks';
import { AddOnsPanel } from '@lib/tools/devtools/panels/service/AddOnsPanel';
import { useEffectSkipFirst } from '@lib/tools/views/hooks';
import { PAGES } from '@lib/tools/views/urls';

export const ServicePanel = () => {
  const { pathname } = window.location;
  const dispatch = useDispatch();

  const { locale, productCategory } = useApp();
  const { retailerLanguages } = useRetailer();
  const { retailerLocationId, productCategories } = useRetailerLocation();
  const { isUserKioskAdmin } = useUser();

  const retailerLocations = useSelector(selectRetailerLocationsList);
  const retailerLocation = useSelector(selectRetailerLocation);

  const availableRetailerLocations =
    (isUserKioskAdmin && retailerLocation) || !retailerLocations.length ? [retailerLocation] : retailerLocations;

  const [appDomain, setAppDomain] = useState('app');

  const optionsPages = [
    <option key="" disabled>
      select page
    </option>,
  ];

  const handleChangeRetailerLocation = e => {
    const { target: { value: newRetailerLocationId = retailerLocationId } = {} } = e;
    window.location.href = pathname.replace(retailerLocationId, newRetailerLocationId);
  };

  const optionsProducts = [<option key="none">none</option>];

  productCategories.forEach((product, i) => {
    optionsProducts.push(
      <option key={i} value={product}>
        {product}
      </option>,
    );
  });

  // ! Handle nested routes keys
  // @ts-ignore
  const appPages: any[] = PAGES[isApplicationPmi ? 'ta' : 'vinhood'] || {};
  Object.values(appPages).forEach((page: any, i: number) => {
    if (typeof page !== 'string') {
      // const routeKeys: string[] = Object.keys(page);
      // const routeValues: string[] = Object.values(page);
      // routeKeys.forEach((routeKey, subIndex) => {
      //   optionsPages.push(
      //     <option key={routeKey + subIndex} value={routeValues[subIndex]}>
      //       {routeKeys[subIndex]} - {routeValues[subIndex]}
      //     </option>,
      //   );
      // });
    } else {
      optionsPages.push(
        <option key={i} value={page}>
          {Object.keys(appPages)[i]} - {page}
        </option>,
      );
    }
  });

  const optionsLanguages = [
    <option key="" disabled>
      select locale
    </option>,
  ];

  retailerLanguages.forEach((lang, i) => {
    optionsLanguages.push(
      <option key={i} value={lang.code}>
        {lang.code} - {lang.language}
      </option>,
    );
  });

  const LanguageSelect = (
    <select name="language" value={locale} onChange={e => dispatch(setAppLocale(e.target.value))}>
      {optionsLanguages}
    </select>
  );

  const ServiceSelect = (
    <select name="service" value={locale} onChange={e => setAppDomain(e.target.value)}>
      <option key="app" value="app">
        web
      </option>
      <option key="kiosk" value="kiosk">
        kiosk
      </option>
      <option key="fa" value="fa">
        pmi
      </option>
    </select>
  );

  const RetailerLocationSelect = (
    <select
      disabled={isUserKioskAdmin}
      name="retailerLocation"
      value={retailerLocationId}
      onChange={handleChangeRetailerLocation}
    >
      {availableRetailerLocations.map(({ identifier, store_type, slug }, index) => (
        <option key={index} value={identifier}>
          {store_type} - {slug} - {identifier}
        </option>
      ))}
    </select>
  );

  const ProductSelect = (
    <select name="product" value={productCategory} onChange={e => dispatch(setProductCategory(e.target.value))}>
      {optionsProducts}
    </select>
  );

  const PageSelect = (
    <select name="page" onChange={e => history.navigate(prependBasename(e.target.value))}>
      {optionsPages}
    </select>
  );

  useEffectSkipFirst(() => {
    if (ENV_IS_BUILD) window.location.href = `https://${appDomain}.vinhood.dev/playground`;
  }, [appDomain]);

  return (
    <>
      {/* ? Allow force RTL */}
      <div className="panel collapsible" role="presentation">
        Config
      </div>

      <>
        {ENV_IS_BUILD && (
          <div className="panel">
            <span className="title">Service</span>
            {ServiceSelect}
          </div>
        )}
        <div className="panel">
          <span className="title">Locale</span>
          {LanguageSelect}
        </div>
        <div className="panel">
          <span className="title">Location</span>
          {RetailerLocationSelect}
        </div>
        <div className="panel">
          <span className="title">Product</span>
          {ProductSelect}
        </div>
        <div className="panel">
          <span className="title">Nav</span>
          {PageSelect}
        </div>

        <AddOnsPanel />
      </>
    </>
  );
};
