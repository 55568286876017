import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { useProducts } from '@lib/core/products/hooks/products';
import { useQuiz, useQuizView, useQuizzes } from '@lib/core/quizzes/hooks';
import { actionResetQuizView, actionUpdateQuizAnswerTag } from '@lib/core/quizzes/slices/quizView';
import { TQuiz } from '@lib/core/quizzes/types';
import { isQuizForProductCategory } from '@lib/core/quizzes/utils/filters';
import { prependBasename } from '@lib/core/service/utils';
import { history } from '@lib/core/service/utils/Navigator';
import { Button } from '@lib/tools/devtools/components/Button';
import { PREFERENCES_URL_PARAM } from '@lib/tools/shared/helpers/consts';
import { recommendProducts } from '@lib/tools/shared/pmi/products/recommender';
import {
  QUIZ_SLUG_ALL_PREFERENCES,
  QUIZ_SLUG_NO_DIARY_PREFERENCES,
  QUIZ_SLUG_NO_MEAT_NO_GLUTEN_PREFERENCES,
  QUIZ_SLUG_NO_PREFERENCES,
} from '@lib/tools/shared/utils/quizzes/consts';
import { useEffectSkipFirst } from '@lib/tools/views/hooks';

export const QuizPanel = () => {
  const dispatch = useDispatch();

  const { quizzes: allQuizzes } = useQuizzes();
  const { quizAnswerTags } = useQuiz();
  const {
    quizViewAnswerTags,
    quizViewDepth,
    quizViewQuestionId,
    quizViewPreviousQuestionId,
    quizViewUserPath,
    quizViewPausedQuestionId,
  } = useQuizView();
  const { productsByCharacterCorrelation } = useProducts();

  const quizzes: TQuiz[] = allQuizzes?.filter(isQuizForProductCategory) || [];

  const [selectedQuizIndex, setSelectedQuizIndex] = useState(0);
  const [recommendedProducts, setRecommendedProducts] = useState([]);

  useEffect(() => {
    if (productsByCharacterCorrelation.length) {
      setRecommendedProducts(recommendProducts(productsByCharacterCorrelation));
    }
  }, [productsByCharacterCorrelation]);

  /**
   * @todo complete quiz options for food preferences
   */
  useEffectSkipFirst(() => {
    const quizType = quizzes[selectedQuizIndex].quiz_type;
    const quizSlug = quizzes[selectedQuizIndex].slug;

    let preferencesParam = `?${PREFERENCES_URL_PARAM}=`;

    switch (true) {
      case quizSlug.includes(QUIZ_SLUG_NO_PREFERENCES):
        preferencesParam += QUIZ_SLUG_NO_PREFERENCES;
        break;
      case quizSlug.includes(QUIZ_SLUG_NO_MEAT_NO_GLUTEN_PREFERENCES):
        preferencesParam += QUIZ_SLUG_NO_MEAT_NO_GLUTEN_PREFERENCES;
        break;
      case quizSlug.includes(QUIZ_SLUG_NO_DIARY_PREFERENCES):
        preferencesParam += QUIZ_SLUG_NO_DIARY_PREFERENCES;
        break;
      case quizSlug.includes(QUIZ_SLUG_ALL_PREFERENCES):
        preferencesParam += QUIZ_SLUG_ALL_PREFERENCES;
        break;
      default:
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        preferencesParam = null;
        break;
    }

    history.navigate(prependBasename(`/quiz/${quizType}/`));
  }, [selectedQuizIndex]);

  const listQuizAnswerTags = [];
  const listProductsByCharacterCorrelation = [];
  const uniqueContexts = new Set([]);
  const optionsQuizzes = [
    <option key="" disabled>
      select quiz
    </option>,
  ];

  quizAnswerTags.forEach(answerTag => uniqueContexts.add(answerTag.context));

  uniqueContexts.forEach(context => {
    const tags = quizAnswerTags.filter(qat => qat.context === context);
    const tagsForCurrentContext = [];

    tags.forEach((answerTag, i) => {
      const isChecked = quizViewAnswerTags.some(
        selectedTag => JSON.stringify(selectedTag) === JSON.stringify(answerTag),
      );

      tagsForCurrentContext.push(
        <div key={i}>
          <input
            checked={isChecked}
            className="mr-2"
            name={`${answerTag.context}.${answerTag.name}`}
            type="checkbox"
            onChange={e => {
              e.stopPropagation();
              dispatch(
                actionUpdateQuizAnswerTag({
                  answerTag: quizAnswerTags.filter(qat => `${qat.context}.${qat.name}` === e.target.name)[0],
                }),
              );
            }}
          />
          <span>{answerTag.name}</span>
        </div>,
      );
    });

    listQuizAnswerTags.push(
      <div key={context}>
        <div className="m-2 font-weight-bold title">{context}</div>
        <span className="code">{tagsForCurrentContext}</span>
      </div>,
    );
  });

  recommendedProducts.forEach(productLocation => {
    if (productLocation) {
      listProductsByCharacterCorrelation.push(
        <span key={productLocation?.identifier}>
          <div>
            {productLocation?.product?.name} - {productLocation?.identifier}
          </div>
          <div>{productLocation?.product?.associated_quiz_answer_tags}</div>
        </span>,
      );
    }
  });

  quizzes.forEach((quiz: TQuiz, i) => {
    optionsQuizzes.push(
      <option key={i} value={i}>
        {quiz.identifier} - {quiz.slug} - {quiz.quiz_type}
      </option>,
    );
  });

  const QuizSelect = (
    <select name="quiz" onChange={e => setSelectedQuizIndex(parseInt(e.target.value, 10))}>
      {optionsQuizzes}
    </select>
  );

  const beautifyUserPath = userPath => {
    const printNode = (node, path) => {
      const depth = path.length;
      const isLast = depth === path.length - 1;
      // eslint-disable-next-line no-nested-ternary
      const prefix = depth === 0 ? '' : isLast ? '└─ ' : '├─ ';

      const nodeString = `${'  '.repeat(depth)}${prefix}${node}${quizViewPausedQuestionId === node ? '*' : ''}`;

      if (path.length > 0) {
        const nextNode = path[0];
        const nextPath = path.slice(1);
        return `${printNode(nextNode, nextPath)}\n${nodeString}`;
      }

      return nodeString;
    };

    const userPathKeys = Object.keys(userPath);
    let result = '';
    // Reverse the order of processing userPathKeys
    for (let i = userPathKeys.length - 1; i >= 0; i -= 1) {
      const key = userPathKeys[i];
      const answerIds = Object.keys(userPath[key])[0];
      const questionId = userPath[key][answerIds][0];
      result += `${printNode(questionId, [answerIds])}\n`;
    }

    return result.trim();
  };

  return (
    <>
      {/* Quiz info */}
      <div className="panel collapsible" role="presentation">
        Quiz
      </div>

      <>
        <div className="panel">
          <span className="title">List</span> {QuizSelect}
        </div>
        <div className="panel">
          <span className="title">Depth</span> {quizViewDepth}
        </div>
        <div className="panel">
          <span className="title">Question</span> {quizViewQuestionId}
        </div>
        <div className="panel">
          <span className="title">Prev Question</span> {quizViewPreviousQuestionId}
        </div>

        <div className="panel">
          <Button onClick={() => dispatch(actionResetQuizView())}>Reset</Button>
        </div>

        {Object.keys(quizViewUserPath).length ? (
          <div className="panel" style={{ whiteSpace: 'pre-wrap' }}>
            <span className="title">Path</span> {quizViewPreviousQuestionId}
            <br />
            <br />
            <div className="code">{beautifyUserPath(quizViewUserPath)}</div>
          </div>
        ) : null}

        <>
          <div className="panel collapsible" role="presentation">
            <span className="title">Tags</span>
          </div>

          <div className="panel">{listQuizAnswerTags}</div>

          {!!listProductsByCharacterCorrelation.length && (
            <div className="panel">
              <span className="title">Products</span>

              {listProductsByCharacterCorrelation}
            </div>
          )}
        </>
      </>
    </>
  );
};
