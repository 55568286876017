import { TCharacter } from '@lib/core/characters/types';
import { TProductCategory } from '@lib/core/products/types';
import { store } from '@lib/core/service/store';
import { selectUserCharacterForSinglePCRetailer } from '@lib/core/users/selectors/user';
import RouteUtils from '@lib/tools/routes';
import {
  CHARACTERS_URL_PARAM,
  PRODUCT_CATEGORY_BEER,
  PRODUCT_CATEGORY_COFFEE,
  PRODUCT_CATEGORY_WINE,
} from '@lib/tools/shared/helpers/consts';

export const isCharacterForWineCategory = (character: TCharacter): boolean =>
  character.product_category === PRODUCT_CATEGORY_WINE;

export const isCharacterForBeerCategory = (character: TCharacter): boolean =>
  character.product_category === PRODUCT_CATEGORY_BEER;

export const isCharacterForCoffeeCategory = (character: TCharacter): boolean =>
  character.product_category === PRODUCT_CATEGORY_COFFEE;

export const isCharacterForThisProductCategory = (character: TCharacter, productCategory: TProductCategory): boolean =>
  character.product_category === productCategory;

export const filterArrayByIdentifiers = (character: TCharacter, identifiers: string[]): boolean =>
  identifiers?.includes(character.identifier);

export const isCharacterByIdentifiers = (character: TCharacter, identifiers?: string[]): boolean => {
  let charactersIdentifiers;
  if (!identifiers) {
    const searchParams = new URLSearchParams(RouteUtils.getSearch());
    const characterStringFromUrl = searchParams.get(CHARACTERS_URL_PARAM) || '';

    if (characterStringFromUrl) {
      charactersIdentifiers = [...new Set(characterStringFromUrl.split('-'))];
    } else {
      charactersIdentifiers = [selectUserCharacterForSinglePCRetailer(store?.getState())?.identifier];
    }
  } else {
    charactersIdentifiers = identifiers;
  }
  return charactersIdentifiers.includes(character.identifier);
};
