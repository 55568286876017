import { useState } from 'react';
import { useMediaQuery } from 'react-responsive';

import { TQuizAnswerData } from '@lib/core/quizzes/types';
import { localeV2 } from '@lib/tools/locale/source/pmi/v2';
import LocaleFragment, { ILocaleText } from '@lib/tools/locale/views/LocaleFragment';
import { useHeight } from '@lib/tools/views/hooks';

import HealthWarningFooterToIsrael from '@components/pmi/src/common/HealthWarningFooterToIsrael';
import CTAButton from '@components/pmi/src/designSetsV2/Components/CTA/Button';
import QuestionHeader from '@components/pmi/src/designSetsV2/Components/Header/Header';
import HealthWarning from '@components/pmi/src/designSetsV2/Components/HealthWarning/HealthWarning';
import AnswerSectionHeader from '@components/pmi/src/designSetsV2/Components/Quiz/AnswerSectionHeader/AnswerSectionHeader';
import ProductReferenceCard from '@components/pmi/src/designSetsV2/Components/Quiz/ProductReferenceCard/ProductReferenceCard';
import AnswerItem from '@components/pmi/src/designSetsV2/Components/Quiz/QDS8001/AnswerItem/AnswerItem';
import QuizFooter from '@components/pmi/src/designSetsV2/Components/Quiz/QuizFooter/QuizFooter';
import { useCurrentQuizBackground } from '@components/pmi/src/hooks/useCurrentQuizBackground';

interface Props {
  questionImage: string;
  questionTitle: string;
  questionCaption: string;
  isBackButtonVisible: boolean;
  isBackButtonEnabled: boolean;
  isLoading: boolean;
  goBack: (count?: number) => void;
  progressPercent: number;
  selectedChoicesCount: number;
  answers: TQuizAnswerData[];
  choicesQuantity: {
    maxChoices: number;
    minChoices: number;
  };
  handleConfirmAnswer: (answerId: string | string[], callback: () => void) => void;
  productCardCaption: string;
  productCardEssenceDescription: string;
  productCardName: string;
  productCardImage: string;
  productCardPrimaryColor: string;
  productCardShowEssenceLink: boolean;
  isPearlProduct: boolean;
  isHealthWarningQuizAddon: boolean;
  isHeetsBackgroundEnabled: boolean;
  isLight?: boolean;
  currentPrimaryColor: string;
  isVeev?: boolean;
  isTobaccoExperienceHiddenAddon: boolean;
  isShowRestrictedBackground: boolean;
  isHealthWarningFooterToIsraelAddon?: boolean;
}

const QuizPage = ({
  questionTitle,
  questionCaption,
  isLoading,
  questionImage,
  goBack,
  isBackButtonEnabled,
  isBackButtonVisible,
  progressPercent,
  answers,
  choicesQuantity,
  handleConfirmAnswer,
  productCardCaption,
  productCardEssenceDescription,
  productCardName,
  productCardImage,
  productCardPrimaryColor,
  productCardShowEssenceLink,
  isPearlProduct,
  isHealthWarningQuizAddon,
  isHeetsBackgroundEnabled,
  isLight = false,
  currentPrimaryColor,
  isVeev = false,
  isTobaccoExperienceHiddenAddon,
  isShowRestrictedBackground,
  isHealthWarningFooterToIsraelAddon,
}: Props) => {
  const [answersArray, setAnswersArray] = useState<string[]>([]);
  const handleClick = () => {
    handleConfirmAnswer(answersArray, () => {
      setAnswersArray([]);
    });
  };
  const goChange = () => {
    goBack(1);
  };

  const productCardLinkText: ILocaleText = localeV2.quiz.productReferenceLinkIntensity;
  const [footerRef, paddingForFooter] = useHeight<HTMLDivElement>();
  const minChoicesQuantity = choicesQuantity?.minChoices;
  const maxAnswersChoices = choicesQuantity?.maxChoices;
  const isMobileResolution = useMediaQuery({ maxWidth: 767 });
  const isNextButtonEnabled = answersArray.length >= minChoicesQuantity && maxAnswersChoices >= 2;
  const currentBackground = useCurrentQuizBackground(questionImage, isShowRestrictedBackground);

  const questionHeaderProductCardDetails = {
    goChange,
    isPearlProduct,
    isTobaccoExperienceHiddenAddon,
    productCardCaption,
    productCardEssenceDescription,
    productCardImage,
    productCardLinkText,
    productCardName,
    productCardPrimaryColor,
    productCardShowEssenceLink,
  };

  return (
    <div
      className={`ta-v2-qds8008-page ${isHealthWarningFooterToIsraelAddon ? 'padding-bottom-30vh minh-101vh' : 'minh-100vh'}`}
      style={{
        background: currentBackground,
        paddingBottom: paddingForFooter,
      }}
    >
      <QuestionHeader
        caption={questionCaption}
        currentPrimaryColor={currentPrimaryColor}
        goBack={goBack}
        isBackButtonEnabled={isBackButtonEnabled}
        isBackButtonVisible={isBackButtonVisible}
        isHeetsBackgroundEnabled={isHeetsBackgroundEnabled}
        isLoading={isLoading}
        isVeev={isVeev}
        progressPercent={progressPercent}
        questionHeaderProductCardDetails={questionHeaderProductCardDetails}
        title={questionTitle}
      />
      <div className="ta-v2-qds8008-page-answer-section-cover">
        <div className="ta-v2-qds8008-page-answer-section">
          <ProductReferenceCard
            caption={productCardCaption}
            classname="ta-v2-qds8008-product-reference-card"
            essenceDescription={productCardEssenceDescription}
            goChange={goChange}
            image={productCardImage}
            isPearlProduct={isPearlProduct}
            isTobaccoExperienceHiddenAddon={isTobaccoExperienceHiddenAddon}
            isVeev={isVeev}
            name={productCardName}
            primaryColor={productCardPrimaryColor}
            productCardLinkText={productCardLinkText}
            showEssenceLink={productCardShowEssenceLink}
          />
          <AnswerSectionHeader
            className="ta-v2-qds8008-page-answer-section-header"
            isLight={isLight}
            maxChoicesCount={maxAnswersChoices}
            selectedChoicesCount={answersArray?.length || 0}
          />
          <div className="ta-v2-qds8008-page-answer-item-container">
            {answers.map(answer => {
              const answerId = Object.keys(answer)[0];
              return (
                <AnswerItem
                  key={answerId}
                  answerId={answerId}
                  answerText={answer[answerId].text}
                  answersArray={answersArray}
                  className="ta-v2-qds8008-page-answer-item"
                  currentPrimaryColor={currentPrimaryColor}
                  handleConfirmAnswer={handleConfirmAnswer}
                  isLight={isLight}
                  maxAnswersChoices={maxAnswersChoices}
                  setAnswersArray={setAnswersArray}
                />
              );
            })}
          </div>
        </div>
        {!isMobileResolution && maxAnswersChoices > 1 ? (
          <CTAButton
            btnText={<LocaleFragment message={localeV2.common.nextQuestion} />}
            className="ta-v2-cta-btn-margin"
            isDark={isNextButtonEnabled}
            isDisabled={!isNextButtonEnabled}
            onBtnClick={handleClick}
          />
        ) : null}
      </div>
      <div ref={footerRef} className="ta-v2-quiz-footer-wrapper">
        {isMobileResolution && maxAnswersChoices > 1 ? (
          <QuizFooter handleClick={handleClick} isNextButtonEnabled={isNextButtonEnabled} />
        ) : null}
        {isHealthWarningQuizAddon && <HealthWarning />}
      </div>
      {isHealthWarningFooterToIsraelAddon && <HealthWarningFooterToIsrael />}
    </div>
  );
};

export default QuizPage;
