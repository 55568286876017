import { FC } from 'react';
import { Row } from 'reactstrap';

import CustomMDReactComponent from '@lib/tools/locale/views/CustomMDReactComponent';

interface Props {
  text: string;
}

const QuestionText: FC<Props> = ({ text }) => (
  <Row className="taste-advisor-4-one-question-text justify-content-center ta-jp-h4-bold ta-jp-mob-h4-bold text-center">
    <div>
      {text.split('\n').map((el, i) => (
        <CustomMDReactComponent key={i} className="mb-0 text-margin" text={el} />
      ))}
    </div>
  </Row>
);

export default QuestionText;
