import classNames from 'classnames';
import React, { FC, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Col, Row } from 'reactstrap';

import CharacterDescriptionContainer from '@app/pmi/src/containers/ResultDescriptionContainer';
import useHealthWarningFooter from '@app/pmi/src/hooks/useHealthWarningFooter';

import { TCharacter } from '@lib/core/characters/types';
import { TProductCategory, TProductInstance } from '@lib/core/products/types';
import { localeTEREA } from '@lib/tools/locale/source/pmi/terea';
import LocaleFragment from '@lib/tools/locale/views/LocaleFragment';
import { recommendProducts } from '@lib/tools/shared/pmi/products/recommender';
import { HEETS } from '@lib/tools/shared/pmi/retailers/consts';
import { useAddons, useRetailerDesignSet } from '@lib/tools/views/hooks';

import Spinner from '@components/pmi/src/common/Spinner';
import HealthWarningFooter from '@components/pmi/src/designSets/addOns/common/HealthWarningFooter3';
import MentholDisclaimer from '@components/pmi/src/designSets/addOns/common/MentholDisclaimer';
import CharacterProducts from '@components/pmi/src/designSets/terea/main/one/CharacterProducts';
import ProductPortfolio from '@components/pmi/src/designSets/terea/main/one/ProductPortfolio';
import StaticBannerComponent from '@components/pmi/src/designSets/terea/main/one/StaticBanner';
import { navigateHome } from '@components/pmi/src/utils';

interface Props {
  character: TCharacter;
  gprls: TProductInstance[];
  getAssociatedCharacterDispatched: (p: TProductCategory) => void;
  isMultiProduct: boolean;
  retailerTags: Record<string, string>;
}

const TasteAdvisorResultTestComponent: FC<Props> = ({
  character,
  gprls,
  getAssociatedCharacterDispatched,
  isMultiProduct,
  retailerTags,
}) => {
  const dispatch = useDispatch();
  const { isDesignSetTasteAdvisorIQOS4Global } = useRetailerDesignSet();

  const {
    isNoHeetsAddon,
    isHealthWarningFooterAddon,
    isHealthWarningBottomBlack15PerAddon,
    isHealthWarningLandingBottom10PerAddon,
    isMentholDisclaimerAddon,
    isNoMarketingBanner,
  } = useAddons();

  const isHealthWarningFooterEnabled =
    isHealthWarningBottomBlack15PerAddon || isHealthWarningLandingBottom10PerAddon || isHealthWarningFooterAddon;

  const onDiscoverHeets = () => {
    getAssociatedCharacterDispatched(HEETS);
  };

  const marginFallback = useHealthWarningFooter(24, true);
  const margin = isHealthWarningFooterEnabled && marginFallback;

  const [showLoaderAnimation, setShowLoaderAnimation] = useState(true);
  useEffect(() => {
    const timer = setTimeout(() => {
      setShowLoaderAnimation(false);
    }, 1000);
    return () => {
      clearTimeout(timer);
    };
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Row
        className={classNames('mx-0 taste_advisor_4_one_result_test', {
          invisible: showLoaderAnimation,
        })}
      >
        <Col className="px-0" style={{ marginBottom: `${margin}px` }} xs={12}>
          <CharacterDescriptionContainer character={character} />
          <CharacterProducts products={recommendProducts(gprls)} retailerTags={retailerTags} />
          {isMentholDisclaimerAddon && !isDesignSetTasteAdvisorIQOS4Global && (
            <div className="max-width-1236px mx-auto margin-top-10px padding-x-10px">
              <MentholDisclaimer />
            </div>
          )}
          {/* @todo Show this only if HEETS is one of the supported product categories. */}
          {!isNoHeetsAddon && isMultiProduct && (
            <Row className="align-items-center justify-content-center py-2 mx-0">
              <div
                role="button"
                tabIndex={0}
                className={`dis-heets-btn d-flex justify-content-center align-items-center ta-jp-p2 ta-jp-mob-p2 my-1 ${
                  isDesignSetTasteAdvisorIQOS4Global ? '' : 'col px-0'
                }`}
                onClick={onDiscoverHeets}
                onKeyDown={e => {
                  if (e.key === 'Enter') {
                    onDiscoverHeets();
                  }
                }}
              >
                <LocaleFragment message={localeTEREA.result.discoverHeets} />
              </div>
            </Row>
          )}
          {!isNoMarketingBanner && <StaticBannerComponent />}

          <ProductPortfolio gprls={gprls} retailerTags={retailerTags} />

          {!isDesignSetTasteAdvisorIQOS4Global && (
            <div
              className="taste-advisor-4-cta-start-over text-center ta-jp-p3_1 ta-jp-mob-p3_1"
              role="button"
              tabIndex={0}
              onClick={() => navigateHome(dispatch)}
              onKeyDown={() => navigateHome(dispatch)}
            >
              <LocaleFragment message={localeTEREA.result.startOver} />
            </div>
          )}

          <button
            className="go-top-top d-xl-none"
            type="button"
            onClick={() => {
              window.scrollTo({ behavior: 'smooth', top: 0 });
            }}
          />
        </Col>
        <HealthWarningFooter isVisible={isHealthWarningFooterEnabled} legalWarningFlag={false} />
      </Row>
      {showLoaderAnimation && <Spinner />}
    </>
  );
};

export { TasteAdvisorResultTestComponent as TasteAdvisorResultTestComponentWithoutMemo };
export default React.memo(TasteAdvisorResultTestComponent);
