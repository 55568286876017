export const B2B_MOBILE_RESOLUTION_MAX_VALUE = 833;
export const B2B_TABLET_RESOLUTION_MAX_VALUE = 1079;
export const B2B_DESKTOP_RESOLUTION_MIN_VALUE = 1080;
export const LOCATION_ICON = 'Location';
export const NONE_ICON = 'none';

// home page
export const KIOSK_HOME_PAGE_CARD_TYPES = {
  tip: 'tip',
  wishlist: 'wishlist',
} as const;

// result and situational result pages
export const KIOSK_RESULT_PAGE_MAX_PRODUCTS = 3;
export const KIOSK_PRODUCT_TIP_MAX_PRODUCT = 1;
