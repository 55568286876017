import cn from 'classnames';
import { ButtonHTMLAttributes, DetailedHTMLProps, FC } from 'react';

type Props = DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> & {
  btnText: string | JSX.Element;
  onBtnClick: () => void;
  variant?: 'filled' | 'outlined';
  className?: string;
};

const Button: FC<Props> = ({ btnText, onBtnClick, variant = 'filled', className }) => (
  <button
    type="button"
    className={cn('blends-cta-btn', className, {
      'ht-cta-btn-filled': variant === 'filled',
      'ht-cta-btn-outlined': variant === 'outlined',
    })}
    onClick={onBtnClick}
  >
    <span>{btnText}</span>
  </button>
);

export default Button;
