import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { TProductCategory, TProductInstance } from '@lib/core/products/types';
import { IRequestParams } from '@lib/core/products/utils';
import { selectRetailerSlug } from '@lib/core/retailers/selectors/retailer';
import {
  selectAssociatedRetailerLocation,
  selectRetailerLocationSlug,
} from '@lib/core/retailers/selectors/retailerLocation';
import { ASSOCIATED_RETAILER_SLUG_QUERY, GPRL_PROMOTIONS_QUERY, PRODUCT_NAME_QUERY } from '@lib/core/service/consts';
import backendApiUrls from '@lib/core/service/requests/backend_api_urls';
import request from '@lib/core/service/requests/request';
import { RootState } from '@lib/core/service/types/appStateType';
import {
  ICatalogFilterDataKeys,
  IFetchProductsLimitedOptions,
  IFetchProductsOptions,
  IProductsRequestMeta,
  IProductsResponse,
} from '@lib/core/service/types/interface';
import {
  createBackendRetailerLocationSlug,
  createBackendRetailerUrl,
  shuffleProductsInsideRankGroups,
} from '@lib/core/service/utils';
import { TProductCatalogOrdering, actionResetCatalogFilters } from '@lib/tools/filterManager/slices/productFilter';
import {
  FILTER_TYPE_ORDERING,
  PRICE,
  PRODUCT_CATEGORY_QUERY,
  PROMOTION_FEATURED_SLUG,
} from '@lib/tools/shared/helpers/consts';

export interface IProductsRequestData {
  count: number | undefined;
  next: string;
  previous: string;
  results: TProductInstance[];
}

export interface IProductsLimitedRequestData {
  first_priority: TProductInstance[];
  second_priority: TProductInstance[];
  third_priority: TProductInstance[];
}

export type IGenericCatalogFilterBasic = {
  [Key in Exclude<ICatalogFilterDataKeys, typeof PRICE>]?: { active: boolean; name: string; value: string }[];
} & {
  [Key in Extract<typeof PRICE, ICatalogFilterDataKeys>]?: {
    count: number;
    min: number;
    max: number;
    avg: number;
    sum: number;
  };
};
export type IGenericCatalogFilter = IGenericCatalogFilterBasic & {
  [FILTER_TYPE_ORDERING]?: TProductCatalogOrdering;
};
export type IFiltersData = {
  [Key in TProductCategory]?: IGenericCatalogFilter;
};

export interface IProductCatalogState {
  isProductsRequestLoading: boolean;
  isFeaturedProductsLoading: boolean;
  productsRequestError: string;
  productsData: IProductsRequestData;
  productsDataLimited: IProductsLimitedRequestData;
  filtersData: IFiltersData;
  lastDataLoadedTime: number;
  lastFeaturedProductsLoadedTime: number;
  featuredProducts: TProductInstance[];
  staticMatchedProducts: TProductInstance[];
}

export const initialState: IProductCatalogState = {
  featuredProducts: [],
  filtersData: {},
  isFeaturedProductsLoading: false,
  isProductsRequestLoading: false,
  lastDataLoadedTime: 0,
  lastFeaturedProductsLoadedTime: 0,
  productsData: {
    count: undefined,
    next: '',
    previous: '',
    results: [],
  },
  productsDataLimited: {
    first_priority: [],
    second_priority: [],
    third_priority: [],
  },
  productsRequestError: '',
  staticMatchedProducts: [],
};
export const getProductsListData = ({ params, state }: { params: IRequestParams; state: RootState }) => {
  const retailerLocationSlug = selectRetailerLocationSlug(state);
  const retailerSlug = selectRetailerSlug(state);

  const requestUrl = createBackendRetailerLocationSlug(
    createBackendRetailerUrl(
      params[PRODUCT_NAME_QUERY]
        ? backendApiUrls.apiUrlB2CProductsListSearchByText
        : backendApiUrls.apiUrlB2CProductsList,
      retailerSlug,
    ),
    retailerLocationSlug,
  );

  return request(requestUrl, {
    additionalHeaders: {
      Accept: 'application/json; version="2.0"',
    },
    params,
  });
};

export const fetchProductsListsExtended = createAsyncThunk(
  'table/fetchProductsLists',
  async ({ params }: IFetchProductsOptions, thunkAPI) => {
    const { getState } = thunkAPI;
    const state: RootState = getState() as RootState;
    const response = await getProductsListData({ params, state });
    return { ...response, results: shuffleProductsInsideRankGroups(response.results) };
  },
);

export const fetchProductsListsFeatured = createAsyncThunk(
  'table/fetchProductsListsFeatured',
  async ({ params }: IFetchProductsOptions, thunkAPI) => {
    const { getState } = thunkAPI;
    const state: RootState = getState() as RootState;
    const response = await getProductsListData({
      params: { ...params, [GPRL_PROMOTIONS_QUERY]: PROMOTION_FEATURED_SLUG },
      state,
    });
    return { ...response, results: shuffleProductsInsideRankGroups(response.results) };
  },
);

export const fetchProductsListsLimited = createAsyncThunk(
  'table/fetchProductsListsLimited',
  async ({ params }: IFetchProductsLimitedOptions, thunkAPI) => {
    const { getState } = thunkAPI;
    const state: RootState = getState() as RootState;
    const rtSlug = selectRetailerSlug(state);
    const rtlSlug = selectRetailerLocationSlug(state);
    const associatedRTL = selectAssociatedRetailerLocation(state);
    const requestUrl = createBackendRetailerLocationSlug(
      createBackendRetailerUrl(backendApiUrls.searchProductLimitedUrl, rtSlug),
      rtlSlug,
    );
    return await request(requestUrl, {
      params: { ...params, [ASSOCIATED_RETAILER_SLUG_QUERY]: associatedRTL },
    });
  },
);

export const productCatalogSlice = createSlice({
  extraReducers: builder => {
    builder.addCase(fetchProductsListsLimited.pending, state => {
      state.isProductsRequestLoading = true;
      state.lastDataLoadedTime = 0;
      state.productsDataLimited = initialState.productsDataLimited;
    });
    builder.addCase(fetchProductsListsLimited.fulfilled, (state, action: { payload: IProductsLimitedRequestData }) => {
      const { payload } = action;
      state.isProductsRequestLoading = false;
      state.productsDataLimited = payload;
      state.lastDataLoadedTime = new Date().getTime();
    });
    builder.addCase(fetchProductsListsLimited.rejected, (state, action: any) => {
      if (action.payload?.errorMessage) {
        state.productsRequestError = action.payload.errorMessage;
      } else if (action.error?.message) {
        state.productsRequestError = action.error.message;
      }

      state.isProductsRequestLoading = false;
      state.lastDataLoadedTime = new Date().getTime();
    });

    builder.addCase(
      fetchProductsListsExtended.pending,
      (state, action: { meta: IProductsRequestMeta; payload: IProductsResponse }) => {
        const { meta: { arg: { isPagination = false } = {} } = {} } = action;
        if (!isPagination) {
          state.productsData = {
            count: undefined,
            next: '',
            previous: '',
            results: [],
          };
        }
        state.isProductsRequestLoading = true;
      },
    );
    builder.addCase(
      fetchProductsListsExtended.fulfilled,
      (state, action: { meta: IProductsRequestMeta; payload: IProductsResponse }) => {
        const {
          payload: { results = [], stats = {} } = {},
          meta: {
            arg: {
              shouldShuffleResponse,
              isPagination,
              isForCollectFilters,
              params: { [PRODUCT_CATEGORY_QUERY]: productCategory } = {},
            } = {},
          } = {},
        } = action;
        if (!isForCollectFilters) {
          const newArray = shouldShuffleResponse ? shuffleProductsInsideRankGroups(results) : results;
          state.productsData = {
            ...action.payload,
            results: isPagination ? [...state.productsData.results, ...newArray] : newArray,
          };
        }
        state.isProductsRequestLoading = false;
        state.lastDataLoadedTime = isForCollectFilters ? 0 : new Date().getTime();
        const currentData = state.filtersData[productCategory] || {};
        const filtersDataFull = { [PRICE]: isForCollectFilters ? stats[PRICE] : currentData[PRICE] };
        Object.keys(stats).forEach(filterDataKey => {
          if (filterDataKey !== PRICE) {
            filtersDataFull[filterDataKey] = Object.keys(stats[filterDataKey]).map(filterValueKey => ({
              active: false,
              name: stats[filterDataKey][filterValueKey]?.name,
              value: filterValueKey,
            }));
          }
        });
        state.filtersData = { [productCategory]: filtersDataFull };
      },
    );
    builder.addCase(fetchProductsListsFeatured.pending, state => {
      state.isFeaturedProductsLoading = true;
    });
    builder.addCase(fetchProductsListsFeatured.rejected, state => {
      state.isFeaturedProductsLoading = false;
    });
    builder.addCase(
      fetchProductsListsFeatured.fulfilled,
      (state, action: { meta: IProductsRequestMeta; payload: IProductsResponse }) => {
        const { payload: { results = [] } = {} } = action;
        state.isFeaturedProductsLoading = false;
        state.featuredProducts = results;
        state.lastFeaturedProductsLoadedTime = new Date().getTime();
      },
    );
    builder.addCase(fetchProductsListsExtended.rejected, (state, action: any) => {
      if (action.payload?.errorMessage) {
        state.productsRequestError = action.payload.errorMessage;
      } else if (action.error?.message) {
        state.productsRequestError = action.error.message;
      }
      state.isProductsRequestLoading = false;
    });
    builder.addCase(actionResetCatalogFilters, state => {
      state.filtersData = initialState.filtersData;
    });
  },
  initialState,
  name: 'table',
  reducers: {
    resetProductCatalogState: state => {
      state.isProductsRequestLoading = false;
      state.productsData = initialState.productsData;
      state.filtersData = initialState.filtersData;
      state.featuredProducts = initialState.featuredProducts;
      state.lastDataLoadedTime = 0;
      state.lastFeaturedProductsLoadedTime = 0;
      state.staticMatchedProducts = [];
    },
    setStaticMatchedProducts: (state, action) => {
      state.staticMatchedProducts = action.payload;
    },
  },
});

export default productCatalogSlice.reducer;
export const { resetProductCatalogState, setStaticMatchedProducts } = productCatalogSlice.actions;
