import { apiUrlGetProduct } from '@lib/core/products/slices/urls';
import { IApiUrlGetProductProps, TProductInstance } from '@lib/core/products/types';
import { parseFindProduct } from '@lib/core/products/utils/parseFindProduct';
import { parseProductIdentities } from '@lib/core/products/utils/parseProductIdentities';
import { parseProductInstance } from '@lib/core/products/utils/parseProductInstance';
import { parseProductPreparations } from '@lib/core/products/utils/parseProductPreparations';
import { selectRetailerSlug } from '@lib/core/retailers/selectors/retailer';
import { selectRetailerLocationSlug } from '@lib/core/retailers/selectors/retailerLocation';
import { GPRL_CHARACTER_QUERY, GPRL_PROMOTIONS_QUERY, PREFERENCES_QUERY } from '@lib/core/service/consts';
import backendApiUrls from '@lib/core/service/requests/backend_api_urls';
import request from '@lib/core/service/requests/request';
import { selectServiceProduct } from '@lib/core/service/selectors';
import { store } from '@lib/core/service/store';
import { IProductsResponse } from '@lib/core/service/types/interface';
import { createBackendRetailerLocationSlug, createBackendRetailerUrl } from '@lib/core/service/utils';
import { productsSuggestionApiUrlCreator } from '@lib/core/users/slices/urls';
import { FILTER_TYPE_ORDERING, PRODUCT_CATEGORY_QUERY } from '@lib/tools/shared/helpers/consts';

export interface IRequestParams {
  offset?: number;
  limit?: number;
  [GPRL_CHARACTER_QUERY]?: string;
  [PRODUCT_CATEGORY_QUERY]?: string;
  [GPRL_PROMOTIONS_QUERY]?: string;
  [PREFERENCES_QUERY]?: string;
  [FILTER_TYPE_ORDERING]?: string;
}

const getProductData = ({
  productId,
  customRetailerSlug,
  customRetailerLocationSlug,
}: IApiUrlGetProductProps): TProductInstance => {
  const requestUrl = apiUrlGetProduct({ customRetailerLocationSlug, customRetailerSlug, productId });

  return request(requestUrl, {
    additionalHeaders: {
      Accept: 'application/json; version="2.0"',
    },
  });
};

const getProductsListData = (params: IRequestParams): IProductsResponse => {
  const state = store.getState();
  const retailerLocationSlug = selectRetailerLocationSlug(state);
  const retailerSlug = selectRetailerSlug(state);

  const requestUrl = createBackendRetailerLocationSlug(
    createBackendRetailerUrl(backendApiUrls.apiUrlB2CProductsList, retailerSlug),
    retailerLocationSlug,
  );

  return request(requestUrl, {
    additionalHeaders: {
      Accept: 'application/json; version="2.0"',
    },
    params,
  });
};

const getTastePathProductsData = params => {
  const state = store.getState();
  const productCategory = selectServiceProduct(state);

  const requestUrl = productsSuggestionApiUrlCreator({ productCategory });

  return request(requestUrl, {
    additionalHeaders: {
      Accept: 'application/json; version="2.0"',
    },
    params,
  });
};

export {
  parseFindProduct,
  parseProductIdentities,
  parseProductInstance,
  parseProductPreparations,
  getProductData,
  getProductsListData,
  getTastePathProductsData,
};
