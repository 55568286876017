import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useRetailer } from '@lib/core/retailers/hooks/retailer';
import { selectRetailerLocationsList } from '@lib/core/retailers/selectors/retailerLocation';
import { actionGetRetailerLocations } from '@lib/core/retailers/slices';
import { isApplicationPmi } from '@lib/core/service/consts';
import { useUser } from '@lib/core/users/hooks';
import { useTracking } from '@lib/tools/dat/hooks';
import { MixpanelExtras } from '@lib/tools/dat/mixpanel';
import { useEffectSkipFirst } from '@lib/tools/views/hooks';

/**
 * * Ghost Inspector assertions for MixPanel and logging
 */
export const TrackingPanel = () => {
  const dispatch = useDispatch();

  const { retailerSlug } = useRetailer();
  const { userGroups, isUserKioskAdmin } = useUser();
  const { logHistory, isLoggingEnabled } = useTracking();

  const retailerLocations = useSelector(selectRetailerLocationsList);

  const [isMixpanelEnabled, toggleMixpanelEvents] = useState(false);

  useEffectSkipFirst(() => {
    MixpanelExtras.switchTrackingOnOff(!isMixpanelEnabled, userGroups);
  }, [isMixpanelEnabled]);

  useEffect(() => {
    if (!retailerLocations.length && isLoggingEnabled && retailerSlug && !isUserKioskAdmin) {
      dispatch(
        actionGetRetailerLocations({
          retailerSlug,
        }),
      );
    }
  }, [isLoggingEnabled, retailerSlug]);

  const logsList = [];

  logHistory.forEach((log, idx) => {
    logsList.push(
      <div key={idx} className="log-item pb-2">
        <div className="log-name mt-3">* {JSON.stringify(log.eventName)}</div>
        <div className="log-args code my-1">{JSON.stringify(log.args)}</div>
      </div>,
    );
  });

  return (
    <>
      <div className="panel collapsible">Logs</div>

      {!!logsList.length && (
        <>
          {!isApplicationPmi && (
            <div>
              <input
                checked={isMixpanelEnabled}
                className="mr-1 mt-2"
                type="checkbox"
                onChange={e => {
                  e.stopPropagation();
                  toggleMixpanelEvents(prev => !prev);
                }}
              />{' '}
              Send events
            </div>
          )}
          <div className="panel">{logsList}</div>
        </>
      )}
    </>
  );
};
