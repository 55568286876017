import classNames from 'classnames';
import React, { FC, Fragment } from 'react';
import { useDispatch } from 'react-redux';
import { Col, Row } from 'reactstrap';

import { TProductInstance } from '@lib/core/products/types';
import { localeLegacy } from '@lib/tools/locale/source/pmi/legacy';
import LocaleFragment from '@lib/tools/locale/views/LocaleFragment';
import { useAddons } from '@lib/tools/views/hooks';

import ProductCard from '@components/pmi/src/designSets/heets/main/one/ProductCard';
import { isOpenIntegration, navigateHome } from '@components/pmi/src/utils';

interface Props {
  products: TProductInstance[];
  hideTitle: boolean;
}

const IQOS3OneCharacterProducts: FC<Props> = ({ products: gprls, hideTitle }) => {
  const dispatch = useDispatch();
  const { isDisplayResultPageDescriptionAddon } = useAddons();

  const nonCreationGrpls = gprls?.filter(({ product: { attributes } }) => attributes.is_dimensions !== 'true') || [];
  const creationGrpls = gprls?.filter(({ product: { attributes } }) => attributes.is_dimensions === 'true') || [];

  if (gprls) {
    return (
      <Row className="mx-auto taste-advisor-3-one-character-products-wrapper">
        <Col>
          {!hideTitle && (
            <Row className="header-wrapper justify-content-center align-item s-center text-center">
              <p className="d-flex justify-content-center align-items-center mb-0 ta-pmi3-p1_2 w-100 mt-4">
                <LocaleFragment message={localeLegacy.iqos3.resultHeetsMatch} />
              </p>
            </Row>
          )}

          <Row
            className={classNames('justify-content-center align-items-stretch text-center', {
              'mt-md-5 mt-4 pt-1': hideTitle,
            })}
          >
            {nonCreationGrpls.map((gprl, i) => {
              const clickPairGprl = gprl.product.attributes.click_pair
                ? gprls.find(item => item.product.identifier === gprl.product.attributes.click_pair)
                : undefined;
              return i === 0 || i === 1 ? (
                <ProductCard
                  key={gprl.identifier}
                  clickPairGprl={clickPairGprl}
                  genericProductRetailerLocation={gprl}
                />
              ) : null;
            })}
          </Row>

          {creationGrpls.map((gprl, i) => {
            const clickPairCreationGprl = gprl.product.attributes.click_pair
              ? gprls.find(item => item.product.identifier === gprl.product.attributes.click_pair)
              : undefined;
            return i === 0 ? (
              <Fragment key={gprl.identifier}>
                <Row className="card__header p-3 mt-2 mt-md-0">
                  <Col className="ta-pmi3-p1 text-center w-75" xs={12}>
                    <LocaleFragment message={localeLegacy.iqos3.creationsDescription} />
                  </Col>
                </Row>

                <Row
                  className={classNames('justify-content-center align-items-start text-center', {
                    'mt-md-5 mt-4 pt-1': hideTitle,
                  })}
                >
                  <ProductCard clickPairGprl={clickPairCreationGprl} genericProductRetailerLocation={gprl} />
                </Row>
              </Fragment>
            ) : null;
          })}
          {isDisplayResultPageDescriptionAddon && (
            <Col className="mx-auto my-3 text-center ta-pmi3-p5" lg={6} md={8} sm={10} xl={4}>
              <LocaleFragment message={localeLegacy.iqos3.resultTestDisclaimer} />
            </Col>
          )}
          {/* Start over */}
          {isOpenIntegration() ? null : (
            <Col className="px-0 d-flex flex-column align-items-center" xs={12}>
              <button className="start-over-btn ta-pmi3-p2_2" type="button" onClick={() => navigateHome(dispatch)}>
                <LocaleFragment message={localeLegacy.iqos3.resultTestStartOver} />
              </button>
            </Col>
          )}
        </Col>
      </Row>
    );
  }
  return <div />;
};

export default React.memo(IQOS3OneCharacterProducts);
