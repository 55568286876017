import React, { FC } from 'react';
import { Col } from 'reactstrap';

import { localeLegacy } from '@lib/tools/locale/source/pmi/legacy';
import LocaleFragment from '@lib/tools/locale/views/LocaleFragment';

interface Props {
  dispatchAnswer: () => void;
  isNextBtnActive: boolean;
}

const NextBtn: FC<Props> = ({ dispatchAnswer, isNextBtnActive }) => {
  return (
    <Col className="ta-pmi3-next-btn-wrapper px-0 d-flex flex-column align-items-center" xs={12}>
      <button className="d-block qds3-next-btn-v2" disabled={!isNextBtnActive} type="button" onClick={dispatchAnswer}>
        <div className="d-flex align-items-center justify-content-center">
          <span className="ta-pmi3-p2-bold">
            <LocaleFragment message={localeLegacy.iqos3.quizAnswerNext} />
          </span>
        </div>
      </button>
    </Col>
  );
};

export default React.memo(NextBtn);
