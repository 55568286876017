import classNames from 'classnames';
import { FC } from 'react';

interface IBackButton {
  className?: string;
  goBack: () => void;
}

const BackButton: FC<IBackButton> = ({ className, goBack }) => (
  <button className={classNames('blends-gen-btn back-btn', className)} type="button" onClick={goBack} />
);

export default BackButton;
