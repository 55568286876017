import { FC } from 'react';

import { TCharacter } from '@lib/core/characters/types';
import { useApp } from '@lib/core/service/hooks';
import {
  DESIGN_SET_TASTE_ADVISOR_BLENDS_ONE,
  DESIGN_SET_TASTE_ADVISOR_IQOS3_ONE,
  DESIGN_SET_TASTE_ADVISOR_IQOS3_THREE,
  DESIGN_SET_TASTE_ADVISOR_IQOS4_GLOBAL,
  DESIGN_SET_TASTE_ADVISOR_IQOS4_ONE,
} from '@lib/tools/shared/pmi/retailers/consts';
import { useRetailerDesignSet } from '@lib/tools/views/hooks';

import CharacterDescriptionComponentIQOS3One from '@components/pmi/src/designSets/heets/main/one/Description';
import CharacterDescriptionComponentIQOS3Three from '@components/pmi/src/designSets/heets/main/three/Description';
import CharacterDescriptionComponentIQOS4One from '@components/pmi/src/designSets/terea/main/one/Description';

interface Props {
  character: TCharacter;
}

const CharacterDescriptionContainer: FC<Props> = ({ character }) => {
  const { retailerDesignSet } = useRetailerDesignSet();
  const { isLayoutRightToLeft } = useApp();

  const sendProps = {
    character,
    isLayoutRightToLeft,
  };

  const componentMapper = {
    [DESIGN_SET_TASTE_ADVISOR_BLENDS_ONE]: <CharacterDescriptionComponentIQOS3Three {...sendProps} />,
    [DESIGN_SET_TASTE_ADVISOR_IQOS3_ONE]: <CharacterDescriptionComponentIQOS3One {...sendProps} />,
    [DESIGN_SET_TASTE_ADVISOR_IQOS3_THREE]: <CharacterDescriptionComponentIQOS3Three {...sendProps} />,
    [DESIGN_SET_TASTE_ADVISOR_IQOS4_GLOBAL]: <CharacterDescriptionComponentIQOS4One {...sendProps} />,
    [DESIGN_SET_TASTE_ADVISOR_IQOS4_ONE]: <CharacterDescriptionComponentIQOS4One {...sendProps} />,
  };
  return componentMapper[retailerDesignSet] || null;
};

export default CharacterDescriptionContainer;
