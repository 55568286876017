import classnames from 'classnames';
import React, { FC, useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Row } from 'reactstrap';

import { actionPostProductFeedback } from '@lib/core/products/slices/feedback';
import { TProduct } from '@lib/core/products/types';
import { selectUserQuizId } from '@lib/core/quizzes/selectors';
import { localeTEREA } from '@lib/tools/locale/source/pmi/terea';
import LocaleFragment from '@lib/tools/locale/views/LocaleFragment';
import { useRetailerDesignSet } from '@lib/tools/views/hooks';

const threeProductsLikeImg = (
  <svg fill="none" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
    <ellipse cx="11.9243" cy="11.9243" rx="11.9243" ry="11.9243" />
    <path d="M18.0349 8.0387C17.3291 7.23789 16.3501 6.79688 15.278 6.79688C13.7703 6.79688 12.8158 7.69735 12.2805 8.45277C12.1416 8.64879 12.0235 8.84535 11.9243 9.03031C11.8251 8.84535 11.707 8.64879 11.5681 8.45277C11.0329 7.69735 10.0783 6.79688 8.5706 6.79688C7.49858 6.79688 6.51949 7.23792 5.8137 8.03873C5.1405 8.80265 4.76971 9.82575 4.76971 10.9196C4.76971 12.1102 5.23457 13.2176 6.23263 14.4046C7.12464 15.4655 8.40791 16.5591 9.89392 17.8253C10.4476 18.2972 11.0203 18.7852 11.6299 19.3185L11.6482 19.3346C11.7272 19.4038 11.8258 19.4384 11.9243 19.4384C12.0229 19.4384 12.1214 19.4038 12.2004 19.3346L12.2187 19.3185C12.8284 18.7852 13.401 18.2972 13.9548 17.8253C15.4407 16.5591 16.724 15.4655 17.616 14.4046C18.6141 13.2176 19.0789 12.1102 19.0789 10.9196C19.0789 9.82575 18.7081 8.80265 18.0349 8.0387ZM13.4109 17.1872C12.9336 17.5939 12.4423 18.0126 11.9243 18.4629C11.4063 18.0126 10.915 17.594 10.4376 17.1871C7.52941 14.7089 5.60814 13.0717 5.60814 10.9196C5.60814 10.03 5.90453 9.20375 6.44272 8.59307C6.98708 7.97545 7.74276 7.6353 8.5706 7.6353C9.72006 7.6353 10.4631 8.34344 10.884 8.93749C11.2615 9.47029 11.4585 10.0074 11.5257 10.2136C11.5819 10.3861 11.7428 10.5029 11.9243 10.5029C12.1058 10.5029 12.2667 10.3861 12.3229 10.2136C12.3901 10.0074 12.5871 9.47029 12.9646 8.93747C13.3855 8.34344 14.1286 7.6353 15.278 7.6353C16.1059 7.6353 16.8616 7.97545 17.4059 8.59307C17.9441 9.20375 18.2405 10.03 18.2405 10.9196C18.2405 13.0717 16.3192 14.7089 13.4109 17.1872Z" />
  </svg>
);

const threeProductsDislikeImg = (
  <svg fill="none" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
    <ellipse cx="11.9243" cy="11.9243" rx="11.9243" ry="11.9243" />
    <path d="M18 6.67034L17.3297 6L12 11.3344L6.67034 6L6 6.67034L11.3344 12L6 17.3297L6.67034 18L12 12.6656L17.3297 18L18 17.3297L12.6656 12L18 6.67034Z" />
  </svg>
);

interface Props {
  product: TProduct;
  isThreeProducts?: boolean;
  isLayoutRightToLeft: boolean;
}

const Feedback: FC<Props> = ({ product, isThreeProducts, isLayoutRightToLeft }) => {
  const dispatch = useDispatch();
  const quizSessionId = useSelector(selectUserQuizId);
  const [isLiked, setIsLiked] = useState<null | boolean>(null);

  const { isDesignSetTasteAdvisorIQOS4Global } = useRetailerDesignSet();

  const handlePostFeedbackDispatched = useCallback(
    (value: boolean) => {
      setIsLiked(!!value);
      dispatch(
        actionPostProductFeedback({
          metadata: {
            feedback: {
              liked: value,
              product: product.identifier,
            },
          },
          session: quizSessionId,
        }),
      );
    },
    [isLiked],
  );

  return (
    <Col>
      <div
        className={`d-flex align-items-center span-wrapper ${
          !isThreeProducts ? 'justify-content-start justify-content-md-center' : 'justify-content-center'
        }`}
      >
        <span
          className={classnames('ta-jp-p4_2 ta-jp-mob-p4_2 color-white', {
            isThreeProducts,
            'text-center': isThreeProducts,
            'text-start text-md-center': !isThreeProducts,
          })}
        >
          <LocaleFragment message={localeTEREA.product.feedbackText} />
        </span>
      </div>
      <Row
        className={classnames('answer-wrapper mx-0', {
          'justify-content-center': isThreeProducts,
          'justify-content-start justify-content-md-center': !isThreeProducts,
          'rtl-orientation': isLayoutRightToLeft,
        })}
      >
        <button
          type="button"
          className={classnames({
            'ltr-orientation': !isLayoutRightToLeft,
            'ml-5': isDesignSetTasteAdvisorIQOS4Global && !isThreeProducts && isLayoutRightToLeft,
            'mr-5': isDesignSetTasteAdvisorIQOS4Global && !isThreeProducts && !isLayoutRightToLeft,
            'rtl-orientation': isLayoutRightToLeft,
          })}
          onClick={() => {
            if (isLiked !== true) {
              handlePostFeedbackDispatched(true);
            }
          }}
        >
          <div
            className={classnames({
              'btn-orientation': !isThreeProducts,
              selected: isLiked === true,
            })}
          >
            {threeProductsLikeImg}
            <span
              className={classnames('accept-icon', {
                'accept-icon-selected ta-jp-p4_2_3': isLiked === true,
                'ta-jp-p5-2': isLiked !== true,
              })}
            >
              <LocaleFragment message={localeTEREA.product.feedbackYes} />
            </span>
          </div>
        </button>
        <button
          type="button"
          className={classnames({
            'ml-md-4': isDesignSetTasteAdvisorIQOS4Global && !isThreeProducts && !isLayoutRightToLeft,
            'mr-md-4': isDesignSetTasteAdvisorIQOS4Global && !isThreeProducts && isLayoutRightToLeft,
          })}
          onClick={() => {
            if (isLiked !== false) {
              handlePostFeedbackDispatched(false);
            }
          }}
        >
          <div
            className={classnames({
              'btn-orientation': !isThreeProducts,
              selected: isLiked === false,
            })}
          >
            {threeProductsDislikeImg}
            <span
              className={classnames('accept-icon', {
                'accept-icon-selected ta-jp-p4_2_3': isLiked === false,
                'ta-jp-p5-2': isLiked !== false,
              })}
            >
              <LocaleFragment message={localeTEREA.product.feedbackNo} />
            </span>
          </div>
        </button>
      </Row>
    </Col>
  );
};

export { Feedback as FeedbackWithoutMemo };
export default React.memo(Feedback);
