import classNames from 'classnames';
import React, { FC } from 'react';
import { Col, Container, Row } from 'reactstrap';

import { localeLegacy } from '@lib/tools/locale/source/pmi/legacy';
import LocaleFragment from '@lib/tools/locale/views/LocaleFragment';

interface Props {
  isIQOS3DesignSet: boolean;
}

const TasteAdvisorGeoblock: FC<Props> = ({ isIQOS3DesignSet }) => (
  <Container className="tasteAdvisor-gate">
    <Row className="text-center">
      <Col className="popup position-absolute w-100vw h-100vh">
        <Row className="text-bold mx-auto justify-content-center">
          <Col
            className={classNames({
              'ta-pmi3-p1_2-bold text-white': isIQOS3DesignSet,
              'text-uppercase text_title_geoblock': !isIQOS3DesignSet,
            })}
          >
            <Row className="justify-content-center">
              <p>
                <LocaleFragment message={localeLegacy.geoBlock.title1} />
              </p>
            </Row>
            <Row className="justify-content-center">
              <p>
                <LocaleFragment message={localeLegacy.geoBlock.title2} />
              </p>
            </Row>
            <Row className="justify-content-center">
              <p>
                <LocaleFragment message={localeLegacy.geoBlock.title3} />
              </p>
            </Row>
            <Row className="justify-content-center">
              <p>
                <LocaleFragment message={localeLegacy.geoBlock.title4} />
              </p>
            </Row>
          </Col>
        </Row>
      </Col>
    </Row>
  </Container>
);

export { TasteAdvisorGeoblock as TasteAdvisorGeoblockWithoutMemo };
export default React.memo(TasteAdvisorGeoblock);
