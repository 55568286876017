import React from 'react';
import { Row } from 'reactstrap';

import { TProductCategory } from '@lib/core/products/types';
import { localeLegacy } from '@lib/tools/locale/source/pmi/legacy';
import LocaleFragment from '@lib/tools/locale/views/LocaleFragment';
import { TEREA } from '@lib/tools/shared/pmi/retailers/consts';

interface Props {
  getAssociatedCharacterDispatched: (p: TProductCategory) => void;
}

const DiscoverTereaBtn: React.FC<Props> = ({ getAssociatedCharacterDispatched }) => {
  const onDiscoverTerea = () => getAssociatedCharacterDispatched(TEREA);

  return (
    <Row className="align-items-center justify-content-center mx-0 pt-25px">
      <div
        className="dis-heets-btn d-flex justify-content-center align-items-center ta-jp-p2 ta-jp-mob-p2 my-1"
        role="button"
        tabIndex={0}
        onClick={onDiscoverTerea}
        onKeyDown={e => {
          if (e.key === 'Enter') {
            onDiscoverTerea();
          }
        }}
      >
        <LocaleFragment message={localeLegacy.heets.resultDiscoverTerea} />
      </div>
    </Row>
  );
};

export default DiscoverTereaBtn;
