import classNames from 'classnames';
import React, { FC, Fragment } from 'react';
import { useMediaQuery } from 'react-responsive';
import { Row } from 'reactstrap';

interface Props {
  answers: Record<
    string,
    {
      image?: string;
      text: string;
    }
  >[];
  handleSelectAnswerId: (asnwerId: string) => void;
}

const QuestionAnswers: FC<Props> = ({ answers, handleSelectAnswerId }) => {
  const isDesktopResolution = useMediaQuery({ minWidth: 1366 });

  const isDesktopResolutionQuiz = isDesktopResolution || (answers.length === 2 && !isDesktopResolution);

  return (
    <Fragment>
      <Row
        className={classNames('d-flex mx-auto taste-advisor-4-five-question-answers justify-content-between', {
          isTwoAnswersVersion: answers.length === 2,
        })}
      >
        {answers.map(answer => {
          const answerId = Object.keys(answer)[0];
          return (
            <div
              key={answerId}
              className={`${isDesktopResolutionQuiz ? '' : 'col-lg-12'} d-flex flex-column align-items-center`}
            >
              <img alt="prod" src={`${answer[answerId].image}`} />
              <button
                className="ta-jp-select-answer-btn ta-jp-mob-p3 ta-jp-p3-light mt-3"
                type="button"
                onClick={() => {
                  handleSelectAnswerId(answerId);
                }}
              >
                {answer[answerId].text}
              </button>
            </div>
          );
        })}
      </Row>
    </Fragment>
  );
};

export default React.memo(QuestionAnswers);
