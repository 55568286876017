import classNames from 'classnames';
import { FC } from 'react';
import { Row } from 'reactstrap';

import CustomMDReactComponent from '@lib/tools/locale/views/CustomMDReactComponent';

interface Props {
  text: string;
}

const QuestionText: FC<Props> = ({ text }) => (
  <Row className="mx-0 taste-advisor-3-one-question-text justify-content-center">
    <div className="text-center margin-bottom-77px">
      {text.split('\n').map((el, i) => (
        <div
          key={i}
          className={classNames({
            'text-margin-top': i,
          })}
        >
          <CustomMDReactComponent
            className={`text-margin ${i ? 'my-0 ta-pmi3-p1_2' : 'my-0 ta-jp-h3-1-bold ta-jp-mob-h4-bold'}`}
            text={el}
          />
        </div>
      ))}
    </div>
  </Row>
);

export default QuestionText;
