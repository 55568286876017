import cn from 'classnames';

import '@components/pmi/src/atoms/ProgressBar/_progressbar.scss';

interface Props {
  progressPercentage: number;
  isDark?: boolean;
  isVeev?: boolean;
  className?: string;
  currentPrimaryColor: string;
  isLayoutRightToLeft?: boolean;
}

const ProgressBar = ({
  isLayoutRightToLeft,
  currentPrimaryColor,
  isDark = false,
  progressPercentage,
  className,
}: Props) => {
  const progressSliderStyle = {
    backgroundColor: isDark ? '#34303d' : currentPrimaryColor,
    width: `${progressPercentage}%`,
  };

  return (
    <div className={cn('ta-v2-progress-bar-cover', className)}>
      <div
        style={progressSliderStyle}
        className={cn('ta-v2-progress-bar-slider', {
          'full-progress': progressPercentage >= 100,
          isLayoutRightToLeft,
        })}
      />
    </div>
  );
};

export default ProgressBar;
