import classNames from 'classnames';
import { FC, Fragment } from 'react';

import { TProductEssenceScore } from '@lib/core/products/types';
import { SENTIA } from '@lib/tools/shared/pmi/retailers/consts';
import { useAddons } from '@lib/tools/views/hooks';

type Props = {
  essences: TProductEssenceScore[];
  isLayoutRightToLeft?: boolean;
  type?: string | number;
  isThreeProducts?: boolean;
  essenceColor: string;
};

const Essences: FC<Props> = ({ isThreeProducts, type, isLayoutRightToLeft, essences, essenceColor }) => {
  const { isTereaHideEssencesAddon, isTereaEssenceOrderTopDownAddon } = useAddons();

  return (
    <div
      className={classNames('essences-wrapper', {
        'essences-wrapper-top-down-order': isTereaEssenceOrderTopDownAddon,
        'isThreeProduct  mx-0': isThreeProducts,
        'opacity-0': isTereaHideEssencesAddon && type !== SENTIA,
        'rtl-orientation': isLayoutRightToLeft && !isThreeProducts,
      })}
    >
      {essences.map(essence => (
        <Fragment key={essence.essence_id}>
          {parseInt(essence.score, 10) > 0 && (
            <div className="d-flex flex-column">
              <div className="ta-jp-p4_2_2 text-capitalize color-white">{essence.name}</div>
              <div className="essences-score-wrapper mt-1">
                {new Array(5).fill(null).map((_, index) => (
                  <div
                    key={index}
                    className={classNames('essences-score', {
                      empty: !(parseInt(essence.score, 10) >= index + 1),
                      'ltr-orientation': !isLayoutRightToLeft,
                      'rtl-orientation': isLayoutRightToLeft,
                    })}
                    style={{
                      backgroundColor: parseInt(essence.score, 10) >= index + 1 && essenceColor,
                    }}
                  />
                ))}
              </div>
            </div>
          )}
        </Fragment>
      ))}
    </div>
  );
};

export default Essences;
