import classNames from 'classnames';
import React, { FC, Fragment } from 'react';
import { Col, Row } from 'reactstrap';

import retailerPageBackgroundImage from '@app/pmi/src/assets/media/legacy/heets/heets_landing_generic_nbw.png';
import heetsProductDuoImage from '@app/pmi/src/assets/media/legacy/heets/iqos_heets_duo.png';
import heetsNbwOriginalsImage from '@app/pmi/src/assets/media/legacy/heets/iqos_nbw_originals.png';
import heetsProductImage from '@app/pmi/src/assets/media/legacy/heets/iqos_nbw_static_image_tobacco_holder.png';
import useHealthWarningFooter from '@app/pmi/src/hooks/useHealthWarningFooter';

import { useApp } from '@lib/core/service/hooks';
import { localeLegacy } from '@lib/tools/locale/source/pmi/legacy';
import LocaleFragment from '@lib/tools/locale/views/LocaleFragment';
import { useAddons } from '@lib/tools/views/hooks';

import HealthWarningFooterToIsrael from '@components/pmi/src/common/HealthWarningFooterToIsrael';
import LanguageSwitcherComponent from '@components/pmi/src/common/LanguageSwitcher';
import HealthWarningFooter from '@components/pmi/src/designSets/addOns/common/HealthWarningFooter3';
import IQOS3RetailerPageTextBlock from '@components/pmi/src/designSets/heets/common/IQOS3RetailerPageTextBlock';

interface TasteAdvisorRetailersPageTypes {
  handleStartTasteLocalTest: () => void;
  supportedLanguages: {
    code: string;
    language: string;
  }[];
  retailerTags: Record<string, string>;
}

const TasteAdvisor3OneRetailerPageComponent: FC<TasteAdvisorRetailersPageTypes> = ({
  handleStartTasteLocalTest,
  supportedLanguages,
  retailerTags,
}) => {
  const padding = useHealthWarningFooter(24);
  const {
    isLandingIqosDuoAddon,
    isHealthWarningFooterAddon,
    isHealthWarningBottomBlack15PerAddon,
    isHideIqosAddon,
    isEnableDisclaimerAddon,
    isHealthWarningFooterToIsraelAddon,
    isNbwIqosOriginalAddon,
  } = useAddons();
  const { isLayoutRightToLeft } = useApp();

  const isFooterVisible = isHealthWarningFooterAddon || isHealthWarningBottomBlack15PerAddon;

  return (
    <Col
      style={!isHealthWarningFooterToIsraelAddon ? { paddingBottom: `${padding}px` } : {}}
      className={`${
        isHealthWarningFooterToIsraelAddon ? 'minh-101vh padding-bottom-30vh' : 'minh-100vh'
      } mx-0 taste-advisor-3-one-retailer-page-wrapper px-0`}
    >
      <LanguageSwitcherComponent
        supportedLanguages={supportedLanguages}
        className={classNames('taste-advisor3-language-switcher-wrapper', {
          rtl: isLayoutRightToLeft,
        })}
      />
      <Row
        className={classNames('mobile-wrapper bg-color', {
          'flex-row-reverse': isLayoutRightToLeft,
        })}
      >
        <Col
          xs={6}
          className={classNames('bg-img col-lg-mob px-0', {
            'justify-content-end': isLayoutRightToLeft,
          })}
        >
          <img alt="retailer-page_backgroung-img" src={retailerTags?.image_cover || retailerPageBackgroundImage} />
        </Col>
        <Col className="text-content-wrapper col-lg-mob px-0" xs={6}>
          <Row
            className={classNames('margin-left-15px', {
              'flex-row-reverse': isLayoutRightToLeft,
            })}
          >
            <Col
              className="text-content-block"
              lg={isLandingIqosDuoAddon ? 6 : 8}
              md={isLandingIqosDuoAddon ? 8 : 9}
              xs={isLandingIqosDuoAddon ? 9 : 10}
            >
              <IQOS3RetailerPageTextBlock
                isDesktop
                handleStartTasteLocalTest={handleStartTasteLocalTest}
                isLayoutRightToLeft={isLayoutRightToLeft}
              />
            </Col>
            <Col className="img-block">
              {!isHideIqosAddon &&
                (isLandingIqosDuoAddon || isNbwIqosOriginalAddon ? (
                  <img
                    alt=""
                    className="heets-product-duo"
                    src={isNbwIqosOriginalAddon ? heetsNbwOriginalsImage : heetsProductDuoImage}
                  />
                ) : (
                  <img alt="" className="heets-product" src={heetsProductImage} />
                ))}
            </Col>
          </Row>
        </Col>
      </Row>
      <Row
        className={classNames('mobile-text-block text-block-display', {
          rtl: isLayoutRightToLeft,
        })}
      >
        <Col className="text-block-display">
          <IQOS3RetailerPageTextBlock
            handleStartTasteLocalTest={handleStartTasteLocalTest}
            isDesktop={false}
            isLayoutRightToLeft={isLayoutRightToLeft}
          />
        </Col>
      </Row>
      <div className="px-2 ta-pmi3-p5 text-center privacy">
        <Fragment>
          <LocaleFragment message={localeLegacy.heets.disclaimerPrivacyOne} />
          &nbsp;
          <a
            className="text-decoration-underline color-pmi3"
            href="https://www.pmiprivacy.com/consumer/"
            rel="noopener noreferrer"
            target="_blank"
          >
            <LocaleFragment message={localeLegacy.heets.disclaimerPrivacyHyperLink} />
          </a>
        </Fragment>

        {isEnableDisclaimerAddon && (
          <div>
            <div className="pt-2">
              <LocaleFragment message={localeLegacy.heets.disclaimerHealthWarning} />
            </div>
            <div className="pt-2">
              <LocaleFragment message={localeLegacy.heets.disclaimerSurveyInfo} />
            </div>
          </div>
        )}
      </div>
      <HealthWarningFooter isVisible={isFooterVisible} legalWarningFlag={false} />
      {isHealthWarningFooterToIsraelAddon && <HealthWarningFooterToIsrael />}
    </Col>
  );
};

export default React.memo(TasteAdvisor3OneRetailerPageComponent);
