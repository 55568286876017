export const PRODUCT_REQUEST_FOR_HOME_PAGE = 'homePage';
export const PRODUCT_REQUEST_FOR_RESULT_PAGE = 'resultPage';

// Essence total indicator amount
export const TOTAL_INDICATOR_AMOUNT = 5;

// Articles for Different Character Type
export const ARTICLE_CHARACTER_WINE = 'AR00003';
export const ARTICLE_CHARACTER_BEER = 'AR0009';
export const ARTICLE_CHARACTER_COFFEE = 'AR0013';

export const PREFERENCES_QUERY = 'preferences_slug';

export const START_FROM_FULL_CATALOG = 'startFromFullCatalog';

export const EXPERIENCES_MIN_PRICE_QUERY = 'price__gt';
export const EXPERIENCES_MAX_PRICE_QUERY = 'price__lt';

// Banners positions
export const BANNER_POSITION_HOME = 'home';
export const BANNER_POSITION_MULTI_PRODUCT_RESULT = 'multi_product_test_result';
export const BANNER_POSITION_EMAIL_REGISTRATION = 'email-registration';
export const BANNER_POSITION_RECIPE_PAGE = 'recipe';
export const BANNER_POSITION_PROFILE_TASTE_PAGE = 'profileTaste';
export const BANNER_POSITION_CATALOG = 'catalog';
export const BANNER_POSITION_CATALOG_WINE = 'catalog/wine';
export const BANNER_POSITION_CATALOG_COFFEE = 'catalog/coffee';
export const BANNER_POSITION_CATALOG_BEER = 'catalog/beer';
export const BANNER_POSITION_PRODUCT_DETAILS = 'product';
export const BANNER_POSITION_EXPERIENCES = 'experiences';
export const BANNER_POSITION_ARTICLES = 'articles';
export const BANNER_POSITION_TIP_AND_TRICKS = 'tip-and-tricks';
export const BANNER_POSITION_EDUTAINMENT = 'edutainment';
export const BANNER_ID_REDIRECT_EDUTAINMENT = 'NL0046';
export const ARTICLE_ID_URL_PARAM = 'articleID';

export const VH_EXP_TEST_ID = 'vh-exp-test-id';
export const VH_EXP_USER_SESSION = 'vh-exp-user-session';

export const B2C_HTTPS_V2_HEADERS = {
  Accept: 'application/json; version="2.0"',
  'Content-Type': 'application/json; version="2.0"',
  Version: '2.0',
};

// B2C SearchTypes
export const B2C_SEARCH_TYPE_PRODUCTS = 'products';
export const B2C_SEARCH_TYPE_ARTICLES = 'articles';
export const B2C_SEARCH_TYPE_EXPERIENCES = 'experiences';

// Order of Search Result Tabs
export const B2C_SEARCH_TABS_PRIORITIES = {
  articles: 3,
  experiences: 2,
  products: 1,
};

// Pages
export const HOME_PAGE = 'home';
export const CATALOG_PAGE = 'catalog';
export const EDUTAINMENT_PAGE = 'edutainment';
export const EXPERIENCES_CATALOG_PAGE = 'experiences';
export const PROFILE_PAGE = 'profile';
export const TASTE_ID_PAGE = 'taste-id';
export const PROFILE_PAGE_TASTE_TAB = 'taste';

// B2C File Check Constants
export const MAXIMUM_IMAGE_CONVERT_SIZE = 4000000;
export const MAXIMUM_IMAGE_HEIGHT = 400;
export const MAXIMUM_IMAGE_WIDTH = 400;
export const MAXIMUM_IMAGE_QUALITY = 1;
export const MAXIMUM_USER_UPLOAD_IMAGE_SIZE = 5;
export const MAXIMUM_UPLOAD_IMAGE_SIZE_IN_KB = 500;

// B2C Image Files Errors
export const IMAGE_ERRORS = {
  415: 'Unsupported Media',
  500: 'Could not Upload',
  513: 'Payload Too Large',
};
// Experience page
export const EXPERIENCE_TYPE_EXPERIENCES = 'EXT0002';
export const FILTER_VALUE_EXPERIENCES_PRICE_MIN = 0;
export const FILTER_VALUE_EXPERIENCES_PRICE_MAX = 1000;

// Tags
export const B2C_EXPOSURE_TAGS = 'tags';

export const B2C_DESKTOP_RESOLUTION_MIN_VALUE = 500;

// Skeleton Blocks Constants
export const NUM_SEARCH_SKELETONS = 6;

// Queries
export const PRODUCT_CATEGORY_QUERY_KEY = 'product_type';

// Typeform ROOT
export const TYPEFORM_DOMAIN_ROOT = 'https://tastecharacter.typeform.com/to';

// MultiProductResult Email Banner
export const BANNER_STATIC_MULTI_PRODUCT_RESULT = {
  identifier: 'NL0045',
  linkIndentifier: 'R00003',
  positionIdentifier: 'R00002',
};

export const BANNER_ICONS = {
  bookmark: 'bookmark',
  box: 'box',
  cart: 'cart',
  compass: 'compass',
  doc: 'doc',
  filter: 'filter',
  'finger-print': 'finger-print',
  'fork-knife': 'fork-knife',
  list: 'list',
  promo: 'promo',
  rocket: 'rocket',
  save: 'save',
  star: 'star',
} as const;
