import classNames from 'classnames';
import React, { FC, useEffect, useState } from 'react';
import { Col, Container, Row } from 'reactstrap';

import CharacterDescriptionContainer from '@app/pmi/src/containers/ResultDescriptionContainer';
import useHealthWarningFooter from '@app/pmi/src/hooks/useHealthWarningFooter';

import { TCharacter } from '@lib/core/characters/types';
import { TProductCategory, TProductInstance } from '@lib/core/products/types';
import { localeLegacy } from '@lib/tools/locale/source/pmi/legacy';
import LocaleFragment from '@lib/tools/locale/views/LocaleFragment';
import { TEREA } from '@lib/tools/shared/pmi/retailers/consts';
import { useAddons, useRetailerDesignSet } from '@lib/tools/views/hooks';

import HealthWarningFooterToIsrael from '@components/pmi/src/common/HealthWarningFooterToIsrael';
import Spinner from '@components/pmi/src/common/Spinner';
import HealthWarningFooter from '@components/pmi/src/designSets/addOns/common/HealthWarningFooter3';
import DiscoverTereaBtn from '@components/pmi/src/designSets/heets/common/DiscoverTereaBtn';
import CharacterProducts from '@components/pmi/src/designSets/heets/main/one/CharacterProducts';
import ProductPortfolio from '@components/pmi/src/designSets/heets/main/one/ProductPortfolio';

interface Props {
  character: TCharacter;
  gprls: TProductInstance[];
  productCategories: string[];
  getAssociatedCharacterDispatched: (p: TProductCategory) => void;
}

const TasteAdvisorResultTestComponent: FC<Props> = ({
  character,
  gprls,
  productCategories,
  getAssociatedCharacterDispatched,
}) => {
  const { isDesignSetTasteAdvisorIQOS3Three } = useRetailerDesignSet();

  const hideTitle = !!isDesignSetTasteAdvisorIQOS3Three;

  const {
    isHideHeetsToTereaAddon,
    isExpandablePortfolioAddon,
    isPortfolioHiddenAddon,
    isHealthWarningFooterToIsraelAddon,
  } = useAddons();

  const [isPortfolioOpen, setIsPortfolioOpen] = useState(!isExpandablePortfolioAddon);
  const toggleShowPortfolio = () => {
    if (isExpandablePortfolioAddon) {
      setIsPortfolioOpen(!isPortfolioOpen);
    }
  };

  const paddingBottom = useHealthWarningFooter(24, true);

  const [showLoaderAnimation, setShowLoaderAnimation] = useState(true);
  useEffect(() => {
    const timer = setTimeout(() => {
      setShowLoaderAnimation(false);
    }, 1000);
    return () => {
      clearTimeout(timer);
    };
  }, []);

  return (
    <>
      <Row
        style={!isHealthWarningFooterToIsraelAddon ? { paddingBottom } : {}}
        className={classNames('mx-0 taste_advisor_3_one_result_test', {
          'full-height': !isPortfolioOpen,
          invisible: showLoaderAnimation,
        })}
      >
        <Col className="px-0 d-flex flex-column" xs={12}>
          <CharacterDescriptionContainer character={character} />
          <CharacterProducts hideTitle={hideTitle} products={gprls} />
          {!isPortfolioHiddenAddon && (
            <Container
              fluid
              className={classNames('taste-advisor-3-product-portfolio px-0 mt-auto', {
                'padding-bottom-30vh': isHealthWarningFooterToIsraelAddon,
              })}
            >
              <Row className="portfolio-wrapper mx-auto justify-content-center">
                <Col
                  className={classNames('d-block', 'ta-pmi3-p1_2-bold', 'text-center', {
                    padding: isPortfolioOpen,
                    pointer: isExpandablePortfolioAddon,
                  })}
                  onClick={toggleShowPortfolio}
                  onKeyDown={toggleShowPortfolio}
                >
                  <LocaleFragment message={localeLegacy.heets.portfolioSeeAllFlavors} />

                  {isExpandablePortfolioAddon && (
                    <Row>
                      <img
                        alt=""
                        className="mx-auto pointer width-15px border-none mt-3 maxh-8px"
                        src={require(
                          `@app/pmi/src/assets/media/legacy/icons/feedback_${isPortfolioOpen ? 'collapse' : 'expand'}.png`,
                        )}
                      />
                    </Row>
                  )}
                </Col>

                {isPortfolioOpen && <ProductPortfolio gprls={gprls} />}
                {productCategories.filter(category => category === TEREA).length && !isHideHeetsToTereaAddon ? (
                  <DiscoverTereaBtn getAssociatedCharacterDispatched={getAssociatedCharacterDispatched} />
                ) : null}
              </Row>
              {isHealthWarningFooterToIsraelAddon ? (
                <HealthWarningFooterToIsrael />
              ) : (
                <HealthWarningFooter isVisible legalWarningFlag={false} />
              )}
            </Container>
          )}
        </Col>
      </Row>
      {showLoaderAnimation && <Spinner />}
    </>
  );
};

export { TasteAdvisorResultTestComponent as TasteAdvisorResultTestComponentWithoutMemo };
export default React.memo(TasteAdvisorResultTestComponent);
