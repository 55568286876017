import { FC } from 'react';
import { Container } from 'reactstrap';

const Spinner: FC = () => (
  <Container fluid className="h-100">
    <div>
      <div className="taste-advisor-spinner-small window-center">
        <div className="loader" />
      </div>
    </div>
  </Container>
);

export default Spinner;
