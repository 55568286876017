import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { apiUrlGetQuiz } from '@lib/core/quizzes/slices/urls';
import { TQuizDetail, TQuizPath } from '@lib/core/quizzes/types';
import { createTypedAsyncThunk } from '@lib/core/service/createTypedAsyncThunk';
import request from '@lib/core/service/requests/request';
import { IToolkitRejectedAction } from '@lib/core/service/types/common';

export interface IQuizSlice {
  isLoading: boolean;
  data: TQuizDetail;
  error: string;
}
const initialState: IQuizSlice = {
  data: undefined,
  error: '',
  isLoading: false,
};

export const actionGetQuiz = createTypedAsyncThunk('quizzes/quiz/get', async ({ quizSlug }: { quizSlug: string }) => {
  return await request(apiUrlGetQuiz(quizSlug)).then(async (quizDetail: TQuizDetail) => {
    // Obtain pregenerated gzipped file from S3, if supplied.
    if (quizDetail.quiz_test_data_id) {
      await request(quizDetail.quiz_path, { ignoreProfileHeaders: true }).then((response: TQuizPath) => {
        quizDetail.quiz_path = response;
      });
    }

    const preparedQuizPath = { ...quizDetail.quiz_path };
    const questionIds = Object.keys(preparedQuizPath);

    questionIds.forEach(quizPathKey => {
      const answerIds = Object.keys(preparedQuizPath[quizPathKey].answers);
      answerIds.forEach(answerId => {
        const questionPool = preparedQuizPath[quizPathKey].answers[answerId].question_pool;

        // Randomly reduce one of the questions in the question_pool.
        if (questionPool?.length) {
          preparedQuizPath[quizPathKey].answers[answerId].question_pool = [
            questionPool[Math.floor(Math.random() * questionPool.length)],
          ];
        }
      });
    });

    return {
      ...quizDetail,
      quiz_path: preparedQuizPath,
    };
  });
});

export const quizSlice = createSlice({
  extraReducers: builder => {
    builder.addCase(actionGetQuiz.pending, state => {
      state.error = '';
      state.isLoading = true;
    });
    builder.addCase(actionGetQuiz.fulfilled, (state, action: PayloadAction<TQuizDetail>) => {
      const { payload } = action;
      state.isLoading = false;
      state.data = payload;
    });
    builder.addCase(actionGetQuiz.rejected, (state, action: IToolkitRejectedAction) => {
      const { payload: { errors: { detail } = {} } = {} } = action;
      state.isLoading = false;
      state.error = detail;
    });
  },
  initialState,
  name: 'quiz',
  reducers: {},
});

export default quizSlice.reducer;
