import React, { FC } from 'react';
import { useDispatch } from 'react-redux';
import { Col, Row } from 'reactstrap';

import { TProductInstance } from '@lib/core/products/types';
import { localeLegacy } from '@lib/tools/locale/source/pmi/legacy';
import LocaleFragment from '@lib/tools/locale/views/LocaleFragment';

import ProductCard from '@components/pmi/src/designSets/blends/main/ProductCard';
import { navigateHome } from '@components/pmi/src/utils';

interface Props {
  products: TProductInstance[];
}

const CharacterProducts: FC<Props> = ({ products: gprls }) => {
  const dispatch = useDispatch();
  if (gprls) {
    return (
      <Row className="mx-auto taste-advisor-3-one-character-products-wrapper">
        <Col>
          <Row className="justify-content-center align-items-stretch text-center">
            {gprls.map((gprl, i) => {
              return i === 0 || i === 1 ? (
                <ProductCard key={gprl.identifier} genericProductRetailerLocation={gprls[i]} />
              ) : null;
            })}
          </Row>
          {/* Start over */}
          <Col className="px-0 d-flex flex-column align-items-center" xs={12}>
            <button className="start-over-btn ta-pmi3-p2_2" type="button" onClick={() => navigateHome(dispatch)}>
              <LocaleFragment message={localeLegacy.blends.resultStartOver} />
            </button>
          </Col>
        </Col>
      </Row>
    );
  }
  return <div />;
};

export default React.memo(CharacterProducts);
