export const themeDevTools = {
  color: {
    black: '#121212',
    blue: '#0068ff',
    green: '#4af6c3',
    orange: '#fb8b1e',
    red: '#ff433d',
    white: '#eee',
  },
  font: {
    large: '18px',
    medium: '14px',
    small: '12px',
    tiny: '10px',
  },
  spacing: {
    large: '15px',
    medium: '10px',
    small: '5px',
  },
};
