import cn from 'classnames';
import { FC } from 'react';
import { CircularProgressbar } from 'react-circular-progressbar';
import { useMediaQuery } from 'react-responsive';

type Props = {
  score: number;
  name: string;
  color: string;
  isItInPortfolioCardHeets?: boolean;
  isItInPortfolioCardTerea?: boolean;
  isItInResultPage?: boolean;
  isItInResultPageEcom?: boolean;
  className?: string;
};

const EUessenceComponent: FC<Props> = ({
  score,
  name,
  color,
  isItInPortfolioCardHeets = false,
  isItInPortfolioCardTerea = false,
  isItInResultPage = false,
  isItInResultPageEcom = false,
  className,
}) => {
  const isMobileResolution = useMediaQuery({ maxWidth: 767 });
  const progressPercent = score * 10;
  return (
    <div
      className={cn('ta-eu-essence-progressbar', className, {
        'portfolio-progressbar-heets': isItInPortfolioCardHeets,
        'portfolio-progressbar-terea': isItInPortfolioCardTerea,
      })}
    >
      <span
        className={cn('ta-iqos4-p5 text-brand-slate-color essence-name-margin', {
          'color-white': isItInResultPage || isItInResultPageEcom,
          'ta-ecom-p5': isItInResultPageEcom,
          'ta-iqos4-p7': isItInPortfolioCardHeets,
        })}
      >
        {name}
      </span>
      <div className="progressbar-wrapper">
        <span
          className={cn('ta-iqos4-p1-bold text-brand-slate-color essence-name-margin', {
            'color-white': isItInResultPage || isItInResultPageEcom,
            'ta-iqos4-p2-bold': isItInPortfolioCardHeets,
          })}
        >
          {score}
        </span>
        <CircularProgressbar
          circleRatio={0.85}
          className="essence-progressbar"
          strokeWidth={isMobileResolution && !isItInResultPageEcom ? 7 : 10}
          value={progressPercent}
          styles={{
            path: {
              stroke: color,
              strokeLinecap: 'round',
              transform: 'rotate(-153deg)',
              transformOrigin: 'center center',
            },
            trail: {
              stroke: '#D9D1C8',
              strokeLinecap: 'round',
              transform: 'rotate(-153deg)',
              transformOrigin: 'center center',
            },
          }}
        />
      </div>
    </div>
  );
};

export default EUessenceComponent;
