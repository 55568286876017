import { createSelector } from 'reselect';

import { TProductCategory } from '@lib/core/products/types';
import { selectRetailerCustomStyle, selectRetailerSlug } from '@lib/core/retailers/selectors/retailer';
import { IRetailerLocationState } from '@lib/core/retailers/slices/retailerLocation';
import { TRetailerLocationStoreType } from '@lib/core/retailers/types';
import {
  TAddons,
  TAddonsTasteAdvisor,
  TAddonsVinhood,
  TCommonDesignSets,
  TDesignSetName,
  TDesignSets,
  TTasteAdvisorDesignSets,
} from '@lib/core/retailers/types/addons';
import { IRetailerLocation } from '@lib/core/retailers/types/retailerLocation';
import { RetailerLocationStoreType } from '@lib/core/retailers/utils/consts';
import { DESIGN_SET_VINHOOD_APP, DESIGN_SET_VINHOOD_EXPERIENCE, isApplicationPmi } from '@lib/core/service/consts';
import { selectServiceProduct } from '@lib/core/service/selectors';
import { RootState } from '@lib/core/service/types/appStateType';
import {
  selectKioskUserRetailerLocationId,
  selectUserRetailerLocationSlug,
  selectUserRoleIsKiosk,
} from '@lib/core/users/selectors/user';
import { VH_RETAILER_URL_SLUG_APP } from '@lib/tools/shared/helpers/consts';
import { ITheme } from '@lib/tools/shared/helpers/interfaces';
import {
  DESIGN_SET_TASTE_ADVISOR_BLENDS_ONE,
  DESIGN_SET_TASTE_ADVISOR_CRAFTED,
  DESIGN_SET_TASTE_ADVISOR_IQOS3_ONE,
  DESIGN_SET_TASTE_ADVISOR_IQOS3_THREE,
  DESIGN_SET_TASTE_ADVISOR_IQOS4_GLOBAL,
  DESIGN_SET_TASTE_ADVISOR_IQOS4_ONE,
  DESIGN_SET_TASTE_ADVISOR_V2,
} from '@lib/tools/shared/pmi/retailers/consts';

/**
 * @returns the whole retailerLocation state
 */
const selectRetailerLocationState = (state): IRetailerLocationState => state.retailerLocation || {};

/**
 * @returns status of loading retailerLocation state
 */
export const selectIsRetailerLocationLoading: (state) => boolean = createSelector(
  [selectRetailerLocationState],
  retailerLocation => retailerLocation?.isLoading || false,
);

/**
 * @returns {IRetailerLocation} the current retailer location object
 */
export const selectRetailerLocation: (state) => IRetailerLocation | Record<string, never> = createSelector(
  [selectRetailerLocationState],
  retailerLocation => retailerLocation?.data || {},
);

/**
 * @returns {TProductCategory[]} the retailer location's supported product categories
 */
export const selectProductCategories: (state) => TProductCategory[] = createSelector(
  [selectRetailerLocation],
  retailerLocationData => retailerLocationData?.product_categories || [],
);

/**
 * @returns {TDesignSetName} the design set for the required product category.
 */
export const selectRetailerDesignSet = (state): TDesignSetName => {
  const productCategory = selectServiceProduct(state);
  const retailerSlug = selectRetailerSlug(state);
  const retailerLocation = selectRetailerLocation(state);
  if (retailerSlug === VH_RETAILER_URL_SLUG_APP) {
    return DESIGN_SET_VINHOOD_APP;
  }
  return (retailerLocation?.design_sets?.[productCategory] || '') as TDesignSetName;
};

/**
 * @returns {TDesignSetName} all designsets for the retailer8.
 */

export const selectRetailerDesignSets: (state) => Record<TProductCategory, TDesignSetName> = createSelector(
  [selectRetailerLocation],
  retailerLocationData => retailerLocationData?.design_sets || {},
);

/**
 * @returns {string} the retailer location's slug
 */
export const selectRetailerLocationSlug = (state: RootState): string => {
  // ! Revisit issue with tests
  const isUserRoleKiosk = selectUserRoleIsKiosk(state);
  const userRetailerLocationSlug = selectUserRetailerLocationSlug(state);
  const retailerLocationSlug = state.retailerLocation?.data?.slug || '';

  return isUserRoleKiosk ? userRetailerLocationSlug : retailerLocationSlug;
};

/**
 * @returns {string} the retailer location's description
 */
export const selectRetailerLocationDescription: (state) => string = createSelector(
  [selectRetailerLocation],
  retailerLocationData => retailerLocationData?.description || '',
);

/**
 * @returns {string[]} the addons applied on the retailer location.
 */

export const selectRetailerLocationAddOns: (state) => string[] = createSelector(
  [selectRetailerLocation],
  retailerLocationData => retailerLocationData?.addons || [],
);

/**
 * @returns {string[]} the physical kiosk attached to mobile kiosks.
 */
export const selectAssociatedRetailerLocation: (state) => string = createSelector(
  [selectRetailerLocation],
  retailerLocationData => retailerLocationData?.associated_kiosk_retailer_location || '',
);

/**
 * @returns {string} retailer comms text.
 */
export const selectRetailerCommunicationSetText: (state) => string = createSelector(
  [selectRetailerLocation],
  retailerLocationData => retailerLocationData?.retailer_communication?.text || '',
);

/**
 * @returns {string} the retailer location's identifier.
 */
// export const selectRetailerLocationId = (state: RootState, defaultValue = ''): string =>
//   selectUserRoleIsKiosk(state)
//     ? selectKioskUserRetailerLocationId(state, defaultValue)
//     : state?.retailerLocation?.data?.identifier || defaultValue;
export const selectRetailerLocationId = (state): string => {
  // ! Revisit issue with tests
  const userRoleIsKiosk = selectUserRoleIsKiosk(state);
  const kioskUserRetailerLocationId = selectKioskUserRetailerLocationId(state);
  const retailerLocation = selectRetailerLocation(state);
  return userRoleIsKiosk ? kioskUserRetailerLocationId || '' : retailerLocation?.identifier || '';
};
/**
 * @returns {string} the retailer location's catalog type.
 */
export const selectRetailerCatalogType: (state) => string = createSelector(
  [selectRetailerLocation],
  retailerLocationData => retailerLocationData?.catalog_type || '',
);

/**
 * @returns {string} the retailer location's physical address.
 */
export const selectRetailerPhysicalAddress: (state) => string = createSelector(
  [selectRetailerLocation],
  retailerLocationData => retailerLocationData?.physical_address || '',
);
/**
 * @returns {TRetailerLocationStoreType} the retailer location's store type
 */
export const selectRetailerLocationStoreType: (state) => TRetailerLocationStoreType = createSelector(
  [selectRetailerLocation],
  retailerLocationData => retailerLocationData?.store_type || RetailerLocationStoreType.main,
);

export const selectIsRetailerLocationStoreTypeEcommerce: (state) => boolean = createSelector(
  [selectRetailerLocation],
  retailerLocationData => retailerLocationData?.store_type === RetailerLocationStoreType.ecommerce || false,
);

/**
 * @returns {IRetailerLocation[]} list of available locations for current retailer
 */
export const selectRetailerLocationsList: (state) => IRetailerLocation[] = createSelector(
  [selectRetailerLocationState],
  retailerLocation => retailerLocation?.list || [],
);
/**
 * @returns {product_categories[]} list of available product types for current retailer location
 */
export const selectRetailerLocationProductCategories: (state) => TProductCategory[] = createSelector(
  [selectRetailerLocation],
  retailerLocationData => retailerLocationData?.product_categories || [],
);

export const selectRetailerLocationCustomStyle: (state) => ITheme = createSelector(
  [selectRetailerLocationId, selectRetailerCustomStyle],
  (retailerLocationId, retailerCustomStyle) => {
    if (!!retailerLocationId && !!retailerCustomStyle) {
      return { ...retailerCustomStyle?.[retailerLocationId] };
    }
    return {};
  },
);

/**
 * @todo Remove consts
 * @returns {TProductCategory[]} the retailer location's design set
 */

export const selectAppliedDesignSet: (state) => TDesignSets = createSelector(
  [selectRetailerDesignSet],
  retailerDesignSetSelect => {
    const retailerDesignSet: TDesignSetName = retailerDesignSetSelect;
    const tasteAdvisorDesignSets: Record<TTasteAdvisorDesignSets, boolean> = {
      isDesignSetTasteAdvisorBlendsOne: retailerDesignSet === DESIGN_SET_TASTE_ADVISOR_BLENDS_ONE,
      isDesignSetTasteAdvisorCrafted: retailerDesignSet === DESIGN_SET_TASTE_ADVISOR_CRAFTED,
      isDesignSetTasteAdvisorIQOS3One: retailerDesignSet === DESIGN_SET_TASTE_ADVISOR_IQOS3_ONE,
      isDesignSetTasteAdvisorIQOS3Three: retailerDesignSet === DESIGN_SET_TASTE_ADVISOR_IQOS3_THREE,
      isDesignSetTasteAdvisorIQOS4Global: retailerDesignSet === DESIGN_SET_TASTE_ADVISOR_IQOS4_GLOBAL,
      isDesignSetTasteAdvisorIQOS4One: retailerDesignSet === DESIGN_SET_TASTE_ADVISOR_IQOS4_ONE,
      isDesignSetTasteAdvisorV2: retailerDesignSet === DESIGN_SET_TASTE_ADVISOR_V2,
    };

    const commonDesignSets: Record<TCommonDesignSets, boolean> = {
      isDesignSetTasteAdvisor: Object.values(tasteAdvisorDesignSets).some(Boolean),
      isDesignSetTasteAdvisorIQOS3: Object.values(tasteAdvisorDesignSets).some(Boolean),
      isDesignSetVinhoodApp: retailerDesignSet === DESIGN_SET_VINHOOD_APP,
      isDesignSetVinhoodExperience: retailerDesignSet === DESIGN_SET_VINHOOD_EXPERIENCE,
    };

    return {
      retailerDesignSet,
      ...tasteAdvisorDesignSets,
      ...commonDesignSets,
    } as const;
  },
);

/**
 * @returns {TProductCategory[]} the retailer location's addons
 */

export const selectAppliedAddOns: (state) => TAddons = createSelector([selectRetailerLocationAddOns], addons => {
  if (isApplicationPmi) {
    return {
      isAddToCartAddon: addons.includes('add-to-cart'),
      isAgeGateAddon: addons.includes('age-gate'),
      isAromaNoteSecondaryAddon: addons.includes('aroma-note-secondary'),
      isAromaNotesDisabledAddon: addons.includes('hide-aroma-notes'),
      isCustomInfoPageAddon: addons.includes('custom-info-page'),
      isDisableRetailerAddon: addons.includes('disable-retailer'),
      isDisplayResultPageDescriptionAddon: addons.includes('result-description'),
      isEnableBuyEcommerceAddon: addons.includes('enable-buy-ecommerce'),
      isEnableDisclaimerAddon: addons.includes('landing-disclaimer'),
      isEnableLeviaBannerAddon: addons.includes('enable-levia-banner'),
      isEnableProductBaseLoopAddon: addons.includes('enable-product-base-loop'),
      isEnableTereaBannerAddon: addons.includes('enable-terea-banner'),
      isEnableVeevBannerAddon: addons.includes('enable-veev-banner'),
      isEssenceAromaDisabledAddon: addons.includes('hide-essence-aroma'),
      isEssenceBodyDisabledAddon: addons.includes('hide-essence-body'),
      isEssenceCoolingDisabledAddon: addons.includes('hide-essence-cooling'),
      isExpandablePortfolioAddon: addons.includes('expandable-portfolio'),
      isFeedbackDisabledAddon: addons.includes('hide-feedback'),
      isGoogleAnalyticsDisabledAddon: addons.includes('disable-google-analytics'),
      isHealthWarningBottomBlack15PerAddon: addons.includes('health-warning-bottom-black-15-per'),
      isHealthWarningFooterAddon: addons.includes('health-warning-footer'),
      isHealthWarningFooterToIsraelAddon: addons.includes('health-warning-footer-to-israel'),
      isHealthWarningLandingBottom10PerAddon: addons.includes('health-warning-landing-bottom-10-per'),
      isHealthWarningMultilingualAddon: addons.includes('health-warning-multilingual'),
      isHealthWarningPrepageAddon: addons.includes('health-warning-prepage'),
      isHealthWarningQuizAddon: addons.includes('health-warning-quiz'),
      isHideHeetsToTereaAddon: addons.includes('hide-heets-to-terea'),
      isHideIqosAddon: addons.includes('hide-iqos'),
      isIgnoreProductSeparationAddon: addons.includes('ignore-product-separation'),
      isKeyVisualAddon: addons.includes('key-visual'),
      isKeyVisualSentiaAddon: addons.includes('key-visual-sentia'),
      isLandingIqosDuoAddon: addons.includes('landing-iqos-duo'),
      isLandingPageCoachesAddon: addons.includes('landing-coaches'),
      isLandingPageLongDescriptionAddon: addons.includes('landing-long-description'),
      isLowercasePortfolioAddon: addons.includes('lowercase-portfolio'),
      isMentholDisclaimerAddon: addons.includes('menthol-disclaimer'),
      isNbwIqosOriginalAddon: addons.includes('nbw-iqos-original'),
      isNoHeetsAddon: addons.includes('no-heets'),
      isNoMarketingBanner: addons.includes('no-marketing-banner'),
      isOpenFeedbackAddon: addons.includes('open-feedback'),
      isPortfolioHiddenAddon: addons.includes('hide-portfolio'),
      isProductLongDescriptionHiddenAddon: addons.includes('hide-product-long-description'),
      isProductShortDescriptionHiddenAddon: addons.includes('hide-product-short-description'),
      isRestrictedBundlesPageAddon: addons.includes('restricted-bundles-page'),
      isRestrictedProductCardsAddon: addons.includes('restricted-product-cards'),
      isRestrictedQuizPageAddon: addons.includes('restricted-quiz-page'),
      isRestrictedResultDescriptionAddon: addons.includes('restricted-result-description'),
      isRestrictedResultPageAddon: addons.includes('restricted-result-page'),
      isResultLongDisclaimerAddon: addons.includes('result-long-disclaimer'),
      isTereaEssenceOrderTopDownAddon: addons.includes('terea-essence-order-top-down'),
      isTereaEssenceSortAddon: addons.includes('terea-essence-sort'),
      isTereaHideEssencesAddon: addons.includes('terea-hide-essences'),
      isTobaccoExperienceHiddenAddon: addons.includes('hide-tobacco-experience'),
      isWyngEcommerceAddon: addons.includes('wyng-ecommerce'),
    } as TAddonsTasteAdvisor;
  }
  return {
    isApplyCustomStyle: addons.includes('apply-custom-styles'),
    isDisableHomeAddon: addons.includes('disable-home'),
    isDisableQuizNoGluten: addons.includes('disable-quiz-no-gluten'),
    isDisableRecipeCTAHomeAddon: addons.includes('disable-recipe-cta-home'),
    isEnableKioskFidelityPromotionAddon: addons.includes('enable-kiosk-fidelity-promotion'),
    isEnableKioskFidelityScanAddon: addons.includes('enable-kiosk-fidelity-scan'),
    isEnableLocationMapAddon: addons.includes('enable-location-map'),
    isEnableTastePathAddon: addons.includes('enable-taste-path'),
    isEnableVusionAddon: addons.includes('enable-vusion'),
    isExternalCharacterPage: addons.includes('external-character-page'),
    isFeatureProductsAddon: addons.includes('feature-products'),
    isHideFeedbackAddon: addons.includes('hide-feedback'),
    isHideSkipAndGoToProductsAddon: addons.includes('hide-skip-and-go-to-products'),
    isNoEmailAddon: addons.includes('no-email'),
    isPriceRangeAddon: addons.includes('price-range'),
    isShowProductLocationAddon: addons.includes('show-product-location'),
    isTasteIdResultPageAddon: addons.includes('taste-id-result-page'),
  } as TAddonsVinhood;
});
