import { ERROR_UNRECOGNIZED_POST_MESSAGE_EVENT } from '@lib/core/service/consts';
import { selectServiceProduct } from '@lib/core/service/selectors';
import { setAppLocale, setProductCategory } from '@lib/core/service/slices';
import { store } from '@lib/core/service/store';
import { prependBasename } from '@lib/core/service/utils';
import { history } from '@lib/core/service/utils/Navigator';
import {
  POST_MESSAGE_ADD_TO_CART,
  POST_MESSAGE_BEST_MATCH,
  POST_MESSAGE_DISPLAY_ROUTE,
  POST_MESSAGE_DOM_HEIGHT,
  POST_MESSAGE_HANDSHAKE,
  POST_MESSAGE_LOCALE,
  POST_MESSAGE_PRODUCT_CATEGORY,
  POST_MESSAGE_PRODUCT_SETS,
  POST_MESSAGE_QUIZ_COMPLETED,
  POST_MESSAGE_REQUEST_DOM_HEIGHT,
  POST_MESSAGE_REQUEST_HOST_HANDSHAKE,
  POST_MESSAGE_SEND_TA_GA_EVENT,
} from '@lib/tools/comms/consts';
import {
  actionSetCommsHandshake,
  actionSetCommsHostCustomId,
  actionSetCommsHostRedirectUrl,
  actionSetCommsHostRedirectUrlLevia,
} from '@lib/tools/comms/slices';

/**
 * Returns a boolean true if the app is mounted inside an iframe
 */
export const isAppInIframe = window.self !== window.top;

/**
 * Send payloads back to hosting libraries for PMI.
 */
export const sendPostMessage = (payload?: Record<string, any>) => {
  if (isAppInIframe) {
    if (
      payload[POST_MESSAGE_ADD_TO_CART] ||
      payload[POST_MESSAGE_QUIZ_COMPLETED] ||
      payload[POST_MESSAGE_PRODUCT_SETS] ||
      payload[POST_MESSAGE_BEST_MATCH] ||
      payload[POST_MESSAGE_SEND_TA_GA_EVENT] ||
      payload[POST_MESSAGE_DOM_HEIGHT] ||
      payload[POST_MESSAGE_REQUEST_HOST_HANDSHAKE]
    ) {
      window.parent.postMessage(payload, '*');
    }
  }
};

export const initComms = dispatch => {
  /**
   * Establish a handshake with hosting libraries. This prevents direct injection of our apps into iframes.
   * @todo implement host handshake on vinhood library
   */

  window.addEventListener('message', event => {
    if (event.data?.source?.includes('@devtools')) return;

    if (event.origin) {
      switch (event.data.type) {
        case POST_MESSAGE_HANDSHAKE:
          dispatch(actionSetCommsHandshake(true));

          if (event.data?.payload?.catalogRedirectUrl) {
            dispatch(actionSetCommsHostRedirectUrl(event.data?.payload.catalogRedirectUrl));
          }

          if (event.data?.payload?.catalogRedirectUrlLevia) {
            dispatch(actionSetCommsHostRedirectUrlLevia(event.data?.payload.catalogRedirectUrlLevia));
          }

          if (event.data?.payload?.customId) {
            dispatch(actionSetCommsHostCustomId(event.data?.payload.customId));
          }
          break;
        case POST_MESSAGE_LOCALE:
          dispatch(setAppLocale(event.data.payload));
          break;
        case POST_MESSAGE_PRODUCT_CATEGORY:
          const requestedProductCategory = event.data.payload;
          const currentProductCategory = selectServiceProduct(store.getState());

          if (currentProductCategory !== requestedProductCategory) {
            dispatch(setProductCategory(event.data.payload));
          }
          break;
        case POST_MESSAGE_REQUEST_DOM_HEIGHT:
          const frameRoot = document.getElementById('root');
          if (frameRoot) {
            // * Account for sticky footer height on mobile
            const footerElement = document.getElementsByClassName('ta-v2-quiz-footer-wrapper')?.[0];

            if (footerElement) {
              footerElement.setAttribute('style', 'position: relative;');
              const footerElementPos = footerElement.getBoundingClientRect();
              // Add an offset for some extra height.
              sendPostMessage({
                [POST_MESSAGE_DOM_HEIGHT]: footerElementPos.y + footerElementPos.height,
              });
            } else {
              // Add an offset for some extra height.
              sendPostMessage({
                [POST_MESSAGE_DOM_HEIGHT]: frameRoot.offsetHeight,
              });
            }
          }
          break;
        case POST_MESSAGE_DISPLAY_ROUTE:
          if (window.location?.pathname?.includes(event.data.payload)) {
            break;
          }
          history.push(prependBasename(event.data.payload));
          break;
        default:
          // Ignore webpack events
          if (!event.data?.source?.includes('devtools')) {
            console.warn(ERROR_UNRECOGNIZED_POST_MESSAGE_EVENT, event.data);
          }
          break;
      }
    }
  });

  sendPostMessage({
    [POST_MESSAGE_REQUEST_HOST_HANDSHAKE]: true,
  });
};

export const broadcastQuizCompletionToHost = (characterIdentifier: string, characterName: string) => {
  const postMessage = {};
  postMessage[POST_MESSAGE_QUIZ_COMPLETED] = { identifier: characterIdentifier, name: characterName };
  sendPostMessage(postMessage);
};
