/* eslint-disable sort-keys-fix/sort-keys-fix */
import { defineMessages } from '@formatjs/intl';

export const localeCommon = {
  loginForms: defineMessages({
    email: { defaultMessage: 'Email', id: 'auth.login.form.email' },
    password: { defaultMessage: 'Password', id: 'auth.login.form.password' },
  }),
  commonMessages: defineMessages({
    cancelBtn: {
      defaultMessage: 'Cancel',
      id: 'vinhood.common.common_messages.cancelBtn',
    },
    startToAdd: {
      defaultMessage: 'Start adding',
      id: 'vinhood.common.common_messages.startToAdd',
    },
    close: {
      defaultMessage: 'Close',
      id: 'vinhood.common.common_messages.close',
    },
    back: {
      defaultMessage: 'Back',
      id: 'vinhood.common.common_messages.back',
    },
    goBackBtn: {
      defaultMessage: 'Go Back',
      id: 'vinhood.vinhood_experience.common_messages.goBackBtn',
    },
    previousBtn: {
      defaultMessage: 'Previous',
      id: 'vinhood.vinhood_experience.common_messages.previousBtn',
    },
    search: {
      defaultMessage: 'Search',
      id: 'vh.vinhood_experience.common_messages.search',
    },
    termsAndConditionsTitle: {
      defaultMessage: 'Legal',
      id: 'vh.vinhood_experience.common_messages.termsAndConditionsTitle',
    },
    cookiePolicyTitle: {
      defaultMessage: 'Cookie policy',
      id: 'vh.vinhood_experience.common_messages.cookiePolicyTitle',
    },
    cookiePolicyLowercase: {
      defaultMessage: 'cookie policy',
      id: 'vh.vinhood_experience.common_messages.cookiePolicyLowercase',
    },
    privacyPolicyTitle: {
      defaultMessage: 'Privacy policy',
      id: 'vh.vinhood_experience.common_messages.privacyPolicyTitle',
    },
    tasteSrl: {
      defaultMessage: 'Taste srl',
      id: 'vh.vinhood_experience.common_messages.tasteSrl',
    },
    catalog: {
      defaultMessage:
        '{productCategory, select, wine {{productCategoryText} catalog} beer {{productCategoryText} catalog} bread {{productCategoryText} catalog} coffee {{productCategoryText} catalog} other {}}',
      id: 'vh.vinhood_experience.common_messages.catalog',
    },
    promo: {
      defaultMessage: 'Promo',
      id: 'vinhood.vinhood_experience.common_messages.promo,',
    },
    more: {
      defaultMessage: 'more',
      id: 'vinhood.vinhood_experience.common_messages.more,',
    },
    registerNow: {
      defaultMessage: 'Register now',
      id: 'vinhood.vinhood_experience.common_messages.registerNow,',
    },
    returnToLogin: {
      defaultMessage: 'Return to the Login',
      id: 'vinhood.common.common_messages.returnToLogin',
    },
    resetPassword: {
      defaultMessage: 'Reset Your Password',
      id: 'vinhood.common.common_messages.resetPassword',
    },
    emailRequired: {
      defaultMessage: 'Email is required',
      id: 'vinhood.common.common_messages.emailRequired',
    },
    validEmailValidation: {
      defaultMessage: 'Please enter a valid email address.',
      id: 'vinhood.common.common_messages.validEmailValidation',
    },
    forgotPasswordText: {
      defaultMessage: 'Have you forgotten your password?',
      id: 'vinhood.common.common_messages.forgotPasswordText',
    },
    emailField: {
      defaultMessage: 'Email',
      id: 'vinhood.common.common_messages.emailField',
    },
    passwordField: {
      defaultMessage: 'Password',
      id: 'vinhood.common.common_messages.passwordField',
    },
    passwordTooLongValidation: {
      defaultMessage: 'The password must not exceed 30 characters.',
      id: 'vinhood.common.common_messages.passwordTooLongValidation',
    },
    passwordTooShortValidation: {
      defaultMessage: 'Password must be at least 8 characters.',
      id: 'vinhood.common.common_messages.passwordTooShortValidation',
    },
    passwordUpperLowerNumberValidation: {
      defaultMessage: 'Passwords should contain at least one capital letter, one lowercase letter and one number',
      id: 'vinhood.common.common_messages.passwordUpperLowerNumber',
    },
    emailPlaceholder: {
      defaultMessage: 'Enter your email',
      id: 'vinhood.common.common_messages.emailPlaceholder',
    },
    vusionAddonDefaultDescription: {
      defaultMessage: 'What a light! Do you see it? Look for it among the shelves to reach the product you need.',
      id: 'vinhood.common.common_messages.vusionAddonDefaultDescription ,',
    },
    copyrightText: {
      defaultMessage: '© {currentYear} Taste srl - P.Iva IT09388380967',
      id: 'vinhood.common.common_messages.copyrightText',
    },
  }),
  productCategoriesPlural: defineMessages({
    wine: {
      defaultMessage: 'wines',
      id: 'vh.common_messages.winesPlural',
    },
    coffee: {
      defaultMessage: 'coffees',
      id: 'vh.common_messages.coffeesPlural',
    },
    beer: {
      defaultMessage: 'beers',
      id: 'vh.common_messages.beersPlural',
    },
    bread: {
      defaultMessage: 'bread',
      id: 'vh.common_messages.breadPlural',
    },
    all: {
      defaultMessage: 'products',
      id: 'vh.common_messages.productCategoriesPlural',
    },
  }),
  productCategories: defineMessages({
    all: {
      defaultMessage: 'All',
      id: 'vh.common_messages.all',
    },
    wine: {
      defaultMessage: 'wine',
      id: 'vh.common_messages.wine',
    },
    coffee: {
      defaultMessage: 'coffee',
      id: 'vh.common_messages.coffee',
    },
    beer: {
      defaultMessage: 'beer',
      id: 'vh.common_messages.beer',
    },
    bread: {
      defaultMessage: 'bread',
      id: 'vh.common_messages.bread',
    },
  }),
  searchPlaceHolderPlural: defineMessages({
    wine: {
      defaultMessage: 'Search wines',
      id: 'vh.vinhood_common.product_search_placeholder.wine',
    },
    beer: {
      defaultMessage: 'Search beers',
      id: 'vh.vinhood_common.product_search_placeholder.beer',
    },
    coffee: {
      defaultMessage: 'Search coffees',
      id: 'vh.vinhood_common.product_search_placeholder.coffee',
    },
  }),
  productCatalog: defineMessages({
    sortRelevanceButtonText: {
      defaultMessage: 'Relevance',
      id: 'vh.vinhood_common.catalog_page.sortRelevanceButtonText',
    },
    sortPriceButtonText: {
      defaultMessage: 'Price',
      id: 'vh.vinhood_common.catalog_page.sortPriceButtonText',
    },
    matchingText: {
      defaultMessage: 'matching',
      id: 'vh.vinhood_experience.catalog_page.matchingText',
    },
    searchPlaceHolder: {
      defaultMessage:
        '{productCategory, select, wine {Search {productCategoryPluralText}} beer {Search {productCategoryPluralText}} bread {Search {productCategoryPluralText}} coffee {Search {productCategoryPluralText}} other {}}',
      id: 'vh.vinhood_experience.catalog_page.searchProducts',
    },
    filterButtonText: {
      defaultMessage: 'Filters',
      id: 'vh.vinhood_experience.catalog_page.filterButtonText',
    },
    modalTitle: {
      defaultMessage: 'Special for you',
      id: 'vinhood.vinhood_kiosk.catalog_page.promotionModal.modalTitle',
    },
    modalDescription: {
      defaultMessage: 'Frame the Qr Code and present the discount directly at checkout',
      id: 'vinhood.vinhood_kiosk.catalog_page.promotionModal.modalDescription',
    },
    mobileModalDescription: {
      defaultMessage: 'Click the link and present the discount coupon directly at checkout',
      id: 'vinhood.vinhood_kiosk.catalog_page.promotionModal.mobileModalDescription',
    },
    modalBtnShowCoupon: {
      defaultMessage: 'Show discount coupon',
      id: 'vinhood.vinhood_kiosk.catalog_page.promotionModal.modalBtnShowCoupon',
    },
  }),
  recipePage: defineMessages({
    searchPlaceholder: {
      defaultMessage: `Recipe's name`,
      id: 'vinhood.vinhood_experience.recipe_page.searchPlaceholder',
    },
  }),

  foodHabitsContainer: defineMessages({
    avoidDairy: {
      defaultMessage: 'I prefer to exclude dairy products',
      id: 'vinhood.b2c.foodHabits.avoidDairy',
    },
    avoidGluten: {
      defaultMessage: 'I prefer to avoid gluten',
      id: 'vinhood.b2c.foodHabits.avoidGluten',
    },
    avoidMeat: {
      defaultMessage: 'I prefer not to eat meat',
      id: 'vinhood.b2c.foodHabits.avoidMeat',
    },
    noPreference: {
      defaultMessage: 'I eat everything',
      id: 'vinhood.b2c.foodHabits.noPreference',
    },
  }),
  foodHabitsPage: defineMessages({
    ctaBtn: {
      defaultMessage: 'Continue',
      id: 'vinhood.vinhood_experience.food_habits_page.ctaBtn',
    },
    titleQuestionText: {
      defaultMessage: 'Do you follow an eating regimen?',
      id: 'vinhood.vinhood_experience.food_habits_page.titleQuestionText',
    },
  }),
  loginPage: defineMessages({
    alternateFormChoice: {
      defaultMessage: 'Did you sign up with an e-mail address?',
      id: 'vinhood.vinhood_experience.login_page.alternateFormChoice',
    },
    passwordTooShortValidation: {
      defaultMessage: 'Password is Required',
      id: 'vinhood.vinhood_experience.login_page.passwordRequiredValidation',
    },
    submitBtnText: {
      defaultMessage: 'Log in',
      id: 'vinhood.vinhood_experience.login_page.submitBtnText',
    },
    subtitleFirst: {
      defaultMessage: `We’re happy to see you again! ||The world of food and beverages is vast and complex, or so it seems. We simplify it, starting with this login: use your usual one and we’ll see you inside in just a sec.`,
      id: 'vinhood.vinhood_experience.login_page.subtitleFirst',
    },
    subtitleSecond: {
      defaultMessage: `The world of food and beverages is vast and complex, or so it seems. We simplify it, starting with this login: use your usual one and we’ll see you inside in just a sec.`,
      id: 'vinhood.vinhood_experience.login_page.subtitleSecond',
    },
    title: {
      defaultMessage: 'Woo-hoo!',
      id: 'vinhood.vinhood_experience.login_page.title',
    },
    logInDescription: {
      defaultMessage: 'Log in using the method you chose to register',
      id: 'vinhood.vinhood_experience.login_page.logInDescription',
    },
  }),
  productCard: defineMessages({
    ctaGotoProductBtn: {
      defaultMessage: 'Go to product page',
      id: 'vinhood.common.productCard.ctaGotoProductBtn',
    },
    ctaReadMoreBtn: {
      defaultMessage: 'Read more',
      id: 'vinhood.common.productCard.ctaReadMoreBtn',
    },
    ctaGiveItBtn: {
      defaultMessage: 'Give it a peek!',
      id: 'vinhood.common.productCard.ctaGiveItBtn',
    },
    rateIt: {
      defaultMessage: 'RATE',
      id: 'vinhood.common.productCard.rateIt',
    },
    findMeBtn: {
      defaultMessage: 'Find me',
      id: 'vinhood.vinhood_kiosk.productCard.findMeBtn',
    },
  }),
  filterComponent: defineMessages({
    applyBtn: {
      defaultMessage: `Apply`,
      id: 'vinhood.b2c.filterPage.applyBtn',
    },
    characteristicsTitle: {
      defaultMessage: 'Characteristics',
      id: 'vinhood.b2c.filterPage.characteristicsFilterTitle',
    },
    wishlistTitle: {
      defaultMessage: 'My wishlist',
      id: 'vinhood.b2c.filterPage.wishlistTitle',
    },
    ratingTitle: {
      defaultMessage: 'My rating',
      id: 'vinhood.b2c.filterPage.ratingTitle',
    },
    formatTitle: { defaultMessage: 'Product format', id: 'vinhood.b2c.filterPage.formatFilterTitle' },
    locationTitle: { defaultMessage: 'Location', id: 'vinhood.b2c.filterPage.locationTitle' },
    moodTitle: { defaultMessage: 'Mood', id: 'vinhood.b2c.filterPage.moodFilterTitle' },
    originTitle: { defaultMessage: 'Origin', id: 'vinhood.b2c.filterPage.originFilterTitle' },
    priceRangeTitle: { defaultMessage: 'Price range', id: 'vinhood.b2c.filterPage.priceRangeFilterTitle' },
    productCategoryTitle: { defaultMessage: 'Product', id: 'vinhood.b2c.filterPage.productCategoryTitle' },
    styleTitle: { defaultMessage: 'Style', id: 'vinhood.b2c.filterPage.styleFilterTitle' },
    title: {
      defaultMessage: 'Filter',
      id: 'vinhood.b2c.filterPage.title',
    },
    toggleActiveSubtitle: {
      defaultMessage: 'Your Taste Identity is active.',
      id: 'vinhood.b2c.filterPage.tasteIdentityOn',
    },
    toggleInactiveSubtitle: {
      defaultMessage: 'Your Taste Identity is inactive.',
      id: 'vinhood.b2c.filterPage.tasteIdentityOff',
    },
    toggleTitle: { defaultMessage: 'Follow my #Characters', id: 'vinhood.b2c.filterPage.followMyCharacters' },
    togglePromotionTitle: { defaultMessage: 'Show promotions', id: 'vinhood.b2c.filterPage.togglePromotionTitle' },
  }),
  characterDetailMessages: defineMessages({
    characterTitleTextB2B: {
      defaultMessage:
        'The best solution for you is {productCategory, select, beer {a beer} bread {a bread} wine {a wine} coffee {a coffee} other {}}',
      id: 'vinhood.b2b.characterDetails.characterTitleText',
    },
    userCharacterTitleTextB2B: {
      defaultMessage:
        'The best solution for you, {userFirstName}, is {productCategory, select, beer {a beer} bread {a bread} wine {a wine} coffee {a coffee} other {}}:',
      id: 'vinhood.b2b.characterDetails.userCharacterTitleText',
    },
    characterTitleTextB2C: {
      defaultMessage: '{productCategory, select, other {Your best **{productCategoryText}** is}}',
      id: 'vinhood.b2c.characterDetails.characterTitleText',
    },
  }),
  productDetails: defineMessages({
    seeItOnYourMobile: {
      defaultMessage: 'See it on your mobile',
      id: 'vinhood.vinhood_experience.productDetails.seeItOnYourMobile',
    },
    findItOnShelf: {
      defaultMessage: 'Find it on the shelf',
      id: 'vinhood.vinhood_experience.productDetails.findItOnShelf,',
    },
    descriptionTitle: {
      defaultMessage: 'This one looks good',
      id: 'vinhood.vinhood_experience.productDetails.descriptionTitle ,',
    },
    contactProducerBtn: {
      defaultMessage: 'Contact the producer',
      id: 'vinhood.vinhood_experience.productDetails.contactProducerBtn',
    },
    tasteMatchTooltip: {
      defaultMessage: 'New! Click here and discover...',
      id: 'vinhood.common.productCard.tasteMatchTooltip',
    },
  }),
  productPage: defineMessages({
    perfectForTitle: {
      defaultMessage: 'The Perfect Situation',
      id: 'vinhood.vinhood_experience.productPage.perfectForTitleText',
    },
    identityCardTitle: {
      defaultMessage: 'Unique Features',
      id: 'vinhood.vinhood_experience.productPage.identityCardTitleText',
    },
    identityCardSubtitle: {
      defaultMessage:
        '{ productCategory, select, wine {Facts about this} beer {Facts about this} bread {Facts about this} coffee {Facts about this} other {}} {productCategoriesText}',
      id: 'vinhood.vinhood_experience.productPage.identityCardSubtitleText',
    },
    chefRecommendsTitle: {
      defaultMessage: 'The Chef Recommends',
      id: 'vinhood.vinhood_experience.productPage.chefRecommendsTitleText',
    },
    chefRecommendsSubtitle: {
      defaultMessage:
        '{ productCategory, select, wine {The Perfect Pairings for this } beer {The Perfect Pairings for this } bread {The Perfect Pairings for this } coffee {The Perfect Pairings for this } other {}}{productCategoriesText}',
      id: 'vinhood.vinhood_experience.productPage.chefRecommendsSubtitleTextWine',
    },
    essenceCardTitle: {
      defaultMessage:
        '{productCategory, select, wine {The ABCs of this } beer {The ABCs of this } bread {The ABCs of this } coffee {The ABCs of this } other{}}{productCategoriesText}',
      id: 'vinhood.vinhood_experience.productPage.essenceCardTitleText',
    },
    essenceCardSubtitle: {
      defaultMessage: 'Read About Its Characteristics',
      id: 'vinhood.vinhood_experience.productPage.essenceCardSubtitleText',
    },
    sustainabilityTitle: {
      defaultMessage: 'Giving Credit Where Credit is Due',
      id: 'vinhood.vinhood_experience.productPage.sustainabilityTitleText',
    },
    sustainabilitySubtitle: {
      defaultMessage:
        '{ productCategory, select, wine {Why This} beer {Why This} bread {Why This} coffee {Why This} other{}} {productCategoriesText} is Special',
      id: 'vinhood.vinhood_experience.productPage.sustainabilitySubtitleText',
    },
    seeSimilarBtn: {
      defaultMessage:
        '{ productCategory, select, wine {See similar {productCategoryPluralText}} beer {See similar {productCategoryPluralText}} bread {See similar {productCategoryPluralText}} coffee {See similar {productCategoryPluralText}} other{}}',
      id: 'vinhood.vinhood_experience.productPage.seeSimilarBtnText',
    },
    preparationsTitle: {
      defaultMessage: 'Preparations',
      id: 'vinhood.vinhood_experience.productPage.preparationsTitleText',
    },
    preparationsClassic: {
      defaultMessage: 'Classic',
      id: 'vinhood.vinhood_experience.productPage.preparationsClassicText',
    },
    preparationsSpecial: {
      defaultMessage: 'Special',
      id: 'vinhood.vinhood_experience.productPage.preparationsSpecialText',
    },
    classicRecipe: {
      defaultMessage: 'Paired With a Classic',
      id: 'vinhood.vinhood_experience.productPage.classicRecipe',
    },
    healthyRecipe: {
      defaultMessage: 'Paired With a Healthy Dish',
      id: 'vinhood.vinhood_experience.productPage.healthyRecipe',
    },
    ingredientRecipe: {
      defaultMessage: 'Used as an Ingredient',
      id: 'vinhood.vinhood_experience.productPage.ingredientRecipe',
    },
    sustainabilityWellness: {
      defaultMessage: 'Good for You',
      id: 'vinhood.vinhood_experience.productPage.sustainabilityWellnessText',
    },
    sustainabilityRecycling: {
      defaultMessage: 'Good for the World',
      id: 'vinhood.vinhood_experience.productPage.sustainabilityRecyclingText',
    },
    signUp: {
      defaultMessage: 'Sign up for more information about your taste',
      id: 'vinhood.vinhood_experience.productPage.signUp',
    },
  }),
  productIdentitiesCard: defineMessages({
    notPresentText: {
      defaultMessage: 'Not present',
      id: 'vinhood.vinhood_experience.productIdentitiesCard.notPresentText',
    },
    serveAt: {
      defaultMessage: 'Serve at',
      id: 'vinhood.vinhood_experience.productIdentitiesCard.serveAt',
    },
    warningText: {
      defaultMessage:
        'The indications on ingredients and allergens of the products may vary due to changes made by the manufacturers that could temporarily cause variations in the information herein. We therefore invite you to check the information on the product label or pack (where applicable) before consuming or using it.',
      id: 'vinhood.vinhood_experience.productIdentitiesCard.warningText',
    },
    typeTitleText: {
      defaultMessage: 'Type',
      id: 'vinhood.vinhood_experience.productIdentitiesCard.typeTitleText',
    },
    originTitleText: {
      defaultMessage: 'Origin',
      id: 'vinhood.vinhood_experience.productIdentitiesCard.originTitleText',
    },
    colorTitleText: {
      defaultMessage: 'Color',
      id: 'vinhood.vinhood_experience.productIdentitiesCard.identityCardColorTitleText',
    },
    grapeTitleText: {
      defaultMessage: 'Grape',
      id: 'vinhood.vinhood_experience.productIdentitiesCard.grapeTitleText',
    },
    alcoholTitleText: {
      defaultMessage: 'Alcohol',
      id: 'vinhood.vinhood_experience.productIdentitiesCard.alcoholTitleText',
    },
    aromasTitleText: {
      defaultMessage: 'Aromas',
      id: 'vinhood.vinhood_experience.productIdentitiesCard.aromasTitleText',
    },
    tasteTitleText: {
      defaultMessage: 'Taste',
      id: 'vinhood.vinhood_experience.productIdentitiesCard.tasteTitleText',
    },
    glassTitleText: {
      defaultMessage: 'Glass',
      id: 'vinhood.vinhood_experience.productIdentitiesCard.glassTitleText',
    },
    temperatureTitleText: {
      defaultMessage: 'Temperature',
      id: 'vinhood.vinhood_experience.productIdentitiesCard.temperatureTitleText',
    },
    allergensTitleText: {
      defaultMessage: 'Allergens',
      id: 'vinhood.vinhood_experience.productIdentitiesCard.allergensTitleText',
    },
    fermentationTitleText: {
      defaultMessage: 'Fermentation',
      id: 'vinhood.vinhood_experience.productIdentitiesCard.fermentationTitleText',
    },
    specialIngredientTitleText: {
      defaultMessage: 'Special ingredient',
      id: 'vinhood.vinhood_experience.productIdentitiesCard.specialIngredientTitleText',
    },
    varietyTitleText: {
      defaultMessage: 'Variety',
      id: 'vinhood.vinhood_experience.productIdentitiesCard.varietyTitleText',
    },
    roastedTitleText: {
      defaultMessage: 'Roasted in',
      id: 'vinhood.vinhood_experience.productIdentitiesCard.roastedTitleText',
    },
  }),
  charactersMapScroller: defineMessages({
    info: {
      defaultMessage: 'Find out where you stand',
      id: 'vinhood.b2c.charactersMapScroller.info',
    },
    productIntro: {
      defaultMessage:
        '{ productCategory, select, wine {The world of} beer {The world of} bread {The world of} coffee {The world of} other {}} {productCategoryText}',
      id: 'vinhood.b2c.charactersMapScroller.productIntro',
    },
    productCharactersName: {
      defaultMessage: '{ productCategory, select, other {{productCategoryPluralText} Characters}}',
      id: 'vinhood.b2c.charactersMapScroller.productCharactersName',
    },
    charactersCountDescription: {
      defaultMessage:
        'According to vinhood methodology, { productCategory, select, other {{productCategoryPluralText} are divided into}} **{charactersCount} characters**',
      id: 'vinhood.b2c.charactersMapScroller.charactersCountDescription',
    },
  }),
  onBoardingCard: {
    onBoardingCardOne: defineMessages({
      headingText: {
        defaultMessage: '3, 2, 1 …take off!',
        id: 'vinhood.common.onBoardingCardOne.headingText',
      },
      descriptionText: {
        defaultMessage: 'Customized content, quizzes, and games.',
        id: 'vinhood.common.onBoardingCardOne.descriptionText',
      },
      btnText: {
        defaultMessage: "But there's more",
        id: 'vinhood.common.onBoardingCardOne.btnText',
      },
    }),
    onBoardingCardTwo: defineMessages({
      headingText: {
        defaultMessage: 'On a journey',
        id: 'vinhood.common.onBoardingCardTwo.headingText',
      },
      descriptionText: {
        defaultMessage: 'A memorable experience based on your values and what you like.',
        id: 'vinhood.common.onBoardingCardTwo.descriptionText',
      },
      btnText: {
        defaultMessage: 'Even more',
        id: 'vinhood.common.onBoardingCardTwo.btnText',
      },
    }),
    onBoardingCardThree: defineMessages({
      headingText: {
        defaultMessage: 'Land, land!',
        id: 'vinhood.common.onBoardingCardThree.headingText',
      },
      descriptionText: {
        defaultMessage: 'Choose the best for yourself with Taste Identity and your Characters.',
        id: 'vinhood.common.onBoardingCardThree.descriptionText',
      },
      btnText: {
        defaultMessage: 'Let’s start!',
        id: 'vinhood.common.onBoardingCardThree.btnText',
      },
    }),
  },
  ratingModal: defineMessages({
    titleText: {
      defaultMessage: 'How much do you like this product?',
      id: 'vinhood.common.feedbackModal.titleText',
    },
    descriptionText: {
      defaultMessage: 'Rate this product and unlock the next stage of the journey!',
      id: 'vinhood.common.feedbackModal.descriptionText',
    },
    ratingVeryGood: {
      defaultMessage: 'My soulmate',
      id: 'vinhood.common.feedbackModal.emojiVeryGood',
    },
    ratingGood: {
      defaultMessage: 'I want more!',
      id: 'vinhood.common.feedbackModal.emojiGood',
    },
    ratingNeutral: {
      defaultMessage: 'Mediocre, nothing special',
      id: 'vinhood.common.feedbackModal.emojiNeutral',
    },
    ratingBad: {
      defaultMessage: "I wouldn't try it again",
      id: 'vinhood.common.feedbackModal.emojiBad',
    },
    ratingVeryBad: {
      defaultMessage: 'Yuck',
      id: 'vinhood.common.feedbackModal.emojiVeryBad',
    },
  }),
  resetPasswordPage: defineMessages({
    subTitle: {
      defaultMessage: 'Don’t panic, we’ll send you the instructions again.',
      id: 'vinhood.common.resetPassword.subTitle',
    },
  }),
  setNewPasswordPage: defineMessages({
    headerText: {
      defaultMessage: 'Okay, get a new password',
      id: 'vinhood.common.setNewPasswordPage.title',
    },
    hintText: {
      defaultMessage:
        'Must be between 8 and 30 characters, at least one uppercase, one lowercase letters and one number.',
      id: 'vinhood.common.setNewPasswordPage.hintText',
    },
    subHeaderText: {
      defaultMessage: `Be creative: come up with a password that’s different from the previous one.`,
      id: 'vinhood.common.setNewPasswordPage.subtitle',
    },
  }),
  completeResetPasswordPage: defineMessages({
    subTitle: {
      defaultMessage: 'You’ve successfully changed your password. Click below to enter.',
      id: 'vinhood.common.completeResetPasswordPage.subTitle',
    },
    title: {
      defaultMessage: 'Done!',
      id: 'vinhood.common.completeResetPasswordPage.title',
    },
  }),
  checkResetPasswordPage: defineMessages({
    resendBtnText: {
      defaultMessage: 'Click here to receive it for real this time',
      id: 'vinhood.common.checkResetPasswordPage.resendBtnText',
    },
    retryTitleText: {
      defaultMessage: 'Haven’t received the e-mail?',
      id: 'vinhood.common.checkResetPasswordPage.retryTitleText',
    },
    subtitleFirst: {
      defaultMessage: `We’ve sent to`,
      id: 'vinhood.common.checkResetPasswordPage.sub-title-first',
    },
    subtitleSecond: {
      defaultMessage: `a convenient link for resetting your password.`,
      id: 'vinhood.common.checkResetPasswordPage.sub-title-second',
    },
    title: {
      defaultMessage: 'Check your e-mail',
      id: 'vinhood.common.checkResetPasswordPage.title',
    },
    retryIn: {
      defaultMessage: 'retry in',
      id: 'vinhood.common.checkResetPasswordPage.retryIn',
    },
  }),
  resultsLoadingScreen: defineMessages({
    titleText: {
      defaultMessage: `Give us a sec. ||We're turning your responses into a scrumptious outcome...`,
      id: 'vinhood.b2c.resultLoadingScreen.titleText',
    },
  }),
  recipeSearchHeader: defineMessages({
    beer: {
      defaultMessage: `A beer to accompany a meal`,
      id: `vinhood.b2c.recipeSearch.subheading.beer`,
    },
    coffee: {
      defaultMessage: `A coffee to accompany a meal`,
      id: `vinhood.b2c.recipeSearch.subheading.coffee`,
    },
    noRecipesFound: {
      defaultMessage: `Sorry, I can't find any recipes with this name, why don't you try the ingredient search below!`,
      id: 'vinhood.b2c.recipeSearch.noRecipesFoundFirst',
    },
    title: {
      defaultMessage: 'Type in a recipe',
      id: 'vinhood.b2c.recipeSearch.title',
    },
    wine: {
      defaultMessage: `A wine to accompany a meal`,
      id: `vinhood.b2c.recipeSearch.subheading.wine`,
    },
    bread: {
      defaultMessage: `A bread to accompany a meal`,
      id: `vinhood.b2c.recipeSearch.subheading.bread`,
    },
  }),
  registrationPage: defineMessages({
    headerTitle: {
      defaultMessage: 'High five: ||gift unlocked!',
      id: `vinhood.common.registrationPage.headerTitle`,
    },
    headerSubTitle: {
      defaultMessage: `Receive immediately **a personalized report** based on your taste by sharing your email with us. ||But the surprises aren't over yet...`,
      id: `vinhood.common.registrationPage.headerSubTitle`,
    },
    headerTitleApp: {
      defaultMessage: `Train your Taste`,
      id: `vinhood.common.registrationPage.headerTitleApp`,
    },
    headerSubTitleApp: {
      defaultMessage: `Become a part of the Vinhood community and embark on a personalized training journey that will be both enjoyable and highly educational, ensuring you stay in great shape like never before.`,
      id: `vinhood.common.registrationPage.headerSubTitleApp`,
    },
    createAccount: {
      defaultMessage: `Create your account`,
      id: `vinhood.common.registrationPage.createAccount`,
    },
    loginTitle: {
      defaultMessage: `Already registered?`,
      id: `vinhood.common.registrationPage.loginTitle`,
    },
    loginLinkTitle: {
      defaultMessage: `Enable your Vinhood taste profile.`,
      id: `vinhood.common.registrationPage.loginLinkTitle`,
    },
    catalogTitle: {
      defaultMessage: `Perhaps next time?`,
      id: `vinhood.common.registrationPage.catalogTitle`,
    },
    catalogLinkTitle: {
      defaultMessage: `Skip and go to products.`,
      id: `vinhood.common.registrationPage.catalogLinkTitle`,
    },
  }),
  emailRegistrationForm: defineMessages({
    nameField: {
      defaultMessage: 'Name',
      id: 'vinhood.common.emailRegistrationForm.nameField',
    },
    nameFieldPlaceholder: {
      defaultMessage: 'Enter your name',
      id: 'vinhood.common.emailRegistrationForm.nameFieldPlaceholder',
    },
    emailField: {
      defaultMessage: 'Email',
      id: 'vinhood.common.emailRegistrationForm.emailField',
    },
    emailFieldPlaceholder: {
      defaultMessage: 'Enter your email',
      id: 'vinhood.common.emailRegistrationForm.emailFieldPlaceholder',
    },
    passwordField: {
      defaultMessage: 'Password',
      id: 'vinhood.common.emailRegistrationForm.passwordField',
    },
    passwordFieldPlaceholder: {
      defaultMessage: 'Create a password',
      id: 'vinhood.common.emailRegistrationForm.passwordFieldPlaceholder',
    },
    submitBtn: {
      defaultMessage: 'Submit',
      id: 'vinhood.common.emailRegistrationForm.submitBtn',
    },
    emailValidation: {
      defaultMessage: 'Email is required',
      id: 'vinhood.common.emailRegistrationForm.emailValidation',
    },
    validEmailValidation: {
      defaultMessage: 'You cannot use this email to register',
      id: 'vinhood.common.emailRegistrationForm.validEmailValidation',
    },
    nameValidation: {
      defaultMessage: 'First Name should be at least two characters.',
      id: 'vinhood.common.emailRegistrationForm.nameValidation',
    },
    passwordValidation: {
      defaultMessage:
        'Must be between 8 and 30 characters, at least one uppercase, one lowercase letters and one number.',
      id: 'vinhood.common.emailRegistrationForm.passwordValidation',
    },
    passwordTooShortValidation: {
      defaultMessage: 'Must be at least 8 characters.',
      id: 'vinhood.common.emailRegistrationForm.passwordTooShortValidation',
    },
    passwordTooLongValidation: {
      defaultMessage: 'The password must not exceed 30 characters.',
      id: 'vinhood.common.emailRegistrationForm.passwordTooLongValidation',
    },
    passwordUpperLowerNumberValidation: {
      defaultMessage: 'Passwords should contain at least one capital letter, one lowercase letter and one number',
      id: 'vinhood.common.emailRegistrationForm.passwordUpperLowerNumber',
    },
    termsAndConditionsAcceptance: {
      defaultMessage: `I agree to Vinhood's [linkTo=terms-and-conditions]Terms and Conditions[linkTo=terms-and-conditions]`,
      id: 'vinhood.common.emailRegistrationForm.termsAndConditionsAcceptance',
    },
    optionalMarketingAcceptance: {
      defaultMessage:
        'Yes, I would like to receive the VINHOOD newsletter and stay informed about promotions and news. ||Read the [linkTo=privacy-policy]Privacy policy[linkTo=privacy-policy]',
      id: 'vinhood.common.emailRegistrationForm.optionalMarketingAcceptance',
    },
  }),
  completeEmailRegistration: defineMessages({
    title: {
      defaultMessage: `You’re nearly there!`,
      id: `vinhood.common.completeEmailRegistration.title`,
    },
    subTitle: {
      defaultMessage: `Check your inbox for an e-mail that will finally let you enjoy your new account. `,
      id: `vinhood.common.completeEmailRegistration.subTitle`,
    },
    notReceived: {
      defaultMessage: `Haven’t received it? ||**Check your email spam folder:** they age poorly there.`,
      id: `vinhood.common.completeEmailRegistration.notReceived`,
    },
    submitBtn: {
      defaultMessage: `Send it again`,
      id: `vinhood.common.completeEmailRegistration.submitBtn`,
    },
  }),
  productRatedList: defineMessages({
    title: {
      defaultMessage: 'Already tasted:',
      id: `vinhood.common.productRatedList.title`,
    },
  }),
  tastePathStartPage: defineMessages({
    title: {
      defaultMessage: 'Your tasting experience is ready to roll!',
      id: 'vinhood.vinhood_experience.tastePathStartPage.title',
    },
    subTitle: {
      defaultMessage: `How it works? It's a piece of cake.`,
      id: 'vinhood.vinhood_experience.tastePathStartPage.subTitle',
    },
    footerTitle: {
      defaultMessage: 'Warning:',
      id: 'vinhood.vinhood_experience.tastePathStartPage.footerTitle',
    },
    footerText: {
      defaultMessage: `it's super personalized. Make the most of it!`,
      id: 'vinhood.vinhood_experience.tastePathStartPage.footerText',
    },
    tastePathDiscoverHint: {
      defaultMessage: `**Discover it** ||Explore your #{characterName}.`,
      id: 'vinhood.vinhood_experience.tastePathStartPage.tastePathDiscoverHint',
    },
    tastePathDirectionHint: {
      defaultMessage: '**Taste it** ||Savor the perfect products for your palate and have fun!',
      id: 'vinhood.vinhood_experience.tastePathStartPage.tastePathDirectionHint',
    },
    tastePathFeedbackHint: {
      defaultMessage: '**Rate it** ||How was your taste? Tell us to unlock the next products.',
      id: 'vinhood.vinhood_experience.tastePathStartPage.tastePathFeedbackHint',
    },
    tastePathPageButtonText: {
      defaultMessage: 'Let’s dive in!',
      id: 'vinhood.vinhood_experience.tastePathStartPage.tastePathButtonText',
    },
  }),
  exitAndGoToTheAppModal: defineMessages({
    title: {
      defaultMessage: `Sure?! You're are about to leave the {retailerName} catalog`,
      id: 'vinhood.common.exitModal.title',
    },
    subtitle: {
      defaultMessage: `To reactive it, you'll have to scan the QR code again.`,
      id: 'vinhood.common.exitModal.subtitle',
    },
    description: {
      defaultMessage: `But don't worry: our app is just one click away!`,
      id: 'vinhood.common.exitModal.description',
    },
    cancelBtn: {
      defaultMessage: `Cancel`,
      id: 'vinhood.common.exitModal.cancelBtn',
    },
    exitBtn: {
      defaultMessage: `Switch to the app`,
      id: 'vinhood.common.exitModal.exitBtn',
    },
  }),
  discoveryQuizModal: defineMessages({
    skipBtn: {
      defaultMessage: 'skip',
      id: 'vinhood.vinhood_experience.discoveryQuizModal.skipBtn',
    },
    initiateQuizHeaderTitle: {
      defaultMessage: 'Wannabe Taster',
      id: 'vinhood.vinhood_experience.discoveryQuizModal.initiateQuizHeaderTitle',
    },
    initiateQuizContentTitle: {
      defaultMessage: 'So, what did it taste like?',
      id: 'vinhood.vinhood_experience.discoveryQuizModal.initiateQuizContentTitle',
    },
    initiateQuizContentSubtitle: {
      defaultMessage:
        'Just, {productCategory, select, wine {{productCategoryText} isn’t enough to describe it!} beer {{productCategoryText} isn’t enough to describe it!} bread {{productCategoryText} isn’t enough to describe it!} coffee {{productCategoryText} isn’t enough to describe it!} other {}}',
      id: 'vinhood.vinhood_experience.discoveryQuizModal.initiateQuizContentSubtitle',
    },
    initiateQuizBtn: {
      defaultMessage: 'Discover the aroma and taste',
      id: 'vinhood.vinhood_experience.discoveryQuizModal.initiateQuizBtn',
    },
    aromasQuizTitle: {
      defaultMessage: 'Sniff',
      id: 'vinhood.vinhood_experience.discoveryQuizModal.aromasQuizTitle',
    },
    aromasQuizType: {
      defaultMessage: 'Aromas',
      id: 'vinhood.vinhood_experience.discoveryQuizModal.aromasQuizType',
    },
    aromasQuizBtn: {
      defaultMessage: 'Next, please',
      id: 'vinhood.vinhood_experience.discoveryQuizModal.aromasQuizBtn',
    },
    tasteQuizTitle: {
      defaultMessage: 'Gulp',
      id: 'vinhood.vinhood_experience.discoveryQuizModal.tasteQuizTitle',
    },
    tasteQuizType: {
      defaultMessage: 'Taste',
      id: 'vinhood.vinhood_experience.discoveryQuizModal.tasteQuizType',
    },
    tasteQuizBtn: {
      defaultMessage: 'One click to reveal a tip...',
      id: 'vinhood.vinhood_experience.discoveryQuizModal.tasteQuizBtn',
    },
    tasteQuizFinishBtn: {
      defaultMessage: 'Keep following the taste path',
      id: 'vinhood.vinhood_experience.discoveryQuizModal.tasteQuizFinishBtn',
    },
    yesRight: {
      defaultMessage: 'Yes, Right!',
      id: 'vinhood.vinhood_experience.discoveryQuizModal.yesRight',
    },
    ohNo: {
      defaultMessage: 'Oh no!',
      id: 'vinhood.vinhood_experience.discoveryQuizModal.ohNo',
    },
  }),
  findItModal: defineMessages({
    titleText: {
      defaultMessage: 'Find it',
      id: 'vinhood.vinhood_experience.findItModal.titleText',
    },
  }),
  takeTestStartPage: defineMessages({
    description: {
      defaultMessage:
        'The full experience is just around the corner, but we need to start with the **Taste Test** to recommend you personalized products, experiences and content.',
      id: 'vinhood.common.takeTestStartPage.description',
    },
    header: {
      defaultMessage: 'The World of Taste is just a click away!',
      id: 'vinhood.common.takeTestStartPage.header',
    },
    button: {
      defaultMessage: 'Start the test!',
      id: 'vinhood.common.takeTestStartPage.button',
    },
  }),
  fixedInfoBanner: defineMessages({
    description: {
      defaultMessage: `Hey, it's still a beta version, but we are preparing the fireworks`,
      id: 'vinhood.common.fixedInfoBanner',
    },
  }),
  wishlist: defineMessages({
    save: {
      defaultMessage: 'SAVE',
      id: 'vinhood.common.wishlist.save',
    },
    saved: {
      defaultMessage: 'SAVED',
      id: 'vinhood.common.wishlist.saved',
    },
  }),
  authOptions: defineMessages({
    title: {
      defaultMessage: `There's a lot more to learn!`,
      id: 'vinhood.commom.authOptions.title',
    },
    description: {
      defaultMessage: `Use Vinhood everywhere, freely!`,
      id: 'vinhood.commom.authOptions.description',
    },
    orText: {
      defaultMessage: 'or',
      id: 'vinhood.commom.authOptions.orText',
    },
    facebookSignup: {
      defaultMessage: 'Sign up with Facebook',
      id: 'vinhood.commom.authOptions.facebookSignup',
    },
    facebookLogIn: {
      defaultMessage: 'Log in with Facebook',
      id: 'vinhood.commom.authOptions.facebookLogin',
    },
    googleSignup: {
      defaultMessage: 'Sign up with Google',
      id: 'vinhood.commom.authOptions.googleSignup',
    },
    googleLogIn: {
      defaultMessage: 'Log in with Google',
      id: 'vinhood.commom.authOptions.googleLogin',
    },
    emailSignup: {
      defaultMessage: 'Sign up with Email',
      id: 'vinhood.commom.authOptions.emailSignup',
    },
    emailLogIn: {
      defaultMessage: 'Log in with Email',
      id: 'vinhood.commom.authOptions.emailLogi n',
    },
  }),
  tasteMatchLevel: defineMessages({
    none: {
      defaultMessage: 'Your taste match?',
      id: 'vinhood.commom.tasteMatchLevel.none',
    },
    levelTwo: {
      defaultMessage: 'You might like it',
      id: 'vinhood.commom.tasteMatchLevel.levelTwo',
    },
    levelThree: {
      defaultMessage: 'Close to your taste',
      id: 'vinhood.commom.tasteMatchLevel.levelThree',
    },
    levelFour: {
      defaultMessage: 'Soulful Match',
      id: 'vinhood.commom.tasteMatchLevel.levelFour',
    },
    random1: {
      defaultMessage: 'A bit alternative, will you try it?',
      id: 'vinhood.commom.tasteMatchLevel.random1',
    },
    random2: {
      defaultMessage: 'A bit out of the ordinary',
      id: 'vinhood.commom.tasteMatchLevel.random2',
    },
    random3: {
      defaultMessage: 'It might surprise you!',
      id: 'vinhood.commom.tasteMatchLevel.random3',
    },
    random4: {
      defaultMessage: 'A tasty challenge!',
      id: 'vinhood.commom.tasteMatchLevel.random4',
    },
    random5: {
      defaultMessage: 'Not your style, but why not?',
      id: 'vinhood.commom.tasteMatchLevel.random5',
    },
  }),
  tasteMatchModal: defineMessages({
    btnText: {
      defaultMessage: 'Find out now',
      id: 'vinhood.commom.tasteMatchModal.btnText',
    },
    actionTitle: {
      defaultMessage: "Don't have a taste match yet?",
      id: 'vinhood.commom.tasteMatchModal.actionTitle',
    },
    modalTitle: {
      defaultMessage: 'Taste Match',
      id: 'vinhood.commom.tasteMatchModal.modalTitle',
    },
    contentText: {
      defaultMessage: `Taste Match: your new favorite feature for discovering the affinity between a product and your palate.`,
      id: 'vinhood.commom.tasteMatchLevel.contentText',
    },
    listOne: {
      defaultMessage: 'Take the Test',
      id: 'vinhood.commom.tasteMatchModal.listOne',
    },
    listTwo: {
      defaultMessage: 'Discover your Taste Character',
      id: 'vinhood.commom.tasteMatchModal.listTwo',
    },
    descriptionTwo: {
      defaultMessage: `In our catalog, you'll find a wide range of products with varying levels of compatibility with your taste! Discover them all.`,
      id: 'vinhood.commom.tasteMatchModal.descriptionTwo',
    },
    descriptionThree: {
      defaultMessage: 'Find your match with each one, a scale that guides you towards your ideal combinations',
      id: 'vinhood.commom.tasteMatchModal.descriptionThree',
    },
  }),
  characterInfoGroup: defineMessages({
    title: {
      defaultMessage: 'How to taste it',
      id: 'vinhood.commom.characterInfoGroup.title',
    },
  }),
  registrationBanner: defineMessages({
    title: {
      defaultMessage: "Sign up, don't let anyone tell you about it.",
      id: 'vinhood.commom.registrationBanner.title',
    },
    description: {
      defaultMessage: 'If you like what you see now, imagine it multiplied by 1000, over there',
      id: 'vinhood.commom.registrationBanner.description',
    },
  }),
  tasteId: defineMessages({
    personality: {
      defaultMessage: 'Personality',
      id: 'vinhood.commom.tasteId.personality',
    },
    typeOf: {
      defaultMessage:
        'Type of {productCategory, select, beer {beer} bread {bread} wine {wine} coffee {coffee} other {}}',
      id: 'vinhood.commom.tasteId.typeOf',
    },
    buyerPrinciplesTitle: {
      defaultMessage: 'My Buyer principles',
      id: 'vinhood.commom.tasteId.buyerPrinciplesTitle',
    },
    buyerPrinciplesDescription: {
      defaultMessage:
        'Are you close to sustainability topics, or interested in discovering niche and special products? Click here and select your subjects of interest!',
      id: 'vinhood.commom.tasteId.buyerPrinciplesDescription',
    },
    journalTitle: {
      defaultMessage:
        'My {productCategory, select, beer {beer} bread {bread} wine {wine} coffee {coffee} other {}} Journal',
      id: 'vinhood.commom.tasteId.journalTitle',
    },
    viewAllCTA: {
      defaultMessage: 'View all',
      id: 'vinhood.commom.tasteId.viewAllCTA',
    },
  }),
};
