import ReactMarkdown, { Options } from 'react-markdown';

type Props = Omit<Options, 'children'> & {
  text: string | number | JSX.Element;
};

/**
 * ! Improve after removing legacy PMI design sets
 */
const CustomMDReactComponent = ({ text = '', ...rest }: Props) => (
  <ReactMarkdown {...rest}>{String(text).replace(/\|\|/g, '  \n')}</ReactMarkdown>
);

export default CustomMDReactComponent;
