/* eslint-disable func-names */
/**
 * Utility functions to decorate the MixpanelTracker class with.
 */
import ReactPixel from 'react-facebook-pixel';

import { selectRetailerDesignSet } from '@lib/core/retailers/selectors/retailerLocation';
import {
  DESIGN_SET_VINHOOD_APP as APP_DESIGN,
  DESIGN_SET_VINHOOD_EXPERIENCE as WIDGET_DESIGN,
  isApplicationKiosk,
} from '@lib/core/service/consts';
import { store } from '@lib/core/service/store';
import { AppStateType } from '@lib/core/service/types/appStateType';
import { selectUserRole } from '@lib/core/users/selectors/user';
import { USER_ROLE_ANONYMOUS, USER_ROLE_NORMAL } from '@lib/core/users/utils/consts';
import { isAppInIframe } from '@lib/tools/comms/utils';
import MixpanelTracker from '@lib/tools/dat/mixpanel';
import { MP_PROPERTIES } from '@lib/tools/dat/mixpanel/consts';
import Utilities from '@lib/tools/dat/mixpanel/utils';
import { actionUpdateTrackingLogs } from '@lib/tools/dat/slices';

/**
 * Class decorator.
 *
 * Allow Mixpanel tracking on user that are of type {@link NORMAL} or `0`.
 * Prevent tracking on KIOSK user.
 */
export const trackOnlyNormalUsers = () => {
  return <T extends new (...args: any[]) => any>(target: T) => {
    Object.getOwnPropertyNames(target.prototype).forEach(prop => {
      const descriptor = Object.getOwnPropertyDescriptor(target.prototype, prop);

      // if it's a getter/setter, skip it
      if (descriptor.get || descriptor.set) return;

      const oldFunc = target.prototype[prop];
      if (oldFunc instanceof Function) {
        target.prototype[prop] = function (...args: any[]) {
          const state: AppStateType = store.getState();
          const userRole = selectUserRole(state);
          if (userRole === USER_ROLE_NORMAL || userRole === USER_ROLE_ANONYMOUS) {
            return oldFunc.apply(this, args);
          }
          return undefined;
        };
      }
    });
  };
};

/**
 * Class decorator.
 *
 * Disallow tracking if Mixpanel SDK is not loaded.
 */
export const checkIfMixpanelExists = () => {
  // adapted from https://stackoverflow.com/a/49120018/12127578
  return <T extends new (...args: any[]) => any>(target: T) => {
    Object.getOwnPropertyNames(target.prototype).forEach(prop => {
      const descriptor = Object.getOwnPropertyDescriptor(target.prototype, prop);

      // if it's a getter/setter, skip it
      if (descriptor.get || descriptor.set) return;

      const oldFunc = target.prototype[prop];
      if (oldFunc instanceof Function) {
        // eslint-disable-next-line func-names
        target.prototype[prop] = function (...args: any[]) {
          const isMixpanelEnabled = store.getState().tracking.mixPanelState;
          if (isMixpanelEnabled) {
            return oldFunc.apply(this, args);
          }
          return undefined;
        };
      }
    });
  };
};

/**
 * Method decorator.
 *
 * Enable logging function of function args if `?tools=<token>` URL parameter exists.
 * Used with GhostInspector to ensure consistent behavior of tracking events.
 *
 * @param userMustBeIdentified - Optional. If true, the decorated function will be logged
 * only if the user is identified with Mixpanel.
 */
export const logEventForQA = (userMustBeIdentified?: boolean) => {
  // NOTE: Super Properties are prefixed with "SP -" to differentiate them from
  // regular event properties when using the Mixpanel console. However, the logger
  // displays them without the prefix, as the prefix is added within the
  // setSuperProperties method. This discrepancy is not a cause for concern.
  return (target: any, key: string, descriptor: PropertyDescriptor) => {
    const oldFunc = descriptor.value;
    const isTrackEvent = key === 'track';

    descriptor.value = function (...args: any[]) {
      const eventName = isTrackEvent ? args[0] : key;
      const eventArgs = isTrackEvent ? args[1] : args[0];
      const newArgs =
        typeof eventArgs === 'object' && Object.keys(MP_PROPERTIES).includes(Object.keys(eventArgs)[0])
          ? (target as Utilities).remap(eventArgs)
          : eventArgs;

      if (!userMustBeIdentified || MixpanelTracker.profile.didIdentify) {
        store.dispatch(actionUpdateTrackingLogs({ log: { args: newArgs, eventName } }));
      }

      oldFunc.apply(this, args);
    };
  };
};

/** Method decorator.
 *
 * Disables execution of decorated method on {@link APP App Design Set}.
 */
export const disableOnApp = () => {
  return (_target: any, _key: string, descriptor: PropertyDescriptor) => {
    const oldFunc = descriptor.value;

    descriptor.value = function (...args: any[]) {
      const state: AppStateType = store.getState();
      const currentDesignSet = selectRetailerDesignSet(state);
      if (currentDesignSet === APP_DESIGN) return;
      oldFunc.apply(this, args);
    };
  };
};

/** Method decorator.
 *
 * Disables execution of decorated method on {@link WIDGET Widget Design Set}.
 */
export const disableOnWidget = () => {
  return (_target: any, _key: string, descriptor: PropertyDescriptor) => {
    const oldFunc = descriptor.value;

    descriptor.value = function (...args: any[]) {
      const state: AppStateType = store.getState();
      const currentDesignSet = selectRetailerDesignSet(state);
      if (currentDesignSet === WIDGET_DESIGN && isAppInIframe) return;
      oldFunc.apply(this, args);
    };
  };
};

/** Method decorator.
 *
 * Disables execution of decorated method on {@link KIOSK Kiosk Design Set}.
 */
export const disableOnKiosk = () => {
  return (_target: any, _key: string, descriptor: PropertyDescriptor) => {
    const oldFunc = descriptor.value;

    descriptor.value = function (...args: any[]) {
      if (isApplicationKiosk) return;
      oldFunc.apply(this, args);
    };
  };
};

/** Method decorator.
 *
 * execute tracking event with facebook pixel.
 */
export const trackFacebookPixelEvent = (event: string) => {
  return (_target: any, _key: string, descriptor: PropertyDescriptor) => {
    const oldFunc = descriptor.value;

    descriptor.value = function (...args: any[]) {
      const state: AppStateType = store.getState();
      const currentDesignSet = selectRetailerDesignSet(state);
      if (currentDesignSet === APP_DESIGN) {
        ReactPixel.track(event);
      }
      oldFunc.apply(this, args);
    };
  };
};
