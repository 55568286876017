import BuildIcon from '@mui/icons-material/Build';
import MiscellaneousServicesIcon from '@mui/icons-material/MiscellaneousServices';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import ScienceIcon from '@mui/icons-material/Science';
import SpellcheckIcon from '@mui/icons-material/Spellcheck';
import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import { Resizable } from 're-resizable';
import { ComponentType, MouseEvent, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';

import { ENV_DEVTOOLS_TOKEN, URL_PARAM_TOOLS } from '@lib/core/service/consts';
import { useApp } from '@lib/core/service/hooks';
import { setIsDevToolsEnabled } from '@lib/core/service/slices';
import StyledTooltip from '@lib/tools/devtools/components/Tooltip';
import { LocalePanel } from '@lib/tools/devtools/panels/locale/LocalePanel';
import { QuizPanel } from '@lib/tools/devtools/panels/sensory/QuizPanel';
import { ServicePanel } from '@lib/tools/devtools/panels/service/ServicePanel';
import { TrackingPanel } from '@lib/tools/devtools/panels/tracking/TrackingPanel';
import { useEffectSkipFirst } from '@lib/tools/views/hooks';

// eslint-disable-next-line no-restricted-imports
import './dev_tools.scss';

/**
 * @todo add toggles for isAppFa, isAppKiosk
 * @todo add a toggle for mocking hosting sites and iframes
 */
export default (App: ComponentType) => {
  /**
   * @note requires app state
   */
  function DevToolsComponent() {
    const dispatch = useDispatch();

    const { isDevToolsEnabled } = useApp();

    // Using `window` outside react-router's scope
    const { search } = window.location;

    const [theme, setTheme] = useState(true);
    const [utilsViewSize, setUtilsViewSize] = useState({ height: 35, width: 38 });
    const resizableElementRef = useRef<Resizable>(null);
    const [view, setView] = useState('panels');

    const handleChange = (_event: MouseEvent<HTMLElement>, nextView: string) => {
      setView(nextView);
    };

    useEffect(() => {
      // Sentry.init({ dsn: process.env.REACT_APP_SENTRY_DSN });

      if (ENV_DEVTOOLS_TOKEN) {
        const urlUtilsToken = new URLSearchParams(search).get(URL_PARAM_TOOLS);

        if (urlUtilsToken === ENV_DEVTOOLS_TOKEN || isDevToolsEnabled) {
          dispatch(setIsDevToolsEnabled());
        }
      }
    }, []);

    useEffect(() => {
      resizableElementRef.current?.updateSize(utilsViewSize);
    }, [utilsViewSize]);

    useEffectSkipFirst(() => {
      const newSize = { height: 35, width: 38 };

      switch (view) {
        case 'panels':
          if (utilsViewSize.height <= 35) newSize.height = 236;
          setUtilsViewSize(newSize);
          break;
        case 'service':
        case 'locale':
        case 'sensory':
        case 'tracking':
          if (utilsViewSize.width <= 48) {
            newSize.height = window.innerHeight - 20;
            if (window.innerWidth <= 500) newSize.width = window.innerWidth - 20;
            else newSize.width = window.innerWidth * 0.33;
            setUtilsViewSize(newSize);
          }
          break;
        default:
          if (utilsViewSize.width === 38 && utilsViewSize.height === 35) {
            newSize.height = 236;
          } else {
            newSize.height = 35;
          }
          setUtilsViewSize(newSize);
          break;
      }
    }, [view]);

    if (!isDevToolsEnabled) return <App />;

    return (
      <>
        <App />

        <div className="tools-wrapper">
          <div
            className={`panels-wrapper ${theme ? 'dark' : ''}`}
            role="presentation"
            onClick={event => {
              if (event.detail === 3) {
                return;
                // ! improve light theme
                // eslint-disable-next-line no-unreachable
                setTheme(current => !current);
              }
            }}
          >
            <Resizable
              ref={resizableElementRef}
              defaultSize={utilsViewSize}
              handleWrapperClass="handles"
              minHeight={48}
              minWidth={48}
              onResizeStop={(_e, _direction, _ref, d) => {
                setUtilsViewSize({
                  height: utilsViewSize.height + d.height,
                  width: utilsViewSize.width + d.width,
                });
              }}
            >
              <div className="content-wrapper">
                <div className="panel-views">
                  {view === 'service' && <ServicePanel />}
                  {view === 'locale' && <LocalePanel />}
                  {view === 'sensory' && <QuizPanel />}
                  {view === 'tracking' && <TrackingPanel />}
                </div>

                <div className="sidebar">
                  <ToggleButtonGroup exclusive orientation="vertical" value={view} onChange={handleChange}>
                    <ToggleButton aria-label="panels" value="panels">
                      <BuildIcon sx={{ color: '#eee' }} />
                    </ToggleButton>

                    <StyledTooltip placement="left" title="Service">
                      <ToggleButton
                        aria-label="service"
                        sx={{ '&.Mui-selected, &.Mui-selected:hover, &:hover': { background: '#0068ff' } }}
                        value="service"
                      >
                        <MiscellaneousServicesIcon sx={{ color: '#eee' }} />
                      </ToggleButton>
                    </StyledTooltip>

                    <StyledTooltip placement="left" title="Localization">
                      <ToggleButton
                        aria-label="locale"
                        sx={{ '&.Mui-selected, &.Mui-selected:hover, &:hover': { background: '#F97300' } }}
                        value="locale"
                      >
                        <SpellcheckIcon sx={{ color: '#eee' }} />
                      </ToggleButton>
                    </StyledTooltip>

                    <StyledTooltip placement="left" title="Sensory">
                      <ToggleButton
                        aria-label="sensory"
                        sx={{ '&.Mui-selected, &.Mui-selected:hover, &:hover': { background: '#FF204E' } }}
                        value="sensory"
                      >
                        <ScienceIcon sx={{ color: '#eee' }} />
                      </ToggleButton>
                    </StyledTooltip>

                    <StyledTooltip placement="left" title="Logging">
                      <ToggleButton
                        aria-label="tracking"
                        sx={{ '&.Mui-selected, &.Mui-selected:hover, &:hover': { background: '#4E9F3D' } }}
                        value="tracking"
                      >
                        <QueryStatsIcon sx={{ color: '#eee' }} />
                      </ToggleButton>
                    </StyledTooltip>
                  </ToggleButtonGroup>
                </div>
              </div>
            </Resizable>
          </div>
        </div>
      </>
    );
  }

  return DevToolsComponent;
};
