import classNames from 'classnames';
import { FC } from 'react';
import { Col, Container, Row } from 'reactstrap';

import { useApp } from '@lib/core/service/hooks';
import { localeTEREA } from '@lib/tools/locale/source/pmi/terea';
import LocaleFragment from '@lib/tools/locale/views/LocaleFragment';
import { useAddons, useRetailerDesignSet } from '@lib/tools/views/hooks';

const StaticBanner: FC = () => {
  const { isKeyVisualAddon, isKeyVisualSentiaAddon } = useAddons();

  const { isLayoutRightToLeft } = useApp();

  const { isDesignSetTasteAdvisorIQOS4Global } = useRetailerDesignSet();

  return (
    <Row className="mx-0 static-banner">
      <Col className="px-0 static-banner-container" xs={12}>
        {!isDesignSetTasteAdvisorIQOS4Global && (
          <Row className="static-translatable-sentence-1 ta-jp-p3-light ta-jp-mob-p7 mx-auto justify-content-center">
            <LocaleFragment message={localeTEREA.banner.sentenceOne} />
          </Row>
        )}
        <Row
          className={classNames('banner-wrapper', {
            isKeyVisualSentiaAddon,
          })}
        >
          <Container
            className={classNames('banner', {
              global: isDesignSetTasteAdvisorIQOS4Global && !isKeyVisualAddon,
              global_default: isDesignSetTasteAdvisorIQOS4Global && isKeyVisualAddon,
              japanese: isKeyVisualAddon,
              sentia: isKeyVisualSentiaAddon,
            })}
          >
            <div
              className={classNames('banner-title', {
                'rtl-orientation': isLayoutRightToLeft,
              })}
            >
              <div
                className={classNames('banner-text', {
                  'ta-jp-h2-2': isDesignSetTasteAdvisorIQOS4Global,
                  'ta-jp-h3-japan': !isDesignSetTasteAdvisorIQOS4Global,
                })}
              >
                <LocaleFragment message={localeTEREA.banner.titleOne} />
              </div>

              <div
                className={classNames('banner-text', {
                  'ta-jp-h3-japan': !isDesignSetTasteAdvisorIQOS4Global,
                  'ta-jp-p6-bold-2 banner-text-margin d-inline-block': isDesignSetTasteAdvisorIQOS4Global,
                })}
              >
                <LocaleFragment message={localeTEREA.banner.titleTwo} />
              </div>
            </div>
          </Container>
        </Row>
        <Row className="static-translatable-sentence-2 ta-jp-p3-light ta-jp-mob-p7 mx-auto justify-content-center">
          <LocaleFragment message={localeTEREA.banner.sentenceTwo} />
        </Row>
      </Col>
    </Row>
  );
};

export default StaticBanner;
