import { FC } from 'react';
import { useDispatch } from 'react-redux';
import { Col, Row } from 'reactstrap';

import { TCharacter } from '@lib/core/characters/types';
import { localeLegacy } from '@lib/tools/locale/source/pmi/legacy';
import LocaleFragment from '@lib/tools/locale/views/LocaleFragment';

import { navigateHome } from '@components/pmi/src/utils';

interface TobaccoCharacterDescriptionProps {
  character: TCharacter;
}

const TobaccoCharacterDescriptionComponent: FC<TobaccoCharacterDescriptionProps> = ({ character }) => {
  const dispatch = useDispatch();
  return (
    <Row className="mx-0 spec-resolution-taste-advisor-3">
      <Col className="tobacco-header-taste-advisor-3-container px-0" xs={12}>
        <div className="mx-0 taste-advisor-3-result-header">
          <button
            className="home-btn"
            type="button"
            onClick={() => {
              navigateHome(dispatch);
            }}
          />
        </div>
        <div className="text-justify">
          <img alt="header_image" src={character.attributes?.image_nbw} />
        </div>
        <Row className="text-center no-gutters general-m">
          <Col>
            <div className="ta-pmi3-h2_1 color-white bold px-2">
              <LocaleFragment message={localeLegacy.iqos3.resultCharacterText} />
              <p className="ta-pmi3-h1_2-bold color-white my-1 mb-md-2">{character?.name || 'character-name'}</p>
            </div>

            <div className="hide ta-pmi3-p1_2 color-white w-75 w-md-50 text-center mx-auto">
              {character?.character_caption || 'character_caption'}
            </div>
            <div className="hide ta-pmi3-p1_2 color-white w-75 w-md-50 text-center mx-auto description-m">
              {character?.character_description || 'character_description'}
            </div>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default TobaccoCharacterDescriptionComponent;
export { TobaccoCharacterDescriptionComponent as TobaccoCharacterDescriptionComponentWithoutMemo };
