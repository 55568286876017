import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { useRetailerLocation } from '@lib/core/retailers/hooks/retailerLocation';
import { updateRetailerLocationAddons } from '@lib/core/retailers/slices/retailerLocation';

export const AddOnsPanel = () => {
  const dispatch = useDispatch();

  const { addOns } = useRetailerLocation();

  const [selectedAddOns, setSelectedAddOn] = useState(addOns);
  const [originalAddOns, setOriginalAddOns] = useState([]);

  useEffect(() => {
    if (addOns.length && !originalAddOns.length) {
      setOriginalAddOns(addOns);
    }
  }, [addOns]);

  useEffect(() => {
    dispatch(updateRetailerLocationAddons(selectedAddOns));
  }, [selectedAddOns]);

  const listAddOns = [];

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleChangeAddOns = (name, isChecked) => {
    const currentAddOns = [...selectedAddOns];

    if (isChecked) {
      if (!currentAddOns.includes(name)) {
        setSelectedAddOn([...currentAddOns, name]);
      }
    } else if (currentAddOns.includes(name)) {
      currentAddOns.splice(currentAddOns.indexOf(name), 1);
      setSelectedAddOn(currentAddOns);
    }
  };

  originalAddOns.forEach(addOn => {
    const isChecked = selectedAddOns.includes(addOn);

    listAddOns.push(
      <div key={addOn}>
        <input
          checked={isChecked}
          className="mr-1"
          name={addOn}
          type="checkbox"
          onChange={e => {
            e.stopPropagation();
            handleChangeAddOns(e.target.name, !isChecked);
          }}
        />
        <span className="code">{addOn}</span>
      </div>,
    );
  });

  return (
    <>
      <div className="panel collapsible" role="presentation">
        Add Ons
      </div>

      {/* // ! Convert to multi-select list */}
      <div className="panel">{listAddOns}</div>
    </>
  );
};
