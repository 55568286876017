import { useSelector } from 'react-redux';

import { TProductCategory } from '@lib/core/products/types';
import {
  selectIsRetailerLocationLoading,
  selectIsRetailerLocationStoreTypeEcommerce,
  selectProductCategories,
  selectRetailerDesignSet,
  selectRetailerLocationAddOns,
  selectRetailerLocationCustomStyle,
  selectRetailerLocationId,
  selectRetailerLocationSlug,
  selectRetailerLocationStoreType,
} from '@lib/core/retailers/selectors/retailerLocation';
import { TRetailerLocationStoreType } from '@lib/core/retailers/types';
import { TDesignSetName } from '@lib/core/retailers/types/addons';
import { ITheme } from '@lib/tools/shared/helpers/interfaces';

type IRetailerLocationDetails = {
  isRetailerLocationStoreTypeEcommerce: boolean;
  addOns: string[];
  retailerDesign: TDesignSetName;
  retailerLocationId: string;
  retailerLocationSlug: string;
  retailerStoreType: TRetailerLocationStoreType;
  productCategories: TProductCategory[];
  customStyle: ITheme;
  isRetailerLocationLoading: boolean;
};

export const useRetailerLocation = (): IRetailerLocationDetails => {
  return {
    addOns: useSelector(selectRetailerLocationAddOns),
    customStyle: useSelector(selectRetailerLocationCustomStyle),
    isRetailerLocationLoading: useSelector(selectIsRetailerLocationLoading),
    isRetailerLocationStoreTypeEcommerce: useSelector(selectIsRetailerLocationStoreTypeEcommerce),
    productCategories: useSelector(selectProductCategories),
    retailerDesign: useSelector(selectRetailerDesignSet),
    retailerLocationId: useSelector(selectRetailerLocationId),
    retailerLocationSlug: useSelector(selectRetailerLocationSlug),
    retailerStoreType: useSelector(selectRetailerLocationStoreType),
  };
};
