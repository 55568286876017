import { FC } from 'react';
import { connect } from 'react-redux';

import { selectRetailerLanguages } from '@lib/core/retailers/selectors/retailer';
import { localeLegacy } from '@lib/tools/locale/source/pmi/legacy';
import LocaleFragment from '@lib/tools/locale/views/LocaleFragment';

import LanguageSwitcherComponent from '@components/pmi/src/common/LanguageSwitcher';
import { checkCurrentPrimaryColor } from '@components/pmi/src/utils';

interface Props {
  handleClose: () => void;
  supportedLanguages: {
    code: string;
    language: string;
  }[];
}

const HealthWarningPrePage: FC<Props> = ({ handleClose, supportedLanguages }) => {
  const currentPrimaryColor = checkCurrentPrimaryColor();
  const warning = (
    <LocaleFragment message={localeLegacy.healthWarning.retailerPrepageHealthWarning} options={{ jsx: 'true' }} />
  );

  return (
    <div className="health-warning-prepage">
      <div className="health-warning-prepage-header" />

      <div className="health-warning-prepage-body">
        <LanguageSwitcherComponent
          isTA4OneDS
          className="health-warning-language-switcher-wrapper"
          supportedLanguages={[...supportedLanguages].reverse()}
        />

        <div className="health-warning">{warning}</div>

        <button
          className="agree-button"
          style={{ backgroundColor: currentPrimaryColor }}
          type="button"
          onClick={handleClose}
        >
          <LocaleFragment message={localeLegacy.healthWarning.retailerPrepageAgreeBtnText} />
        </button>
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  supportedLanguages: selectRetailerLanguages(state),
});

export default connect(mapStateToProps, null)(HealthWarningPrePage);
