import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { retailerLocationDataApiUrlCreator } from '@lib/core/retailers/slices/urls';
import { IRetailerLocation } from '@lib/core/retailers/types/retailerLocation';
import { isRetailerLocationDefault, isRetailerLocationKiosk } from '@lib/core/retailers/utils/filters';
import { isApplicationKiosk, isApplicationPmi } from '@lib/core/service/consts';
import request from '@lib/core/service/requests/request';
import { DESIGN_SET_TASTE_ADVISOR_V2 } from '@lib/tools/shared/pmi/retailers/consts';

export interface IRetailerLocationState {
  isLoading: boolean;
  data: IRetailerLocation;
  list: IRetailerLocation[];
}
const initialState: IRetailerLocationState = {
  data: undefined,
  isLoading: false,
  list: [],
};

export const actionGetRetailerLocations = createAsyncThunk(
  'retailerLocations/get',
  async (options: { retailerSlug: string; retailerLocationID?: string }) => {
    const response = await request(retailerLocationDataApiUrlCreator(options));
    const isKiosk = isApplicationKiosk;
    const retailerLocation = response.length
      ? response.find(isKiosk ? isRetailerLocationKiosk : isRetailerLocationDefault)
      : response;

    const {
      product_categories: productCategoriesRaw,
      design_sets: designSetsRaw,
      addons: addonsRaw,
    } = retailerLocation;

    const productCategories = [];
    productCategoriesRaw.forEach(productCategory => productCategories.push(productCategory.name));
    const addons = [];
    addonsRaw.forEach(addon => addons.push(addon.name));

    const preparedData = retailerLocation;
    if (preparedData.design_sets?.length) {
      const assignedDesignSets = [];
      const allowedDesignSets = {};
      let currentAssignedDesignSets = [];

      designSetsRaw.forEach(designSet => {
        const designSetProductCategories = designSet.product_categories;
        designSetProductCategories.forEach(product => {
          assignedDesignSets.push({ [product.name]: designSet.name });
        });
      });
      let currentDesignSet = [];
      let productDesignSets = [];

      if (isApplicationPmi) {
        productCategories.forEach(product => {
          productDesignSets = assignedDesignSets.filter(ds => Object.keys(ds)[0] === product);
          if (productDesignSets.length > 1) {
            const isLegacyDesignSet = productDesignSet => productDesignSet[product] !== DESIGN_SET_TASTE_ADVISOR_V2;
            currentDesignSet = productDesignSets.filter(isLegacyDesignSet);
          } else {
            currentDesignSet = productDesignSets;
          }
          currentAssignedDesignSets.push(...currentDesignSet);
        });
      } else {
        currentAssignedDesignSets = assignedDesignSets;
      }

      currentAssignedDesignSets.forEach(designSet => {
        productCategories.forEach(product => {
          if (designSet[product]) allowedDesignSets[product] = designSet[product];
        });
      });

      preparedData.design_sets = allowedDesignSets;
      preparedData.product_categories = productCategories;
      preparedData.addons = addons;
    } else {
      return Promise.reject();
    }

    return {
      retailerLocation,
      retailerLocationsList: response.length ? response : null,
    };
  },
);

export const retailerLocationSlice = createSlice({
  extraReducers: builder => {
    builder.addCase(actionGetRetailerLocations.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(
      actionGetRetailerLocations.fulfilled,
      (
        state,
        action: PayloadAction<{ retailerLocation: IRetailerLocation; retailerLocationsList: IRetailerLocation[] }>,
      ) => {
        const {
          payload: { retailerLocation, retailerLocationsList },
        } = action;
        state.isLoading = false;
        state.data = state.data || retailerLocation;
        state.list = retailerLocationsList || state.list;
      },
    );
    builder.addCase(actionGetRetailerLocations.rejected, state => {
      state.isLoading = false;
    });
  },
  initialState,
  name: 'retailerLocation',
  reducers: {
    updateRetailerLocationAddons: (state, { payload }) => {
      if (state.data) state.data.addons = payload;
    },
  },
});

export const { updateRetailerLocationAddons } = retailerLocationSlice.actions;
export default retailerLocationSlice.reducer;
