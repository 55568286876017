import classNames from 'classnames';
import React, { FC } from 'react';
import { Container, Row } from 'reactstrap';

import { useApp } from '@lib/core/service/hooks';
import { localeLegacy } from '@lib/tools/locale/source/pmi/legacy';
import LocaleFragment from '@lib/tools/locale/views/LocaleFragment';

import { isOpenIntegration } from '@components/pmi/src/utils';

interface Props {
  isIQOS3DesignSet?: boolean;
}

const InvalidVerificationLinkGate: FC<Props> = ({ isIQOS3DesignSet = false }): JSX.Element => {
  const { isLayoutRightToLeft } = useApp();
  return (
    <Container className="tasteAdvisor-gate">
      <Row className="position-absolute w-100vw h-100vh top-0px left-0px">
        <div className={classNames('w-100 h-100 bg-color-iqos3-cta')} />
        <div className="content position-absolute max-width-600px">
          <Row
            className={classNames('text-center', 'text-white', {
              'letter-spacing-zero': isLayoutRightToLeft,
              'ta-pmi3-p1_2-bold': isIQOS3DesignSet,
              'text-uppercase taste-advisor-h4': !isIQOS3DesignSet,
            })}
          >
            <p className="mx-auto mb-1">
              <LocaleFragment message={localeLegacy.remoteAccessBlock.title} />
            </p>
            <p className="mb-0">
              {isOpenIntegration() ? (
                <LocaleFragment message={localeLegacy.remoteAccessBlock.title} />
              ) : (
                <LocaleFragment message={localeLegacy.remoteAccessBlock.text} />
              )}
            </p>
          </Row>
        </div>
      </Row>
    </Container>
  );
};

export { InvalidVerificationLinkGate as InvalidVerificationLinkGateWithoutMemo };
export default React.memo(InvalidVerificationLinkGate);
