import classNames from 'classnames';
import { FC } from 'react';
import { Row } from 'reactstrap';

import { TProductAroma } from '@lib/core/products/types';
import { localeTEREA } from '@lib/tools/locale/source/pmi/terea';
import LocaleFragment from '@lib/tools/locale/views/LocaleFragment';
import { SENTIA } from '@lib/tools/shared/pmi/retailers/consts';
import { useAddons } from '@lib/tools/views/hooks';

type Props = {
  aroma: TProductAroma;
  isPearl: string;
  pistilImage: string;
  aromaColor: string;
  isLayoutRightToLeft?: boolean;
  type?: string | number;
  isThreeProducts?: boolean;
};

const AromaNote: FC<Props> = ({
  isThreeProducts = false,
  type,
  isLayoutRightToLeft,
  isPearl,
  pistilImage,
  aroma,
  aromaColor,
}) => {
  const { isAromaNoteSecondaryAddon } = useAddons();

  return (
    <Row
      className={classNames('aroma-note-info-wrapper', {
        invisible: type === SENTIA,
        'mx-0': isThreeProducts,
        'padding-x-15px': !isThreeProducts,
        'rtl-orientation': isLayoutRightToLeft,
      })}
    >
      <div className="aroma-note-icon-wrapper">
        <img alt="" src={isAromaNoteSecondaryAddon && aroma.secondary_image ? aroma.secondary_image : aroma.image} />
      </div>
      <div className="d-flex flex-column justify-content-center">
        <span className="ta-jp-p4_2_2 text-nowrap color-white">
          {isPearl === 'true' || pistilImage ? (
            <LocaleFragment message={localeTEREA.product.onAClick} />
          ) : (
            <LocaleFragment message={localeTEREA.product.aromaNote} />
          )}
        </span>
        <span className="ta-jp-p4_2_2" style={{ color: aromaColor }}>
          {aroma.description}
        </span>
      </div>
    </Row>
  );
};

export default AromaNote;
