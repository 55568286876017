import { useSelector } from 'react-redux';

import { actionResetProductFeedback } from '@lib/core/products/slices/feedback';
import { useQuiz } from '@lib/core/quizzes/hooks';
import {
  selectQuizViewAnswerTags,
  selectQuizViewDepth,
  selectQuizViewIsBackward,
  selectQuizViewIsCompleted,
  selectQuizViewIsPaused,
  selectQuizViewIsPostNewUserQuiz,
  selectQuizViewPausedQuestionId,
  selectQuizViewPausedRoute,
  selectQuizViewPreviousQuestionId,
  selectQuizViewQuestionData,
  selectQuizViewQuestionDepths,
  selectQuizViewQuestionId,
  selectQuizViewUserPath,
} from '@lib/core/quizzes/selectors';
import { actionPatchUserQuizComplete, actionPatchUserQuizSubmitAnswer } from '@lib/core/quizzes/slices';
import { actionTraverseQuizViewData } from '@lib/core/quizzes/slices/quizView';
import { TQuizAnswerTag, TQuizQuestion } from '@lib/core/quizzes/types';
import { TQuizViewUserPath } from '@lib/core/quizzes/types/quizView';
import { useTypedDispatch } from '@lib/tools/views/hooks/useTypedDispatch';

type IQuizView = {
  handleQuizViewGoBack: () => void;
  handleQuizViewSubmitAnswer: (answerIds: string[]) => void;

  isQuizViewBackward: boolean;
  isQuizViewCompleted: boolean;
  isQuizViewPaused: boolean;
  isQuizViewPostNewUserQuiz: boolean;

  quizViewAnswerTags: TQuizAnswerTag[];
  quizViewData: TQuizQuestion;
  quizViewDepth: number;
  quizViewPausedQuestionId: string;
  quizViewPreviousQuestionId: string;
  quizViewQuestionDepths: Record<string, number>;
  quizViewQuestionId: string;
  quizViewUserPath: TQuizViewUserPath;
  quizViewPausedRoute: string;
};

export const useQuizView = (): IQuizView => {
  const dispatch = useTypedDispatch();

  const { quizPath } = useQuiz();
  const quizViewQuestionId = useSelector(selectQuizViewQuestionId);

  /**
   * ! Send string[] for answerIds from all views.
   */
  const handleQuizViewSubmitAnswer = (answerIds: string[]) => {
    const nextQuestionId =
      quizPath[quizViewQuestionId]?.answers[Array.isArray(answerIds) ? answerIds[0] : answerIds]?.question_pool?.[0];

    dispatch(actionTraverseQuizViewData({ answerIds, steps: 1 }));
    dispatch(actionPatchUserQuizSubmitAnswer({ answerIds, questionId: quizViewQuestionId })).then(() => {
      if (nextQuestionId) {
        // Clear bundles/best match feedback
        dispatch(actionResetProductFeedback());
      } else {
        dispatch(actionPatchUserQuizComplete());
      }
    });
  };

  // ? Improve with consistent counts from views
  const handleQuizViewGoBack = (count = 1) => {
    if (typeof count === 'number') {
      dispatch(actionTraverseQuizViewData({ steps: -count }));
    } else {
      dispatch(actionTraverseQuizViewData({ steps: -1 }));
    }
  };

  return {
    // Handlers
    handleQuizViewGoBack,
    handleQuizViewSubmitAnswer,

    // Booleans
    isQuizViewBackward: useSelector(selectQuizViewIsBackward),
    isQuizViewCompleted: useSelector(selectQuizViewIsCompleted),
    isQuizViewPaused: useSelector(selectQuizViewIsPaused),
    isQuizViewPostNewUserQuiz: useSelector(selectQuizViewIsPostNewUserQuiz),

    quizViewAnswerTags: useSelector(selectQuizViewAnswerTags),
    quizViewData: useSelector(selectQuizViewQuestionData),
    quizViewDepth: useSelector(selectQuizViewDepth),
    quizViewPausedQuestionId: useSelector(selectQuizViewPausedQuestionId),
    quizViewPausedRoute: useSelector(selectQuizViewPausedRoute),
    quizViewPreviousQuestionId: useSelector(selectQuizViewPreviousQuestionId),
    quizViewQuestionDepths: useSelector(selectQuizViewQuestionDepths),
    quizViewQuestionId: useSelector(selectQuizViewQuestionId),
    quizViewUserPath: useSelector(selectQuizViewUserPath),
  };
};
