import classNames from 'classnames';
import { Dispatch, FC, SetStateAction, useCallback, useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';

import { ReactComponent as ArrowIcon } from '@app/pmi/src/assets/media/caret.svg';

import { localeLegacy } from '@lib/tools/locale/source/pmi/legacy';
import LocaleFragment from '@lib/tools/locale/views/LocaleFragment';

interface NumberOfUsesDropdownProps {
  number: { value: number; label: string };
  setNumber: Dispatch<SetStateAction<{ value: number; label: string }>>;
}

const NumberOfUsesDropdown: FC<NumberOfUsesDropdownProps> = ({ number, setNumber }) => {
  const [isVisible, setIsVisible] = useState(false);

  const periodArr = [
    { label: '1', value: 1 },
    { label: '2', value: 2 },
    { label: '5', value: 3 },
    { label: '10', value: 4 },
    { label: '25', value: 5 },
    { label: '50', value: 6 },
    { label: '100', value: 7 },
    { label: '500', value: 8 },
    { label: '1000', value: 9 },
    { label: '10000', value: 10 },
    { label: '100000', value: 11 },
  ];

  const isMobileResolution = useMediaQuery({ maxHeight: 540, maxWidth: 767 });
  const isTabletOrDesctopResolution = useMediaQuery({ maxHeight: 780, minWidth: 768 });

  useEffect(() => {
    if (isVisible) {
      if (isMobileResolution) {
        document.querySelector<HTMLElement>('.access-key-generator-wrapper-nbw').style.height = '540px';
      }
      if (isTabletOrDesctopResolution) {
        document.querySelector<HTMLElement>('.access-key-generator-wrapper-nbw').style.height = '780px';
      }
    }
  }, [isVisible, isMobileResolution, isTabletOrDesctopResolution]);

  const choseNumber = useCallback(item => {
    setNumber(item);
    setIsVisible(false);
  }, []);

  return (
    <div className="dropdown-select">
      <button className="dropdown-toggle-select text-left" type="submit" onClick={() => setIsVisible(!isVisible)}>
        {number.label}
        <ArrowIcon
          className={classNames('toggle-icon-select', {
            open: isVisible,
          })}
        />
      </button>
      <div
        className={classNames('dropdown-menu-select', {
          open: isVisible,
        })}
      >
        {periodArr.map(item => (
          <button
            key={item.value}
            className="dropdown-menu-select-btn"
            type="button"
            value={item.value}
            onClick={() => {
              choseNumber(item);
            }}
            onKeyDown={() => {
              choseNumber(item);
            }}
          >
            <p className="mb-0">{item.label}</p>
          </button>
        ))}
      </div>
    </div>
  );
};

interface Props {
  retriesValue: string;
  selectedNumberOfUses: { value: number; label: string };
  setSelectedNumberOfUses: Dispatch<SetStateAction<{ value: number; label: string }>>;
}
const Uses: FC<Props> = ({ retriesValue, selectedNumberOfUses, setSelectedNumberOfUses }) => (
  <div className="d-flex flex-column">
    <span className="text-description margin-bottom-5px">
      <LocaleFragment message={localeLegacy.accessKeyGenerator.numberOfUses} />: &nbsp;
    </span>
    <span>
      {retriesValue || <NumberOfUsesDropdown number={selectedNumberOfUses} setNumber={setSelectedNumberOfUses} />}
    </span>
  </div>
);

export default Uses;
