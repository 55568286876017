import { defineMessages } from '@formatjs/intl';

export const localeLegacy = {
  accessKeyGenerator: defineMessages({
    copyToClipboard: {
      defaultMessage: 'Copy to clipboard',
      id: 'taste_advisor.access_key_generator.copy_to_clipboard',
    },
    expirationPeriod: {
      defaultMessage: 'expiration period',
      id: 'taste_advisor.access_key_generator.expiration_period',
    },
    fromTheMoment: {
      defaultMessage: 'From the moment the link is generated',
      id: 'taste_advisor.access_key_generator.from_the_moment',
    },
    generateLink: {
      defaultMessage: 'generate link',
      id: 'taste_advisor.access_key_generator.generate_link',
    },
    header: {
      defaultMessage: 'Links generator',
      id: 'taste_advisor.access_key_generator.header',
    },
    hours: { defaultMessage: 'Hours', id: 'taste_advisor.access_key_generator.hours' },
    month: { defaultMessage: 'month', id: 'taste_advisor.access_key_generator.month' },
    months: { defaultMessage: 'months', id: 'taste_advisor.access_key_generator.months' },
    numberOfUses: {
      defaultMessage: 'Number of uses',
      id: 'taste_advisor.access_key_generator.number_of_uses',
    },
    week: { defaultMessage: 'week', id: 'taste_advisor.access_key_generator.week' },
    weeks: { defaultMessage: 'weeks', id: 'taste_advisor.access_key_generator.weeks' },
    year: { defaultMessage: 'year', id: 'taste_advisor.access_key_generator.year' },
  }),
  ageGate: defineMessages({
    accessBlocked: {
      defaultMessage: 'You must be { ageLimit } or over to access materials on this site.',
      id: 'taste_advisor_age_gate.not_available',
    },
    april: { defaultMessage: 'April', id: 'taste_advisor_age_gate.month_april' },
    august: { defaultMessage: 'August', id: 'taste_advisor_age_gate.month_august' },
    confirmBtn: {
      defaultMessage: 'Confirm',
      id: 'taste_advisor_age_gate.Confirm-btn',
    },
    december: { defaultMessage: 'December', id: 'taste_advisor_age_gate.month_december' },
    descriptionOne: {
      defaultMessage: 'Customer Support Center - LiveChat on iqos.ro, daily from 8:00 to 22:00',
      id: 'taste_advisor_age_gate.description_1',
    },
    descriptionTwo: {
      defaultMessage: 'Copyright 2020 - All rights reserved',
      id: 'taste_advisor_age_gate.description_2',
    },
    february: { defaultMessage: 'February', id: 'taste_advisor_age_gate.month_february' },
    january: { defaultMessage: 'January', id: 'taste_advisor_age_gate.month_january' },
    july: { defaultMessage: 'July', id: 'taste_advisor_age_gate.month_july' },
    june: { defaultMessage: 'June', id: 'taste_advisor_age_gate.month_june' },
    march: { defaultMessage: 'March', id: 'taste_advisor_age_gate.month_march' },
    may: { defaultMessage: 'May', id: 'taste_advisor_age_gate.month_may' },
    month: { defaultMessage: 'Month', id: 'age_gate.month' },
    november: { defaultMessage: 'November', id: 'taste_advisor_age_gate.month_november' },
    october: { defaultMessage: 'October', id: 'taste_advisor_age_gate.month_october' },
    september: { defaultMessage: 'September', id: 'taste_advisor_age_gate.month_september' },
    titleOne: {
      defaultMessage:
        'Are you { ageLimit } years old or older and a smoker or user of other products containing nicotine?',
      id: 'taste_advisor_age_gate.title_1',
    },
    titleTwo: {
      defaultMessage: 'Insert your date of birth',
      id: 'taste_advisor_age_gate.title_2',
    },
    year: { defaultMessage: 'Year', id: 'age_gate.year' },
  }),
  blends: defineMessages({
    healthWarning1: {
      defaultMessage: 'This product is not risk free and provides nicotine, which is addictive.',
      id: 'taste-advisor.blends.add_on.health_warning_1',
    },
    healthWarning2: {
      defaultMessage: 'Only for use by adults.',
      id: 'taste-advisor.blends.add_on.health_warning_2',
    },
    landingCta: {
      defaultMessage: "Let's begin",
      id: 'taste-advisor.blends.landing_page.cta',
    },
    landingDisclaimer1: {
      defaultMessage:
        'Flavour quiz helps us to understand your taste preferences in general, to make further recommendations on BLENDS flavours. It does not imply that any products mentioned in the quiz are linked to BLENDS flavours.',
      id: 'taste-advisor.blends.landing_page.disclaimer1',
    },
    landingDisclaimer2: {
      defaultMessage: 'Your data will be processed in accordance to the',
      id: 'taste-advisor.blends.landing_page.disclaimer3',
    },
    landingDisclaimerLink: {
      defaultMessage: 'PMI privacy policy',
      id: 'taste-advisor.blends.landing_page.disclaimer-link',
    },
    landingTitle1: {
      defaultMessage: 'Authentic delights within',
      id: 'taste-advisor.blends.landing_page.title1',
    },
    landingTitle2: {
      defaultMessage: 'your reach',
      id: 'taste-advisor.blends.landing_page.title2',
    },
    quizPickOneAnswer: {
      defaultMessage: 'Pick one answer',
      id: 'taste-advisor.blends.quiz_page.pick_one_answer',
    },
    resultAromaNote: {
      defaultMessage: 'Aroma Note:',
      id: 'taste-advisor.blends.result_page.aroma_note',
    },
    resultImageDisclaimer: {
      defaultMessage: 'product image might differ from original',
      id: 'taste-advisor.blends.result_page.image_disclaimer',
    },
    resultOnClick: {
      defaultMessage: 'On a click:',
      id: 'taste-advisor.blends.result_page.on_click',
    },
    resultStartOver: {
      defaultMessage: 'Start over',
      id: 'taste-advisor.blends.result_page.start_over',
    },
    resultTobaccoExperience: {
      defaultMessage: 'tobacco experience',
      id: 'taste-advisor.blends.result_page.tobacco_experience',
    },
    resultTobaccoExperienceWithCapsule: {
      defaultMessage: 'tobacco experience with a capsule',
      id: 'taste-advisor.blends.result_page.tobacco_experience_with_a_capsule',
    },
  }),
  common: defineMessages({
    disableRetailerPageText1: {
      defaultMessage: 'The page you are trying to reach is no longer active.',
      id: 'taste-advisor.disable-retailer-page.text_1',
    },
    disableRetailerPageText2: {
      defaultMessage: "To Access the Flavour Advisor tool, please go to your country's IQOS website.",
      id: 'taste-advisor.disable-retailer-page.text_2',
    },
  }),
  disclaimer: defineMessages({
    healthWarning: {
      defaultMessage: 'This product is not risk free and is addictive.',
      id: 'taste-advisor.disclaimer.health_warning',
    },
    privacyHyperLink: {
      defaultMessage: 'PMI privacy policy',
      id: 'taste-advisor.disclaimer.pmi_privacy_hyperlink',
    },
    privacyTextOne: {
      defaultMessage: 'Your data will be processed in accordance to the',
      id: 'taste-advisor.disclaimer.privacy_part_one',
    },
    surveyInfo: {
      defaultMessage:
        'This survey serves as product knowledge during guided trial and is only intended for adult tobacco users / smokers over the age of 18. Participation in this survey is voluntary.',
      id: 'taste-advisor.disclaimer.survey_info',
    },
  }),
  fiit: defineMessages({
    brandColorsText: {
      defaultMessage: 'FIIT Brand Colors',
      id: 'taste-advisor.brand-colors.text',
    },
    characterText: {
      defaultMessage: 'Your taste character is',
      id: 'taste-advisor.fiit.resultPage.your_character_is',
    },
    healthWarningFooter: {
      defaultMessage:
        'This product is not risk free and provides nicotine, which is addictive. Only for use by adults.',
      id: 'taste_advisor.fiit.retailer_page.health_warning_footer',
    },
    productColorsText: {
      defaultMessage: 'FIIT Product Colors',
      id: 'taste-advisor.product-colors.text',
    },
    quizNextText: {
      defaultMessage: 'Next',
      id: 'vinhood.taste-advisor.fiit.quiz-page.quiz-next-text',
    },
    quizSkipText: {
      defaultMessage: 'I am not able to answer',
      id: 'vinhood.taste-advisor.fiit.quiz-page.quiz-skip-text',
    },
    recommendedTobaccoExperience: {
      defaultMessage: 'tobacco experience',
      id: 'taste-advisor.fiit.recommended_product.tobacco_experience',
    },
    resultDislike: {
      defaultMessage: 'No',
      id: 'taste_advisor.fiit.result_page.dislike',
    },
    resultDragToSelect: {
      defaultMessage: 'Drag to select',
      id: 'taste_advisor.fiit.result_page.drag_to_select',
    },
    resultFeedbackQuestion: {
      defaultMessage: 'Is this variant a good match?',
      id: 'taste-advisor.fiit_result_page.feedback_question',
    },
    resultLike: {
      defaultMessage: 'Yes',
      id: 'taste_advisor.fiit.result_page.like',
    },
    resultSeeAllFlavors: {
      defaultMessage: 'See all flavors',
      id: 'taste_advisor.fiit.result_page.see_all_flavors',
    },
    resultTobaccoExperience: {
      defaultMessage: 'tobacco experience',
      id: 'taste_advisor.fiit.result_page.tobacco_experience',
    },
  }),
  geoBlock: defineMessages({
    title1: {
      defaultMessage: 'We are sorry.',
      id: 'taste_advisor_geoblock.geoblock_title1',
    },
    title2: {
      defaultMessage: 'Please note this website is intended for another',
      id: 'taste_advisor_geoblock.geoblock_title2',
    },
    title3: {
      defaultMessage: 'Country. In order to ensure compliance with local',
      id: 'taste_advisor_geoblock.geoblock_title3',
    },
    title4: {
      defaultMessage: 'legal requirements, you are not allowed to access it.',
      id: 'taste_advisor_geoblock.geoblock_title4',
    },
  }),
  healthWarning: defineMessages({
    iqos3HealthWarning: {
      defaultMessage:
        'This product is not risk free and provides nicotine, which is addictive. Only for use by adults.',
      id: 'taste-advisor.iqos3.health_warning_footer',
    },
    iqos3HealthWarningFooterToIsraelWarning: {
      defaultMessage: 'WARNING:',
      id: 'taste-advisor.iqos3.health_warning_footer_to_israel_warning',
    },
    iqos3HealthWarningFooterToIsraelWarningText: {
      defaultMessage: 'Medical researches have determined that cigarettes cause addictions',
      id: 'taste-advisor.iqos3.health_warning_footer_to_israel_warning_text',
    },
    landingBottomBlack: {
      defaultMessage: 'Tobacco products damage your health and are addictive.',
      id: 'taste-advisor.health_warning_landing_bottom_black',
    },
    retailerPageMultilingualOne: {
      defaultMessage: 'Dieses Tabakerzeugnis kann Ihre Gesundheit schädigen und macht abhängig.',
      id: 'taste-advisor.retailer_page.health_warning_multilingual.one',
    },
    retailerPageMultilingualThree: {
      defaultMessage: 'Questo prodotto del tabacco può nuocere alla tua salute e provoca dipendenza.',
      id: 'taste-advisor.retailer_page.health_warning_multilingual.three',
    },
    retailerPageMultilingualTwo: {
      defaultMessage: 'Ce produit du tabac peut nuire à votre santé et crée une dépendance.',
      id: 'taste-advisor.retailer_page.health_warning_multilingual.two',
    },
    retailerPageWarningOne: {
      defaultMessage: 'Your data will be processed in accordance to the',
      id: 'taste-advisor.retailer-page.warning_footer.part_one',
    },
    retailerPageWarningThree: {
      defaultMessage: 'Philip Morris Switzerland Sàrl',
      id: 'taste-advisor.retailer-page.warning_footer.part_three',
    },
    retailerPageWarningTwo: {
      defaultMessage: 'of',
      id: 'taste-advisor.retailer-page.warning_footer.part_two',
    },
    retailerPrepageAgreeBtnText: {
      defaultMessage: 'Agree',
      id: 'taste-advisor.retailer-prepage.agree-button',
    },
    retailerPrepageHealthWarning: {
      defaultMessage: `Smoking by persons under twenty years of age is prohibited by law.
        &nbsp; \n
        Adverse effect on the health of surrounding people cannot be denied for smoke (vapor) from
        Heated Tobacco Products. Smoking is prohibited in non-smoking areas under the Health Promotion Act.
        \n
        Adverse effect on your health cannot be denied for smoke (vapor) from Heated Tobacco Products which
        includes carcinogen and addictive nicotine.`,
      id: 'taste-advisor.retailer-prepage.health-warning',
    },
  }),
  heets: defineMessages({
    disclaimerHealthWarning: {
      defaultMessage: 'This product is not risk free and is addictive.',
      id: 'taste-advisor.disclaimer.health_warning',
    },
    disclaimerPrivacyHyperLink: {
      defaultMessage: 'PMI privacy policy',
      id: 'taste-advisor.disclaimer.pmi_privacy_hyperlink',
    },
    disclaimerPrivacyOne: {
      defaultMessage: 'Your data will be processed in accordance to the',
      id: 'taste-advisor.disclaimer.privacy_part_one',
    },
    disclaimerSurveyInfo: {
      defaultMessage:
        'This survey serves as product knowledge during guided trial and is only intended for adult tobacco users / smokers over the age of 18. Participation in this survey is voluntary.',
      id: 'taste-advisor.disclaimer.survey_info',
    },
    healthWarningFooter: {
      defaultMessage: 'Danger: Tobacco is addictive',
      id: 'taste-advisor.health_warning_footer',
    },
    portfolioSeeAllFlavors: {
      defaultMessage: 'See all flavors',
      id: 'taste_advisor.taste-advisor-3.product_portfolio.see_all_flavors',
    },
    quizPickAnswer: {
      defaultMessage: 'Pick one answer',
      id: 'taste-advisor_answer_test_qds3.pick_one_answer',
    },
    resultDiscoverTerea: {
      defaultMessage: 'Discover your recommended TEREA',
      id: 'taste_advisor.result_page.discover_terea',
    },
    resultRecommendation: {
      defaultMessage: 'This is our recommendation based on the results of the test',
      id: 'taste-advisor.result-page.recommendation',
    },
  }),
  iqos3: defineMessages({
    coachesFindHeets: {
      defaultMessage: 'Find the HEETS variant that suits your adult smoker who has chosen to switch to IQOS.',
      id: 'taste-advisor_retailer_page.coaches_find_the_heets',
    },
    coachesText: {
      defaultMessage: 'Find out their taste profile with this simple quiz. It is fast and easy.',
      id: 'taste-advisor_retailer_page.coaches_main_text',
    },
    creationsDescription: {
      defaultMessage:
        'Explore also HEETS Creations, created in collaboration with Juan Amador, a 3-Michelin Star Chef, and Rene Soffner, an award-winning mixologist.',
      id: 'taste_advisor.creations.description',
    },
    discover: {
      defaultMessage: 'Discover your personal taste character using our flavor quiz and find your tobacco blend.',
      id: 'taste-advisor.retailer-page.discover',
    },
    findFlavors: {
      defaultMessage: 'Find your matching flavor.',
      id: 'taste-advisor.retailer-page.find_flavors',
    },
    letsBegin: {
      defaultMessage: "Let's begin",
      id: 'taste-advisor.retailer-page.lets_begin',
    },
    longDescriptionText1: {
      defaultMessage:
        "Discover your personal taste profile with our quick and simple survey and help us find the flavor for you. It's quick and easy. Follow your intuition when answering.",
      id: 'taste-advisor_retailer_page.long_description_main_text_1',
    },
    longDescriptionText2: {
      defaultMessage: 'The results of this survey might help you to make a right choice.',
      id: 'taste-advisor_retailer_page.long_description_main_text_2',
    },
    portfolioAromaNote: {
      defaultMessage: 'Aroma note',
      id: 'taste_advisor.iqos3.product_portfolio_card.aroma_note',
    },
    portfolioOnClick: {
      defaultMessage: 'On a click',
      id: 'taste_advisor.iqos3.product_portfolio_card.on_click',
    },
    quizAnswerNext: {
      defaultMessage: 'Next',
      id: 'taste-advisor-qds3_answer_test.next',
    },
    resultAromaNote: {
      defaultMessage: 'Aroma Note:',
      id: 'taste-advisor-iqos3_result_page.aroma_note',
    },
    resultCharacterText: {
      defaultMessage: 'Your taste character is',
      id: 'taste-advisor-iqos3_result_page.your_character_is',
    },
    resultClickProductOff: {
      defaultMessage: 'Slide to see post-click values',
      id: 'taste-taste-advisor_result_test.click_product.off',
    },
    resultClickProductOn: {
      defaultMessage: 'Slide to see pre-click values',
      id: 'taste-advisor_result_test.click_product.on',
    },
    resultDislike: {
      defaultMessage: 'No',
      id: 'taste-advisor-iqos3_result_page.dislike',
    },
    resultFeedbackQuestion: {
      defaultMessage: 'Is this variant a good match?',
      id: 'taste-advisor-iqos3_result_page.feedback_question',
    },
    resultHeetsMatch: {
      defaultMessage: 'Your HEETS match',
      id: 'taste-advisor-iqos3_result_page.your_heets_match',
    },
    resultImageDisclaimer: {
      defaultMessage: 'product image might differ from original',
      id: 'taste-advisor.result-page.image_disclaimer',
    },
    resultLike: {
      defaultMessage: 'Yes',
      id: 'taste-advisor-iqos3_result_page.like',
    },
    resultOnClick: {
      defaultMessage: 'On a click:',
      id: 'taste-advisor-iqos3_result_page.on_click',
    },
    resultTestDisclaimer: {
      defaultMessage:
        '* Based on your responses we were able to identify HEETS that may match your taste preferences. However liking is not guaranteed.',
      id: 'taste-advisor_result_test.disclaimer',
    },
    resultTestStartOver: {
      defaultMessage: 'Start over',
      id: 'taste-advisor-iqos3_result_page_test.start_over',
    },
    resultTobaccoExperience: {
      defaultMessage: 'tobacco experience',
      id: 'taste-advisor.iqos3.result-page.tobacco_experience',
    },
    resultTobaccoExperienceWithCapsule: {
      defaultMessage: 'tobacco experience with a capsule',
      id: 'taste-advisor.iqos3.result-page.tobacco_experience_with_a_capsule',
    },
  }),
  remoteAccessBlock: defineMessages({
    openIntegration: {
      defaultMessage:
        'The expiration time for the page has passed.Please reload the experience using the "start over" button above to refresh.',
      id: 'ta.remote_access_block.text.open_integration',
    },
    text: {
      defaultMessage:
        'The link to the flavor advisor experience you tried to access has expired. Please contact IQOS customer care to request a new link.',
      id: 'ta.remote_access_block.text',
    },
    title: {
      defaultMessage: 'We are sorry.',
      id: 'ta.remote_access_block.title',
    },
  }),
  terea: defineMessages({
    quizAnswerNext: {
      defaultMessage: 'Next',
      id: 'taste-advisor_answer_test.next',
    },
    quizPickOneAnswer: {
      defaultMessage: 'previous',
      id: 'taste-advisor.previous-btn.text',
    },
  }),
};
