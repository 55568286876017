import { TQuiz, TQuizAnswerTag, TQuizProductCategory } from '@lib/core/quizzes/types';
import {
  FILTER_CHARACTERS_TAG_CONTEXT,
  FILTER_PRODUCT_PREFERENCES_TAG_CONTEXT,
  FILTER_REDIRECT_ROUTE_TAG_CONTEXT,
  PRODUCT_CATEGORY_TAG_CONTEXT,
} from '@lib/core/quizzes/utils/consts';
import { selectLocale, selectServiceProduct } from '@lib/core/service/selectors';
import { store } from '@lib/core/service/store';
import { PREFERENCES_URL_PARAM, PRODUCT_CATEGORY_URL_PARAM } from '@lib/tools/shared/helpers/consts';
import { QUIZ_SLUG_NO_PREFERENCES } from '@lib/tools/shared/utils/quizzes/consts';

/**
 * Useful filters to perform quick conditional operations based on the current quiz's attributes
 */

export const isQuizForProductCategory = (quiz: TQuiz): boolean => {
  const state = store.getState();
  const { search } = window.location;

  const productCategoryFromState = selectServiceProduct(state);
  const productCategoryFromUrl = new URLSearchParams(search).get(PRODUCT_CATEGORY_URL_PARAM);
  const productCategory = productCategoryFromUrl || productCategoryFromState;
  return quiz.product_categories.some((pc: TQuizProductCategory) => pc.name === productCategory);
};

// ? @pmi
export const isQuizSlugEcommerce = (quiz: TQuiz): boolean => quiz.slug.includes('-ecomm');

// ? @pmi
export const isQuizSlugWithLocale = (quiz: TQuiz): boolean => {
  const locale = selectLocale(store.getState());
  return quiz.slug.endsWith(`-${locale}`) && isQuizForProductCategory(quiz);
};

export const isQuizSlugUserPreference = (quiz: TQuiz): boolean => {
  const { search } = window.location;
  const quizPreferencesFromUrl = new URLSearchParams(search).get(PREFERENCES_URL_PARAM);
  const quizSlug = quizPreferencesFromUrl
    ? quiz.slug.includes(quizPreferencesFromUrl)
    : quiz.slug.includes(QUIZ_SLUG_NO_PREFERENCES);
  return quizSlug;
};

export const isAnswerTagProductCategoryContext = (tag: TQuizAnswerTag): boolean =>
  tag.context === PRODUCT_CATEGORY_TAG_CONTEXT;

export const isAnswerTagFilterCharacterContext = (tag: TQuizAnswerTag): boolean =>
  tag.context === FILTER_CHARACTERS_TAG_CONTEXT;

export const isAnswerTagProductPreferencesContext = (tag: TQuizAnswerTag): boolean =>
  tag.context === FILTER_PRODUCT_PREFERENCES_TAG_CONTEXT;

export const isAnswerTagRedirectRouteContext = (tag: TQuizAnswerTag): boolean =>
  tag.context === FILTER_REDIRECT_ROUTE_TAG_CONTEXT;
