import { TProductInstance } from '@lib/core/products/types';
import { selectQuizViewAnswerTags } from '@lib/core/quizzes/selectors';
import { selectServiceProduct } from '@lib/core/service/selectors';
import { store } from '@lib/core/service/store';
import {
  isGprlBestMatch,
  isGprlNotHidden,
  isGprlSentiaTereaAssociation,
  isProductDimensions,
  isProductSentia,
  isProductStandard,
} from '@lib/tools/shared/pmi/products/filters';
import { recommendBlends } from '@lib/tools/shared/pmi/products/recommender/blends';
import { recommendTerea } from '@lib/tools/shared/pmi/products/recommender/terea';
import { recommendVeev } from '@lib/tools/shared/pmi/products/recommender/veev';
import {
  isQuizAnswerTagContextBlendBase,
  isQuizAnswerTagContextFilterProduct,
  isQuizAnswerTagContextShowPage,
  isQuizAnswerTagLauSentia,
  isQuizAnswerTagRecommendSentia,
} from '@lib/tools/shared/pmi/quizzes/filters';
import { TEREA, VEEV } from '@lib/tools/shared/pmi/retailers/consts';

export const recommendProducts = (retailerLocationProducts = []): TProductInstance[] => {
  const state = store.getState();
  const answerTags = selectQuizViewAnswerTags(state);
  const productCategory = selectServiceProduct(state);
  const sentiaProducts = retailerLocationProducts.filter(isProductSentia);
  const sentiaBestMatch = sentiaProducts.find(isGprlBestMatch);

  // Remove hidden and best match products and sentia_terea_association product
  let availableGprls = retailerLocationProducts.filter(isGprlNotHidden);
  if (answerTags.some(isQuizAnswerTagLauSentia) && answerTags.some(isQuizAnswerTagRecommendSentia)) {
    availableGprls = availableGprls.filter(gprl => !isGprlSentiaTereaAssociation(gprl, sentiaBestMatch));
  }
  if (answerTags.some(isQuizAnswerTagContextShowPage) || answerTags.some(isQuizAnswerTagContextFilterProduct)) {
    availableGprls = availableGprls.filter(gprl => !isGprlBestMatch(gprl));
  }

  const isBlendsFlow = answerTags.some(isQuizAnswerTagContextBlendBase);

  if (availableGprls.length) {
    if (isBlendsFlow) {
      return recommendBlends(availableGprls, answerTags);
    }
    switch (productCategory) {
      case TEREA:
        return recommendTerea(availableGprls, answerTags);
      case VEEV:
        const recommendedProducts = recommendVeev(availableGprls, answerTags);

        if (!recommendedProducts.length) return availableGprls.slice(0, 2);

        if (recommendedProducts.length === 1) {
          const deduplicatedProduct = availableGprls.find(
            gprl => gprl.identifier !== recommendedProducts[0].identifier,
          );
          return [recommendedProducts[0], deduplicatedProduct];
        }

        return recommendedProducts;
      default:
        // HEETS
        const standardProducts = availableGprls.filter(isProductStandard);
        const dimensionsProduct = availableGprls.find(isProductDimensions);

        return dimensionsProduct
          ? [standardProducts[0], standardProducts[1], dimensionsProduct]
          : [availableGprls[0], availableGprls[1]];
    }
  }

  return availableGprls;
};
