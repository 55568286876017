import cn from 'classnames';
import React, { FC, Fragment } from 'react';
import { Col, Row } from 'reactstrap';

import IconSelectedImage from '@app/pmi/src/assets/media/legacy/icons/select_active_variant2.svg';

import { useAddons } from '@lib/tools/views/hooks';

interface Props {
  answers: Record<
    string,
    {
      image?: string;
      text: string;
    }
  >[];
  handleSelectAnswerId: (asnwerId: string) => void;
}

const QuestionAnswers: FC<Props> = ({ answers, handleSelectAnswerId }) => {
  const { isHealthWarningFooterToIsraelAddon } = useAddons();
  const isAnswersMoreThanFour = answers.length > 4;
  return (
    <Fragment>
      <Row
        className={cn('mx-auto taste-advisor-3-qds2001-question-answers justify-content-center', {
          'four-answers': !isAnswersMoreThanFour,
          'padding-bottom-with-israel-footer': isHealthWarningFooterToIsraelAddon,
        })}
      >
        {answers.map(answer => {
          const answerId = Object.keys(answer)[0];
          return (
            <Col
              key={answerId}
              className="ta-taste-advisor-3-one-select-answer-btn px-0 d-flex flex-column align-items-center"
              md={2}
              xs={6}
            >
              <Row className="image-box mx-auto">
                <img alt="selected" className="tick" src={IconSelectedImage} />
                <button
                  className="image"
                  style={{ background: `url(${answer[answerId].image || ''}) no-repeat` }}
                  type="button"
                  onClick={() => {
                    handleSelectAnswerId(answerId);
                  }}
                />
              </Row>
              <Row className="mx-auto">
                <button
                  className="answer-ta-button ta-pmi3-p1_1 justify-content-center"
                  type="button"
                  onClick={() => {
                    handleSelectAnswerId(answerId);
                  }}
                >
                  {answer[answerId].text}
                </button>
              </Row>
            </Col>
          );
        })}
      </Row>
    </Fragment>
  );
};

export default React.memo(QuestionAnswers);
