import React, { FC } from 'react';
import { Row } from 'reactstrap';

interface Props {
  answers: Record<
    string,
    {
      image?: string;
      text: string;
    }
  >[];
  handleSelectAnswerId: (asnwerId: string) => void;
}

const QuestionAnswers: FC<Props> = ({ answers, handleSelectAnswerId }) => {
  const additionalFakeAnswers = new Array(4 - (answers.length % 4)).fill(null);

  return (
    <Row className="mx-auto taste-advisor-4-three-question-answers">
      {answers.map(answer => {
        const answerId = Object.keys(answer)[0];
        return (
          <div key={answerId} className="ta-jp-03-select-answer-btn px-0">
            <Row className="image-box mx-0">
              <button
                className="image"
                style={{ background: `url(${answer[answerId].image || ''}) no-repeat` }}
                type="button"
                onClick={() => handleSelectAnswerId(answerId)}
              />
            </Row>
          </div>
        );
      })}
      {window.screen.width >= 1366 &&
        additionalFakeAnswers.map((_, i) => (
          <div
            key={i} // @todo check it later
            className="ta-jp-03-select-answer-btn px-0 fake"
          >
            <Row className="image-box mx-0" />
          </div>
        ))}
    </Row>
  );
};

export default React.memo(QuestionAnswers);
