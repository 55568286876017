import classNames from 'classnames';
import React, { FC, useCallback, useEffect, useState } from 'react';
import { Col, Container, Row } from 'reactstrap';

import { ReactComponent as ArrowIcon } from '@app/pmi/src/assets/media/arrow.svg';

import { AGE_CONFIRMED } from '@lib/core/service/consts';
import { localeLegacy } from '@lib/tools/locale/source/pmi/legacy';
import LocaleFragment from '@lib/tools/locale/views/LocaleFragment';
import { useTypedSelector } from '@lib/tools/views/hooks';

import { calculateAge } from '@components/pmi/src/utils';

/* ------------ MONTH DROPDOWN ------------ */
interface MonthPropsType {
  value: number;
  label: null | string;
}

interface MonthDropdownPropsType {
  month: MonthPropsType;
  setMonth: (item: MonthPropsType) => void;
}

export const MonthDropdown: FC<MonthDropdownPropsType> = ({ month, setMonth }) => {
  const monthArr = [
    { label: <LocaleFragment message={localeLegacy.ageGate.january} />, value: 1 },
    { label: <LocaleFragment message={localeLegacy.ageGate.february} />, value: 2 },
    { label: <LocaleFragment message={localeLegacy.ageGate.march} />, value: 3 },
    { label: <LocaleFragment message={localeLegacy.ageGate.april} />, value: 4 },
    { label: <LocaleFragment message={localeLegacy.ageGate.may} />, value: 5 },
    { label: <LocaleFragment message={localeLegacy.ageGate.june} />, value: 6 },
    { label: <LocaleFragment message={localeLegacy.ageGate.july} />, value: 7 },
    { label: <LocaleFragment message={localeLegacy.ageGate.august} />, value: 8 },
    { label: <LocaleFragment message={localeLegacy.ageGate.september} />, value: 9 },
    { label: <LocaleFragment message={localeLegacy.ageGate.october} />, value: 10 },
    { label: <LocaleFragment message={localeLegacy.ageGate.november} />, value: 11 },
    { label: <LocaleFragment message={localeLegacy.ageGate.december} />, value: 12 },
  ];

  const [isVisible, setIsVisible] = useState(false);

  const handleChangeMonth = useCallback(
    item => {
      setMonth(item);
    },
    [setMonth],
  );

  const choseMonth = useCallback(item => {
    handleChangeMonth(item);
    setIsVisible(false);
  }, []);

  return (
    <div className={`ta-pmi3-p5-bold dropdown ${isVisible ? 'open' : ''}`}>
      <button className="dropdown-toggle" type="submit" onClick={() => setIsVisible(!isVisible)}>
        {month.label || <LocaleFragment message={localeLegacy.ageGate.month} />}
        <ArrowIcon
          className={classNames('toggle-icon', {
            open: isVisible,
          })}
        />
      </button>
      <div className="dropdown-menu ta-pmi3-p5-bold">
        {monthArr.map(item => (
          <button
            key={item.value}
            type="button"
            value={item.value}
            onClick={() => {
              choseMonth(item);
            }}
            onKeyDown={() => {
              choseMonth(item);
            }}
          >
            {item.label}
          </button>
        ))}
      </div>
    </div>
  );
};

/* ------------ YEAR DROPDOWN ------------ */
interface YearDropdownPropsType {
  year: number;
  setYear: (item: number) => void;
}

export const YearDropdown: FC<YearDropdownPropsType> = ({ year, setYear }) => {
  const currentYear = new Date().getUTCFullYear();
  const years = Array(currentYear - (currentYear - 101)).fill('');

  const [isVisible, setIsVisible] = useState(false);

  const handleChangeYear = useCallback(
    item => {
      setYear(item);
    },
    [setYear],
  );

  const choseYear = useCallback(value => {
    handleChangeYear(currentYear - value);
    setIsVisible(false);
  }, []);

  return (
    <div className={`ta-pmi3-p5-bold dropdown ${isVisible ? 'open' : ''}`}>
      <button className="dropdown-toggle" type="button" onClick={() => setIsVisible(!isVisible)}>
        {year || <LocaleFragment message={localeLegacy.ageGate.year} />}
        <ArrowIcon
          className={classNames('toggle-icon', {
            open: isVisible,
          })}
        />
      </button>
      <div className="dropdown-menu ta-pmi3-p5-bold">
        {/* @todo item is never used. It may be an issue & item is bad name */}
        {years.map((_item, index) => (
          <button
            key={index}
            type="button"
            value={currentYear - index}
            onClick={() => {
              choseYear(index);
            }}
            onKeyDown={() => {
              choseYear(index);
            }}
          >
            <p>{currentYear - index}</p>
          </button>
        ))}
      </div>
    </div>
  );
};

interface Props {
  handleUpdateCurrentAgeConfirmedValue: () => void;
}

const TasteAdvisorAgeGate: FC<Props> = ({ handleUpdateCurrentAgeConfirmedValue }) => {
  const [inputMonth, setInputMonth] = useState<MonthPropsType>({ label: null, value: new Date().getMonth() });
  const [inputYear, setInputYear] = useState<number>(null as any);
  const [ageGate, setAgeGate] = useState<boolean>(false);
  const [isBtnDisabled, setIsBtnDisabled] = useState<boolean>(true);
  const [isBlocked, setIsBlocked] = useState<boolean>(false);
  const ageLimit = useTypedSelector(state => state.retailer?.data?.tags?.age_gate_limit || '18');

  const ageConfirmedFromLocalStorage = localStorage.getItem(AGE_CONFIRMED);

  const checkAge = useCallback(() => {
    const age = calculateAge(inputYear, inputMonth.value) >= +ageLimit;
    setAgeGate(age);
    setIsBlocked(!age);
  }, [inputYear, inputMonth.value]);

  useEffect(() => {
    if (ageGate) {
      localStorage.setItem(AGE_CONFIRMED, JSON.stringify(true));
      handleUpdateCurrentAgeConfirmedValue();
    }
  }, [ageGate, ageConfirmedFromLocalStorage]);

  useEffect(() => {
    setIsBtnDisabled(!(inputMonth.value && inputYear));
  }, [inputMonth, inputYear]);

  return (
    <Container className="tasteAdvisor-gate">
      <Row className="text-center">
        <Col className="popup w-100vw h-100vh">
          {isBlocked ? (
            <Row className="text-title mx-auto">
              <p className="mb-0">
                <LocaleFragment message={localeLegacy.ageGate.accessBlocked} options={{ ageLimit }} />
              </p>
            </Row>
          ) : (
            <div>
              <Row className="text-title mx-auto">
                <p className="mb-0">
                  <LocaleFragment message={localeLegacy.ageGate.titleOne} options={{ ageLimit }} />
                </p>
                <p className="mx-auto mb-0">
                  <LocaleFragment message={localeLegacy.ageGate.titleTwo} />
                </p>
              </Row>
              <Row className="justify-content-center margin-top-30px">
                <div className="margin-right-38px">
                  <MonthDropdown month={inputMonth} setMonth={setInputMonth} />
                </div>
                <div className="margin-right-38px">
                  <YearDropdown setYear={setInputYear} year={inputYear} />
                </div>
                <button
                  className="ta-pmi3-p5-bold confirm-btn"
                  disabled={isBtnDisabled}
                  type="button"
                  onClick={checkAge}
                >
                  <LocaleFragment message={localeLegacy.ageGate.confirmBtn} />
                </button>
              </Row>
              <Col className="ta-pmi3-p6 text-color-white text-center margin-top-30px">
                <p>
                  <LocaleFragment message={localeLegacy.ageGate.descriptionOne} />
                </p>
                <p>
                  <LocaleFragment message={localeLegacy.ageGate.descriptionTwo} />
                </p>
              </Col>
            </div>
          )}
        </Col>
      </Row>
    </Container>
  );
};

export { TasteAdvisorAgeGate as TasteAdvisorAgeGateWithoutMemo };
export default React.memo(TasteAdvisorAgeGate);
