// ! service/views
export const B2B_CIRCULAR_COLORS = {
  beer: {
    external: '#F5BC7B',
    internal: '#EB7F00',
  },
  coffee: {
    external: '#867C7B',
    internal: '#150400',
  },
  neutral: {
    external: '#8DCFAD',
    internal: '#24A360',
  },
  wine: {
    external: '#CD7BAB',
    internal: '#9E005D',
  },
};

export const WIDGET_COLORS = {
  darkTheme: { backgroundColor: '#565E70', color: '#F9F9FA', itemsColor: '#FFFFFF', name: 'dark' },
  lightTheme: { backgroundColor: '#F4F4F2', itemsColor: '#232021', name: 'light' },
};

export const PRIVACY_PAGE_BACKGROUND_COLOR = '#565E70';
export const LOGO_FILL_COLORS = { dark: '#FFFFFF', light: '#232021' };
// lead page and policy acceptance
// ! service/terms
export const VINHOOD_PRIVACY_POLICY_PERMISSION = 'permission_flag_privacy_policy';
export const VINHOOD_MARKETING_PERMISSION = 'permission_flag_marketing';
export const RETAILER_POLICY_ACCEPTANCE_AGREEMENT = 'permission_flag_partner_privacy_policy';
export const SAVED_EMAIL = 'email';
export const THIRD_PARTY_CATEGORY = 'marketing';

// ! core/quiz
// expert quiz preferences
export const EXPERT_QUIZ_PREFERENCE_SLUG = 'expert-quiz-preference';
export const TASTE_QUIZ_PREFERENCE_SLUG = 'taste-quiz-preference';
