import { createSelector } from 'reselect';

import { TQuizAnswerTag, TQuizQuestion } from '@lib/core/quizzes/types';
import { IQuizView, TQuizViewUserPath } from '@lib/core/quizzes/types/quizView';

/**
 * @returns the whole quizView state
 */
const selectQuizViewState = (state): IQuizView => state.quizView || {};

/**
 * @returns the list of tags from the submitted answers
 */
export const selectQuizViewAnswerTags: (state) => TQuizAnswerTag[] = createSelector(
  [selectQuizViewState],
  quizView => quizView.answerTags || [],
);

/**
 * @returns the user's path during the quiz
 */
export const selectQuizViewUserPath: (state) => TQuizViewUserPath = createSelector(
  [selectQuizViewState],
  quizView => quizView.userPath || [],
);

/**
 * @returns if the last user action was navigating back in the quiz graph
 */
export const selectQuizViewIsBackward: (state) => boolean = createSelector(
  [selectQuizViewState],
  quizView => quizView.isBackward || false,
);

/**
 * @returns if the quiz is currently paused
 */
export const selectQuizViewIsPaused: (state) => boolean = createSelector(
  [selectQuizViewState],
  quizView => quizView.isPaused || false,
);

/**
 * @returns the path on which the quiz was paused
 */
export const selectQuizViewPausedRoute: (state) => string = createSelector(
  [selectQuizViewState],
  quizView => quizView.pausedRoute || '',
);

/**
 * @returns the paused question id
 */
export const selectQuizViewPausedQuestionId: (state) => string = createSelector(
  [selectQuizViewState],
  quizView => quizView.pausedQuestionId || '',
);

/**
 * * To be used only for the DevTools QuizPanel.
 * @returns the previous question id
 */
export const selectQuizViewPreviousQuestionId: (state) => string = createSelector(
  [selectQuizViewState],
  quizView => quizView.previousQuestionId || '',
);

/**
 * @returns the current depth in the `TQuizPath` graph. `null` if `QuizView` has no quiz
 */
export const selectQuizViewDepth: (state) => number = createSelector(
  [selectQuizViewState],
  quizView => quizView.currentDepth,
);

/**
 * @returns an object containing all the graph nodes' depths
 */
export const selectQuizViewQuestionDepths: (state) => Record<string, number> = createSelector(
  [selectQuizViewState],
  quizView => quizView.questionsDepths || {},
);

/**
 * @returns the traversed quiz path
 */
export const selectQuizViewIsCompleted: (state) => boolean = createSelector(
  [selectQuizViewState],
  quizView => quizView.isCompleted || false,
);

/**
 * @returns the current question's data
 */
export const selectQuizViewQuestionData: (state) => TQuizQuestion = createSelector(
  [selectQuizViewState],
  quizView => quizView.viewData || null,
);

/**
 * @returns the current question's id
 */
export const selectQuizViewQuestionId: (state) => string = createSelector(
  [selectQuizViewState],
  quizView => quizView.questionId || '',
);

/**
 * @returns flag for botanicals if a new user quiz needs to be posted when the gears are reopened.
 */

export const selectQuizViewIsPostNewUserQuiz: (state) => boolean = createSelector(
  [selectQuizViewState],
  quizView => quizView.isPostNewUserQuiz || false,
);
